import { openDb, UpgradeDB } from 'idb';
import { IUserToken } from '../@types/model/right';
import { IFileQueue } from '../@types/viewmodel/file';
import generalFunctions from '../store/general/functions';

const SESSION_NAME = 'zz2-security-session';
const SESSION_KEY = 'zz2-security-token';

const FILE_NAME = 'zz2-files';

export default class LocalStorageService {
    private static sessionCallback : (userToken : IUserToken | null) => void;

    public static async getLocalStorageToken() {
        let session : string | null = null;
        if (self.indexedDB) {
            session = await this.getSessionIndexedDB();
        } else if (self.localStorage) {
            session = this.getSessionLocalStorage();
        }

        if (session) {
            return session;
        } else {
            return null;
        }
    }

    public static async setLocalStorageSession(userToken : IUserToken | null) {
        if (!!self.indexedDB) {
            await this.setSessionIndexedDB(userToken);
        } else if (!!self.localStorage) {
            this.setSessionLocalStorage(userToken);
        }

        if (this.sessionCallback) {
            this.sessionCallback(userToken);
        }
    }

    private static setSessionLocalStorage(userToken : IUserToken | null) {
        if (!!userToken) {
            localStorage.setItem(SESSION_KEY, JSON.stringify(userToken.token));
        } else {
            localStorage.removeItem(SESSION_KEY);
        }
    }

    private static getSessionLocalStorage() : string | null {
        const session = localStorage.getItem(SESSION_KEY);

        if (session) {
            return JSON.parse(session);
        } else {
            return null;
        }
    }

    /**
     * Creates all object stores up to the current DB version. i.e. for version 2, this function will execute for versions
     * 0, 1 and 2.
     * @param db
     */
    private static upgradeDb(db : UpgradeDB) {
        switch (db.oldVersion) {
            case 0:
                if (!db.objectStoreNames.contains(SESSION_NAME)) {
                    db.createObjectStore<string, string>(SESSION_NAME);
                }
            case 1:
                if (!db.objectStoreNames.contains(FILE_NAME)) {
                    db.createObjectStore<IFileQueue, string>(FILE_NAME);
                }
        }
    }

    /**
     * Sets the auth session. If no session is specified, deletes the existing entry.
     * @param userToken The session.
     */
    private static async setSessionIndexedDB(userToken : IUserToken | null) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), this.upgradeDb);

        const tx = db.transaction(SESSION_NAME, 'readwrite');

        const store = tx.objectStore(SESSION_NAME);

        if (!userToken) {
            await store.delete(SESSION_KEY);
        } else {
            await store.delete(SESSION_KEY);
            await store.add(userToken.token, SESSION_KEY);
        }
        await tx.complete;
    }

    /**
     * Opens the DB and retrieves the current auth session.
     */
    private static async getSessionIndexedDB() {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), this.upgradeDb);

        const tx = db.transaction(SESSION_NAME, 'readonly');

        const result = await tx.objectStore<string>(SESSION_NAME).get(SESSION_KEY);

        await tx.complete;

        return result;
    }

    /**
     * Specifies the callback that will be fired whenever the auth session undergoes a change.
     * @param callback
     */
    public static async onSessionChanged(callback : (userToken : IUserToken | null) => void) {
        this.sessionCallback = callback;
    }

    public static async setQueueFile(file : IFileQueue) {
        if (!!self.indexedDB) {
            this.setQueueFileIndexedDB(file);
        } else {
            generalFunctions.showWarningSnackbar('Files will not presist offline.');
        }
    }

    private static async setQueueFileIndexedDB(file : IFileQueue) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), this.upgradeDb);

        const tx = db.transaction(FILE_NAME, 'readwrite');
        const store = tx.objectStore(FILE_NAME);

        await store.delete(file.guid);
        await store.add(file, file.guid);

        await tx.complete;
    }

    public static async deleteQueueFile(guid : string) {
        if (!!self.indexedDB) {
            this.deleteQueueFileIndexedDB(guid);
        } else {
            generalFunctions.showWarningSnackbar('Files will not presist offline.');
        }
    }

    private static async deleteQueueFileIndexedDB(guid : string) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), this.upgradeDb);

        const tx = db.transaction(FILE_NAME, 'readwrite');
        const store = tx.objectStore(FILE_NAME);

        await store.delete(guid);

        await tx.complete;
    }

    /**
     * Gets all queue files not sent/compeleted processing. Also removes all files that were processed.
     *
     * @export
     */
    public static getNotCompletedQueueFiles() {
        if (!!self.indexedDB) {
            this.clearProcessedQueueFilesIndexedDB();
            return this.getQueueFilesIndexedDB();
        } else {
            generalFunctions.showWarningSnackbar('Files will not presist offline.');
        }

        return [];
    }
    private static async getQueueFilesIndexedDB() {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), this.upgradeDb);

        const tx = db.transaction(FILE_NAME, 'readonly');

        const files = await tx.objectStore<IFileQueue>(FILE_NAME).getAll();

        await tx.complete;

        const result = files.filter(n => !n.completed);

        return result;
    }

    private static async clearProcessedQueueFilesIndexedDB() {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), this.upgradeDb);

        const tx = db.transaction(FILE_NAME, 'readwrite');

        const files = await tx.objectStore<IFileQueue>(FILE_NAME).getAll();

        for (const file of files) {
            if (file.completed) {
                await tx.objectStore<IFileQueue>(FILE_NAME).delete(file.guid);
            }
        }

        await tx.complete;
    }
}
