import { Button, ButtonProps } from '@material-ui/core';
import React from 'react';

interface IStadiumButtonProps extends ButtonProps {
    icon? : React.ReactNode;
}

interface IStadiumButtonState {}

export default class StadiumButton extends React.PureComponent<IStadiumButtonProps, IStadiumButtonState> {
    constructor(props : IStadiumButtonProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { icon, className, children, ...rest } = this.props;
        return (
            <Button className={`h40 br25 bcy standard-box pl15 pr15 ${className ?? ''}`} {...rest}>
                {
                    icon &&
                    <div className='aii jci pr15'>
                        {
                            icon
                        }
                    </div>
                }
                {
                    children
                }
            </Button>
        );
    }
}
