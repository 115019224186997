import { IVirusIncident } from '../../../@types/model/incident';
import { IRootState, RootAction, DispatchCall } from '../../../@types/redux';
import React from 'react';
import { connect } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import DateRange from '../custom/DateRange';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import AccordionActions from '@material-ui/core/AccordionActions';
import Button from '@material-ui/core/Button';
import { orange } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Card from '@material-ui/core/Card';
import IncidentFunctions from '../../../store/incident/functions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { CustomMouseEvent } from '../../../@types/helpers';
import Toolbar from '@material-ui/core/Toolbar';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import MaterialTable from '../custom/materialTable/Table';
import { RouteComponentProps, withRouter } from 'react-router';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Dispatch, bindActionCreators } from 'redux';
import incidentActions from '../../../store/incident/actions';
import VirusIncidentInfoDialog from './dialog/VirusIncidentInfoDialog';
import { Transitions } from '../custom/animations/Transitions';
import { IDivision } from '../../../@types/model/division';
import DivisionDropdown from '../custom/dropdowns/DivisionDropdown';

interface IIncidentListProps extends RouteComponentProps<{
    guid : string;
}, {}, {}> {
    incidents : Array<IVirusIncident>;
    isLoading : boolean;

    setIncident : DispatchCall<IVirusIncident | undefined>;
}

interface IIncidentListState {
    expandSearch : boolean;

    startDate : moment.Moment;
    endDate : moment.Moment;

    person : string;

    division? : IDivision;
}

class IncidentList extends React.Component<IIncidentListProps, IIncidentListState> {
    constructor(props : IIncidentListProps) {
        super(props);

        this.state = {
            expandSearch: true,
            startDate: moment.utc().startOf('day'),
            endDate: moment.utc().startOf('day'),
            person: '',
        };
    }

    public componentDidMount = () => {
        this.loadData(this.state.startDate, this.state.startDate).then(() => {
            const guid = this.props.location.search.substr(this.props.location.search.indexOf('=') + 1);
            if (guid) {
                // TODO: Get Selected
            }
        });

    }

    private loadData = async (startDate : moment.Moment, endDate : moment.Moment) => {
        await IncidentFunctions.getAllVirusIncidents(startDate.valueOf(), endDate.valueOf());
    }

    private onSearchExpansionChange = (e : React.ChangeEvent<{}>, expanded : boolean) => {
        this.setState({
            expandSearch: expanded,
        });
    }

    private onDateRangeChanged = (start : moment.Moment, end : moment.Moment) => {
        this.setState({
            startDate: start,
            endDate: end,
        });
        this.loadData(start, end);
    }

    private onClearSearch = () => {
        this.setState({
            startDate: moment.utc().startOf('day'),
            endDate: moment.utc().startOf('day'),
        }, () => {
            this.onSearch();
        });
    }

    private onSearch = () => {
        this.loadData(this.state.startDate, this.state.endDate);
    }

    private onIncidentClick = (e : CustomMouseEvent) => {
        const selected = this.props.incidents.find(n => n.id === Number(e.currentTarget.value));

        this.props.setIncident(selected);
    }

    private onPersonChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            person: event.currentTarget.value,
        });
    }

    private onDivisionChange = (division : IDivision | undefined) => {
        this.setState({
            division,
        });
    }

    private getData = (state : IIncidentListState, props : IIncidentListProps) => props.incidents;
    private getPerson = (state : IIncidentListState, props : IIncidentListProps) => state.person;
    private getDivision = (state : IIncidentListState, props : IIncidentListProps) => state.division;
    private getIncidents = createSelector(
        [this.getData, this.getPerson, this.getDivision],
        (incidents, person, division) => {
            return incidents.filter(n => (!person || (n.person?.initials && n.person?.initials.toLowerCase().includes(person))
                                                  || (n.person?.name && n.person?.name.toLowerCase().includes(person))
                                                  || (n.person?.employeeNumber && n.person?.employeeNumber.toLowerCase().includes(person))
                                                  || (n.person?.surname && n.person?.surname.toLowerCase().includes(person))) &&
                                        (!division || (n.accessPoint?.division?.code ?? n.divisionCode) === division.code));
        },
    );

    public render = () => {
        const { expandSearch, startDate, endDate, person, division } = this.state;
        const { isLoading } = this.props;

        const incidents = this.getIncidents(this.state, this.props);
        return (
            <div className={'flx1 fdc p5'}>
                <Card className={'flx1 fdc'}>
                    <Accordion expanded={expandSearch} onChange={this.onSearchExpansionChange} >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant='h6'>SEARCH</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={'flx1 fdr aic'}>
                                <div className={'aic p5 mb10 pr20'}>
                                    <DateRange onChange={this.onDateRangeChanged} start={startDate} end={endDate} />
                                </div>
                                <div className={'aic p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            style={{
                                                width: '250px',
                                            }}
                                            autoComplete='off'
                                            id='reference'
                                            label='Person'
                                            value={person}
                                            onChange={this.onPersonChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'aic p5 mb10 pr20'}>
                                    <DivisionDropdown onChange={this.onDivisionChange} value={division} />
                                </div>
                            </div>
                        </AccordionDetails>
                        <Divider />
                        <AccordionActions>
                            <Button style={{ backgroundColor: orange[600] }} color='primary' variant='contained' onClick={this.onClearSearch}>
                                <Icon style={{ marginRight: 10 }}>highlight_off</Icon>
                                Clear
                            </Button>
                            <Button variant='contained' onClick={this.onSearch}>
                                <Icon style={{ marginRight: 10 }}>search</Icon>
                                SEARCH
                            </Button>
                        </AccordionActions>
                    </Accordion>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            Covid-19 Incidents
                        </Typography>
                        <IconButton color='primary' onClick={this.onSearch}>
                            <Tooltip title='Refresh'>
                                <Icon>
                                    refresh
                                </Icon>
                            </Tooltip>
                        </IconButton>
                        <span className={'flx1'} />
                        <div className={'fdr pt5 jcfe aic'}>
                            <div className={'fdr pt5'}>
                                <span style={{ paddingRight: '15px' }}><b>Total: </b>{incidents.length}</span>
                            </div>
                        </div>
                    </Toolbar>
                    <MaterialTable<IVirusIncident>
                        id={'incidentTable'}
                        data={incidents}
                        isLoading={isLoading}
                        rowsPerPage={20}
                        columns={[
                            {
                                header: '',
                                width: '60px',
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Info'>
                                            <IconButton value={`${row.id}`} onClick={this.onIncidentClick}>
                                                <Icon>
                                                    info
                                                </Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                ),
                            },
                            {
                                header: 'Date',
                                field: 'date',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (<span>{moment.utc(row.date).local().format('ddd YYYY-MM-DD')}</span>);
                                },
                            },
                            {
                                header: 'Time',
                                field: 'date',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (<span>{moment.utc(row.date).local().format('HH:mm')}</span>);
                                },
                            },
                            {
                                header: 'Person',
                                field: 'person.name',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                        <Tooltip title={!row.identifier ? 'Unknown' : row.identifier}>
                                            <div >
                                                {
                                                    !!row.person &&
                                                    <span>
                                                        {
                                                            !!row.person.initials && !row.person.name && !!row.person.surname &&
                                                            `${row.person.initials} ${row.person.surname}`
                                                        }
                                                        {
                                                            !!row.person.name && !!row.person.surname &&
                                                            `${row.person.name} ${row.person.surname}`
                                                        }
                                                        {
                                                            !row.person.initials && !row.person.surname && !!row.person.name &&
                                                            row.person.name
                                                        }
                                                        {
                                                            !row.person.name && !row.person.initials && !row.person.surname &&
                                                            row.identifier
                                                        }
                                                    </span>
                                                }
                                                {
                                                    !row.person &&
                                                    <span>
                                                        {row.identifier}
                                                        <Icon className='cpr'>
                                                            warning
                                                        </Icon>
                                                    </span>
                                                }
                                            </div>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                header: 'Division',
                                field: 'divisionCode',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                        <span>{row.accessPoint?.division?.description ?? row.divisionName}</span>
                                    );
                                },
                            },
                            {
                                header: 'Created By',
                                field: 'createdByName',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                    <Tooltip title={row.createdBy}>
                                        <span>{!row.createdByName ? row.createdBy : row.createdByName.toTitleCase()}</span>
                                    </Tooltip>);
                                },
                            },

                        ]}
                    />
                </Card>
                <VirusIncidentInfoDialog maxWidth='md' fullWidth fullScreen transition={Transitions.Up} />
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        incidents: state.incident.covid19Incidents,
        isLoading: state.incident.isLoadingCovid19Incidents,
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => bindActionCreators({
    setIncident: incidentActions.setSelectedVirusIncident,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(IncidentList));
