import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import moment, { Moment } from 'moment';
import { IIncident, IIncidentCategory, IIncidentIncidentCategory } from '../../../../../@types/model/incident';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import lodash from 'lodash';
import IncidentFunctions from '../../../../../store/incident/functions';
import { DatePicker } from '@material-ui/pickers/DatePicker';
import { KeyboardTimePicker } from '@material-ui/pickers/TimePicker';
import IncidentCategoryMultiDropdown from '../../../custom/dropdowns/IncidentCategoryMultiDropdown';
import PoachingMethodDropdown from '../../../custom/dropdowns/PoachingMethodDropdown';
import HousebreakingEntryPointsDropdown from '../../../custom/dropdowns/HousebreakingEntryPointsDropdown';
import WeatherConditionsDropdown from '../../../custom/dropdowns/WeatherConditionsDropdown';
import MultiFileDropZone from '../../../custom/file/MultiFileDropZone';
import { IFileUpload } from '../../../../../@types/viewmodel/file';
import FileFunctions from '../../../../../store/file/functions';
import { EnumFileCategory } from '../../../../../@types/model/base';

interface IIncidentInfoEditDialogProps {
    incident : IIncident;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IIncidentInfoEditDialogState {
    open : boolean;

    incidentDate : Moment;
    incidentTime : Moment;
    incidentPlace : string;
    incidentInjury : string;
    incidentValue : string;
    incidentType : string;
    incidentDescription : string;
    incidentCategories : Record<string, string>;
    incidentOutcome : string;
    poachingMethods : Array<string>;
    houseBreakingEntryPoints : Array<string>;
    weatherConditions : Array<string>;
    roadCondition : string;

    incidentFiles : Array<IFileUpload>;

    actionTaken : string;
    suggestions : string;
}

export default class IncidentInfoEditDialog extends React.Component<IIncidentInfoEditDialogProps, IIncidentInfoEditDialogState> {
    constructor(props : IIncidentInfoEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            incidentDate: moment.utc().startOf('day'),
            incidentTime: moment.utc().startOf('day'),
            incidentPlace: '',
            incidentInjury: '',
            incidentValue: '',
            incidentType: '',
            incidentDescription: '' ,
            incidentOutcome: '' ,
            poachingMethods: [],
            houseBreakingEntryPoints: [],
            weatherConditions: [],
            roadCondition: '',
            incidentCategories: {},
            actionTaken: '',
            suggestions: '',
            incidentFiles: [],
        };
    }

    public componentDidUpdate = (prevProps : IIncidentInfoEditDialogProps, prevState : IIncidentInfoEditDialogState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                incidentDate: moment.utc().startOf('day'),
                incidentTime: moment.utc().startOf('day'),
                incidentPlace: '',
                incidentInjury: '',
                incidentValue: '',
                incidentType: '',
                incidentDescription: '' ,
                incidentOutcome: '' ,
                incidentCategories: {},
                poachingMethods: [],
                houseBreakingEntryPoints: [],
                weatherConditions: [],
                roadCondition: '',
                actionTaken: '',
                suggestions: '',
            });
        }
        if (!prevState.open && this.state.open && this.props.incident) {
            this.setState({
                incidentDate: !this.props.incident ? moment.utc().startOf('day') : moment.utc(this.props.incident.date),
                incidentTime: !this.props.incident ? moment.utc().startOf('day') : moment.utc(this.props.incident.time, 'HH:mm:SS'),
                incidentPlace: !this.props.incident.place ? '' : this.props.incident.place,
                incidentInjury: !this.props.incident.injuryType ? '' : this.props.incident.injuryType,
                incidentValue: !this.props.incident || !this.props.incident.value ? '' : this.props.incident.value.toString(),
                incidentType: !this.props.incident.type ? '' : this.props.incident.type,
                incidentDescription: !this.props.incident.comment ? '' : this.props.incident.comment,
                incidentOutcome: !this.props.incident.outcome ? '' : this.props.incident.outcome,
                incidentCategories: !this.props.incident.incidentCategories.length ? {} : this.props.incident.incidentCategories.reduce((prev, incidentCategory) => {
                    prev[incidentCategory.categoryGuid] = incidentCategory.categoryName;
                    return prev;
                }, {} as Record<string, string>),
                houseBreakingEntryPoints: this.props.incident.houseBreakingEntryPoints ?? [],
                poachingMethods: this.props.incident.poachingMethods ?? [],
                weatherConditions: this.props.incident.weatherConditions ?? [],
                roadCondition: this.props.incident.roadCondition ?? '',
                actionTaken: this.props.incident.actionTaken ?? '',
                suggestions: this.props.incident.suggestions ?? '',
            });
        }
    }

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private save = async () => {
        const { incidentDate, incidentTime, incidentPlace, incidentInjury, incidentValue, incidentType, incidentDescription,
            incidentCategories, incidentOutcome, poachingMethods, houseBreakingEntryPoints,
            weatherConditions, roadCondition, actionTaken, suggestions, incidentFiles } = this.state;

        const incident = lodash.cloneDeep(this.props.incident);

        if (incident) {

            incident.date = incidentDate.format('YYYY-MM-DD');
            incident.time = incidentTime.format('HH:mm:SS');
            incident.place = incidentPlace;
            incident.injuryType = incidentInjury;
            incident.value = parseFloat(incidentValue);
            incident.type = incidentType;
            incident.comment = incidentDescription;
            incident.incidentCategories = lodash.map(incidentCategories, (value, key) => ({
                categoryGuid: key,
                categoryName: value,
            } as IIncidentIncidentCategory));
            incident.outcome = incidentOutcome;
            incident.poachingMethods = !Object.values(incidentCategories).some(n => n.toLowerCase() === 'poaching') ? [] : poachingMethods;
            incident.houseBreakingEntryPoints = !Object.values(incidentCategories).some(n => n.toLowerCase() === 'housebreaking') ? [] : houseBreakingEntryPoints;
            incident.weatherConditions = !Object.values(incidentCategories).some(n => n.toLowerCase() === 'motor vehicle accident') ? [] : weatherConditions;
            incident.roadCondition = !Object.values(incidentCategories).some(n => n.toLowerCase() === 'motor vehicle accident') ? '' : roadCondition;
            incident.actionTaken = actionTaken;
            incident.suggestions = suggestions;

            const result = await IncidentFunctions.save(incident, incidentFiles, EnumFileCategory.Incident);

            if (result) {
                this.onClose();
            }
        }
    }

    private onEditClick = () => {
        this.setState({
            open: true,
        });
    }

    private onIncidentDateChange = (momentDate : Moment) => {
        this.setState({
            incidentDate: momentDate,
        });
    }

    private onIncidentTimeChange = (momentDate : Moment) => {
        this.setState({
            incidentTime: momentDate ? momentDate.asUTC() : moment.utc().add(moment.parseZone().utcOffset(), 'minutes'),
        });
    }

    private onIncidentPlaceChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            incidentPlace: event.currentTarget.value,
        });
    }

    private onIncidentInjuryChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            incidentInjury: event.currentTarget.value,
        });
    }

    private onIncidentTypeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            incidentType: event.currentTarget.value,
        });
    }

    private onIncidentValueChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.currentTarget.value && parseFloat(event.currentTarget.value) === null) return;
        this.setState({
            incidentValue: event.currentTarget.value,
        });
    }

    private onIncidentDescriptionChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            incidentDescription: event.currentTarget.value,
        });
    }

    private onIncidentOutcomeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            incidentOutcome: event.currentTarget.value,
        });
    }

    private onIncidentCategoryChange = (value : Array<IIncidentCategory>) => {
        this.setState({
            incidentCategories: value.reduce((prev, incidentCategory, index) => {
                prev[incidentCategory.guid] = incidentCategory.name;
                return prev;
            }, {} as Record<string, string>),
        });
    }

    private onPoachingMethodsChange = (value : Array<string>) => {
        this.setState({
            poachingMethods: value.slice(),
        });
    }

    private onHouseBreakingEntryPointsChange = (value : Array<string>) => {
        this.setState({
            houseBreakingEntryPoints: value.slice(),
        });
    }

    private onWeatherConditionsChange = (value : Array<string>) => {
        this.setState({
            weatherConditions: value.slice(),
        });
    }

    private onRoadConditionChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            roadCondition: event.currentTarget.value,
        });
    }

    private onActionTakenChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            actionTaken: event.currentTarget.value,
        });
    }

    private onSuggestionsChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            suggestions: event.currentTarget.value,
        });
    }

    private onFileSelected = (file : IFileUpload) => {
        const incidentFiles = this.state.incidentFiles.slice();

        const index = incidentFiles.findIndex(n => n.name === file.name);

        if (index > -1) {
            incidentFiles.splice(index, 1, file);
        } else {
            incidentFiles.push(file);
        }

        this.setState({
            incidentFiles,
        });
    }

    private onFileRemove = (file : IFileUpload) => {
        const incidentFiles = this.state.incidentFiles.slice();

        const index = incidentFiles.findIndex(n => n.name === file.name);

        if (index > -1) {
            incidentFiles.splice(index, 1);
        }

        this.setState({
            incidentFiles,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, incident } = this.props;
        const { open, incidentDate, incidentTime, incidentPlace, incidentInjury, incidentValue, incidentType, incidentDescription,
            incidentCategories, poachingMethods, incidentOutcome, houseBreakingEntryPoints,
            weatherConditions, roadCondition, actionTaken, suggestions, incidentFiles } = this.state;
        return (<React.Fragment>
            <Tooltip title='Edit'>
                <div>
                    <IconButton color='secondary' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                        <Icon>edit</Icon>
                    </IconButton>
                </div>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='incident-info-title'
                aria-describedby='incident-info-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            Edit Incident Info
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                    <DialogContent className='fdc flx1 hfill'>
                        <div className='fdc flx1'>
                            <div className='fdr aifs'>
                                <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <DatePicker fullWidth value={incidentDate} onChange={this.onIncidentDateChange} format='YYYY-MM-DD' label='Date' />
                                </div>
                                <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <KeyboardTimePicker fullWidth value={incidentTime} onChange={this.onIncidentTimeChange}
                                        ampm={false}
                                        todayLabel='now'
                                        showTodayButton
                                        required
                                        format='HH:mm' label='Time' />
                                </div>
                            </div>
                            <div className='fdr aifs'>
                                <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <IncidentCategoryMultiDropdown group={incident.group} fullWidth value={Object.keys(incidentCategories)} onChange={this.onIncidentCategoryChange} required />
                                </div>
                            </div>
                            <div className='flx1 fdr aifs'>
                                <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor='incidentValue-input'>Value</InputLabel>
                                        <Input
                                            id='incidentValue-input'
                                            type='number'
                                            value={incidentValue}
                                            onChange={this.onIncidentValueChange}
                                            error={!!incidentValue && !Number(incidentValue)}
                                            aria-describedby='incidentValue-input-helper-text'
                                        />
                                        {
                                            incidentValue && !Number(incidentValue) &&
                                            <FormHelperText error>Invalid</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel required error={!incidentPlace} htmlFor='incidentPlace-input'>Place</InputLabel>
                                        <Input
                                            id='incidentPlace-input'
                                            value={incidentPlace}
                                            onChange={this.onIncidentPlaceChange}
                                            aria-describedby='incidentPlace-input-helper-text'
                                            required
                                            error={!incidentPlace}
                                        />
                                        {
                                            !incidentPlace &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor='incidentInjury-input'>Injury Type</InputLabel>
                                        <Input
                                            id='incidentInjury-input'
                                            value={incidentInjury}
                                            onChange={this.onIncidentInjuryChange}
                                            aria-describedby='incidentInjury-input-helper-text'
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            {
                                Object.values(incidentCategories).some(n => n.toLowerCase() === 'poaching') &&
                                <div className='fdr ais'>
                                    <div className={'flx2 aife p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <PoachingMethodDropdown fullWidth value={poachingMethods} onChange={this.onPoachingMethodsChange} required />
                                    </div>
                                </div>
                            }
                            {
                                Object.values(incidentCategories).some(n => n.toLowerCase() === 'housebreaking') &&
                                <div className='fdr ais'>
                                    <div className={'flx2 aife p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <HousebreakingEntryPointsDropdown fullWidth value={houseBreakingEntryPoints} onChange={this.onHouseBreakingEntryPointsChange} required />
                                    </div>
                                </div>
                            }
                            {
                                Object.values(incidentCategories).some(n => n.toLowerCase() === 'motor vehicle accident') &&
                                <div className='fdr ais'>
                                    <div className={'flx2 aife p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <WeatherConditionsDropdown fullWidth value={weatherConditions} onChange={this.onWeatherConditionsChange} required />
                                    </div>
                                    <div className={'flx1 aife p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <FormControl fullWidth>
                                            <InputLabel required error={!roadCondition} htmlFor='roadCondition-input'>Road Condition</InputLabel>
                                            <Input
                                                id='roadCondition-input'
                                                value={roadCondition}
                                                onChange={this.onRoadConditionChange}
                                                aria-describedby='roadCondition-input-helper-text'
                                                required
                                                multiline
                                                error={!roadCondition}
                                            />
                                            {
                                                !roadCondition &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                </div>
                            }
                            <div className='fdr ais'>
                                <div className={'flx1 aifs p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel required error={!incidentType} htmlFor='incidentType-input'>Short Description</InputLabel>
                                        <Input
                                            id='incidentType-input'
                                            value={incidentType}
                                            onChange={this.onIncidentTypeChange}
                                            aria-describedby='incidentType-input-helper-text'
                                            required
                                            error={!incidentType}
                                        />
                                        {
                                            !incidentType &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                            </div>
                            <div className='flx1 fdr ais'>
                                <div className={'flx2 aife p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel required error={!incidentDescription} htmlFor='incidentDescription-input'>Detailed Description</InputLabel>
                                        <Input
                                            id='incidentDescription-input'
                                            value={incidentDescription}
                                            onChange={this.onIncidentDescriptionChange}
                                            aria-describedby='incidentDescription-input-helper-text'
                                            required
                                            multiline
                                            error={!incidentDescription}
                                        />
                                        {
                                            !incidentDescription &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                            </div>
                            <div className='fdr aifs'>
                                <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor='incidentActionTake-input'>Action Taken</InputLabel>
                                        <Input
                                            id='incidentActionTake-input'
                                            value={actionTaken}
                                            onChange={this.onActionTakenChange}
                                            aria-describedby='incidentActionTake-input-helper-text'
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor='incidentSuggestions-input'>Suggestions</InputLabel>
                                        <Input
                                            id='incidentSuggestions-input'
                                            value={suggestions}
                                            onChange={this.onSuggestionsChange}
                                            aria-describedby='incidentSuggestions-input-helper-text'
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className='flx1 fdr ais'>
                                <div className={'flx2 aife p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor='incidentOutcome-input'>Outcome</InputLabel>
                                        <Input
                                            id='incidentOutcome-input'
                                            value={incidentOutcome}
                                            onChange={this.onIncidentOutcomeChange}
                                            aria-describedby='incidentOutcome-input-helper-text'
                                            multiline
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className='flx1 fdr aifs'>
                                <div className={'flx1'}>
                                    <MultiFileDropZone accept='audio/*,video/*,image/*,application/pdf' files={incidentFiles}
                                    onFileSelected={this.onFileSelected} onFileRemoved={this.onFileRemove} />
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading || !incidentPlace || !incidentDate || !incidentTime ||
                            !incidentType || !incidentDescription || !Object.keys(incidentCategories).length} type='submit' variant='contained' color='primary'>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>);
    }
}
