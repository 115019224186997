import { createAction } from 'typesafe-actions';
import { IDivision } from '../../@types/model/division';
import { IAccessPointCategory } from '../../@types/model/masterData/accessPointCategory';
import { IDivisionType } from '../../@types/model/masterData/divisionType';
import { IGraveVisitReason } from '../../@types/model/masterData/graveVisitReason';
import { IRegion } from '../../@types/model/region';
import { IPowerSource } from '../../@types/model/masterData/powerSource';

const masterDataActions = {
    setIsLoadingDivisions: createAction('MASTER_DATA_SET_LOADING_DIVISIONS', resolve => (isLoading : boolean) => resolve(isLoading)),
    setDivisions: createAction('MASTER_DATA_SET_DIVISIONS', resolve => (list : Array<IDivision>) => resolve(list)),
    setIsLoadingRegions: createAction('MASTER_DATA_SET_LOADING_REGIONS', resolve => (isLoading : boolean) => resolve(isLoading)),
    setRegions: createAction('MASTER_DATA_SET_REGIONS', resolve => (list : Array<IRegion>) => resolve(list)),
    setIsLoadingGraveVisitReason: createAction('MASTER_DATA_SET_LOADING_GRAVE_VISIT_REASON', resolve => (isLoading : boolean) => resolve(isLoading)),
    setGraveVisitReasons: createAction('MASTER_DATA_SET_GRAVE_VISIT_REASONS', resolve => (list : Array<IGraveVisitReason>) => resolve(list)),
    setIsLoadingAccessPointCategory: createAction('MASTER_DATA_SET_LOADING_ACCESS_POINT_CATEGORY', resolve => (isLoading : boolean) => resolve(isLoading)),
    setAccessPointCategories: createAction('MASTER_DATA_SET_ACCESS_POINT_CATEGORIES', resolve => (list : Array<IAccessPointCategory>) => resolve(list)),
    setIsLoadingDivisionTypes: createAction('MASTER_DATA_SET_LOADING_DIVISION_TYPES', resolve => (isLoading : boolean) => resolve(isLoading)),
    setDivisionTypes: createAction('MASTER_DATA_SET_DIVISION_TYPES', resolve => (list : Array<IDivisionType>) => resolve(list)),
    setIsLoadingPowerSource: createAction('MASTER_DATA_SET_LOADING_POWER_SOURCE_TYPES', resolve => (isLoading : boolean) => resolve(isLoading)),
    setPowerSources: createAction('MASTER_DATA_SET_POWER_SOURCES', resolve => (list : Array<IPowerSource>) => resolve(list)),
};

export default masterDataActions;
