export const OBSERVATION_NOTIFICATION = 1;
export const INCIDENT_NOTIFICATION = 2;
export const OCCURENCE_NOTIFICATION = 3;

export const RIGHT_TO_ICON : {[key : string] : string} = {
    USER : `${ASSET_BASE}/assets/images/icons/users.svg`,
    ROLE : `${ASSET_BASE}/assets/images/icons/roles.svg`,
    ACCESSEV : `${ASSET_BASE}/assets/images/icons/access_event_vehicle.svg`,
    ACCESSEP : `${ASSET_BASE}/assets/images/icons/users.svg`,
    ACCESSP : `${ASSET_BASE}/assets/images/icons/gate_remote_controlled.svg`,
    INS_CAT : `${ASSET_BASE}/assets/images/icons/incidentCategory.svg`,
    DIV_EDIT : `${ASSET_BASE}/assets/images/icons/division.svg`,
    REG_EDIT : `${ASSET_BASE}/assets/images/icons/region.svg`,
    INS_GROUP : `${ASSET_BASE}/assets/images/icons/incidentCategory.svg`,
    ACCESS_POINT_CAT : `${ASSET_BASE}/assets/images/icons/access_point_category.svg`,
    GRAVE_REASON : `${ASSET_BASE}/assets/images/icons/grave_site_visit_reason.svg`,
};

export const INCIDENT_TYPE_TO_TEXT : {[key : number] : string} = {
    1 : 'Evidence',
    2 : 'Incident',
    3 : 'Suspect',
};

export const ACCESS_POINT_TYPE_STRING : {[key : number] : string} = {
    0 : 'Unknown',
    1 : 'Gate',
};

export const ACCESS_POINT_TYPE_TO_ICON : {[key : string] : string} = {
    0 : `${ASSET_BASE}/assets/images/icons/unkown_acp.svg`,
    1 : `${ASSET_BASE}/assets/images/icons/gate.svg`,
};

export const ACCESS_POINT_REASONS : {[key : string] : string} = {
    WRK : 'Work',
    MEE : 'Meeting',
    DEL : 'Delivery',
    RES : 'Resident',
    VIS : 'Visit',
    MAR : 'Market',
    COL : 'Collection',
};

export enum RIGHTS {
    RIGHTS = 1,
    USER = 2,
    INS = 3,
    OBS = 4,
    ROLE = 5,
    ACCESSE = 6,
    MASTER = 7,
    ACCESSP = 8,
    ACCESSEV = 9,
    INS_EDIT = 10,
    INS_CAT = 11,
    DIV_EDIT = 12,
    REG_EDIT = 13,
    ACCESSEP = 14,
    INS_VIRUS_COVID19 = 15,
    ACCESSEV_VIEW = 16,
    ACCESSEP_VIEW = 17,
    INS_DEL = 18,
    OBS_DEL = 19,
    OBS_EDIT = 20,
    MOBILE = 21,
    MACCESSP = 22,
    GRAVESITE = 23,
    INS_APPROVE = 24,
    INS_GROUP = 25,
    GRAVE_REASON = 26,
    ACCESS_POINT_CAT = 27,
    DIVISION_TYPE = 28,
    USER_DETAILS = 29,
    USER_DIVISIONS = 30,
    USER_ACCESS_POINT = 31,
    USER_RIGHTS = 32,
    OCC = 35,
    OCC_DEL = 36,
    OCC_EDIT = 37,
}

export enum ROLES {
    SCHEDULER = 999,
    MANAGER = 1000,
    GUARD = 1001,
}

export const SYMPTOM_TYPE : {[key : number] : string} = {
    0 : 'Covid-19',
};
export const LANGUAGES = [
    'Afrikaans',
    'Chewa',
    'English',
    'isiNdinbele',
    'isiZulu',
    'Sepedi',
    'Sesotho',
    'Setswana',
    'Shona',
    'siSwati',
    'Tshivenda',
    'Tsonga',
    'Venda',
    'Xhosa',
    'Xitsonga',
];

export const POACHING_METHODS = [
    'Firearms',
    'Fishing Nets',
    'Fishing Rods',
    'Poison',
    'Snare Wires',
    'Spears/Dogs',
    'Traps',
];
export const HOUSEBREAKING_ENTRY_POINTS = [
    'Window(s)',
    'Back Door',
    'Front Door',
    'Garage Door',
];
export const WEATHER_CONDITIONS = [
    'Clear',
    'Cloudy',
    'Rain',
    'Thunderstorm',
    'Hail',
];

export const PROVINCES = [
    'Eastern Cape',
    'Free State',
    'Gauteng',
    'KwaZulu-Natal',
    'Limpopo',
    'Mpumalanga',
    'North West',
    'Northern Cape',
    'Western Cape',
];
