import { EnumFileType } from '../@types/model/base';

export default class AppFunctions {
    public static setArrayElement = <T>(arr : null | Array<T>, index : number, value : T) : Array<T> => {
        if (arr === undefined) return [];
        if (arr === null) return [];
        if (index === -1) return AppFunctions.addArrayElement(arr, value);
    
        return Object.assign([...arr], { [index]: value });
    };
    
    /**
     * Adds a new value to an array without performing mutation i.e. a new array is returned with the change effected.
     * @param arr
     * @param index
     * @param value
     */
    public static addArrayElement = <T>(arr : null | Array<T>, value : T, position : 'start' | 'end' = 'start') : Array<T> => {
        if (arr === undefined) return [];
        if (arr === null) return [];
    
        if (position === 'start') {
            return [value, ...arr];
        } else if (position === 'end') {
            return [...arr, value];
        }
        return arr;
    };
    
    /**
     * Inserts value if not currently in the provided array, otherwise updates the existing value, by finding the first
     * element for which the equals comparator passes.
     * @param arr
     * @param value
     * @param equals
     * @param position
     */
    public static upsertArrayElement = <T extends {[key : string] : any}>(arr : null | Array<T>, value : T, equals : (a : T) => boolean, position : 'start' | 'end' = 'start') : null | Array<T> => {
        if (arr === null) return null;
    
        const index = arr.findIndex(equals);
        if (index > -1) {
            return AppFunctions.setArrayElement(arr, index, value);
        } else {
            return AppFunctions.addArrayElement(arr, value, position);
        }
    };
    
    /**
     * Removes the value at the specified index of an array without performing mutation i.e. a new array is returned with
     * the change effected.
     * @param arr
     * @param index
     * @param value
     */
    public static removeArrayElement = <T>(arr : null | Array<T>, index : number) : Array<T> => {
        if (arr === undefined) return [];
        if (arr === null) return [];
        if (index === -1) return arr;
    
        return [...arr.slice(0, index), ...arr.slice(index + 1)];
    };
    
    /**
     * Removed the value where condition is met without performing mutation i.e. a new array is returned with
     * the change effected.
     * @param arr
     * @param equals
     */
    public static removeArrayElementWhere = <T>(arr : null | Array<T>, equals : (a : T) => boolean) : null | Array<T> => {
        if (arr === null) return null;
    
        const index = arr.findIndex(equals);
        if (index > -1) {
            return AppFunctions.removeArrayElement(arr, index);
        } else {
            return arr.slice();
        }
    };
    
    public static arrayToRecord = <V extends {[key : string] : any}>(arr : null | Array<V>, key : string) : Record<number, V> => {
        if (!arr) return {};
    
        const record : Record<number, V> = {};
        arr.forEach((val : V) => {
            if (!val[key]) return {};
            record[val[key]] = val;
        });
        return record;
    };
    
    public static urlBase64ToUint8Array = (base64String : string) => {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
    
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    };
    /**
     * Changes axios array params to proper uri.
     * @param params
     */
    public static parseParams = (params : any) => {
        const keys = Object.keys(params);
        let options = '';
    
        keys.forEach((key) => {
            const isParamTypeObject = typeof params[key] === 'object';
            const isParamTypeArray = isParamTypeObject && (params[key].length >= 0);
    
            if (!isParamTypeObject) {
                options += `${key}=${params[key]}&`;
            }
    
            if (isParamTypeObject && isParamTypeArray) {
                params[key].forEach((element : any) => {
                    options += `${key}=${element}&`;
                });
            }
        });
    
        return options ? options.slice(0, -1) : options;
    };
    
    public static getIndexOfArrayElement = <T>(arr : null | Array<T> | any, item : any, field : string) => {
        if (arr === undefined) return -1;
        if (arr === null) return -1;
        if (item === undefined || item === null) return -1;
    
        for (let i = 0; i < arr.length; i++) {
            if (arr[i][field] === item[field]) {
                return i;
            }
        }
        return -1;
    };
    
    public  static setPageTitle(title : string) {
        document.title = title;
    }
    
    public static resetPageTitle() {
        document.title = 'Security';
    }
    
    public static blobToBase64(blob : Blob) {
        return new Promise<string>((res, rej) => {
            try {
                const reader = new FileReader();
                reader.onloadend = () => {
    
                    if (reader.result && typeof(reader.result) === 'string') {
                        res(reader.result.split(',')[1]);
                    } else {
                        rej();
                    }
                };
                reader.readAsDataURL(blob);
            } catch (ex) {
                rej(ex);
            }
        });
    }
    
    public static fileTypeToEnumFileType(type : string) {
        const typeStrings = type.split('/');
    
        switch (typeStrings[0]) {
            case 'image':
                return EnumFileType.Picture;
            case 'video':
                return EnumFileType.Video;
            case 'audio':
                return EnumFileType.Audio;
            case 'application':
                if (typeStrings[1] === 'pdf') return EnumFileType.Document;
                break;
        }
        return EnumFileType.Unknown;
    }
}

