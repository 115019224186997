import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IDivisionType } from '../../../@types/model/masterData/divisionType';

export default class DivisionTypeHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel current Person request
     */
    public static cancelGet? : Canceler;

    public static getAll() : AxiosPromise<Array<IDivisionType>> {
        if (DivisionTypeHttpService.cancelGet) {
            DivisionTypeHttpService.cancelGet();
        }
        return axios.get(`${API_URL}/api/MasterData/DivisionType/GetAll`, {
            cancelToken: new DivisionTypeHttpService.CancelToken((c) => {
                DivisionTypeHttpService.cancelGet = c;
            }),
            params: {
            },
        });
    }

    public static save(graveSite : IDivisionType) {
        return axios.post<IDivisionType>(`${API_URL}/api/MasterData/DivisionType/Save`, graveSite);
    }

}
