import OccurrenceHttpService from '../../service/http/occurrenceHttpService';
import generalFunctions from '../general/functions';
import { dispatch, getState } from '..';
import occurrenceActions from './actions';
import { IOccurrence } from '../../@types/model/occurrence';

export default class OccurrenceFunctions {
    public static async get(id? : number, guid? : string) {
        try {
            dispatch(occurrenceActions.setLoadingOccurrences(true));
            const result = await OccurrenceHttpService.get(id, guid);

            dispatch(occurrenceActions.setOccurrence(result.data));
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading occurrence', e);
        } finally {
            dispatch(occurrenceActions.setLoadingOccurrences(false));
        }
    }

    public static async getAll(startDateUnixMilli : number, endDateUnixMilli : number) {
        try {
            dispatch(occurrenceActions.setLoadingOccurrences(true));
            const result = await OccurrenceHttpService.getAll(startDateUnixMilli, endDateUnixMilli);

            dispatch(occurrenceActions.setOccurrences(result.data));
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading occurrences', e);
        } finally {
            dispatch(occurrenceActions.setLoadingOccurrences(false));
        }
    }

    public static async create(guid : string, date : number, divisionId : number, description : string, incident : boolean, isActive: boolean) {
        try {
            dispatch(occurrenceActions.setLoadingOccurrences(true));

            const result = await OccurrenceHttpService.create(guid, date, divisionId, description, incident, isActive);

            if (result.data) {
                const occurrences = getState().occurrence.occurrences.slice();
                occurrences.push(result.data);
                dispatch(occurrenceActions.setOccurrences(occurrences));
                dispatch(occurrenceActions.setOccurrence(result.data));
            }

            return result.data;
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while creating occurrence.', e);
        } finally {
            dispatch(occurrenceActions.setLoadingOccurrences(false));
        }

        return null;
    }

    public static async update(guid : string, date : number, divisionId : number, description : string, incident : boolean, isActive: boolean) {
        try {
            dispatch(occurrenceActions.setLoadingOccurrences(true));

            const result = await OccurrenceHttpService.update(guid, date, divisionId, description, incident, isActive);

            if (result.data) {
                const occurrences = getState().occurrence.occurrences.slice();
                const index = occurrences.findIndex(x => x.guid === result.data.guid);

                occurrences.splice(index, 1, result.data);
                dispatch(occurrenceActions.setOccurrences(occurrences));
            }

            return result.data;
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while updating occurrence.', e);
        } finally {
            dispatch(occurrenceActions.setLoadingOccurrences(false));
        }

        return null;
    }

    public static async remove(occurrence : IOccurrence) {
        try {
            dispatch(occurrenceActions.setLoadingOccurrences(true));

            await OccurrenceHttpService.remove(occurrence);

            const occurrences = getState().occurrence.occurrences.slice();

            const index = occurrences.findIndex(x => x.guid === occurrence.guid);
            occurrences.splice(index, 1);

            dispatch(occurrenceActions.setOccurrences(occurrences));
            dispatch(occurrenceActions.setLoadingOccurrences(false));
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while removing occurrence', e);
            dispatch(occurrenceActions.setLoadingOccurrences(false));
            throw e;
        }
    }
}
