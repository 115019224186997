import React from 'react';
import { IIncident } from '../../../../@types/model/incident';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IncidentComplainantEditDialog from '../dialog/edit/ComplainantEditDialog';

interface IIncidentComplainantViewProps {
    incident : IIncident;
    isEditAdmin : boolean;
    isSaving : boolean;
}

interface IIncidentComplainantViewState {}

export default class IncidentComplainantView extends React.PureComponent<IIncidentComplainantViewProps, IIncidentComplainantViewState> {
    constructor(props : IIncidentComplainantViewProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const {} = this.state;
        const { isEditAdmin, incident, isSaving } = this.props;
        return (
            <div className='flx1 fdr aifs pt20 pb20 bcg1'>
                <span className='flx1' />
                <Paper className='fdc flx2' elevation={3}>
                    <AppBar position='static' className='pbr bcpl' elevation={0}>
                        <Toolbar>
                            <Typography className='fwm fs18'>
                                COMPLAINANT
                            </Typography>
                            <span className='flx1' />
                            {
                                isEditAdmin &&
                                <div className='posa posr10'>
                                    <IncidentComplainantEditDialog maxWidth='sm' fullWidth incident={incident} isLoading={isSaving}/>
                                </div>
                            }
                        </Toolbar>
                    </AppBar>
                    <div className='fdc'>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Name
                            </Typography>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Employee Number
                            </Typography>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Telephone Number
                            </Typography>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Cellphone Number
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                {incident.complainant.name}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                {incident.complainant.employeeNr}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                {incident.complainant.tel ?? 'None'}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                {incident.complainant.cel ?? 'None'}
                            </Typography>
                        </div>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Spoken Language
                            </Typography>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Address
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                {incident.complainant.language}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                {incident.complainant.address}
                            </Typography>
                        </div>
                    </div>
                </Paper>
                <span className='flx1' />
            </div>
        );
    }
}
