import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import moment, { Moment } from 'moment';
import { IIncident, IIncidentCategory, IIncidentIncidentCategory } from '../../../../../@types/model/incident';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import lodash from 'lodash';
import IncidentFunctions from '../../../../../store/incident/functions';
import { ILatLng } from '../../../../../@types/model/base';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LocationInput from '../../../custom/input/LocationInput';

interface IIncidentLocationEditDialogProps {
    incident : IIncident;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IIncidentLocationEditDialogState {
    open : boolean;

    geo : ILatLng;
}

export default class IncidentLocationEditDialog extends React.Component<IIncidentLocationEditDialogProps, IIncidentLocationEditDialogState> {
    constructor(props : IIncidentLocationEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            geo: {
                latitude: 0,
                longitude: 0,
            },
        };
    }

    public readonly componentDidUpdate = (prevProps : IIncidentLocationEditDialogProps, prevState : IIncidentLocationEditDialogState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                geo: {
                    latitude: 0,
                    longitude: 0,
                },
            });
        }
        if (!prevState.open && this.state.open && this.props.incident) {
            this.setState({
                geo: this.props.incident.geo,
            });
        }
    }

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private readonly save = async () => {
        const { geo } = this.state;

        const incident = lodash.cloneDeep(this.props.incident);

        if (incident) {

            incident.geo = { ...geo };
            const result = await IncidentFunctions.save(incident);

            if (result) {
                this.onClose();
            }
        }
    }

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    }

    private readonly onLatitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.currentTarget.value && parseFloat(event.currentTarget.value) === null) return;
        this.setState({
            geo: { ...this.state.geo, latitude: parseFloat(!event.currentTarget.value ? '0' : event.currentTarget.value) },
        });
    }

    private readonly onLongitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.currentTarget.value && parseFloat(event.currentTarget.value) === null) return;
        this.setState({
            geo: { ...this.state.geo, longitude: parseFloat(!event.currentTarget.value ? '0' : event.currentTarget.value) ?? null },
        });
    }

    private readonly onLocationChange = (geo : ILatLng) => {
        this.setState({
            geo,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, incident } = this.props;
        const { open, geo } = this.state;
        return (
            <>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='primary' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='incident-location-title'
                    aria-describedby='incident-location-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Incident Location
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className='fdc flx1'>
                                <div className='fdr'>
                                    <div className={'flx1 ais mb10 pr20'}>
                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete='off'
                                                id='lat'
                                                label='Latitude'
                                                value={geo?.latitude ?? null}
                                                onChange={this.onLatitudeChange}
                                                margin='normal'
                                                className={'TextField'}
                                                required
                                                error={!geo?.latitude && !!geo?.longitude}
                                                type='number'
                                            />
                                            {
                                                !geo?.latitude && !!geo?.longitude &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className={'flx1 ais mb10'}>
                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete='off'
                                                id='long'
                                                label='Longitude'
                                                value={geo?.longitude}
                                                onChange={this.onLongitudeChange}
                                                margin='normal'
                                                className={'TextField'}
                                                required
                                                error={!!geo?.latitude && !geo?.longitude}
                                                type='number'
                                            />
                                            {
                                                !!geo?.latitude && !geo?.longitude &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='h220'>
                                    <LocationInput id='edit-dialog-map' geo={geo} onChange={this.onLocationChange} />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading || !geo} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </>
        );
    }
}
