import React from 'react';
import { IIncident } from '../../../../@types/model/incident';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IncidentSAPSInfoDialog from '../dialog/edit/SAPSEditDialog';

interface IIncidentSAPSViewProps {
    incident : IIncident;
    isEditAdmin : boolean;
    isSaving : boolean;
}

interface IIncidentSAPSViewState {}

export default class IncidentSAPSView extends React.PureComponent<IIncidentSAPSViewProps, IIncidentSAPSViewState> {
    constructor(props : IIncidentSAPSViewProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const {} = this.state;
        const { isEditAdmin, incident, isSaving } = this.props;
        return (
            <div className='flx1 fdr aifs pt20 pb20 bcg1'>
                <span className='flx1' />
                <Paper className='fdc flx2' elevation={3}>
                    <AppBar position='static' className='pbr bcpl' elevation={0}>
                        <Toolbar>
                            <Typography className='fwm fs18'>
                                SAPS INFO
                            </Typography>
                            <span className='flx1' />
                            {
                                isEditAdmin &&
                                <div className='posa posr10'>
                                    <IncidentSAPSInfoDialog maxWidth='md' fullWidth incident={incident} isLoading={isSaving}/>
                                </div>
                            }
                        </Toolbar>
                    </AppBar>
                    {
                        !incident.saps &&
                        <div className='fdc pt10 mb10 aic jcc'>
                            <Typography className={'flx1 fs16 lh37 fwm'} variant='subtitle1'>
                                Not Involved
                            </Typography>
                        </div>
                    }
                    {
                        incident.saps &&
                        <div className='fdc'>
                            <div className={'fdr pl10 pr10 pt10 mb10'}>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Station
                                </Typography>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Info Book Number
                                </Typography>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Occ. Reg. Nr
                                </Typography>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Accident Report Nr
                                </Typography>
                            </div>
                            <div className={'fdr mb10 pl10 pr10'}>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.saps.station ?? 'None'}
                                </Typography>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.saps.infBookNr ?? 'None'}
                                </Typography>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.saps.occRegNr ?? 'None'}
                                </Typography>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.saps.accidentReportNr ?? 'None'}
                                </Typography>
                            </div>
                            <div className={'fdr pl10 pr10 pt10 mb10'}>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Officer on Scene
                                </Typography>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Officer on Scene Rank
                                </Typography>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Investigating Officer
                                </Typography>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Investigating Officer Rank
                                </Typography>
                            </div>
                            <div className={'fdr mb10 pl10 pr10'}>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.saps.officerName ?? 'None'}
                                </Typography>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.saps.officerRank ?? 'None'}
                                </Typography>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.saps.investigatingOfficerName ?? 'None'}
                                </Typography>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.saps.investigatingOfficerRank ?? 'None'}
                                </Typography>
                            </div>
                            <div className={'fdr pl10 pr10 pt10 mb10'}>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Exhibit Register Number
                                </Typography>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Case Reg. Nr
                                </Typography>
                            </div>
                            <div className={'fdr mb10 pl10 pr10'}>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.saps.exhibitRegNr ?? 'None'}
                                </Typography>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.saps.caseRegNr ?? 'None'}
                                </Typography>
                            </div>
                        </div>
                    }
                </Paper>
                <span className='flx1' />
            </div>
        );
    }
}
