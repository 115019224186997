export interface IBaseImportModel {
    id : number;
    createdBy : string;
    createdByName : string;
    createdOn : string;
    updatedBy : string;
    updatedByName : string;
    updatedOn : string;
    isActive : boolean;
}

export interface ILatLng {
    latitude : number;
    longitude : number;
}

export interface IBaseModel {
    id : number;
    createdBy : number;
    createdByName : string;
    createdOn : string;
    updatedBy : number;
    updatedByName : string;
    updatedOn : string;
    isActive : boolean;
}

export interface IFile extends IBaseImportModel {
    fileName : string;
    fileType : EnumFileType;
    url : string;
    thumbUrl? : string;
    duration? : number;
}

export interface IFileUpload {
    name : string;
    base64 : string;
}

export enum EnumFileType {
    Picture = 0,
    Video = 1,
    Audio = 2,
    Unknown = 3,
    Document = 4,
}

export enum EnumGender {
    Male = 1,
    Female = 2,
    Unknown = 3,
}

export enum EnumAccessPointType {
    Unknown = 0,
    Gate = 1,
}

export enum EnumFileUploadType {
    Observation = 0,
    Incident = 1,
    AccessPoint = 2,
    GraveSite = 3,
    Grave = 4,
}

export enum EnumFileCategory {
    Evidence = 0,
    Suspect = 1,
    Incident = 2,
    Observation = 3,
    GraveSiteImage = 4,
    GraveSiteRepresentativeImage = 5,
    GraveImage = 6,
    GraveBurialAgreementFiles = 7,
    GraveInitialApplicationFiles = 8,
    GraveVisitAgreementFiles = 9,
    GraveTombstoneErectionApplicationFiles = 10,
    IncidentUpdate = 11,
}
