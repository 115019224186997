import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { EnumIncidentCourtCaseStatus } from '../../../../../@types/model/incident';
import { TextField } from '@material-ui/core';
import { createSelector } from 'reselect';
import lodash from 'lodash';

interface IIncidentCourtCaseStatusAutocompleteProps {
    label ?: string;
    value : EnumIncidentCourtCaseStatus | null;
    disabled ?: boolean;
    required ?: boolean;
    onChange : (value : EnumIncidentCourtCaseStatus | null) => void;
}

interface IIncidentCourtCaseStatusAutocompleteState {}

export default class IncidentCourtCaseStatusAutocomplete extends React.PureComponent<IIncidentCourtCaseStatusAutocompleteProps, IIncidentCourtCaseStatusAutocompleteState> {
    constructor(props : IIncidentCourtCaseStatusAutocompleteProps) {
        super(props);
        this.state = {};
    }

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : number | null;
    } | null) => {
        this.props.onChange(value?.value ?? null);
    }

    private readonly getValue = (state : IIncidentCourtCaseStatusAutocompleteState, props : IIncidentCourtCaseStatusAutocompleteProps) => props.value;
    private readonly getRequired = (state : IIncidentCourtCaseStatusAutocompleteState, props : IIncidentCourtCaseStatusAutocompleteProps) => props.required;

    private getStatuses = createSelector([], () => {
        return lodash
            .map(EnumIncidentCourtCaseStatus, (n) => EnumIncidentCourtCaseStatus[n])
            .map((n) => Number(n))
            .filter((n) => !isNaN(n));
    });

    private readonly getStatusesForDropdown = createSelector([
        this.getRequired,
        this.getStatuses,
    ], (
        required,
        statuses,
    ) => {
        const dropdownData : Array<{
            label : string;
            value : number | null;
        }> = lodash.map(
            statuses,
            (element) => ({
                label: EnumIncidentCourtCaseStatus[element],
                value: element,
            }));

        if (!required) {
            dropdownData.unshift({
                label: 'None',
                value: null,
            });
        }
        
        return dropdownData;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue,
    ], (
        value,
    ) => {
        if (value != null) {
            return {
                label: EnumIncidentCourtCaseStatus[value],
                value : value
            };
        }

        return {
            label: 'None',
            value : null
        };
    });

    public render = () => {
        const { required, disabled, label } = this.props;

        const dropdownData = this.getStatusesForDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        return (
            <Autocomplete 
                disabled={disabled}
                options={dropdownData}
                value={value}
                getOptionSelected={(option, val) => option.value === val.value}
                getOptionLabel={option => option.label}
                onChange={this.onChange}
                disableClearable={required}
                openOnFocus
                renderInput={params => <TextField error={required && !value} {...params} fullWidth label={label ?? 'Case Status'} />}  
            />
        );
    }
}
