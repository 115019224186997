import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import React from 'react';
import { IIncidentDocketUser } from '../../../../../@types/model/incident';
import { Delete } from '@material-ui/icons';
import { connect } from 'react-redux';
import { IRootState } from '../../../../../@types/redux';

interface IIncidentDocketPeopleViewProps {
    disabled ?: boolean;
    users : Array<IIncidentDocketUser>;
    userId ?: number;

    onDeleteClick ?: (event : React.MouseEvent<HTMLButtonElement>) => void;
}

interface IIncidentDocketPeopleViewState {}

class IncidentDocketPeopleViewComponent extends React.PureComponent<IIncidentDocketPeopleViewProps, IIncidentDocketPeopleViewState> {
    constructor(props : IIncidentDocketPeopleViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { disabled, users, onDeleteClick, userId } = this.props;

        return (
            <List className='mt15'>
                {
                    users.map((user, i) => (
                        <ListItem key={i}>
                            <ListItemAvatar>
                                <Avatar
                                    alt={user.user?.name}
                                    src={user.user?.profilePictureURL ?? '/broken-image.jpg'}
                                />
                            </ListItemAvatar>
                            <ListItemText primary={user.user?.name} secondary={user.user?.email} />
                            {
                                !!onDeleteClick &&
                                user.userId !== userId &&
                                <ListItemSecondaryAction>
                                    <IconButton disabled={disabled} value={i} className='cpr' edge="end" aria-label="delete" onClick={onDeleteClick}>
                                        <Delete />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            }
                        </ListItem>
                    ))
                }
            </List>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        userId: state.auth.session?.userId,
    };
};

const IncidentDocketPeopleView = connect(
    mapStateToProps,
)(IncidentDocketPeopleViewComponent);

export default IncidentDocketPeopleView;
