import React from 'react';
import { createSelector } from 'reselect';
import { IAuthState } from '../../../store/auth/reducer';
import { EnumRightType, IUserRight } from '../../../@types/model/right';
import NavMenuListItem from '../../component/custom/listItem/NavMenuListItem';
import { RIGHT_TO_ICON } from '../../../appConstants';
import NavMenuItem from '../../component/custom/listItem/NavMenuItem';

interface IMasterDataNavMenuProps {
    auth : IAuthState;
    onClick : (url : string) => void;
}

interface IMasterDataNavMenuState {}

export default class MasterDataNavMenu extends React.PureComponent<IMasterDataNavMenuProps, IMasterDataNavMenuState> {
    constructor(props : IMasterDataNavMenuProps) {
        super(props);
        this.state = {};
    }
    public getUserRights = (props : IMasterDataNavMenuProps) => props.auth.session?.user.userRights ?? [];
    public getMasterDataRights = createSelector(
        [this.getUserRights],
        (userRights : Array<IUserRight>) => {
            return userRights
                .filter(x => x.right?.type === EnumRightType.Master && x.right.parentId
                    && x.right.isMenu
                    && x.right.parentId
                    && x.isActive)
                .sort((a, b) => a.right?.name.localeCompare(b.right?.name ?? '') ?? 0);
        },
    );

    public render = () => {
        const {
            onClick,
        } = this.props;

        const masterDataRight = this.getMasterDataRights(this.props);

        return (
            !!masterDataRight.length &&
            <NavMenuListItem key={`master_nav_header`} icon={`${ASSET_BASE}/assets/images/icons/master_data.svg`}
                imgProps={{
                    className: 'h30 w30',
                }}
                title={'Master Data'}>
                    {
                        masterDataRight.map((o) => {
                            return (
                                <NavMenuItem userRight={o} key={`${o.right?.name}_Nav`} imgProps={{
                                    className: 'h30 w30',
                                }} onClick={onClick} icon={RIGHT_TO_ICON[o.right?.code ?? '']} />
                            );
                        })
                    }
            </NavMenuListItem>
        );
    }
}
