import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { IGraveVisit } from '../../../../@types/model/graveSite/graveVisit';
import { IGraveSite } from '../../../../@types/model/graveSite/graveSite';
import { Button, DialogActions, Divider, FormControl, FormControlLabel, FormHelperText, Input, InputLabel, Switch, TextField, Typography } from '@material-ui/core';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import { KeyboardTimePicker } from '@material-ui/pickers/TimePicker';
import GraveDropdown from '../../custom/autocomplete/graveSites/GraveAutocomplete';
import { IGrave } from '../../../../@types/model/graveSite/grave';
import GraveSiteFunctions from '../../../../store/graveSites/functions';
import GraveVisitReasonAutocomplete from '../../custom/autocomplete/graveSites/GraveVisitReasonAutocomplete';
import { IGraveVisitReason } from '../../../../@types/model/masterData/graveVisitReason';

const otherVisitReasonId = 1;

interface IGraveVisitEditDialogProps {
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    graveSite : IGraveSite;
    graveVisit : IGraveVisit | null;

    onClose : () => void;
}

interface IGraveVisitEditDialogState {
    open : boolean;

    dateOfVisit : moment.Moment;
    timeOfVisit : moment.Moment;
    graveId : number | null;
    nameOfVisitor : string;
    contactOfVisitor : string;
    reasonForVisit : string;
    visitReasonId : number | null;
    arrived : boolean;
    assistingEmployeeNumber : string;
    assistingEmployeeName : string;

    isLoading : boolean;
}

export default class GraveVisitEditDialog extends React.PureComponent<IGraveVisitEditDialogProps, IGraveVisitEditDialogState> {
    constructor(props : IGraveVisitEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            dateOfVisit: moment.utc().startOf('day').local(),
            timeOfVisit: moment.utc().local(),
            graveId: null,
            nameOfVisitor: '',
            contactOfVisitor: '',
            reasonForVisit: '',
            arrived: false,
            assistingEmployeeNumber: '',
            assistingEmployeeName: '',
            visitReasonId: null,
            isLoading: false,
        };
    }

    public componentDidUpdate = (prevProps : Readonly<IGraveVisitEditDialogProps>) => {
        if (!prevProps.graveVisit && this.props.graveVisit) {
            const { graveVisit } = this.props;
            this.setState({
                open: true,
                dateOfVisit: !graveVisit.visitDateTime ? moment.utc().startOf('day').local() : moment.utc(graveVisit.visitDateTime).startOf('day').local(),
                timeOfVisit: !graveVisit.visitDateTime ? moment.utc().local() : moment.utc(graveVisit.visitDateTime).local(),
                graveId: graveVisit.graveId,
                nameOfVisitor: !graveVisit.visitorName ? '' : graveVisit.visitorName,
                contactOfVisitor: !graveVisit.visitorContactNumber ? '' : graveVisit.visitorContactNumber,
                reasonForVisit: !graveVisit.visitReasonOther ? '' : graveVisit.visitReasonOther,
                visitReasonId: !graveVisit.visitReasonId ? null : graveVisit.visitReasonId,
                arrived: !!graveVisit.visitorArrived,
                assistingEmployeeNumber: !graveVisit.assistingEmployeeNumber ? '' : graveVisit.assistingEmployeeNumber,
                assistingEmployeeName: !graveVisit.assistingEmployeeName ? '' : graveVisit.assistingEmployeeName,
                isLoading: false,
            });
        }

        if (prevProps.graveVisit && !this.props.graveVisit) {
            this.setState({
                open: false,
                dateOfVisit: moment.utc().startOf('day').local(),
                timeOfVisit: moment.utc().local(),
                graveId: null,
                nameOfVisitor: '',
                reasonForVisit: '',
                arrived: false,
                assistingEmployeeNumber: '',
                assistingEmployeeName: '',
                isLoading: false,
            });
        }
    }

    private readonly onClose = () => {
        this.props.onClose();
    }

    private readonly save = async () => {
        const { graveVisit } = this.props;
        const { nameOfVisitor, contactOfVisitor,
            assistingEmployeeNumber, assistingEmployeeName,
            dateOfVisit, timeOfVisit, arrived, graveId,
            isLoading, reasonForVisit, visitReasonId } = this.state;

        if (!graveVisit || isLoading) return;
        if (!graveId) return;
        if (!visitReasonId) return;

        const result : IGraveVisit = {
            ...graveVisit,
            assistingEmployeeName,
            assistingEmployeeNumber,
            graveId,
            visitDateTime: moment(dateOfVisit)
                .add(moment(timeOfVisit).utc().get('h'), 'h')
                .add(moment(timeOfVisit).utc().get('m'), 'm')
                .utc()
                .toISOString(),
            visitorArrived: arrived,
            visitorContactNumber: contactOfVisitor,
            visitorName: nameOfVisitor,
            visitReasonOther: reasonForVisit,
            visitReasonId,
        };

        try {
            this.setState({
                isLoading: true,
            });

            await GraveSiteFunctions.saveVisit(result);

            this.onClose();
        } catch {
            this.setState({
                isLoading: false,
            });
        }

    }

    private readonly onSubmit = (event : React.FormEvent) => {
        event.preventDefault();

        this.save();
    }

    private onNameOfVisitorChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            nameOfVisitor: event.target.value,
        });
    }

    private onContactOfVisitorChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            contactOfVisitor: event.target.value,
        });
    }

    private onAssistingEmployeeNumberChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            assistingEmployeeNumber: event.target.value,
        });
    }

    private onAssistingEmployeeNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            assistingEmployeeName: event.target.value,
        });
    }

    private onVisitDateChange = (dateOfVisit : moment.Moment) => {
        this.setState({
            dateOfVisit: dateOfVisit.asUTC().local(),
        });
    }

    private onVisitTimeChange = (time : moment.Moment) => {
        this.setState({
            timeOfVisit: time,
        });
    }

    private onArrivedChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            arrived: checked,
        });
    }

    private onGraveChange = (grave? : IGrave) => {
        this.setState({
            graveId: grave?.id ?? 0,
        });
    }

    private onReasonForVisitChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            reasonForVisit: event.target.value,
        });
    }

    private readonly onVisitReasonChange = (graveVisitReason? : IGraveVisitReason) => {
        this.setState({
            visitReasonId: graveVisitReason?.id ?? null,
            reasonForVisit: '',
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, graveVisit, graveSite } = this.props;
        const { open, nameOfVisitor, contactOfVisitor,
            assistingEmployeeNumber, assistingEmployeeName,
            dateOfVisit, timeOfVisit, arrived, graveId,
            isLoading, reasonForVisit, visitReasonId } = this.state;
        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='tooltip-dialog-title'
                aria-describedby='tooltip-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h6' color='inherit' className='flx1'>
                            {
                                !graveVisit?.id &&
                                'LOG A VISIT'
                            }
                            {
                                !!graveVisit?.id &&
                                'EDIT A VISIT'
                            }
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                            </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <form onSubmit={this.onSubmit}>
                    <DialogContent className='fdc flx1 hfill'>
                        <div className='fdc'>
                            <Typography className='fs16 fwm cp'>
                                VISITOR
                            </Typography>
                        </div>
                        <div className='fdr aifs jcfs'>
                            <div className='fdc flx1 mr15'>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete='off'
                                        id='nameOfVisitor'
                                        label='Name & Surname'
                                        value={nameOfVisitor}
                                        onChange={this.onNameOfVisitorChange}
                                        margin='normal'
                                        className={'TextField'}
                                        error={!nameOfVisitor}
                                    />
                                    {
                                        !nameOfVisitor &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                            <div className='fdc flx1 ml15'>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete='off'
                                        id='contactOfVisitor'
                                        label='Contact Details'
                                        value={contactOfVisitor}
                                        onChange={this.onContactOfVisitorChange}
                                        margin='normal'
                                        className={'TextField'}
                                        error={!contactOfVisitor}
                                        placeholder='0121231234'
                                        inputProps={{
                                            pattern: '[0-9]{10}',
                                        }}
                                    />
                                    {
                                        !contactOfVisitor &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                        </div>
                        <div className='fdr aifs jcfs'>
                            <div className='fdc flx1 mr15'>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete='off'
                                        id='assistingEmployeeNumber'
                                        label='Employee Assisting Number'
                                        value={assistingEmployeeNumber}
                                        onChange={this.onAssistingEmployeeNumberChange}
                                        margin='normal'
                                        required
                                        className={'TextField'}
                                        error={!assistingEmployeeNumber}
                                    />
                                    {
                                        !assistingEmployeeNumber &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                            <div className='fdc flx1 ml15'>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete='off'
                                        id='assistingEmployeeName'
                                        label='Employee Assisting Name'
                                        value={assistingEmployeeName}
                                        onChange={this.onAssistingEmployeeNameChange}
                                        margin='normal'
                                        className={'TextField'}
                                        required
                                        error={!assistingEmployeeName}
                                    />
                                    {
                                        !assistingEmployeeName &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                        </div>
                        <div className='fdr mt15 mb15'>
                            <Divider className='flx1 bcp' />
                        </div>
                        <div className='fdr aifs jcfs mb15'>
                            <div className='fdc flx1 mr15'>
                                <FormControl fullWidth>
                                    <KeyboardDatePicker
                                        value={dateOfVisit}
                                        onChange={this.onVisitDateChange}
                                        format={'YYYY-MM-DD'}
                                        label='Date of Visit'
                                        id='dateOfVisit'
                                        className={'TextField'}
                                        required
                                        margin={'normal'}
                                        fullWidth
                                    />
                                    {
                                        !dateOfVisit &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                            <div className='fdc flx1 ml15'>
                                <FormControl fullWidth>
                                    <KeyboardTimePicker
                                        value={timeOfVisit}
                                        onChange={this.onVisitTimeChange}
                                        format={'HH:mm'}
                                        label='Time of Visit'
                                        id='timeOfVisit'
                                        className={'TextField'}
                                        required
                                        margin={'normal'}
                                        fullWidth
                                    />
                                    {
                                        !timeOfVisit &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                        </div>
                        <div className='fdr mb15'>
                            <div className='fdc flx1 aifs jcfs mr15'>
                                <GraveDropdown
                                    fullWidth
                                    required
                                    graveSiteId={graveSite.id}
                                    onChange={this.onGraveChange}
                                    value={graveId}
                                />
                            </div>
                            <div className='fdc flx1 ml15'>
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={arrived}
                                                color='primary'
                                                onChange={this.onArrivedChange}/>
                                        }
                                        label={<Typography className='fwb fs20 cpd'>Visitor Arrived?</Typography>}
                                        labelPlacement='end'
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className='fdr'>
                            <div className='fdc flx1 aifs jcfs'>
                                <GraveVisitReasonAutocomplete
                                    value={visitReasonId}
                                    fullWidth
                                    onChange={this.onVisitReasonChange}
                                    required
                                />
                            </div>
                            {
                                otherVisitReasonId === visitReasonId &&
                                <div className='fdc flx1 ml15'>
                                    <FormControl fullWidth>
                                        <InputLabel required error={!reasonForVisit} htmlFor='reasonForVisit-input'>Other Reason For Visit</InputLabel>
                                        <Input
                                            id='reasonForVisit-input'
                                            value={reasonForVisit}
                                            onChange={this.onReasonForVisitChange}
                                            aria-describedby='reasonForVisit-input-helper-text'
                                            required
                                            multiline
                                            error={!reasonForVisit}
                                        />
                                        {
                                            !reasonForVisit &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                            }
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={
                            isLoading ||
                            !visitReasonId ||
                            (visitReasonId === otherVisitReasonId && !reasonForVisit) ||
                            !nameOfVisitor ||
                            !contactOfVisitor ||
                            !assistingEmployeeNumber ||
                            !assistingEmployeeName ||
                            !dateOfVisit.isValid() ||
                            !timeOfVisit.isValid() ||
                            !graveId
                        } type='submit' variant='contained' color='primary'>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}
