import axios, { isCancel } from 'axios';
import GeneralFunctions from '../../store/general/functions';
import LocalStorageService from '../localStorageService';

export default class HttpService {
    private static requestInterceptorNum = 0;
    private static responseInterceptorNum = 0;

    public static initializeInterceptor() {
        axios.interceptors.request.eject(this.requestInterceptorNum);
        axios.interceptors.response.eject(this.responseInterceptorNum);

        this.requestInterceptorNum = axios.interceptors.request.use(async (config) => {
            const token = await LocalStorageService.getLocalStorageToken();

            if (token && config.headers) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            return config;
        });

        this.responseInterceptorNum = axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (axios.isCancel(error)) {
                return Promise.reject();
            }

            if (!!error.response && error.response.status === 401) {
                LocalStorageService.setLocalStorageSession(null);
                GeneralFunctions.showErrorSnackbar(`Unauthorised. ${error.response.data}`);
                return Promise.reject();
            } else if (!!error.response && error.response.status === 403) {
                GeneralFunctions.showErrorSnackbar(`Insufficient rights. ${error.response.data}`);
                return Promise.reject();
            }

            if (!error || !error.response) {
                GeneralFunctions.showErrorSnackbar(`Connection Error.`);
                return Promise.reject();
            }

            if (isCancel(error)) {
                return Promise.reject();
            }

            return Promise.reject(error.response);
        });
    }
}
