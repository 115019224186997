import AppBar from '@material-ui/core/AppBar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { IRootState } from '../../@types/redux';
import { IAuthState } from '../../store/auth/reducer';
import { IGeneralState } from '../../store/general/reducer';
import { createSelector } from 'reselect';
import authFunctions from '../../store/auth/functions';
import { VERSION } from '../../version';
import Link from '@material-ui/core/Link';
import { Avatar } from '@material-ui/core';
import EnvHelper from '../../service/helper/envHelper';

interface INavBarProps extends RouteComponentProps {
    toggleLeftDrawer : () => void;
    toggleUserInfo : () => void;
    auth : IAuthState;
    general : IGeneralState;
}

class NavBar extends React.Component<INavBarProps> {
    constructor(props : INavBarProps) {
        super(props);
    }

    public getAuth = (props : INavBarProps) => props.auth;
    public getLocation = (props : INavBarProps) => props.location;

    public getCurrentTitle = createSelector(
        [this.getAuth, this.getLocation],
        (auth, location) => {
            if (auth.session) {
                // TODO: Complete when adding rights.
                return auth.session.user.userRights
                    .filter(x => !!x.right)
                    .find(x => x.right?.url === location.pathname)?.right?.name ?? '';
            } else {
                return '';
            }
        },
    );

    public onNameClick = () => {
        this.props.toggleUserInfo();
    }

    public logOut = async () => {
        await authFunctions.authLogOut();
    }

    public render() {
        const currentTitle = this.getCurrentTitle(this.props);
        const { session } = this.props.auth;
        const env = EnvHelper.getEnvName();

        return (
            <AppBar position='static' color='primary' elevation={0}>
                <Toolbar variant='dense'>
                    <div className={'fdr aic w400'}>
                        {
                            !!session &&
                            <IconButton color={'inherit'} onClick={this.props.toggleLeftDrawer}>
                                <Icon>menu</Icon>
                            </IconButton>
                        }
                        <img height={40} src={`${ASSET_BASE}/assets/images/logo.png`} className={'mr10'} />
                        <Typography color='inherit' variant='h6'>
                            {env}
                        </Typography>
                        <sub className={'ml10 mr10 pt5'}>v{VERSION.version}</sub>
                    </div>
                    <span className={'flx1'} />
                    <div className={'jcc'} >
                        <Typography color='inherit' variant='h6'>
                            {currentTitle}
                        </Typography>
                    </div>
                    <span className={'flx1'} />
                    <div className={'w300 fdr jcfe aic'}>
                        {
                            session &&
                            <div className={'fdr aic pr5'}>
                                <Link component='button'
                                variant='h6'
                                color='inherit'
                                onClick={this.onNameClick}>
                                    {session.user.name}
                                </Link>
                                <Avatar
                                    className='cursorPointer ml20'
                                    alt={session.user.name}
                                    src={session.user.profilePictureURL ?? '/broken-image.jpg'}
                                    onClick={this.onNameClick}
                                />
                            </div>
                        }
                    </div>
                </Toolbar>
            </AppBar>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        general: state.general,
    };
};

export default withRouter(connect(
    mapStateToProps,
)(NavBar));
