import React from 'react';
import { AccessPointEventFunctions } from '../../../store/accessPointEvent/functions';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import Accordion from '@material-ui/core/Accordion';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall } from '../../../@types/redux';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import DateRange from '../custom/DateRange';
import MaterialTable from '../custom/materialTable/Table';
import Divider from '@material-ui/core/Divider';
import AccordionActions from '@material-ui/core/AccordionActions';
import Toolbar from '@material-ui/core/Toolbar';
import { orange } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { Dispatch, bindActionCreators } from 'redux';
import accessPointEventActions from '../../../store/accessPointEvent/actions';
import { CustomMouseEvent } from '../../../@types/helpers';
import { Transitions } from '../custom/animations/Transitions';
import EntryInfoDialog from './dialog/EntryInfo';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { IAccessPointEventList } from '../../../@types/model/access';
import { CSVLink } from 'react-csv';
import { RouteComponentProps, withRouter } from 'react-router';
import { RIGHTS } from '../../../appConstants';
import { HelpOutline as HelpOutlineIcon, PhoneAndroid } from '@material-ui/icons';
import { FormControlLabel, Switch } from '@material-ui/core';

interface IPersonEntryProps extends RouteComponentProps<{}, {}, {}> {
    personEntries : Array<IAccessPointEventList>;
    isLoading : boolean;
    setGuid : DispatchCall<string | undefined>;

    hasViewRight? : boolean;
}

interface IPersonEntryState {
    expandSearch : boolean;

    startDate : moment.Moment;
    endDate : moment.Moment;
    person : string;
    gate : string;
    gateName : string;

    appOnly : boolean;
}

class PersonEntryComponent extends React.Component<IPersonEntryProps, IPersonEntryState> {
    private csvLink : any;
    constructor(props : IPersonEntryProps) {
        super(props);
        this.state = {
            expandSearch: true,
            startDate: moment.utc().startOf('day'),
            endDate: moment.utc().startOf('day'),
            person: '',
            gate: '',
            gateName: '',
            appOnly: false,
        };
    }

    public componentDidMount = () => {
        AccessPointEventFunctions.getAllPeople(this.state.startDate.valueOf(), this.state.endDate.valueOf());
    }

    public componentWillUnmount = () => {
        this.props.setGuid(undefined);
    }

    private onSearchExpansionChange = (e : React.ChangeEvent<{}>, expanded : boolean) => {
        this.setState({
            expandSearch: expanded,
        });
    }

    private onDateRangeChanged = (start : moment.Moment, end : moment.Moment) => {
        this.setState({
            startDate: start,
            endDate: end,
        });
        AccessPointEventFunctions.getAllPeople(start.valueOf(), end.valueOf());
    }

    private onSearch = () => {
        AccessPointEventFunctions.getAllPeople(this.state.startDate.valueOf(), this.state.endDate.valueOf());
    }

    private onClearSearch = () => {
        this.setState({
            startDate: moment.utc().startOf('day'),
            endDate: moment.utc().startOf('day'),
            person: '',
            gate: '',
            gateName: '',
        }, () => {
            this.onSearch();
        });
    }

    public onPersonEntryClick = (e : CustomMouseEvent) => {
        this.props.setGuid(e.currentTarget.value);
    }

    private onPersonChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            person: event.currentTarget.value,
        });
    }

    private onGateChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            gate: event.currentTarget.value,
        });
    }

    private onGateNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            gateName: event.currentTarget.value,
        });
    }

    private onFormSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.setState({
            expandSearch: false,
        });
    }

    public getEntries = (state : IPersonEntryState, props : IPersonEntryProps) => props.personEntries;
    public getPerson = (state : IPersonEntryState, props : IPersonEntryProps) => state.person.toLowerCase();
    public getGate = (state : IPersonEntryState, props : IPersonEntryProps) => state.gate.toLowerCase();
    public getGateName = (state : IPersonEntryState, props : IPersonEntryProps) => state.gateName.toLowerCase();
    public getAppOnly = (state : IPersonEntryState, props : IPersonEntryProps) => state.appOnly;

    public getFilteredData = createSelector([
        this.getEntries,
        this.getPerson,
        this.getGate,
        this.getGateName,
        this.getAppOnly],
        (entries, person, gate, gateName, appOnly) => {
            return entries
            .filter(n => !person || `${n.init} ${n.surname}`.toLowerCase().includes(person)
                                 || (n.name && n.name.toLowerCase().includes(person))
                                 || (n.employeeNumber && n.employeeNumber.toLowerCase().includes(person)))
            .filter(n => !gate || n.accessPointCode.toLowerCase().includes(gate))
            .filter(n => !gateName || n.accessPointName.toLowerCase().includes(gateName))
            .filter(n => !appOnly || n.guid === null);
        },
    );

    public getUniqueVehiclesCount = createSelector(
        [this.getFilteredData],
        (entries) => {
            return lodash.size(lodash.groupBy(entries.filter(n => !!n.vehicleReg), 'vehicleReg'));
        },
    );

    public getUniqueGatesCount = createSelector(
        [this.getFilteredData],
        (entries) => {
            return lodash.size(lodash.groupBy(entries, 'accessPointCode'));
        },
    );

    public getInsCount = createSelector(
        [this.getFilteredData],
        (entries) => {
            return entries.filter(n => n.isInbound).length;
        },
    );

    public getOutsCount = createSelector(
        [this.getFilteredData],
        (entries) => {
            return entries.filter(n => !n.isInbound).length;
        },
    );

    public getCSVData = createSelector([
        this.getFilteredData,
    ],
        (entries) => {
            return entries.map(n => ({
                date: moment.utc(n.date).local().format('ddd YYYY-MM-DD HH:mm'),
                accessPointCode: n.accessPointCode,
                accessPointName: n.accessPointName,
                direction: n.isInbound ? `IN` : 'OUT',
                vehicleReg: n.vehicleReg,
                vehicleMake: n.vehicleMake,
                vehicleModel: n.vehicleModel,
                vehicleColour: n.vehicleColour,
                driver: !n.identifier ? 'Unknown' : !n.name ? `${n.init} ${n.surname}` : `${n.name} ${!!!n.surname ? '' : n.surname}`,
                driverIdentifier: !n.identifier ? 'Unknown' : n.identifier,
                reason: n.reason,
                createdByName: !n.createdByName ? n.createdBy : n.createdByName.toTitleCase(),
                createdBy: n.createdBy,
            }));
        },
    );

    public onCSVClick = () => {
        if (this.csvLink) {
            this.csvLink.link.click();
        }
    }

    private onAppOnlyChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            appOnly: checked,
        });
    }

    public render = () => {
        const { expandSearch, startDate, endDate, person, gate, gateName, appOnly } = this.state;
        const { isLoading, hasViewRight } = this.props;

        const personEntries = this.getFilteredData(this.state, this.props);
        const uniqueVehiclesCount = this.getUniqueVehiclesCount(this.state, this.props);
        const uniqueGatesCount = this.getUniqueGatesCount(this.state, this.props);
        const insCount = this.getInsCount(this.state, this.props);
        const outsCount = this.getOutsCount(this.state, this.props);
        const csvData = this.getCSVData(this.state, this.props);
        const csvName = moment().valueOf();
        return (
            <div className={'flx1 fdc p5'}>
                <Card className={'flx1 fdc'}>
                    <form autoComplete='off' onSubmit={this.onFormSubmit}>
                        <Accordion expanded={expandSearch} onChange={this.onSearchExpansionChange} >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h6'>SEARCH</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                    <div className='fdc flx1'>
                                        <div className='fdr flx1'>
                                            <div className={'aic p5 mb10 pr20'}>
                                                <DateRange onChange={this.onDateRangeChanged} start={startDate} end={endDate} />
                                            </div>
                                            <div className={'aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='person'
                                                        label='Person'
                                                        value={person}
                                                        onChange={this.onPersonChange}
                                                        margin='normal'
                                                        className={'TextField'}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='gate'
                                                        label='Gate Code'
                                                        value={gate}
                                                        onChange={this.onGateChange}
                                                        margin='normal'
                                                        className={'TextField'}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='gate'
                                                        label='Gate Name'
                                                        value={gateName}
                                                        onChange={this.onGateNameChange}
                                                        margin='normal'
                                                        className={'TextField'}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'aic p5 mb10 pr20'}>
                                                <FormControlLabel
                                                    labelPlacement='start'
                                                    className='cp'
                                                    control={
                                                        <Switch
                                                            checked={appOnly}
                                                            color='primary'
                                                            onChange={this.onAppOnlyChange}/>
                                                    }
                                                    label={`App Only?`}/>
                                            </div>
                                        </div>
                                    </div>
                            </AccordionDetails>
                            <Divider />
                            <AccordionActions>
                                <Button style={{ backgroundColor: orange[600] }} color='primary' variant='contained' onClick={this.onClearSearch}>
                                    <Icon style={{ marginRight: 10 }}>highlight_off</Icon>
                                    Clear
                                </Button>
                                <Button variant='contained' type='submit'>
                                    <Icon style={{ marginRight: 10 }}>search</Icon>
                                    SEARCH
                                </Button>
                            </AccordionActions>
                        </Accordion>
                    </form>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            Person Entries
                        </Typography>
                        <IconButton color='primary' onClick={this.onSearch}>
                            <Tooltip title='Refresh'>
                                <Icon>
                                    refresh
                                </Icon>
                            </Tooltip>
                        </IconButton>
                        <span className={'flx1'} />
                        <div className={'fdr pt5 jcfe aic'}>
                            <div className={'fdr pt5'}>
                                <span style={{ paddingRight: '15px' }}><b>Vehicle Count: </b>{uniqueVehiclesCount}</span>
                                <span style={{ paddingRight: '15px' }}><b>Gates Count: </b>{uniqueGatesCount}</span>
                                <span style={{ paddingRight: '15px' }}><b>Ins: </b>{insCount}</span>
                                <span style={{ paddingRight: '15px' }}><b>Outs: </b>{outsCount}</span>
                                <span style={{ paddingRight: '15px' }}><b>Total: </b>{personEntries.length}</span>
                            </div>
                        </div>
                    </Toolbar>
                    <MaterialTable<IAccessPointEventList>
                        id={'vehicleEntryTable'}
                        data={personEntries}
                        isLoading={isLoading}
                        rowsPerPage={50}
                        onExportCsvClick={this.onCSVClick}
                        columns={[
                            {
                                header: '',
                                width: '60px',
                                renderCell: row => (
                                    <div className='jcc aic'>
                                        {
                                            hasViewRight &&
                                            row.guid &&
                                            <Tooltip title='Info'>
                                                <IconButton value={`${row.guid}`} onClick={this.onPersonEntryClick}>
                                                    <Icon>
                                                        info
                                                    </Icon>
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {
                                            !row.guid &&
                                            <div className='aic jcc p12'>
                                                <PhoneAndroid />
                                            </div>
                                        }
                                    </div>
                                ),
                            },
                            {
                                header: 'Date',
                                field: 'date',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (<span>{moment.utc(row.date).local().format('ddd YYYY-MM-DD HH:mm')}</span>);
                                },
                            },
                            {
                                header: 'Gate',
                                field: 'accessPointCode',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                        <Tooltip title={row.accessPointCode}>
                                            <div>
                                                {!row.accessPointName ? (<span>{row.accessPointCode}</span>) : (<span>{row.accessPointName}</span>)}
                                            </div>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                header: 'Direction',
                                field: 'isInbound',
                                align: 'center',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                        <Tooltip title={row.isInbound == null ? 'UNKNOWN' : (row.isInbound ? `IN` : 'OUT')}>
                                            <div className='aic jcc'>
                                                {
                                                    row.isInbound === true &&
                                                    <span className='icon-direction-in cpd' style={{ fontSize: 20 }} />
                                                }
                                                {
                                                    row.isInbound === false &&
                                                    <span className='icon-direction-out cpd' style={{ fontSize: 20 }} />
                                                }
                                                {
                                                    row.isInbound === null &&
                                                    <HelpOutlineIcon className='cpd' style={{ fontSize: 20 }} />
                                                }
                                            </div>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                header: 'Person',
                                field: 'surname',
                                enableSort: false,
                                renderCell: (row) => {
                                    return !!row.identifier ? (
                                        <Tooltip title={row.identifier}>
                                            <span>{!row.name ? `${row.init} ${row.surname}` : `${row.name} ${!row.surname ? '' : row.surname}`}</span>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title='Unknown'>
                                            <span>Unknown</span>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                header: 'Reason',
                                field: 'reason',
                                enableSort: true,
                            },
                            {
                                header: 'Created By',
                                field: 'createdByName',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                    <Tooltip title={row.createdBy}>
                                        <span>{!row.createdByName ? row.createdBy : row.createdByName.toTitleCase()}</span>
                                    </Tooltip>);
                                },
                            },

                        ]}
                    />
                </Card>
                <EntryInfoDialog maxWidth='md' fullWidth fullScreen transition={Transitions.Up}/>
                <CSVLink
                    data={csvData}
                    headers={[
                        {
                            key: 'date',
                            label: 'Date',
                        },
                        {
                            key: 'accessPointCode',
                            label: 'Gate Code',
                        },
                        {
                            key: 'accessPointName',
                            label: 'Gate Name',
                        },
                        {
                            key: 'direction',
                            label: 'Direction',
                        },
                        {
                            key: 'vehicleReg',
                            label: 'Vehicle Reg',
                        },
                        {
                            key: 'vehicleMake',
                            label: 'Vehicle Make',
                        },
                        {
                            key: 'vehicleModel',
                            label: 'Vehicle Model',
                        },
                        {
                            key: 'vehicleColour',
                            label: 'Vehicle Colour',
                        },
                        {
                            key: 'driver',
                            label: 'Driver Name',
                        },
                        {
                            key: 'driverIdentifier',
                            label: 'Driver ID',
                        },
                        {
                            key: 'reason',
                            label: 'Reason',
                        },
                        {
                            key: 'createdByName',
                            label: 'Created By Name',
                        },
                        {
                            key: 'createdBy',
                            label: 'Created By',
                        },
                    ]}
                    filename={`${csvName}_borehole.csv`}
                    className='dn'
                    ref={(r : any) => this.csvLink = r}
                    target='_blank'/>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        personEntries: state.accessPointEvent.personEntries,
        isLoading: state.accessPointEvent.isLoadingPersonEntries,
        hasViewRight: !!state.auth.session &&  state.auth.session.user.userRights.some(n => n.rightId === RIGHTS['ACCESSEP_VIEW']),
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => bindActionCreators({
    setGuid: accessPointEventActions.setGuid,
}, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(PersonEntryComponent));
