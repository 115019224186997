import axios, { AxiosPromise, CancelTokenStatic, Canceler } from 'axios';
import { IIncidentDocket, IIncidentDocketFile } from '../../../@types/model/incident';
import lodash from 'lodash';

export default class IncidentDocketHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;

    /**
     * Allows you to cancel current get list request
     */
    public static cancelGet? : Canceler;

    public static get(incidentId : number) : AxiosPromise<IIncidentDocket> {
        if (this.cancelGet) {
            this.cancelGet();
        }

        return axios.get(`${API_URL}/api/Incidents/Docket/Get`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGet = c;
            }),
            params: {
                incidentId,
            },
        });
    }
    
    public static save(docket : IIncidentDocket, files : Array<File>) {
        const data = new FormData();

        files.forEach((file) => {
            data.append('files', file);
        });

        data.append('data', JSON.stringify(docket));

        return axios.post<IIncidentDocket>(`${API_URL}/api/Incidents/Docket/Save`, data, {
            params: {
            },
        });
    }
    
    public static deleteFile(docketFile : IIncidentDocketFile) {
        return axios.delete<IIncidentDocket>(`${API_URL}/api/Incidents/Docket/DeleteFile`, {
            params: {
                docketId: docketFile.docketId,
                fileId: docketFile.id,
            },
        });
    }
    
    public static saveFile(docketFile : IIncidentDocketFile) {
        return axios.patch<IIncidentDocket>(`${API_URL}/api/Incidents/Docket/SaveFile`, docketFile);
    }
}
