import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';

interface IVideoThumbnailProps {
    title? : string;
    url : string;
    thumbUrl : string;

    onOpenFileCarousel? : () => void;
}

interface IVideoThumbnailState {
}

class VideoThumbnail extends React.Component<IVideoThumbnailProps, IVideoThumbnailState> {
    constructor(props : IVideoThumbnailProps) {
        super(props);

        this.state = {
        };
    }

    public render() {

        return (
            <Card className='fdc m10 w100 jcc aic'>
                <CardActionArea onClick={this.props.onOpenFileCarousel}>
                    <CardMedia component='img' className='w100 h100' image={this.props.thumbUrl} />
                    {
                        this.props.title &&
                        <CardContent>
                            <Typography variant='subtitle1' align='center'>
                                {this.props.title}
                            </Typography>
                        </CardContent>
                    }
                </CardActionArea>
            </Card>
        );
    }
}

export default VideoThumbnail;
