import { IObservation } from '../../../@types/model/observation';
import { IRootState } from '../../../@types/redux';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import Card from '@material-ui/core/Card';
import observationFunctions from '../../../store/observation/functions';
import MaterialTable from '../custom/materialTable/Table';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { CustomMouseEvent } from '../../../@types/helpers';
import ObservationDialog from './dialog/ObservationDialog';
import { Transitions } from '../custom/animations/Transitions';
import { dispatch } from '../../../store';
import observationActions from '../../../store/observation/actions';
import Toolbar from '@material-ui/core/Toolbar';
import FileCountButton from '../custom/button/FileCountButton';
import FileCarouselDialog from '../custom/dialog/FileCarouselDialog';
import DivisionDropdown from '../custom/dropdowns/DivisionDropdown';
import { IDivision } from '../../../@types/model/division';
import { createSelector } from 'reselect';
import DateRangeInput from '../custom/input/DateRangeInput';
import ObservationCreate from './dialog/ObservationCreateDialog';
import { IUserToken } from '../../../@types/model/right';
import ObservationRemoveDialog from './dialog/ObservationRemoveDialog';
import { RIGHTS } from '../../../appConstants';

interface IObservationListProps {
    observations : Array<IObservation>;
    isLoading : boolean;
    session : IUserToken | null;
}

interface IObservationListState {
    dateRange : [moment.Moment, moment.Moment];

    selectedIndex : number;

    division? : IDivision;
}

class ObservationList extends React.Component<IObservationListProps, IObservationListState> {
    constructor(props : IObservationListProps) {
        super(props);

        this.state = {
            dateRange: [moment.utc().startOf('day'), moment.utc().startOf('day')],
            selectedIndex: -1,
        };
    }

    public componentDidMount = () => {
        this.loadData(this.state.dateRange);
    }

    private loadData = async (dateRange : [moment.Moment, moment.Moment]) => {
        await observationFunctions.getAll(dateRange[0].valueOf(), dateRange[1].valueOf());
    }

    private onDateRangeChanged = (dateRange : [moment.Moment, moment.Moment]) => {
        this.setState({
            dateRange,
        });
        this.loadData(dateRange);
    }

    private onSearch = () => {
        this.loadData(this.state.dateRange);
    }

    public onObservationClick = (e : CustomMouseEvent) => {
        const selected = this.props.observations.find(n => n.id === Number(e.currentTarget.value));
        dispatch(observationActions.setObservation(selected));
    }

    public onFileCountClick = (guid : string) => {
        const selected = this.props.observations.findIndex(n => n.guid === guid);
        this.setState({
            selectedIndex: selected,
        });
    }

    public onFileCarouselClose = () => {
        this.setState({
            selectedIndex: -1,
        });
    }

    private readonly onDivisionChange = (division : IDivision | undefined) => {
        this.setState({
            division,
        });
    }

    private readonly getData = (state : IObservationListState, props : IObservationListProps) => props.observations;
    private readonly getDivision = (state : IObservationListState, props : IObservationListProps) => state.division;
    private readonly getSession = (state : IObservationListState, props : IObservationListProps) => props.session;
    private readonly getObservations = createSelector(
        [this.getData, this.getDivision],
        (observations, division) => {
            return observations.filter(n => (!division || n.divisionCode === division.code));
        },
    );

    private readonly getHasDeleteRight = createSelector(
        [this.getSession],
        (session) => {
            return session?.user?.userRights?.some(x => x.rightId === RIGHTS.OBS_DEL);
        },
    );

    public render = () => {
        const { dateRange, selectedIndex, division } = this.state;
        const { isLoading } = this.props;

        const observations = this.getObservations(this.state, this.props);
        const hasDeleteRight = this.getHasDeleteRight(this.state, this.props);
        return (
            <div className='flx1 fdc p15 bcg1'>
                <Toolbar disableGutters>
                    <div className={'fdc flx1 jcfs p5 mb10 pr20'}>
                        <DateRangeInput value={dateRange} onChange={this.onDateRangeChanged} fullWidth />
                    </div>
                    <div className={'fdc flx1 jcfs p5 mb10 pr20'}>
                        <DivisionDropdown onChange={this.onDivisionChange} value={division} fullWidth label='Area' />
                    </div>
                    <div className={'fdc flx1 jcfs p5 mb10 pr20'}>
                    </div>
                    <div className={'fdc flx1 jcfs p5 mb10 pr20'}>
                    </div>
                </Toolbar>
                <Card className={'flx1 fdc mb70'}>
                    <MaterialTable<IObservation>
                        id={'observationTable'}
                        data={observations}
                        isLoading={isLoading}
                        rowsPerPage={20}
                        columns={[
                            {
                                header: (
                                    <IconButton color='secondary' size='small' onClick={this.onSearch}>
                                        <Tooltip title='Refresh'>
                                            <Icon>
                                                refresh
                                            </Icon>
                                        </Tooltip>
                                    </IconButton>
                                ),
                                width: hasDeleteRight ? 100 : 70,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Edit'>
                                            <IconButton value={`${row.id}`} onClick={this.onObservationClick}>
                                                <Icon>
                                                    info
                                                </Icon>
                                            </IconButton>
                                        </Tooltip>
                                        {
                                            hasDeleteRight &&
                                            <ObservationRemoveDialog fullWidth maxWidth='sm' isLoading={isLoading}
                                            observation={row} />
                                        }
                                    </div>
                                ),
                            },
                            {
                                header: 'Date',
                                field: 'date',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (<span>{moment.utc(row.date).local().format('ddd YYYY-MM-DD')}</span>);
                                },
                            },
                            {
                                header: 'Area',
                                field: 'divisionCode',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (<span>{row.divisionCode && row.divisionName ? `${row.divisionCode} - ${row.divisionName}` : ''}</span>);
                                },
                            },
                            {
                                header: 'Number',
                                field: 'number',
                                enableSort: true,
                            },
                            {
                                header: 'Files',
                                renderCell: (row) => {
                                    return (<FileCountButton guid={row.guid} count={row.files.length} onClick={this.onFileCountClick} />);
                                },
                            },
                            {
                                header: 'Created By',
                                field: 'createdByName',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                    <Tooltip title={row.createdBy}>
                                        <span>{row.createdByName.toTitleCase()}</span>
                                    </Tooltip>);
                                },
                            },

                        ]}
                    />
                </Card>
                <div className='fdr jcfe'>
                    <ObservationCreate disabled={isLoading} isLoading={isLoading} fullWidth maxWidth='sm' transition={Transitions.Up} />
                </div>
                <ObservationDialog maxWidth='md' fullWidth fullScreen transition={Transitions.Up}/>
                <FileCarouselDialog fullWidth transition={Transitions.Up} maxWidth='md'files={selectedIndex > -1 ? observations[selectedIndex].files : []} open={selectedIndex > -1} onClose={this.onFileCarouselClose} />
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        observations: state.observation.observations,
        isLoading: state.observation.isLoading,
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(ObservationList);
