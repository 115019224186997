import axios, { AxiosPromise } from 'axios';
import { IGraveSite, IGraveSiteUpsert } from '../../../@types/model/graveSite/graveSite';

export default class GraveSiteHttpService {

    public static getAll() : AxiosPromise<Array<IGraveSite>> {
        return axios.get(`${API_URL}/api/GraveSites/GraveSite/GetAll`, {
            params: {
            },
        });
    }

    public static save(graveSite : IGraveSiteUpsert) {
        return axios.post<IGraveSite>(`${API_URL}/api/GraveSites/GraveSite/Save`, graveSite);
    }

    public static deleteFile(
        graveSiteId : number,
        fileId : number,
    ) {
        return axios.delete<IGraveSite>(`${API_URL}/api/GraveSites/GraveSite/DeleteFile`, {
            params: {
                graveSiteId,
                fileId,
            }
        });
    }

}
