import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { TextField } from '@material-ui/core';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { IUserListView } from '../../../../@types/model/right';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import RightsFunctions from '../../../../store/right/functions';

interface IUserAutocompleteProps {
    label ?: string;
    value : number | null;
    disabled ?: boolean;
    onChange : (value : IUserListView | null) => void;

    users : Array<IUserListView>;
    isLoading : boolean;
    onlyEmail ?: boolean;

    getOptionDisabled ?: (option : { label : string, value : number | null }) => boolean;

    roleId ?: number;
}

interface IUserAutocompleteState {}

class UserAutocompleteComponent extends React.PureComponent<IUserAutocompleteProps, IUserAutocompleteState> {
    constructor(props : IUserAutocompleteProps) {
        super(props);
        this.state = {};
    }

    public readonly componentDidMount = () => {
        this.loadData();
    }

    public readonly loadData = async () => {
        await RightsFunctions.loadUsers();
    }

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : number | null;
    } | null) => {
        this.props.onChange(this.props.users.find(x => x.id === value?.value) ?? null);
    }

    private readonly getValue = (state : IUserAutocompleteState, props : IUserAutocompleteProps) => props.value;
    private readonly getOnlyEmail = (state : IUserAutocompleteState, props : IUserAutocompleteProps) => props.onlyEmail;
    private readonly getData = (state : IUserAutocompleteState, props : IUserAutocompleteProps) => props.users;

    private getUsers = createSelector([
        this.getData,
        this.getOnlyEmail,
    ], (
        users,
        onlyEmail,
    ) => {
        return lodash
            .filter(users, (n) => n.isActive)
            .filter((n) => !onlyEmail || !!n.email);
    });

    private readonly getDropdown = createSelector([
        this.getUsers,
    ], (
        users,
    ) => {
        const dropdownData : Array<{
            label : string;
            value : number | null;
        }> = lodash.map(
            users,
            (user) => ({
                label: user.name,
                value: user.id,
            }));
        
        return dropdownData;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue,
        this.getDropdown,
    ], (
        value,
        dropdownData,
    ) => {
        if (value != null) {
            return dropdownData.find(x => x.value === value);
        }
        
        return null;
    });

    public render = () => {
        const { disabled, label, isLoading, getOptionDisabled } = this.props;

        const dropdownData = this.getDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        return (
            <Autocomplete 
                disabled={disabled || isLoading}
                options={dropdownData}
                value={value}
                getOptionDisabled={getOptionDisabled}
                getOptionSelected={(option, val) => option.value === val.value}
                getOptionLabel={option => option.label}
                onChange={this.onChange}
                openOnFocus
                renderInput={params => <TextField {...params} fullWidth label={label ?? 'Verdict'} />}  
            />
        );
    }
}

const mapStateToProps = (state : IRootState, props : IUserAutocompleteProps) => {
    return {
        users: state.rights.users.filter(x => !props.roleId || x.roleId === props.roleId),
        isLoading: state.rights.isLoadingUsers,
    };
};

const UserAutocomplete = connect(
    mapStateToProps,
)(UserAutocompleteComponent);

export default UserAutocomplete;