import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import lodash from 'lodash';
import { IRegion } from '../../../../@types/model/region';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import MasterDataFunctions from '../../../../store/masterData/functions';

interface IRegionMultiDropdownProps {
    id? : string;
    value? : Array<number>;
    label? : string;

    onChange? : (value? : Array<IRegion>) => void;

    required? : boolean;
    clearable? : boolean;
    autoFocus? : boolean;
    fullWidth? : boolean;
    disabled? : boolean;

    regions : Array<IRegion>;
    isLoading : boolean;
}

interface IRegionMultiDropdownState {
}

class RegionMultiDropdownComponent extends React.Component<IRegionMultiDropdownProps, IRegionMultiDropdownState> {
    constructor(props : IRegionMultiDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = () => {
        MasterDataFunctions.getAllRegions();
    }

    private onChange = (event : React.ChangeEvent<HTMLSelectElement>, value : Array<{
        label : string,
        value : number,
    }>) => {
        if (!this.props.onChange) return;

        const ids = value.map(x => x.value);
        const result = this.props.regions
            .filter(n => ids.includes(n.id));

        this.props.onChange(result);
    }

    private getRegions = (props : IRegionMultiDropdownProps) => props.regions;
    private getValue = (props : IRegionMultiDropdownProps) => props.value;

    private getActiveRegions = createSelector([
        this.getRegions,
    ], regions => regions.filter(x => x.isActive).sort((a, b) => a.name.localeCompare(b.name)));

    private getDropdown = createSelector([
        this.getActiveRegions,
        this.getValue,
    ], (regions, value) => {
        const result = lodash.map(regions, (n, k) => ({ label: `${n.name}`, value: n.id }));

        if (!value) return result;

        value.forEach((n) => {
            if (!result.some(x => x.value === n)) {
                result.push({
                    label: 'Unknown',
                    value: n,
                });
            }
        });

        return result;
    });

    private getSelectedValue = createSelector([
        this.getValue,
        this.getDropdown,
    ], (value, dropdown) => {
        if (!dropdown.length) return [];
        if (!value) return [];

        return dropdown.filter(x => value.includes(x.value));
    });

    public render = () => {
        const { required,  fullWidth, disabled, id, clearable, label, isLoading } = this.props;

        const dropdown = this.getDropdown(this.props);

        const value = this.getSelectedValue(this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value.length} required={required}>
                <Autocomplete
                    multiple
                    openOnFocus
                    disableCloseOnSelect
                    disabled={disabled || isLoading}
                    id={id}
                    options={dropdown}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required && !clearable}
                    renderTags={s => (<span className='fs16 lh21 cpla lsp024'>{s.map(n => n.label).join(', ')}</span>)}
                    renderInput={params => <TextField maxRows={1} error={required && !value.length} {...params}
                        fullWidth={fullWidth} value={value.map(n => n.label).join(', ')} label={label ?? 'Division Type'} InputLabelProps={{
                            className: 'fs16 lh21 cpla lsp024',
                        }} />}
                />
                {
                    required && !value.length &&
                    <FormHelperText error>Required</FormHelperText>
                }
                {
                    isLoading &&
                    <LinearProgress className='wfill posb0 posa' />
                }
            </FormControl>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        regions: state.masterData.regions,
        isLoading: state.masterData.isLoadingRegions,
    };
};

// tslint:disable-next-line: variable-name
const RegionMultiDropdown = connect(
    mapStateToProps,
)(RegionMultiDropdownComponent);

export default RegionMultiDropdown;
