import axios, { AxiosPromise } from 'axios';
import { IRight } from '../../@types/model/right';

function getList(name? : string, code? : string, isActive? : boolean) : AxiosPromise<Array<IRight>> {
    return axios.get<Array<IRight>>(`${API_URL}/api/Rights/Right/GetList`, {
        params : {
            name,
            code,
            isActive,
        },
    });
}

const rightHttpService = {
    getList,
};

export default rightHttpService;
