import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import React from 'react';
import Fab from '@material-ui/core/Fab';
import { FormControl, TextField } from '@material-ui/core';
import OutsideClicker from '../custom/OutsideClicker';
import AccessPointCategoryDropdown from '../custom/autocomplete/AccessPointCategoryMultiDropdown';
import { IAccessPointCategory } from '../../../@types/model/masterData/accessPointCategory';

interface IAccessPointFilterPopperProps {
    id ?: string;
    open : boolean;
    anchorEl : HTMLButtonElement | null;
    code : string;
    name : string;
    division : string;
    category : Array<number>;
    onChange ?: (code : string, name : string, division : string, category : Array<number>) => void;
    onCancelClick ?: () => void;
}

interface IAccessPointFilterPopperState {
    code : string;
    name : string;
    division : string;
    category : Array<number>;
}

export default class AccessPointFilterPopper extends React.PureComponent<IAccessPointFilterPopperProps, IAccessPointFilterPopperState> {
    constructor(props : IAccessPointFilterPopperProps) {
        super(props);
        this.state = {
            code : '',
            name : '',
            division : '',
            category: [],
        };
    }

    public readonly componentDidUpdate = (prevProps : IAccessPointFilterPopperProps) => {
        if (!prevProps.open && this.props.open) {
            const { code, name, division, category } = this.props;

            this.setState({
                code,
                name,
                division,
                category,
            });
        }
    }

    private readonly onCodeChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({ code : event.target.value });
    }

    private readonly onNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({ name : event.target.value });
    }

    private readonly onDivisionChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({ division : event.target.value });
    }

    private readonly onCategoryChange = (value? : Array<IAccessPointCategory>) => {
        this.setState({ 
            category: value?.map(x => x.id) ?? [],
         });
    }

    private readonly onApplyClick = (event ?: React.FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }

        if (!this.props.onChange) return;

        const { code, name, division, category } = this.state;

        this.props.onChange(code, name, division, category);
    }

    public readonly render = () => {
        const { open, anchorEl, id, onCancelClick } = this.props;
        const { code, name, division, category } = this.state;

        return (
            <Popper
                id={`${id}_rights_filter_menu`}
                open={open}
                anchorEl={anchorEl}
                transition
                className={'filter-popper'}
                placement={'bottom'}
            >
                {
                    ({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <div className='filter-popper-offset'>
                                {
                                    open &&
                                    <OutsideClicker onClickedOutside={onCancelClick}>
                                        <form onSubmit={this.onApplyClick} className={'fdc'}>
                                            <div className={'fdr'}>
                                                <div className={'flx1 pr5'}>
                                                    <FormControl fullWidth> 
                                                        <TextField
                                                            autoComplete={'off'}
                                                            label={'Name'}
                                                            value={name}
                                                            onChange={this.onNameChange}
                                                            margin={'normal'}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div className={'flx1 pl5'}>
                                                    <FormControl fullWidth> 
                                                        <TextField
                                                            autoComplete={'off'}
                                                            label={'Code'}
                                                            value={code}
                                                            onChange={this.onCodeChange}
                                                            margin={'normal'}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div className={'fdr'}>
                                                <div className={'flx1 pr5'}>
                                                    <FormControl fullWidth> 
                                                        <TextField
                                                            autoComplete={'off'}
                                                            label={'Division'}
                                                            value={division}
                                                            onChange={this.onDivisionChange}
                                                            margin={'normal'}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div className={'flx1 pl5'}>
                                                </div>
                                            </div>
                                            <div className={'fdr pb15'}>
                                                <div className={'flx1'}>
                                                    <AccessPointCategoryDropdown
                                                        clearable
                                                        fullWidth
                                                        value={category}
                                                        onChange={this.onCategoryChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className={'fdr aic pb15'}>
                                                <span className='flx1'/>
                                                <Fab variant={'extended'} color={'secondary'} className={'fab cancel'} size={'small'} onClick={onCancelClick} type={'button'}>
                                                    CANCEL
                                                </Fab> 
                                                <Fab variant={'extended'} color={'secondary'} className={'fab save'} size={'small'} type={'submit'}>
                                                    APPLY
                                                </Fab>
                                            </div>
                                        </form>
                                    </OutsideClicker>
                                }
                            </div>
                        </Fade>
                    )
                }
            </Popper>
        )
    }
}