import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import { createHashHistory, History } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import auth from './auth/reducer';
import general from './general/reducer';
import data from './data/reducer';
import rights from './right/reducer';
import graveSites from './graveSites/reducer';
import observation from './observation/reducer';
import incident from './incident/reducer';
import occurrence from './occurrence/reducer';
import accessPointEvent from './accessPointEvent/reducer';
import file from './file/reducer';
import masterData from './masterData/reducer';

export const hashHistory = createHashHistory();
const composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const createRootReducer = (history : History<any>) => combineReducers({
    router: connectRouter(history),
    auth,
    data,
    rights,
    general,
    graveSites,
    observation,
    occurrence,
    incident,
    accessPointEvent,
    file,
    masterData,
});

const configureStore = () => {
    return createStore(
        createRootReducer(hashHistory),
        composeEnhancer(
            applyMiddleware(
                routerMiddleware(hashHistory),
            ),
        ),
    );
};

const store = configureStore();

export default store;
export const dispatch = store.dispatch;
export const getState = store.getState;
