import axios from 'axios';
import { IPowerSource } from '../../../@types/model/masterData/powerSource';

export default class PowerSourceHttpService {
    public static getAll() {
        return axios.get<Array<IPowerSource>>(`${API_URL}/api/MasterData/PowerSource/GetAll`, {
            params : {
            },
        });
    }
    public static save(powerSource : IPowerSource) {
        return axios.post<IPowerSource>(`${API_URL}/api/MasterData/PowerSource/Save`, powerSource, {
            params : {
            },
        });
    }
}
