import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import lodash from 'lodash';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { POACHING_METHODS } from '../../../../appConstants';
import Checkbox from '@material-ui/core/Checkbox';

interface IPoachingMethodDropdownProps {
    id? : string;
    value? : Array<string>;

    onChange? : (value? : Array<string>) => void;

    required? : boolean;
    clearable? : boolean;
    autoFocus? : boolean;

    fullWidth? : boolean;
    disabled? : boolean;
}

interface IPoachingMethodDropdownState {
}

export default class PoachingMethodDropdown extends React.Component<IPoachingMethodDropdownProps, IPoachingMethodDropdownState> {
    constructor(props : IPoachingMethodDropdownProps) {
        super(props);

        this.state = {
        };
    }

    private onChange = (event : React.ChangeEvent<HTMLSelectElement>, value : Array<{
        label : string,
        value : string,
    }>) => {
        if (this.props.onChange) this.props.onChange(value.map(n => n.value));
    }

    private getValue = (props : IPoachingMethodDropdownProps, state : IPoachingMethodDropdownState) => props.value;
    private getRequired = (props : IPoachingMethodDropdownProps, state : IPoachingMethodDropdownState) => props.required;

    private getDropdown = createSelector([
        this.getRequired,
    ], (required) => {
        const result = lodash.map(POACHING_METHODS, n => ({ label: `${n}`, value: n }));

        if (!required) {
            result.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return result;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getDropdown,
    ], (value, required, dropdown) => {
        if (value) {
            return value.map(x => ({
                label: x, value: x,
            }));
        }

        if (!value && !required) {
            return [{
                label: 'ALL',
                value: '',
            }];
        }

        return [];
    });

    public render = () => {
        const { required,  fullWidth, disabled, id, clearable } = this.props;

        const dropdown = this.getDropdown(this.props);

        const value = this.getSelectedValue(this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                <Autocomplete
                    disabled={disabled}
                    id={id}
                    options={dropdown}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required && !clearable}
                    limitTags={2}
                    multiple
                    disableCloseOnSelect
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                            icon={<CheckBoxOutlineBlankIcon color='primary' fontSize='small' />}
                            checkedIcon={<CheckBoxIcon  color='primary' fontSize='small' />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                            />
                            {option.label}
                        </React.Fragment>
                    )}
                    renderInput={params => <TextField maxRows={1} error={required && !value} {...params} fullWidth={fullWidth}  label='Poaching Methods' />}
                />
                {
                    required && !value?.length &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    }
}
