import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { IIncident } from '../../../../@types/model/incident';
import lodash from 'lodash';
import IncidentFunctions from '../../../../store/incident/functions';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

interface IIncidentSuspectDeleteButtonProps {
    incident : IIncident;

    index : number;

    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IIncidentSuspectDeleteButtonState {
    open : boolean;
}

export default class IncidentSuspectDeleteButton extends React.Component<IIncidentSuspectDeleteButtonProps, IIncidentSuspectDeleteButtonState> {
    constructor(props : IIncidentSuspectDeleteButtonProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    private onDeleteClick = () => {
        this.setState({
            open: true,
        });
    }

    private delete = async () => {
        const incident = lodash.cloneDeep(this.props.incident);

        if (incident) {
            incident.suspects.splice(this.props.index, 1);
            const result = await IncidentFunctions.save(incident);

            if (result) {
                this.onClose();
            }
        }
    }

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.delete();
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, incident, index } = this.props;
        const { open } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Delete'>
                    <div>
                        <IconButton className='cpr' size='small' onClick={this.onDeleteClick} aria-label='Delete' disabled={isLoading}>
                            <Icon>
                                delete
                            </Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='complainant-info-title'
                    aria-describedby='complainant-info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Remove Suspect {incident.suspects[index].name}
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className='flx1 fdr aifs'>
                               Are you sure you want to remove suspect {incident.suspects[index].name}?
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    }
}
