import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { IDivision } from '../../../../@types/model/division';
import { IOccurrence } from '../../../../@types/model/occurrence';
import { IRootState } from '../../../../@types/redux';
import OccurrenceFunctions from '../../../../store/occurrence/functions';
import { FormControlLabel, Switch } from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers/TimePicker';
import DepartmentDropdown from '../../custom/dropdowns/DepartmentDropdown';

interface IOccurrenceEditDialogProps {
    occurrence : IOccurrence;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    isLoading : boolean;
}

interface IOccurrenceEditDialogState {
    open : boolean;
    incident : boolean;
    date : moment.Moment | null;
    time: moment.Moment | null;
    description : string;
    divisionId ?: number | null
}

class OccurrenceEditDialog extends React.Component<IOccurrenceEditDialogProps, IOccurrenceEditDialogState> {
    private readonly maxDate : moment.Moment = moment().utc().startOf('day');
    constructor(props : IOccurrenceEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            date: moment.utc().startOf('day'),
            time: moment.utc().startOf('day'),
            description: '',
            incident: false,
        };
    }

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private onClick = () => {
        const { date, description, incident, divisionId} = this.props.occurrence;

        this.setState({
            open: true,
            incident,
            date: moment.utc(date).startOf('day'),
            time: moment.utc(date),
            description,
            divisionId: divisionId
        });
    }

    private readonly onDateChange = (momentDate : moment.Moment | null) => {
        if (momentDate && momentDate?.isAfter(this.maxDate)) return;
        const date = momentDate?.startOf('day') ?? null;

        this.setState({
            date,
        });
    }

    private readonly onTimeChange = (momentTime : moment.Moment | null) => {
        if (!momentTime) {
            this.setState({
                time: null,
            });
            return;
        }

        this.setState({
            time: moment.utc().hours(momentTime.hours()).minutes(momentTime.minutes()),
        });
    }

    private readonly onDivisionChange = (division? : IDivision | undefined) => {
        this.setState({
            divisionId: division?.id,
        });
    }

    private readonly onDescriptionChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            description: event.currentTarget.value,
        });
    }

    private readonly onIncidentChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            incident: event.currentTarget.checked,
        });
    }

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private readonly save = async () => {
        const { date, time, description, divisionId, incident } = this.state;

        if (!date || !description || !divisionId || !time) return;

        const dateTime = moment.utc(date).startOf('day').hours(time.hours()).minutes(time.minutes()).valueOf();

        const result = await OccurrenceFunctions.update(
            this.props.occurrence.guid,
            dateTime,
            divisionId,
            description,
            incident,
            this.props.occurrence.isActive,
        );

        if (result != null) {
            this.onClose();
        }

    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, occurrence, isLoading } = this.props;
        const { open, date, time, description, divisionId, incident } = this.state;
        return (<React.Fragment>
            <Tooltip title='Edit'>
                <div>
                    <IconButton color='inherit' size='small' onClick={this.onClick} aria-label='Edit' disabled={isLoading}>
                        <Icon>edit</Icon>
                    </IconButton>
                </div>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='edit-info-title'
                aria-describedby='edit-info-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            Edit Occurrence {occurrence.number}
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <form className='fdc flx1 aifs ais' onSubmit={this.onSubmit}>
                    <DialogContent className='fdc flx1 hfill'>
                        <div className={'flx1 aic p5 mb10 pr20'}>
                            <FormControl style={{ marginRight: '16px', flex: 1 }}>
                                    <KeyboardDatePicker
                                        value={date}
                                        maxDate={this.maxDate}
                                        onChange={this.onDateChange}
                                        format={'YYYY-MM-DD'} label='Occurrence Date'
                                        id='OccurrenceDate'
                                        className={'TextField'}
                                        required
                                        margin={'normal'}
                                        error={!date}
                                    />
                                    {
                                        !date &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                                <FormControl style={{ marginLeft: '16px', flex: 1 }}>
                                    <KeyboardTimePicker
                                        value={time}
                                        onChange={this.onTimeChange}
                                        format={'HH:mm:ss'} label='Time'
                                        id='OccurrenceDate'
                                        className={'TextField'}
                                        required
                                        margin={'normal'}
                                        error={!time}
                                    />
                                    {
                                        !time &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                        <div className={'flx1 aic p5 mb10 pr20'}>
                            <DepartmentDropdown fullWidth onChange={this.onDivisionChange} value={divisionId}/>
                        </div>
                        <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                            <FormControl fullWidth>
                                <InputLabel required error={!description} htmlFor='description-input'>Description</InputLabel>
                                <Input
                                    id='description-input'
                                    value={description}
                                    onChange={this.onDescriptionChange}
                                    aria-describedby='description-input-helper-text'
                                    required
                                    multiline
                                    error={!description}
                                />
                                {
                                    !description &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <div className={'flx1 aic p5 mb10 pr20'}>
                                <FormControlLabel
                                    labelPlacement='end'
                                    className='cp'
                                    control={
                                        <Switch
                                            checked={incident}
                                            color='primary'
                                            onChange={this.onIncidentChange}/>
                                    }
                                    label={`Incident?`}/>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>);
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.occurrence.isLoading,
    };
};

export default connect(
    mapStateToProps,
)(OccurrenceEditDialog);
