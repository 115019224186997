import React from 'react';
import moment, { Moment } from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import { KeyboardTimePicker } from '@material-ui/pickers/TimePicker';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MultiFileDropZone from '../../custom/file/MultiFileDropZone';
import { IFileUpload } from '../../../../@types/viewmodel/file';
import { IIncidentCapture } from '../../../../@types/viewmodel/incident';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { IIncidentCategory } from '../../../../@types/model/incident';
import PoachingMethodDropdown from '../../custom/dropdowns/PoachingMethodDropdown';
import IncidentCategoryMultiDropdown from '../../custom/dropdowns/IncidentCategoryMultiDropdown';
import HousebreakingEntryPointsDropdown from '../../custom/dropdowns/HousebreakingEntryPointsDropdown';
import WeatherConditionsDropdown from '../../custom/dropdowns/WeatherConditionsDropdown';

interface IIncidentCaptureProps {
    group? : string;
    value? : IIncidentCapture;

    onSubmit? : (value : IIncidentCapture, back? : boolean) => void;
}

interface IIncidentCaptureState {
    incidentDate : Moment;
    incidentTime : Moment;
    incidentPlace : string;
    incidentInjury : string;
    incidentValue : string;
    incidentType : string;
    incidentDescription : string;
    incidentCategories : Record<string, string>;
    incidentOutcome : string;
    poachingMethods : Array<string>;
    houseBreakingEntryPoints : Array<string>;
    weatherConditions : Array<string>;
    roadCondition : string;

    incidentFiles : Array<IFileUpload>;

    actionTaken : string;
    suggestions : string;
}

export default class IncidentCapture extends React.Component<IIncidentCaptureProps, IIncidentCaptureState> {
    private readonly maxFiles = 3;
    private maxDate : moment.Moment = moment().startOf('day');
    constructor(props : IIncidentCaptureProps) {
        super(props);
        this.state = {
            incidentDate: !props.value ? moment.utc().startOf('day') : moment.utc(props.value.incidentDate),
            incidentTime: !props.value ? moment.utc().startOf('day') : moment.utc(props.value.incidentTime),
            incidentPlace: !props.value ? '' : props.value.incidentPlace,
            incidentInjury: !props.value ? '' : props.value.incidentInjury,
            incidentValue: !props.value || !props.value.incidentValue ? '' : props.value.incidentValue.toString(),
            incidentType: !props.value ? '' : props.value.incidentType,
            incidentDescription: !props.value ? '' : props.value.incidentDescription,
            incidentFiles: !props.value ? [] : props.value.incidentFiles,
            incidentCategories: !props.value ? {} : props.value.incidentCategories,
            incidentOutcome: !props.value ? '' : props.value.incidentOutcome,
            poachingMethods: !props.value ? [] : props.value.poachingMethods,
            houseBreakingEntryPoints: !props.value ? [] : props.value.houseBreakingEntryPoints,
            weatherConditions: !props.value ? [] : props.value.weatherConditions,
            roadCondition: !props.value ? '' : props.value.roadCondition,
            actionTaken: !props.value ? '' : props.value.actionTaken,
            suggestions: !props.value ? '' : props.value.suggestions,
        };
    }

    private submit = (back? : boolean) => {
        if (!!this.props.onSubmit) {
            const { incidentDate, incidentTime, incidentPlace, incidentInjury, incidentValue, incidentType, incidentDescription, incidentFiles,
                incidentCategories, incidentOutcome, poachingMethods, houseBreakingEntryPoints,
                weatherConditions, roadCondition, actionTaken, suggestions } = this.state;

            this.props.onSubmit({
                incidentDate: incidentDate.valueOf(),
                incidentTime: incidentTime.valueOf(),
                incidentPlace,
                incidentInjury,
                incidentValue: parseFloat(incidentValue),
                incidentType,
                incidentDescription,
                incidentFiles,
                incidentCategories,
                incidentOutcome,
                poachingMethods,
                houseBreakingEntryPoints,
                weatherConditions,
                roadCondition,
                actionTaken,
                suggestions,
            }, back);
        }
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.submit();
    }

    private onBack = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.submit(true);
    }

    private onIncidentDateChange = (momentDate : Moment | null) => {
        if (momentDate && momentDate?.isAfter(this.maxDate)) return;
        const date = momentDate?.startOf('day');

        this.setState({
            incidentDate: date?.asUTC() ?? this.state.incidentDate,
        });
    }

    private onIncidentTimeChange = (momentDate : Moment | null) => {
        this.setState({
            incidentTime: momentDate ? momentDate.asUTC() : moment.utc().add(moment.parseZone().utcOffset(), 'minutes'),
        });
    }

    private onIncidentPlaceChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            incidentPlace: event.currentTarget.value,
        });
    }

    private onIncidentInjuryChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            incidentInjury: event.currentTarget.value,
        });
    }

    private onIncidentTypeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            incidentType: event.currentTarget.value,
        });
    }

    private onIncidentValueChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.currentTarget.value && parseFloat(event.currentTarget.value) === null) return;
        this.setState({
            incidentValue: event.currentTarget.value,
        });
    }

    private onIncidentDescriptionChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            incidentDescription: event.currentTarget.value,
        });
    }

    private onIncidentOutcomeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            incidentOutcome: event.currentTarget.value,
        });
    }

    private onFileSelected = (file : IFileUpload) => {
        const incidentFiles = this.state.incidentFiles.slice();

        const index = incidentFiles.findIndex(n => n.name === file.name);

        if (index > -1) {
            incidentFiles.splice(index, 1, file);
        } else {
            incidentFiles.push(file);
        }

        this.setState({
            incidentFiles,
        });
    }

    private onIncidentCategoryChange = (value : Array<IIncidentCategory>) => {
        this.setState({
            incidentCategories: value.reduce((prev, incidentCategory, index) => {
                prev[incidentCategory.guid] = incidentCategory.name;
                return prev;
            }, {} as Record<string, string>),
        });
    }

    private onFileRemove = (file : IFileUpload) => {
        const incidentFiles = this.state.incidentFiles.slice();

        const index = incidentFiles.findIndex(n => n.name === file.name);

        if (index > -1) {
            incidentFiles.splice(index, 1);
        }

        this.setState({
            incidentFiles,
        });
    }

    private onPoachingMethodsChange = (value ?: Array<string>) => {
        this.setState({
            poachingMethods: value?.slice() ?? [],
        });
    }

    private onHouseBreakingEntryPointsChange = (value ?: Array<string>) => {
        this.setState({
            houseBreakingEntryPoints: value?.slice() ?? [],
        });
    }

    private onWeatherConditionsChange = (value ?: Array<string>) => {
        this.setState({
            weatherConditions: value?.slice() ?? [],
        });
    }

    private onRoadConditionChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            roadCondition: event.currentTarget.value,
        });
    }

    private onActionTakenChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            actionTaken: event.currentTarget.value,
        });
    }

    private onSuggestionsChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            suggestions: event.currentTarget.value,
        });
    }

    public render = () => {
        const { incidentDate, incidentTime, incidentPlace, incidentInjury, incidentValue, incidentType, incidentDescription, incidentFiles,
            incidentCategories, incidentOutcome, poachingMethods, houseBreakingEntryPoints,
            weatherConditions, roadCondition, actionTaken, suggestions } = this.state;
        const { group } = this.props;
        return (
        <form className='fdc flx1 aifs ais' onSubmit={this.onSubmit}>
            <Card className='fdr aifs mb10 sbs mnh242'>
                <CardContent className='flx1 fdr aifs'>
                    <div className='flx1 fdr aifs'>
                        <div className='fdc flx1'>
                            <div className='fdr aifs'>
                                <div className={'flx1 aic p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <KeyboardDatePicker
                                            value={incidentDate}
                                            maxDate={this.maxDate}
                                            onChange={this.onIncidentDateChange}
                                            format={'YYYY-MM-DD'} label='Incident Date'
                                            id='IncidentDate'
                                            className={'TextField'}
                                            required
                                            margin={'normal'}
                                            error={!!!incidentDate}
                                            fullWidth
                                        />
                                        {
                                            !!!incidentDate &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <KeyboardTimePicker
                                        fullWidth
                                        value={incidentTime}
                                        onChange={this.onIncidentTimeChange}
                                        ampm={false}
                                        todayLabel='now'
                                        showTodayButton
                                        format='HH:mm' label='Time' />
                                </div>
                                <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel required error={!incidentPlace} htmlFor='incidentPlace-input'>Place</InputLabel>
                                        <Input
                                            id='incidentPlace-input'
                                            value={incidentPlace}
                                            onChange={this.onIncidentPlaceChange}
                                            aria-describedby='incidentPlace-input-helper-text'
                                            required
                                            error={!incidentPlace}
                                        />
                                        {
                                            !incidentPlace &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel required error={!incidentType} htmlFor='incidentType-input'>Short Description</InputLabel>
                                        <Input
                                            id='incidentType-input'
                                            value={incidentType}
                                            onChange={this.onIncidentTypeChange}
                                            aria-describedby='incidentType-input-helper-text'
                                            required
                                            error={!incidentType}
                                        />
                                        {
                                            !incidentType &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                            </div>
                            <div className='fdr aifs'>
                                <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <IncidentCategoryMultiDropdown group={group} required fullWidth onChange={this.onIncidentCategoryChange} value={Object.keys(incidentCategories)} />
                                </div>
                                <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor='incidentActionTaken-input'>Action Taken</InputLabel>
                                        <Input
                                            id='incidentActionTaken-input'
                                            value={actionTaken}
                                            onChange={this.onActionTakenChange}
                                            aria-describedby='incidentActionTaken-input-helper-text'
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor='incidentSuggestions-input'>Suggestions</InputLabel>
                                        <Input
                                            id='incidentSuggestions-input'
                                            value={suggestions}
                                            onChange={this.onSuggestionsChange}
                                            aria-describedby='incidentSuggestions-input-helper-text'
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                </div>
                            </div>
                            {
                                Object.values(incidentCategories).some(n => n.toLowerCase() === 'poaching') &&
                                <div className='fdr aifs'>
                                    <div className={'flx1 aife p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <PoachingMethodDropdown
                                            fullWidth
                                            value={poachingMethods}
                                            onChange={this.onPoachingMethodsChange}
                                            required
                                        />
                                    </div>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    </div>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    </div>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    </div>
                                </div>
                            }
                            {
                                Object.values(incidentCategories).some(n => n.toLowerCase() === 'housebreaking') &&
                                <div className='fdr aifs'>
                                    <div className={'flx1 aife p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <HousebreakingEntryPointsDropdown
                                            fullWidth
                                            value={houseBreakingEntryPoints}
                                            onChange={this.onHouseBreakingEntryPointsChange}
                                            required
                                        />
                                    </div>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    </div>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    </div>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    </div>
                                </div>
                            }
                            {
                                Object.values(incidentCategories).some(n => n.toLowerCase() === 'motor vehicle accident') &&
                                <div className='fdr aifs'>
                                    <div className={'flx1 aife p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <WeatherConditionsDropdown
                                            fullWidth
                                            value={weatherConditions}
                                            onChange={this.onWeatherConditionsChange}
                                            required
                                        />
                                    </div>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor='roadCondition-input'>Road Condition</InputLabel>
                                            <Input
                                                id='roadCondition-input'
                                                type='text'
                                                value={roadCondition}
                                                onChange={this.onRoadConditionChange}
                                                error={!roadCondition}
                                                aria-describedby='roadCondition-input-helper-text'
                                                fullWidth
                                            />
                                            {
                                                !roadCondition &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    </div>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    </div>
                                </div>
                            }
                            <div className='flx1 fdr aifs'>
                                <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor='incidentInjury-input'>Injury Type</InputLabel>
                                        <Input
                                            id='incidentInjury-input'
                                            value={incidentInjury}
                                            onChange={this.onIncidentInjuryChange}
                                            aria-describedby='incidentInjury-input-helper-text'
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor='incidentValue-input'>Value</InputLabel>
                                        <Input
                                            id='incidentValue-input'
                                            type='number'
                                            value={incidentValue}
                                            onChange={this.onIncidentValueChange}
                                            error={!!incidentValue && !Number(incidentValue)}
                                            aria-describedby='incidentValue-input-helper-text'
                                        />
                                        {
                                            incidentValue && !Number(incidentValue) &&
                                            <FormHelperText error>Invalid</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx2 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel required error={!incidentDescription} htmlFor='incidentDescription-input'>Detailed Description</InputLabel>
                                        <Input
                                            id='incidentDescription-input'
                                            value={incidentDescription}
                                            onChange={this.onIncidentDescriptionChange}
                                            aria-describedby='incidentDescription-input-helper-text'
                                            required
                                            multiline
                                            error={!incidentDescription}
                                        />
                                        {
                                            !incidentDescription &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx2 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor='incidentOutcome-input'>Outcome</InputLabel>
                                        <Input
                                            id='incidentOutcome-input'
                                            value={incidentOutcome}
                                            onChange={this.onIncidentOutcomeChange}
                                            aria-describedby='incidentOutcome-input-helper-text'
                                            multiline
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className='flx1 fdr aifs'>
                                <div className={'flx1'}>
                                    <MultiFileDropZone accept='audio/*,video/*,image/*,application/pdf' files={incidentFiles} disabled={incidentFiles.length >= this.maxFiles}
                                    onFileSelected={this.onFileSelected} onFileRemoved={this.onFileRemove} />
                                </div>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <div className='fdr mr15 ais'>
                <span className='flx1' />
                <div>
                    <Fab className={`nbs bct cp pl40 pr40 mr15`}
                        variant='extended'
                        onClick={this.onBack}
                        size='medium'
                        type='button'>
                        Back
                    </Fab>
                </div>
                <div>
                    <Fab className={`${!incidentPlace || !incidentDate || !incidentTime || !incidentType || !incidentDescription || !Object.keys(incidentCategories).length ? 'cg0 bcg1' : 'cp bcy'} pl40 pr40`}
                        variant='extended'
                        size='medium'
                        disabled={!incidentPlace || !incidentDate || !incidentTime || !incidentType || !incidentDescription || !Object.keys(incidentCategories).length}
                        type='submit'>
                        Next
                    </Fab>
                </div>
            </div>
        </form>
        );
    }
}
