import { FormControl, Icon, IconButton, TextField, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import moment from 'moment';
import React from 'react';
import { createSelector } from 'reselect';
import * as uuid from 'uuid';
import { IGraveSite } from '../../../../@types/model/graveSite/graveSite';
import { IGraveVisit } from '../../../../@types/model/graveSite/graveVisit';
import { Transitions } from '../../custom/animations/Transitions';
import MaterialTable from '../../custom/materialTable/Table';
import GraveVisitEditDialog from '../dialog/EditGraveVisitDialog';

interface IGraveVisitView {
    id : number;
    dateOfVisit : string;
    timeOfVisit : string;
    fileNumber : string;
    nameOfDeceased : string;
    nameOfVisitor : string;
    reasonForVisit : string;
    arrived : boolean;
    assistingEmployeeNumber : string;
    assistingEmployeeName : string;
}

interface IGraveSiteVisitsViewProps {
    graveSite : IGraveSite;
}

interface IGraveSiteVisitsViewState {
    dateOfVisit : moment.Moment | null;
    nameOfDeceased : string;
    visitor : string;
    reasonForVisit : string;

    selectedVisit : IGraveVisit | null;
}

export default class GraveSiteVisitsView extends React.PureComponent<IGraveSiteVisitsViewProps, IGraveSiteVisitsViewState> {
    constructor(props : IGraveSiteVisitsViewProps) {
        super(props);
        this.state = {
            dateOfVisit: null,
            nameOfDeceased: '',
            visitor: '',
            reasonForVisit: '',
            selectedVisit: null,
        };
    }

    private onVisitDateChange = (dateOfVisit : moment.Moment | null) => {
        this.setState({
            dateOfVisit,
        });
    }

    private onNameOfDeceasedChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            nameOfDeceased: event.target.value,
        });
    }

    private onVisitorChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            visitor: event.target.value,
        });
    }

    private onReasonForVisitChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            reasonForVisit: event.target.value,
        });
    }

    private readonly getData = (state : IGraveSiteVisitsViewState, props : IGraveSiteVisitsViewProps) => props.graveSite;
    private readonly getGraveVisits = createSelector(
        [this.getData],
        (graveSite) => {
            return graveSite
                .graves
                .flatMap(n => n.graveVisits)
                .map<IGraveVisitView>(n => ({
                    id: n.id,
                    dateOfVisit: moment.utc(n.visitDateTime).local().format('YYYY-MM-DD'),
                    timeOfVisit: moment.utc(n.visitDateTime).local().format('HH:mm'),
                    fileNumber: graveSite.graves.find(x => x.id === n.graveId)?.fileNumber ?? '',
                    arrived: n.visitorArrived,
                    assistingEmployeeName: n.assistingEmployeeName,
                    assistingEmployeeNumber: n.assistingEmployeeNumber,
                    nameOfDeceased: graveSite.graves.find(x => x.id === n.graveId)?.nameOfDeceased ?? '',
                    nameOfVisitor: n.visitorName,
                    reasonForVisit: n.graveVisitReason?.description,
                }));
        },
    );

    public readonly onEditClose = () => {
        this.setState({
            selectedVisit: null,
        });
    }

    public readonly onCreateClick = () => {
        this.setState({
            selectedVisit: {
                guid: uuid.v4(),
                graveId: 0,
                id: 0,
                isActive: true,
            } as IGraveVisit,
        });
    }

    public readonly onEditClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const graveIndex = this.props
            .graveSite
            .graves
            .findIndex(x => x.graveVisits.some(n => n.id === Number(event.currentTarget.value)));
        const visit = this.props.graveSite.graves[graveIndex].graveVisits.find(n => n.id === Number(event.currentTarget.value));

        if (!visit) return;

        this.setState({
            selectedVisit: {
                ...visit,
            },
        });
    }

    public render = () => {
        const { dateOfVisit, nameOfDeceased, visitor, reasonForVisit,
        selectedVisit } = this.state;
        const { graveSite } = this.props;

        const graveVisits = this.getGraveVisits(this.state, this.props);

        return (
            <div className={'fdc flx1 bcg1 pr30 pl30 pb30'}>
                <div className='fdr'>
                    <div className='fdc flx1 mr15'>
                        <FormControl fullWidth>
                            <KeyboardDatePicker
                                value={dateOfVisit}
                                onChange={this.onVisitDateChange}
                                format={'YYYY-MM-DD'}
                                label='Date of Visit'
                                id='dateOfVisit'
                                className={'TextField'}
                                margin={'normal'}
                                fullWidth
                                clearable
                            />
                        </FormControl>
                    </div>
                    <div className='fdc flx1 mr15'>
                        <FormControl fullWidth>
                            <TextField
                                autoComplete='off'
                                id='nameOfDeceased'
                                label='Deceased'
                                value={nameOfDeceased}
                                onChange={this.onNameOfDeceasedChange}
                                margin='normal'
                                className={'TextField'}
                                InputProps={{
                                    endAdornment: (<Icon className='cp'>search</Icon>),
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className='fdc flx1 mr15'>
                        <FormControl fullWidth>
                            <TextField
                                autoComplete='off'
                                id='visitor'
                                label='Visitor'
                                value={visitor}
                                onChange={this.onVisitorChange}
                                margin='normal'
                                className={'TextField'}
                                InputProps={{
                                    endAdornment: (<Icon className='cp'>search</Icon>),
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className='fdc flx1 mr15'>
                        <FormControl fullWidth>
                            <TextField
                                autoComplete='off'
                                id='visitor'
                                label='Reason For Visit'
                                value={reasonForVisit}
                                onChange={this.onReasonForVisitChange}
                                margin='normal'
                                className={'TextField'}
                                InputProps={{
                                    endAdornment: (<Icon className='cp'>search</Icon>),
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className='fdc flx1'>
                    </div>
                </div>
                <div className='fdc flx1 mt15 mb15'>
                    <MaterialTable<IGraveVisitView>
                        id={'graveVisitTable'}
                        data={graveVisits}
                        rowsPerPage={20}
                        columns={[
                            {
                                header: '',
                                field: '',
                                width: 50,
                                renderCell: (row) => {
                                    return (<>
                                        <Tooltip title='Info'>
                                            <div>
                                                <IconButton value={row.id} color='primary' onClick={this.onEditClick} aria-label='Info'>
                                                    <Icon>edit</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </>);
                                },
                            },
                            {
                                header: 'Date of Visit',
                                field: 'dateOfVisit',
                                width: 100,
                            },
                            {
                                header: 'Time of Visit',
                                field: 'timeOfVisit',
                                width: 100,
                            },
                            {
                                header: 'File Number',
                                field: 'fileNumber',
                                width: 100,
                            },
                            {
                                header: 'Name of desceased',
                                field: 'nameOfDeceased',
                                width: 100,
                            },
                            {
                                header: 'Name of visitor',
                                field: 'nameOfVisitor',
                                width: 100,
                            },
                            {
                                header: 'Reason for visit',
                                field: 'reasonForVisit',
                                width: 100,
                            },
                            {
                                header: 'Visitor Arrived?',
                                field: 'arrived',
                                width: 100,
                                renderCell: (row) => {
                                    return (
                                        <div className={'jcc aic pr40'}>
                                        {
                                            !!row.arrived &&
                                            <Icon className={'cp'}>
                                                check_circle
                                            </Icon>
                                        }
                                        {
                                            !row.arrived &&
                                            <Icon className={'cpr'}>
                                                cancel_outlined
                                            </Icon>
                                        }
                                        </div>);
                                },
                            },
                            {
                                header: 'Employee Assisting',
                                field: 'assistingEmployeeName',
                                width: 100,
                                renderCell: (row) => {
                                    return (
                                        <Tooltip title={row.assistingEmployeeNumber}>
                                            <span>{row.assistingEmployeeName.toTitleCase()}</span>
                                        </Tooltip>
                                    );
                                },
                            },
                        ]}
                    />
                </div>
                <div className='fdr jcfe'>
                    <Tooltip title='Create'>
                        <div>
                            <IconButton className={'cy standard-box'} onClick={this.onCreateClick}>
                                <AddIcon className={'cp'}/>
                            </IconButton>
                        </div>
                    </Tooltip>
                </div>
                <GraveVisitEditDialog
                    fullWidth
                    maxWidth='md'
                    transition={Transitions.Up}
                    graveVisit={selectedVisit}
                    graveSite={graveSite}
                    onClose={this.onEditClose}
                />
            </div>
        );
    }
}
