import { IBaseImportModel, ILatLng, IFile, IBaseModel } from './base';
import { IPerson, IAccessPoint } from './access';
import { IUser } from './right';

export type vehicleOwnershipType = 'Company' | 'Private';

export interface IIncident extends IBaseImportModel {
    guid : string;
    number : string;
    division : string;
    divisionCode : string;
    farmOBNumber : string;
    date : string;
    time : string;
    place : string;
    type : string;
    injuryType : string;
    value : number;
    comment : string;
    outcome : string;
    poachingMethods? : Array<string>;
    houseBreakingEntryPoints? : Array<string>;
    weatherConditions : Array<string> | null;
    roadCondition : string | null;
    geo : ILatLng;
    saps? : IIncidentSaps;
    complainant : IIncidentComplainant;
    evidence? : IIncidentEvidence;
    suspects : Array<IIncidentSuspect>;
    updates : Array<IIncidentUpdate>;
    files : Array<IIncidentFile>;
    incidentCategories : Array<IIncidentIncidentCategory>;
    group : string | null;
    approved : boolean | null;
    approvedById : number | null;
    approvedByEmployee : string | null;
    approvedByName : string | null;
    approvedOn : string | null;
    webTransaction : boolean | null;

    actionTaken : string | null;
    suggestions : string | null;

    courtCase : IIncidentCourtCase | null;
}

export interface IIncidentIncidentCategory extends IBaseImportModel {
    incidentId : number;
    categoryGuid : string;
    categoryCode : string;
    categoryName : string;
}

export interface IIncidentSaps extends IBaseImportModel {
    incidentId : number;
    station : string;
    occRegNr : string;
    exhibitRegNr : string;
    caseRegNr : string;
    infBookNr : string;
    accidentReportNr : string;
    officerName : string;
    officerRank : string;
    investigatingOfficerName : string;
    investigatingOfficerRank : string;
}

export interface IIncidentComplainant extends IBaseImportModel {
    incidentId : number;
    name : string;
    surname : string;
    employeeNr : string;
    address : string;
    tel : string;
    cel : string;
    language : string;
}

export interface IIncidentEvidence extends IBaseImportModel {
    incidentId : number;
    description : string;
    exhibitRegisterNumber : string;
    result : string;
    ownerReceived : boolean;
}

export interface IIncidentSuspect extends IBaseImportModel {
    incidentId : number;
    name : string;
    surname : string;
    workplace : string;
    employeeNr : string;
    address : string;
    tel : string;
    cel : string;
    language : string;
    vehicleOwnership : vehicleOwnershipType | null;
    vehicleRegistrationNumber : string | null;
    vehicleMake : string | null;
    vehicleColour : string | null;
    insuranceCompany : string | null;
}

export interface IIncidentUpdate extends IBaseImportModel {
    incidentId : number;
    guid : string;
    comment : string;
    date : string;
    files : Array<IIncidentUpdateFile>;
}

export interface IIncidentFile extends IBaseImportModel {
    incidentId : number;
    fileId : number;
    file : IFile;
    incidentFileType : EnumIncidentFileType;
}

export interface IIncidentUpdateFile extends IBaseImportModel {
    incidentId : number;
    fileId : number;
    file : IFile;
}

export enum EnumIncidentFileType {
    Evidence = 1,
    Incident = 2,
    Suspect = 3,
}

export interface IIncidentCategory extends IBaseModel {
    guid : string;
    code : string;
    name : string;
    groups : Array<string> | null;
    isActive : boolean;
}

export enum EnumSymptomType {
    Covid19 = 0,
}

export interface IVirusIncident extends IBaseImportModel {
    guid : string;
    date : string;
    accessPointId : number;
    accessPoint : IAccessPoint | null;
    accessPointCode : string;
    accessPointName : string;
    deviceId : string;
    geo : ILatLng;
    symptomType : EnumSymptomType;
    identifier : string;
    personId : number | null;
    person : IPerson | null;
    temperature : number | null;
    symptoms : Record<string, boolean>;
    divisionCode : string | null;
    divisionName : string | null;
}

export enum EnumIncidentCourtCaseStatus {
    Pending = 0,
    Ongoing = 1,
    Closed = 2,
}

export enum EnumIncidentCourtCaseAccusedVerdict {
    Guilty = 0,
    NotGuilty = 1,
    PleadGuilty = 2,
    ProvisionallyWithdrawn = 3,
    Withdrawn = 4,
}

export enum EnumIncidentCourtCaseAccusedBailApplication {
    NotApplied = 0,
    Pending = 1,
    Granted = 2,
    NotGranted = 3,
}

export const IncidentCourtCaseAccusedVerdict : Record<EnumIncidentCourtCaseAccusedVerdict, string> = {
    [EnumIncidentCourtCaseAccusedVerdict.Guilty]: 'Guilty',
    [EnumIncidentCourtCaseAccusedVerdict.NotGuilty]: 'Not Guilty',
    [EnumIncidentCourtCaseAccusedVerdict.PleadGuilty]: 'Plead Guilty',
    [EnumIncidentCourtCaseAccusedVerdict.ProvisionallyWithdrawn]: 'Provisionally Withdrawn',
    [EnumIncidentCourtCaseAccusedVerdict.Withdrawn]: 'Withdrawn',
}

export const IncidentCourtCaseAccusedBailApplication : Record<EnumIncidentCourtCaseAccusedBailApplication, string> = {
    [EnumIncidentCourtCaseAccusedBailApplication.NotApplied]: 'Not Applied',
    [EnumIncidentCourtCaseAccusedBailApplication.Pending]: 'Pending',
    [EnumIncidentCourtCaseAccusedBailApplication.Granted]: 'Granted',
    [EnumIncidentCourtCaseAccusedBailApplication.NotGranted]: 'Not Granted',
}

export interface IIncidentCourtCase extends IBaseImportModel {
    incidentId : number;
    caseNumber : string | null;
    courtNumber : string | null;
    court : string | null;
    originalCourtDate : string | null;
    magistrate : string | null;
    prosecutor : string | null;
    status : EnumIncidentCourtCaseStatus | null;
    readonly statusDescription : string | null;
    dateOfVerdict : string | null;
    dateOfSentence : string | null;
    nextCourtDate : string | null;
    incidentCourtCaseAccuseds : Array<IIncidentCourtCaseAccused> | null;
}

export interface IIncidentCourtCaseAccused extends IBaseImportModel {
    incidentCourtCaseId : number;
    name : string | null;
    surname : string | null;
    representative : string | null;
    verdict : EnumIncidentCourtCaseAccusedVerdict | null;
    readonly verdictDescription : string | null;
    sentence : string | null;
    charges : string | null;

    bailApplication : EnumIncidentCourtCaseAccusedBailApplication | null;
    readonly bailApplicationDescription : string | null;
    bailAmount : number | null;
    bailConditions : string | null;
    bailRejectReason : string | null;
}

export interface IIncidentDocket extends IBaseModel {
    incidentId : number;
    date : string;
    files : Array<IIncidentDocketFile>;
    users : Array<IIncidentDocketUser>;
}

export interface IIncidentDocketFile extends IBaseModel {
    docketId : number;
    date : string;
    fileId : number;
    file : IFile | null;
}

export interface IIncidentDocketUser extends IBaseModel {
    docketId : number;
    userId : number;
    user : IUser | null;
}