import { IRootState } from '../../../@types/redux';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import Card from '@material-ui/core/Card';
import MaterialTable from '../custom/materialTable/Table';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { CustomMouseEvent } from '../../../@types/helpers';
import OccurrenceDialog from './dialog/OccurrenceDialog';
import { Transitions } from '../custom/animations/Transitions';
import { dispatch } from '../../../store';
import Toolbar from '@material-ui/core/Toolbar';
import { IDivision } from '../../../@types/model/division';
import { createSelector } from 'reselect';
import DateRangeInput from '../custom/input/DateRangeInput';
import OccurrenceCreate from './dialog/OccurrenceCreateDialog';
import { IUserToken } from '../../../@types/model/right';
import OccurrenceRemoveDialog from './dialog/OccurrenceRemoveDialog';
import { RIGHTS } from '../../../appConstants';
import { IOccurrence } from '../../../@types/model/occurrence';
import occurrenceActions from '../../../store/occurrence/actions';
import occurrenceFunctions from '../../../store/occurrence/functions';
import { FormControl, Input, InputLabel } from '@material-ui/core';
import DepartmentDropdown from '../custom/dropdowns/DepartmentDropdown';

interface IOccurrenceListProps {
    occurrences : Array<IOccurrence>;
    isLoading : boolean;
    session : IUserToken | null;
}

interface IOccurrenceListState {
    dateRange : [moment.Moment, moment.Moment];
    occNr: string,
    division? : IDivision;
}

class OccurrenceList extends React.Component<IOccurrenceListProps, IOccurrenceListState> {
    constructor(props : IOccurrenceListProps) {
        super(props);

        this.state = {
            dateRange: [moment.utc().startOf('day'), moment.utc().startOf('day')],
            occNr: '',
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    private loadData = async () => {
        await occurrenceFunctions.getAll(this.state.dateRange[0].startOf('day').valueOf(), this.state.dateRange[1].endOf('day').valueOf());
    }

    private onDateRangeChanged = (dateRange ?: [moment.Moment, moment.Moment]) => {
        if (!dateRange) return;

        this.setState({
            dateRange,
        }, () => {
            this.loadData();
        });
    }

    private onSearch = () => {
        this.loadData();
    }

    public onOccurrenceClick = (e : CustomMouseEvent) => {
        const selected = this.props.occurrences.find(n => n.id === Number(e.currentTarget.value));
        dispatch(occurrenceActions.setOccurrence(selected));
    }

    private readonly onDivisionChange = (division : IDivision | undefined) => {
        this.setState({
            division,
        });
    }

    private readonly onOccurrenceNrChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            occNr: event.currentTarget.value,
        });
    }

    private readonly getData = (state : IOccurrenceListState, props : IOccurrenceListProps) => props.occurrences;
    private readonly getDivision = (state : IOccurrenceListState, props : IOccurrenceListProps) => state.division;
    private readonly getSession = (state : IOccurrenceListState, props : IOccurrenceListProps) => props.session;
    private readonly getOccurrenceNr = (state : IOccurrenceListState, props : IOccurrenceListProps) => state.occNr;
    private readonly getOccurrences = createSelector(
        [this.getData, this.getDivision, this.getOccurrenceNr],
        (occurrences, division, occNr) => {
            return occurrences
            .filter(n => (!division || n.divisionId === division?.id))
            .filter(n => !occNr || n.number.toLocaleUpperCase().includes(occNr.toLocaleUpperCase()));
        },
    );
    
    private readonly getHasDeleteRight = createSelector(
        [this.getSession],
        (session) => {
            return session?.user?.userRights?.some(x => x.rightId === RIGHTS.OCC_DEL);
        },
    );

    public render = () => {
        const { dateRange, occNr, division } = this.state;
        const { isLoading } = this.props;

        const occurrences = this.getOccurrences(this.state, this.props);
        const hasDeleteRight = this.getHasDeleteRight(this.state, this.props);
        return (
            <div className='flx1 fdc p15 bcg1'>
                <Toolbar disableGutters>
                    <div className={'fdc flx1 jcfs p5 mb10 pr20'}>
                        <FormControl fullWidth>
                            <InputLabel className='fs16 lh21 cpla lsp024' htmlFor='occNr'>Occurrence Nr</InputLabel>
                            <Input
                                autoComplete='off'
                                id='occNr'
                                value={occNr}
                                onChange={this.onOccurrenceNrChange}
                            />
                        </FormControl>
                    </div>
                    <div className={'fdc flx1 jcfs p5 mb10 pr20'}>
                        <DateRangeInput value={dateRange} onChange={this.onDateRangeChanged} fullWidth />
                    </div>
                    <div className={'fdc flx1 jcfs p5 mb10 pr20'}>
                        <DepartmentDropdown onChange={this.onDivisionChange} value={division?.id} fullWidth label='Department'/>
                    </div>
                    <div className={'fdc flx1 jcfs p5 mb10 pr20'}>
                    </div>
                    <div className={'fdc flx1 jcfs p5 mb10 pr20'}>
                    </div>
                </Toolbar>
                <Card className={'flx1 fdc mb70'}>
                    <MaterialTable<IOccurrence>
                        id={'occurrenceTable'}
                        data={occurrences}
                        isLoading={isLoading}
                        rowsPerPage={20}
                        columns={[
                            {
                                header: (
                                    <IconButton color='secondary' size='small' onClick={this.onSearch}>
                                        <Tooltip title='Refresh'>
                                            <Icon>
                                                refresh
                                            </Icon>
                                        </Tooltip>
                                    </IconButton>
                                ),
                                width: hasDeleteRight ? 100 : 70,
                                renderCell: row => (
                                    <div className='aic'>
                                        {
                                            hasDeleteRight &&
                                            <OccurrenceRemoveDialog fullWidth maxWidth='sm' isLoading={isLoading}
                                            occurrence={row} />
                                        }
                                        <Tooltip title='Edit'>
                                            <IconButton value={`${row.id}`} onClick={this.onOccurrenceClick}>
                                                <Icon>
                                                    edit
                                                </Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                ),
                            },
                            {
                                header: 'Occurrence Nr',
                                field: 'number',
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Date',
                                field: 'date',
                                enableSort: true,
                                enableFilter: true,
                                renderCell: (row) => {
                                    return (<span>{moment.utc(row.date).local().format('ddd YYYY-MM-DD')}</span>);
                                },
                            },
                            {
                                header: 'Time',
                                field: 'time',
                                enableSort: true,
                                enableFilter: true,
                                renderCell: (row) => {
                                    return (<span>{moment.utc(row.date).local().format('HH:mm:ss')}</span>);
                                },
                            },
                            {
                                header: 'Department',
                                field: 'division.description',
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Description',
                                field: 'description',
                                enableSort: true,
                                enableFilter: true,
                                renderCell: (row) => {
                                    return (<span>{row.description}</span>);
                                },
                            },
                            {
                                header: 'Incident',
                                field: 'incident',
                                enableFilter: true,
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                        <div className={'jcc aic pr40'}>
                                        {
                                            !!row.incident &&
                                            <Icon className={'cp'}>
                                                checked
                                            </Icon>
                                        }
                                        </div>);
                                },
                            },
                            {
                                header: 'Created By',
                                field: 'createdByName',
                                enableSort: true,
                                enableFilter: true,
                                renderCell: (row) => {
                                    return (
                                    <Tooltip title={row.createdBy}>
                                        <span>{row.createdByName.toTitleCase()}</span>
                                    </Tooltip>);
                                },
                            },
                        ]}
                    />
                </Card>
                <div className='fdr jcfe'>
                    <OccurrenceCreate disabled={isLoading} isLoading={isLoading} fullWidth maxWidth='sm' transition={Transitions.Up} />
                </div>
                <OccurrenceDialog maxWidth='md' fullWidth transition={Transitions.Up}/>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        occurrences: state.occurrence.occurrences,
        isLoading: state.occurrence.isLoading,
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(OccurrenceList);
