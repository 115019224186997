import React from 'react';
import { IIncident } from '../../../../@types/model/incident';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IncidentGeneralInfoEditDialog from '../dialog/edit/GeneralInfoEditDialog';

interface IIncidentSummaryViewProps {
    incident : IIncident;
    isEditAdmin : boolean;
    isSaving : boolean;
}

interface IIncidentSummaryViewState {}

export default class IncidentSummaryView extends React.PureComponent<IIncidentSummaryViewProps, IIncidentSummaryViewState> {
    constructor(props : IIncidentSummaryViewProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const {} = this.state;
        const { isEditAdmin, incident, isSaving } = this.props;
        return (
            <div className='flx1 fdr aifs pt20 pb20 bcg1'>
                <span className='flx1' />
                <Paper className='fdc flx2' elevation={3}>
                    <AppBar position='static' className='pbr bcpl' elevation={0}>
                        <Toolbar>
                            <Typography className='fwm fs18'>
                                SUMMARY
                            </Typography>
                            <span className='flx1' />
                            {
                                isEditAdmin &&
                                <div className='posa posr10'>
                                    <IncidentGeneralInfoEditDialog maxWidth='md' fullWidth incident={incident} isLoading={isSaving}/>
                                </div>
                            }
                        </Toolbar>
                    </AppBar>
                    <div className='fdc'>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Group
                            </Typography>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Division
                            </Typography>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Farm OB
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                {incident.group ?? ''}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                {incident.division}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                {incident.farmOBNumber ?? 'None'}
                            </Typography>
                        </div>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Created By
                            </Typography>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Updated By
                            </Typography>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                {incident.createdByName.toTitleCase()}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                {incident.updatedByName.toTitleCase()}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                            </Typography>
                        </div>
                    </div>
                </Paper>
                <span className='flx1' />
            </div>
        );
    }
}
