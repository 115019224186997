import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { createSelector } from 'reselect';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { LANGUAGES } from '../../../../appConstants';

interface ISpokenLanguageDropdownProps {
    id? : string;
    value? : string;

    onChange : (value? : string) => void;

    required? : boolean;
    clearable? : boolean;
    autoFocus? : boolean;

    fullWidth? : boolean;
    disabled? : boolean;
}

interface ISpokenLanguageDropdownState {
}

export default class SpokenLanguageDropdown extends React.Component<ISpokenLanguageDropdownProps, ISpokenLanguageDropdownState> {
    constructor(props : ISpokenLanguageDropdownProps) {
        super(props);

        this.state = {
        };
    }

    private onChanged = (event : React.ChangeEvent<HTMLSelectElement>, value : {
        label : string,
        value : string,
    }) => {
        this.props.onChange(value?.value);
    }

    private getValue = (state : ISpokenLanguageDropdownState, props : ISpokenLanguageDropdownProps) => props.value;
    private getRequired = (state : ISpokenLanguageDropdownState, props : ISpokenLanguageDropdownProps) => props.required;

    private getDropdown = createSelector([
        this.getRequired,
    ], (required) => {
        const drop = LANGUAGES.map(x => ({ label: x, value: x }));

        if (!required) {
            drop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return drop;
    });

    private getSelectedValue = createSelector([
        this.getValue,
        this.getRequired,
    ], (value, required) => {
        if (value) {
            return {
                label: value,
                value,
            };
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public render = () => {
        const {  } = this.state;
        const { required, autoFocus, clearable, fullWidth, id, disabled } = this.props;
        const regions = this.getDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                <Autocomplete
                    id={id}
                    disabled={disabled}
                    options={regions}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChanged}
                    disableClearable={required && !clearable}
                    openOnFocus
                    renderInput={params => <TextField autoFocus={autoFocus}  error={required && !value} {...params} fullWidth={fullWidth} label='Spoken Language' />}
                />
                        {
                            required && !value &&
                            <FormHelperText error>Required</FormHelperText>
                        }
            </FormControl>
        );
    }
}
