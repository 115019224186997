import React from 'react';

interface IOutlinedToggleButtonProps<T> {
    value? : T;
    selected : boolean;
    onClick : (value? : T) => void;
    className? : string | undefined;

    labelText : string;

    disabled ?: boolean;
}

interface IOutlinedToggleButtonState {}

export default class OutlinedToggleButton<T> extends React.Component<IOutlinedToggleButtonProps<T>, IOutlinedToggleButtonState> {
    constructor(props : IOutlinedToggleButtonProps<T>) {
        super(props);
        this.state = {};
    }

    private onClick = () => {
        this.props.onClick(this.props.value);
    }

    public render = () => {
        const { labelText, selected, disabled } = this.props;
        return (
            <div className={`outlined-button ${this.props.className}`} onClick={!disabled ? this.onClick : undefined}>
                <span className={`${!!!selected ? '' : 'selected'}`}>{labelText}</span>
            </div>
        );
    }
}
