import { getState, dispatch } from '..';
import masterDataActions from './actions';
import DivisionHttpService from '../../service/http/master/divisionHttpService';
import generalFunctions from '../general/functions';
import RegionHttpService from '../../service/http/master/regionHttpService';
import { IRegion } from '../../@types/model/region';
import { IDivision } from '../../@types/model/division';
import GraveVisitReasonHttpService from '../../service/http/master/graveVisitHttpService';
import { IGraveVisitReason } from '../../@types/model/masterData/graveVisitReason';
import AccessPointCategoryHttpService from '../../service/http/master/accessPointCategoryHttpService';
import { IAccessPointCategory } from '../../@types/model/masterData/accessPointCategory';
import DivisionTypeHttpService from '../../service/http/master/divisionTypeHttpService';
import { IDivisionType } from '../../@types/model/masterData/divisionType';
import PowerSourceHttpService from '../../service/http/master/powerSourceHttpService';
import { IPowerSource } from '../../@types/model/masterData/powerSource';

export default class MasterDataFunctions {
    public static async getAllDivisions(refresh? : boolean) {
        try {
            const state = getState();
            dispatch(masterDataActions.setIsLoadingDivisions(true));

            if (refresh || state.masterData.divisions.length === 0) {
                const result = await DivisionHttpService.getAll();
                const divisions = result.data.slice();

                divisions.sort((a, b) => a.description.localeCompare(b.description));
                dispatch(masterDataActions.setDivisions(result.data));
            }
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading Divisions', e);
        } finally {
            dispatch(masterDataActions.setIsLoadingDivisions(false));
        }
    }

    public static async saveDivision(division : IDivision) {
        try {
            const state = getState();
            dispatch(masterDataActions.setIsLoadingDivisions(true));

            const result = await DivisionHttpService.save(division);

            const divisions = state.masterData.divisions.slice();

            const index = divisions.findIndex(n => n.code === result.data.code);
            if (index > -1) {
                divisions.splice(index, 1, result.data);
            } else {
                divisions.push(result.data);
            }

            divisions.sort((a, b) => a.description.localeCompare(b.description));
            dispatch(masterDataActions.setDivisions(divisions));

            return result.data;
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while saving Division', e);
        } finally {
            dispatch(masterDataActions.setIsLoadingDivisions(false));
        }
    }

    public static async publishAllDivisions() {
        try {
            const state = getState();
            dispatch(masterDataActions.setIsLoadingDivisions(true));
            await DivisionHttpService.publishAll();
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while publising Divisions', e);
        } finally {
            dispatch(masterDataActions.setIsLoadingDivisions(false));
        }
    }

    public static async getAllRegions(refresh? : boolean) {
        try {
            const state = getState();
            dispatch(masterDataActions.setIsLoadingRegions(true));

            if (refresh || state.masterData.regions.length === 0) {
                const result = await RegionHttpService.getAll();

                dispatch(masterDataActions.setRegions(result.data));
            }
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading Regions', e);
        } finally {
            dispatch(masterDataActions.setIsLoadingRegions(false));
        }
    }

    public static async saveRegion(region : IRegion) {
        try {
            dispatch(masterDataActions.setIsLoadingRegions(true));

            const result = await RegionHttpService.save(region);

            if (result.data) {
                const state = getState();

                const regions = state.masterData.regions.slice();
                const index = regions.findIndex(n => n.guid === result.data.guid);
                if (index > -1) {
                    regions.splice(index, 1, result.data);
                } else {
                    regions.push(result.data);
                }
                dispatch(masterDataActions.setRegions(regions));
            }

            return result.data;
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while saving region.', e);
        } finally {
            dispatch(masterDataActions.setIsLoadingRegions(false));
        }
        return null;
    }

    public static async getAllGraveVisitReasons(refresh? : boolean) {
        try {
            const state = getState();

            if (!refresh && state.masterData.graveVisitReasons.length) return;
            dispatch(masterDataActions.setIsLoadingGraveVisitReason(true));
            const result = await GraveVisitReasonHttpService.getAll();

            dispatch(masterDataActions.setGraveVisitReasons(result.data));
            dispatch(masterDataActions.setIsLoadingGraveVisitReason(false));
        } catch (e) {
            if (e) {
                generalFunctions.showErrorSnackbar('An error occurred while loading Reasons', e);
                dispatch(masterDataActions.setIsLoadingGraveVisitReason(false));
            }
        }
    }

    public static async saveGraveVisitReason(reason : IGraveVisitReason) {
        try {
            dispatch(masterDataActions.setIsLoadingGraveVisitReason(true));

            const result = await GraveVisitReasonHttpService.save(reason);

            if (result.data) {
                const state = getState();

                const regions = state.masterData.graveVisitReasons.slice();
                const index = regions.findIndex(n => n.guid === result.data.guid);
                if (index > -1) {
                    regions.splice(index, 1, result.data);
                } else {
                    regions.push(result.data);
                }
                dispatch(masterDataActions.setGraveVisitReasons(regions));
            }

            return result.data;
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while saving reason.', e);
        } finally {
            dispatch(masterDataActions.setIsLoadingGraveVisitReason(false));
        }
        return null;
    }

    public static async getAllAccessPointCategories(refresh? : boolean) {
        try {
            const state = getState();

            if (!refresh && state.masterData.accessPointCategories.length) return;
            dispatch(masterDataActions.setIsLoadingAccessPointCategory(true));
            const result = await AccessPointCategoryHttpService.getAll();

            dispatch(masterDataActions.setAccessPointCategories(result.data));
            dispatch(masterDataActions.setIsLoadingAccessPointCategory(false));
        } catch (e) {
            if (e) {
                generalFunctions.showErrorSnackbar('An error occurred while loading categories', e);
                dispatch(masterDataActions.setIsLoadingAccessPointCategory(false));
            }
        }
    }

    public static async saveAccessPointCategory(save : IAccessPointCategory) {
        try {
            dispatch(masterDataActions.setIsLoadingAccessPointCategory(true));

            const result = await AccessPointCategoryHttpService.save(save);

            if (result.data) {
                const state = getState();

                const categories = state.masterData.accessPointCategories.slice();
                const index = categories.findIndex(n => n.guid === result.data.guid);
                if (index > -1) {
                    categories.splice(index, 1, result.data);
                } else {
                    categories.push(result.data);
                }
                dispatch(masterDataActions.setAccessPointCategories(categories));
            }

            return result.data;
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while saving category.', e);
        } finally {
            dispatch(masterDataActions.setIsLoadingAccessPointCategory(false));
        }
        return null;
    }

    public static async getAllDivisionTypes(refresh? : boolean) {
        try {
            const state = getState();

            if (!refresh && state.masterData.divisionTypes.length) return;
            dispatch(masterDataActions.setIsLoadingDivisionTypes(true));
            const result = await DivisionTypeHttpService.getAll();

            dispatch(masterDataActions.setDivisionTypes(result.data));
            dispatch(masterDataActions.setIsLoadingDivisionTypes(false));
        } catch (e) {
            if (e) {
                generalFunctions.showErrorSnackbar('An error occurred while loading categories', e);
                dispatch(masterDataActions.setIsLoadingDivisionTypes(false));
            }
        }
    }

    public static async saveDivisionType(save : IDivisionType) {
        try {
            dispatch(masterDataActions.setIsLoadingDivisionTypes(true));

            const result = await DivisionTypeHttpService.save(save);

            if (result.data) {
                const state = getState();

                const types = state.masterData.divisionTypes.slice();
                const index = types.findIndex(n => n.guid === result.data.guid);
                if (index > -1) {
                    types.splice(index, 1, result.data);
                } else {
                    types.push(result.data);
                }
                dispatch(masterDataActions.setDivisionTypes(types));
            }

            return result.data;
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while saving type.', e);
        } finally {
            dispatch(masterDataActions.setIsLoadingDivisionTypes(false));
        }
        return null;
    }

    public static async getPowerSources(refresh? : boolean) {
        try {
            const state = getState();

            if (!refresh && state.masterData.powerSources.length) return;
            dispatch(masterDataActions.setIsLoadingPowerSource(true));
            const result = await PowerSourceHttpService.getAll();

            dispatch(masterDataActions.setPowerSources(result.data));
            dispatch(masterDataActions.setIsLoadingPowerSource(false));
        } catch (e) {
            if (e) {
                generalFunctions.showErrorSnackbar('An error occurred while loading power sources', e);
                dispatch(masterDataActions.setIsLoadingPowerSource(false));
            }
        }
    }

    public static async savePowerSource(save : IPowerSource) {
        try {
            dispatch(masterDataActions.setIsLoadingPowerSource(true));

            const result = await PowerSourceHttpService.save(save);

            if (result.data) {
                const state = getState();

                const powerSources = state.masterData.powerSources.slice();
                const index = powerSources.findIndex(n => n.guid === result.data.guid);
                if (index > -1) {
                    powerSources.splice(index, 1, result.data);
                } else {
                    powerSources.push(result.data);
                }
                dispatch(masterDataActions.setPowerSources(powerSources));
            }

            return result.data;
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while saving power source.', e);
        } finally {
            dispatch(masterDataActions.setIsLoadingPowerSource(false));
        }
        return null;
    }
}
