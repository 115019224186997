import generalFunctions from '../store/general/functions';
import firebase from 'firebase/app';
import 'firebase/messaging';
import userHttpService from './http/userHttpService';
import { IWebNotification } from '../@types/notification';

export default class NotificationService {
    private static tokenSubscription : firebase.Unsubscribe;
    private static messageSubscription : firebase.Unsubscribe;

    /**
     * Subscribes to push notifications for given service worker.
     * @param registration
     */
    public static subscribePush = async () => {
        let messaging : firebase.messaging.Messaging;

        if (firebase.apps.length > 0) {
            messaging = firebase.messaging(firebase.app());
        } else {
            const registration = await NotificationService.registerServiceWorker();

            if (!registration || !registration.pushManager) {
                generalFunctions.showErrorSnackbar('Notifications unsupported');
                return;
            }

            messaging = firebase.messaging(firebase.initializeApp({
                appId: APP_ID,
                apiKey: API_KEY,
                projectId: FIREBASE_PROJECT_ID,
                messagingSenderId: GCM_SENDER_ID,
            }));

            messaging.useServiceWorker(registration);
            messaging.usePublicVapidKey(PUBLIC_PUSH_KEY);
        }

        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            const token = await messaging.getToken();

            await userHttpService.setUserFCMToken(token).catch(() => {
                generalFunctions.showErrorSnackbar('Unable to send FCM token');
            });
        } else {
            generalFunctions.showErrorSnackbar('Notifications blocked');
            return;
        }

        NotificationService.tokenSubscription = messaging.onTokenRefresh(() => {
            messaging.getToken().then((refreshedToken) => {
                userHttpService.setUserFCMToken(refreshedToken).catch(() => {
                    generalFunctions.showErrorSnackbar('Unable to send FCM token');
                });
            }).catch((err) => {
                generalFunctions.showErrorSnackbar('Unable to retrieve refreshed token');
            });
        });

        NotificationService.listen();
    }

    public static unsubscribePush = async () => {
        try {
            await NotificationService.unregisterServiceWorker();
        } catch (ex) {
            // Ignore this
        }

        if (NotificationService.tokenSubscription) {
            NotificationService.tokenSubscription();
        }
    }

    private static registerServiceWorker = async () => {
        if ('serviceWorker' in navigator) {
            try {
                let serviceWorker = await navigator.serviceWorker.getRegistration('./pushNotificationServiceWorker.js');

                if (!serviceWorker) {
                    serviceWorker = await navigator.serviceWorker.register('./pushNotificationServiceWorker.js');
                }

                await serviceWorker.update();

                return serviceWorker;
            } catch (ex) {
                generalFunctions.showErrorSnackbar('Service Worker Error.');
            }
        }

        return null;
    }

    private static unregisterServiceWorker = async () => {
        if ('serviceWorker' in navigator) {
            try {
                const serviceWorker = await navigator.serviceWorker.getRegistration('./assets/pushNotificationServiceWorker.js');

                if (serviceWorker) {
                    await serviceWorker.unregister();
                }
            } catch (ex) {
                generalFunctions.showErrorSnackbar('Service Worker Error.');
            }
        }
    }

    private static listen = () => {
        if (NotificationService.messageSubscription) {
            NotificationService.messageSubscription();
        }

        const messaging = firebase.messaging(firebase.app());

        NotificationService.messageSubscription = messaging.onMessage((payload : IWebNotification) => {
            if (payload.notification) {
                const newNotification = Object.assign({}, payload);
                newNotification.key = new Date().getTime() + Math.random();

                generalFunctions.addWebNotification(newNotification);

                switch (payload.priority) {
                    case 'very-low':
                    case 'low':
                        break;
                    case 'normal':
                        generalFunctions.showWarningSnackbar(`${payload.notification.title} - ${payload.notification.body}`);
                        break;
                    case 'high':
                        generalFunctions.showErrorSnackbar(`${payload.notification.title} - ${payload.notification.body}`);
                        break;
                }
            }
        });
    }
}
