import { IIncident, IIncidentCategory, IIncidentIncidentCategory } from '../../../@types/model/incident';
import { IRootState } from '../../../@types/redux';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import Card from '@material-ui/core/Card';
import IncidentFunctions from '../../../store/incident/functions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { CustomMouseEvent } from '../../../@types/helpers';
import IncidentDialog from './dialog/IncidentDialog';
import { Transitions } from '../custom/animations/Transitions';
import Toolbar from '@material-ui/core/Toolbar';
import DivisionDropdown from '../custom/dropdowns/DivisionDropdown';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import FileCountButton from '../custom/button/FileCountButton';
import FileCarouselDialog from '../custom/dialog/FileCarouselDialog';
import IncidentCreateDialog from './dialog/IncidentCreateDialog';
import { RouteComponentProps, withRouter } from 'react-router';
import { IDivision } from '../../../@types/model/division';
import FormControl from '@material-ui/core/FormControl';
import MaterialTable, { MaterialTableColumn } from '../custom/materialTable/Table';
import DateRangeInput from '../custom/input/DateRangeInput';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { RIGHTS } from '../../../appConstants';
import IncidentRemoveDialog from './dialog/IncidentDeleteDialog';
import Icon from '@material-ui/core/Icon';
import IncidentApprovalDialog from './dialog/IncidentApprovalDialog';
import IncidentCategoryDropdown from '../custom/autocomplete/IncidentCategoryAutocomplete';

interface IIncidentListProps extends RouteComponentProps<{
    guid : string;
}, {}, {}> {
    incidents : Array<IIncident>;
    isLoading : boolean;
    hasApproveRights : boolean;
    hasDeleteRight : boolean;
}

interface IIncidentListState {
    dateRange : [moment.Moment, moment.Moment];

    division? : IDivision;

    selectedIndex : number;

    incidentCategory? : IIncidentCategory;
    reference : string;
    casNumber : string;

    selectedIncident ?: IIncident;
}

class IncidentList extends React.Component<IIncidentListProps, IIncidentListState> {
    constructor(props : IIncidentListProps) {
        super(props);

        this.state = {
            dateRange: [moment.utc().startOf('day').subtract(2, 'weeks'), moment.utc().startOf('day')],
            selectedIndex: -1,
            reference: '',
            casNumber: '',
        };
    }

    public readonly componentDidMount = () => {
        this.loadData(this.state.dateRange).then(() => {
            const urlParams = new URLSearchParams(this.props.location.search);
            const guid = urlParams.get('guid');
            if (guid) {
                IncidentFunctions.getSelected(undefined, guid);
            }
        });

    }

    private readonly loadData = async (dateRange : [moment.Moment, moment.Moment], division? : IDivision) => {
        await IncidentFunctions.getAll(dateRange[0].valueOf(), dateRange[1].valueOf(), !!!division ? undefined : division.description.toTitleCase());
    }

    private readonly onDateRangeChanged = (dateRange ?: [moment.Moment, moment.Moment]) => {
        if (!dateRange) return;
        this.setState({
            dateRange,
        });
        this.loadData(dateRange, this.state.division);
    }

    private readonly onDivisionChange = (division : IDivision | undefined) => {
        this.setState({
            division,
        });
        this.loadData(this.state.dateRange, division);
    }

    private readonly onRefreshClick = () => {
        this.loadData(this.state.dateRange, this.state.division);
    }

    private readonly onIncidentClick = (row : IIncident) => {
        this.setState({ selectedIncident: row });
    }

    public onIncidentDialogClose = () => {
        this.setState({ selectedIncident: undefined });
    }

    private readonly onFileCountClick = (guid : string) => {
        const selected = this.props.incidents.findIndex(n => n.guid === guid);
        this.setState({
            selectedIndex: selected,
        });
    }

    private readonly onFileCarouselClose = () => {
        this.setState({
            selectedIndex: -1,
        });
    }

    private readonly onIncidentCategoryChange = (incidentCategory ?: IIncidentCategory) => {
        this.setState({
            incidentCategory,
        });
    }

    private readonly onReferenceChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            reference: event.currentTarget.value,
        });
    }

    private readonly onCasNumberChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            casNumber: event.currentTarget.value,
        });
    }

    private readonly getData = (state : IIncidentListState, props : IIncidentListProps) => props.incidents;
    private readonly getCategory = (state : IIncidentListState, props : IIncidentListProps) => state.incidentCategory;
    private readonly getReference = (state : IIncidentListState, props : IIncidentListProps) => state.reference;
    private readonly getCasNumber = (state : IIncidentListState, props : IIncidentListProps) => state.casNumber;
    private readonly getHasApproveRights = (state : IIncidentListState, props : IIncidentListProps) => props.hasApproveRights;
    private readonly getHasDeleteRight = (state : IIncidentListState, props : IIncidentListProps) => props.hasDeleteRight;
    private readonly getIsLoading = (state : IIncidentListState, props : IIncidentListProps) => props.isLoading;

    private readonly getIncidents = createSelector(
        [this.getData, this.getCategory, this.getReference, this.getCasNumber],
        (incidents, category, reference, casNumber) => {
            return incidents
                .filter(n => !category || n.incidentCategories.some(cat => cat.categoryCode === category.code))
                .filter(n => !reference || n.number.toLocaleUpperCase().includes(reference.toLocaleUpperCase()))
                .filter(n => !casNumber || n.courtCase?.caseNumber?.toLocaleUpperCase().includes(casNumber.toLocaleUpperCase()));
        },
    );

    private readonly getDivisionCount = createSelector(
        [this.getIncidents],
        (incidents) => {
            if (incidents.length === 0) return 0;
            return lodash.uniqBy(incidents, n => n.division).length;
        },
    );

    private readonly getCategoriesCount = createSelector(
        [this.getIncidents],
        (incidents) => {
            if (incidents.length === 0) return 0;
            return lodash.chain(incidents)
            .reduce((current, incident) => current.concat(incident.incidentCategories), [] as Array<IIncidentIncidentCategory>)
            .map(n => n.categoryCode)
            .uniq().value().length;
        },
    );

    private readonly getColumns = createSelector(
        [this.getHasApproveRights, this.getHasDeleteRight, this.getIsLoading],
        (hasApproveRights, hasDeleteRight, isLoading) => {
            const result : Array<MaterialTableColumn<IIncident>> = [
                {
                    header: '',
                    width: hasDeleteRight ? 100 : 70,
                    renderCell: row => (
                        <div className='aic'>
                            <Tooltip title='Info'>
                                <IconButton color='primary' value={`${row.id}`} onClick={() => this.onIncidentClick(row)}>
                                    <InfoRoundedIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={!row.webTransaction ? 'Mobile Transaction' : 'Web Transaction'}>
                                <Icon className={'cpd'}>
                                    {!row.webTransaction ? 'phone_android' : 'laptop'}
                                </Icon>
                            </Tooltip>
                            {
                                hasDeleteRight &&
                                <IncidentRemoveDialog fullWidth maxWidth='sm' isLoading={isLoading}
                                incident={row} />
                            }
                        </div>
                    ),
                },
                {
                    header: 'Date',
                    field: 'date',
                    enableSort: true,
                    renderCell: (row) => {
                        return (<span>{moment.utc(row.date).local().format('ddd YYYY-MM-DD')}</span>);
                    },
                },
                {
                    header: 'Time',
                    field: 'time',
                    enableSort: true,
                    enableFilter: true,
                },
                {
                    header: 'Group',
                    field: 'group',
                    enableSort: true,
                    enableFilter: true,
                },
                {
                    header: 'Category',
                    field: 'categoryName',
                    enableSort: true,
                    enableFilter: true,
                    renderCell: (row) => {
                        return (
                        <Tooltip title={!row.incidentCategories?.length ? 'Unknown' : row.incidentCategories.map(n => n.categoryCode).join(', ')}>
                            <span>{!row.incidentCategories?.length ? 'Unknown' : row.incidentCategories.map(n => n.categoryName).join(', ')}</span>
                        </Tooltip>);
                    },
                },
                {
                    header: 'Type',
                    field: 'type',
                    enableSort: true,
                    enableFilter: true,
                },
                {
                    header: 'Division',
                    field: 'division',
                    enableSort: true,
                    enableFilter: true,
                },
                {
                    header: 'Number',
                    field: 'number',
                    enableSort: true,
                    enableFilter: true,
                },
                {
                    header: 'Files',
                    renderCell: (row) => {
                        return (<FileCountButton guid={row.guid} count={row.files.length} onClick={this.onFileCountClick} />);
                    },
                },
                {
                    header: 'Created By',
                    field: 'createdByName',
                    enableFilter: true,
                    enableSort: true,
                    renderCell: (row) => {
                        return (
                        <Tooltip title={row.createdBy}>
                            <span>{row.createdByName.toTitleCase()}</span>
                        </Tooltip>);
                    },
                },
            ];

            if (hasApproveRights) {
                result.push({
                    header: 'APPROVE',
                    width: '175px',
                    renderCell: row => (
                        <div className='fdr aic jcc'>
                            {
                                !row.approved && hasApproveRights &&
                                <IncidentApprovalDialog value={row} title='Approve Incident' isLoading={isLoading} />
                            }
                            {
                                !!row.approved && !!row.approvedOn &&
                                <Tooltip title={`${moment.utc(row.approvedOn).local().format('DD-MM-YYYY HH:mm')}`}>
                                    <span>{row.approvedByName}</span>
                                </Tooltip>
                            }
                            {
                                !!row.approved && !!row.approvedOn && hasApproveRights &&
                                <IncidentApprovalDialog value={row} disapprove title='Disapprove Incident' isLoading={isLoading}/>
                            }
                        </div>
                    ),
                });
            }

            return result;
        },
    );

    public readonly render = () => {
        const { dateRange, division, selectedIndex, incidentCategory, reference, casNumber } = this.state;
        const { isLoading } = this.props;

        const divisionCount = this.getDivisionCount(this.state, this.props);
        const categoriesCount = this.getCategoriesCount(this.state, this.props);
        const incidents = this.getIncidents(this.state, this.props);
        const columns = this.getColumns(this.state, this.props);

        return (
            <div className='flx1 fdc p15 bcg1'>
                <Toolbar disableGutters>
                    <div className={'fdc flx1 jcfs p5 mb10 pr20'}>
                        <DateRangeInput value={dateRange} onChange={this.onDateRangeChanged} fullWidth />
                    </div>
                    <div className={'fdc flx1 jcfs p5 mb10 pr20'}>
                        <DivisionDropdown onChange={this.onDivisionChange} value={division} fullWidth />
                    </div>
                    <div className={'fdc flx1 jcfs p5 mb10 pr20'}>
                        <IncidentCategoryDropdown  fullWidth onChange={this.onIncidentCategoryChange} value={incidentCategory?.guid} />
                    </div>
                    <div className={'fdc flx1 jcfs p5 mb10'}>
                        <FormControl fullWidth>
                            <InputLabel className='fs16 lh21 cpla lsp024' htmlFor='reference'>Reference</InputLabel>
                            <Input
                                autoComplete='off'
                                id='reference'
                                value={reference}
                                onChange={this.onReferenceChange}
                            />
                        </FormControl>
                    </div>
                    <div className={'fdc flx1 jcfs p5 mb10'}>
                        <FormControl fullWidth>
                            <InputLabel className='fs16 lh21 cpla lsp024' htmlFor='casNumber'>CAS Number</InputLabel>
                            <Input
                                autoComplete='off'
                                id='casNumber'
                                value={casNumber}
                                onChange={this.onCasNumberChange}
                            />
                        </FormControl>
                    </div>
                </Toolbar>
                <Toolbar disableGutters>
                    <div className={'fdr pt5'}>
                        <Tooltip title='Refresh'>
                            <div>
                                <IconButton className='cp' disabled={isLoading} onClick={this.onRefreshClick}>
                                    <Icon>refresh</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </div>
                    <span className={'flx1'} />
                    <div className={'fdr pt5'}>
                        <span className='pr15 fs18 cpl'><b>Categories: </b>{categoriesCount}</span>
                        <span className='pr15 fs18 cpl'><b>Divisions: </b>{divisionCount}</span>
                        <span className='pr15 fs18 cpl'><b>Total: </b>{incidents.length}</span>
                    </div>
                </Toolbar>
                <Card className='flx1 fdc mb70'>
                    <MaterialTable<IIncident>
                        id={'incidentTable'}
                        data={incidents}
                        isLoading={isLoading}
                        rowsPerPage={20}
                        columns={columns}
                    />
                </Card>
                <div className='fdr jcfe'>
                    <IncidentCreateDialog disabled={isLoading} isLoading={isLoading} fullScreen transition={Transitions.Up} />
                </div>
                <IncidentDialog
                    incident={this.state.selectedIncident}
                    onClose={this.onIncidentDialogClose}
                    maxWidth={'md'}
                    fullWidth
                    fullScreen
                    transition={Transitions.Up} />
                <FileCarouselDialog
                    fullWidth
                    maxWidth='md'
                    files={selectedIndex > -1 ? incidents[selectedIndex].files : []}
                    open={selectedIndex > -1}
                    onClose={this.onFileCarouselClose} />
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        incidents: state.incident.incidents,
        isLoading: state.incident.isLoading,
        hasDeleteRight: !!state.auth?.session?.user?.userRights?.some(n => n.rightId === RIGHTS.INS_DEL),
        hasApproveRights: !!state.auth?.session?.user?.userRights?.some(n => n.rightId === RIGHTS.INS_APPROVE),
    };
};

export default connect(
    mapStateToProps,
)(withRouter(IncidentList));
