import React from 'react';
import { DispatchCall, IRootState, RootAction } from '../../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import { createSelector } from 'reselect';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { ICustomer } from '../../../../@types/model/customer';
import DataFunctions from '../../../../store/data/functions';
import { Transitions } from '../animations/Transitions';
import NewDivisionDialog from '../../division/dialog/New';
import dataActions from '../../../../store/data/actions';

interface ICustomerDivisionDropdownProps {
    value? : string;

    onChange : (value? : ICustomer) => void;

    divisions : Array<ICustomer>;

    required? : boolean;
    autoFocus? : boolean;

    width? : string | number;
    fullWidth? : boolean;
    isLoading : boolean;

    allowNew? : boolean;
    setDivisions : DispatchCall<Array<ICustomer>>;
}

interface ICustomerDivisionDropdownState {
}

class CustomerDivisionDropdown extends React.Component<ICustomerDivisionDropdownProps, ICustomerDivisionDropdownState> {
    constructor(props : ICustomerDivisionDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        await DataFunctions.getAllDivisions();
    }

    private onChanged = (event : React.ChangeEvent<HTMLSelectElement>, value : {
        label : string,
        value : string,
    }) => {
        const result = this.props.divisions.find(n => n.code === value?.value);

        this.props.onChange(result);
    }

    private getData = (state : ICustomerDivisionDropdownState, props : ICustomerDivisionDropdownProps) => props.divisions;
    private getValue = (state : ICustomerDivisionDropdownState, props : ICustomerDivisionDropdownProps) => props.value;
    private getRequired = (state : ICustomerDivisionDropdownState, props : ICustomerDivisionDropdownProps) => props.required;
    private getDivisionsDropdown = createSelector([
        this.getData, this.getRequired,
    ], (customers, required) => {
        const customerDrop = customers.map(x => ({ label: `${x.code} - ${x.description.toTitleCase()}`, value: x.code }));

        if (!required) {
            customerDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return customerDrop;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getData,
    ], (value, required, customers) => {
        if (value) {
            const customer = customers.find(x => x.code === value);

            if (customer) {
                return {
                    label: `${customer.code} - ${customer.description.toTitleCase()}`,
                    value: customer.code,
                };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    private readonly onNewDivision = (code : string, name : string) => {
        const customer : ICustomer = {
            code,
            description: name,
            company: '',
            dept: '',
            gl: '',
            isActive: true,
            updatedOn: '',
        };

        const divisions = [...this.props.divisions, customer];

        this.props.setDivisions(divisions);
        this.props.onChange(customer);
    }

    public render = () => {
        const {  } = this.state;
        const { required, isLoading, autoFocus, width, fullWidth,
            allowNew } = this.props;
        const customers = this.getDivisionsDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                <div className='flx1 fdr ais'>
                    <Autocomplete
                        className='flx1'
                        id='customer_select'
                        options={customers}
                        value={value}
                        getOptionSelected={(option, val) => option.value === val.value}
                        getOptionLabel={option => option.label}
                        onChange={this.onChanged}
                        disableClearable={required}
                        openOnFocus
                        style={{ width: !!width ? width : '' }}
                        renderInput={params => <TextField autoFocus={autoFocus} fullWidth={fullWidth} error={required && !value} {...params} label='Divisions' />}
                    />
                    {
                        allowNew &&
                        <NewDivisionDialog transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading} onSave={this.onNewDivision} />
                    }
                </div>
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                {
                    isLoading &&
                    <LinearProgress />
                }
                </div>
            </FormControl>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        divisions: state.data.divisions,
        isLoading: state.data.isLoadingDivisions,
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => bindActionCreators(
    {
        setDivisions: dataActions.setDivisions,
    },
    dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomerDivisionDropdown);
