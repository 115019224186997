import { ISAPSStation } from '../../../@types/model/sapsStation';
import axios from 'axios';

export default class SAPSHttpService {
    public static getStations() {
        return axios.get<Array<ISAPSStation>>(`${API_URL}/api/MasterData/SAPS/GetStations`, {
            params : {
            },
        });
    }
}
