import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import lodash from 'lodash';
import { connect } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import { IGrave } from '../../../../../@types/model/graveSite/grave';
import { IRootState } from '../../../../../@types/redux';
import GraveSiteFunctions from '../../../../../store/graveSites/functions';
import { IGraveSite } from '../../../../../@types/model/graveSite/graveSite';

interface IGraveDropdownProps {
    id? : string;
    value : number | null;
    label? : string;

    onChange? : (value? : IGrave) => void;

    required? : boolean;
    clearable? : boolean;
    autoFocus? : boolean;

    fullWidth? : boolean;
    disabled? : boolean;

    graveSiteId? : number;
    graveSites : Array<IGraveSite>;
    isLoading : boolean;
}

interface IGraveDropdownState {
    graves : Array<IGrave>;
}

class GraveDropdownComponent extends React.Component<IGraveDropdownProps, IGraveDropdownState> {
    constructor(props : IGraveDropdownProps) {
        super(props);

        this.state = {
            graves: [],
        };
    }

    public componentDidUpdate(prevProps : Readonly<IGraveDropdownProps>) {
        if (prevProps.graveSites !== this.props.graveSites) {
            this.loadGraves();
        }
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        await GraveSiteFunctions.getAll();

        this.loadGraves();
    }

    public loadGraves = () => {
        const { graveSites, graveSiteId } = this.props;

        if (graveSiteId) {
            this.setState({
                graves: graveSites.find(x => x.id === graveSiteId)?.graves?.slice() ?? [],
            });
        } else {
            this.setState({
                graves: graveSites.flatMap(x => x.graves.slice()),
            });
        }
    }

    private onChange = (event : React.ChangeEvent<HTMLSelectElement>, value : {
        label : string,
        value : number,
    }) => {
        if (!this.props.onChange) return;

        // We select the last one as it is the newest.
        this.props.onChange(this.state.graves.find(n => n.id === value.value));
    }

    private getGraves = (state : IGraveDropdownState) => state.graves;
    private getValue = (state : IGraveDropdownState, props : IGraveDropdownProps) => props.value;
    private getRequired = (state : IGraveDropdownState, props : IGraveDropdownProps) => props.required;

    private getActiveGraves = createSelector([
        this.getGraves,
    ], graves => graves.filter(x => x.isActive));

    private getDropdown = createSelector([
        this.getActiveGraves,
        this.getRequired,
    ], (graves, required) => {
        const result = lodash.map(graves, n => ({
            label: `${n.nameOfDeceased}`,
            value: n.id,
        }));

        if (!required) {
            result.unshift({
                label: 'ALL',
                value: 0,
            });
        }

        return result;
    });

    private getSelectedValue = createSelector([
        this.getValue,
        this.getDropdown,
        this.getRequired,
    ], (value, dropdown, required) => {
        if (typeof value === 'number') {
            return dropdown.find(x => x.value === value) ?? null;
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: 0,
            };
        }

        return null;
    });

    public render = () => {
        const { required,  fullWidth, disabled, id, clearable, label, isLoading } = this.props;

        const dropdown = this.getDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                <Autocomplete
                    openOnFocus
                    disabled={disabled || isLoading}
                    id={id}
                    options={dropdown}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required && !clearable}
                    renderInput={
                        params => <TextField error={required && !value} {...params}
                            required={required}
                            fullWidth={fullWidth}
                            label={label ?? 'Name of deceased'}
                            InputLabelProps={{
                                className: 'fs16 lh21 cpla lsp024',
                            }}
                        />
                    }
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
                {
                    isLoading &&
                    <LinearProgress className='wfill posb0 posa' />
                }
            </FormControl>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        graveSites: state.graveSites.graveSites,
        isLoading: state.graveSites.isLoading,
    };
};

// tslint:disable-next-line: variable-name
const GraveDropdown = connect(
    mapStateToProps,
)(GraveDropdownComponent);

export default GraveDropdown;
