import axios from 'axios';
import { IDivision } from '../../../@types/model/division';

export default class DivisionHttpService {
    public static getAll() {
        return axios.get<Array<IDivision>>(`${API_URL}/api/MasterData/Division/GetAll`, {
            params : {
            },
        });
    }
    public static save(division : IDivision) {
        return axios.post<IDivision>(`${API_URL}/api/MasterData/Division/Save`, division, {
            params : {
            },
        });
    }
    public static publishAll() {
        return axios.post<void>(`${API_URL}/api/MasterData/Division/PublishAll`, {}, {
            params : {
            },
        });
    }
}
