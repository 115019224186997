import DeleteSvg from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { IFileUpload } from '../../../../@types/viewmodel/file';

interface IDocumentFileViewProps {
    index : number;
    value : IFileUpload;
    onRemoveFile? : (file : IFileUpload, index : number) => void;
}

interface IDocumentFileViewState {
    blobString : string;
}

export default class DocumentFileView extends React.PureComponent<IDocumentFileViewProps, IDocumentFileViewState> {
    constructor(props : IDocumentFileViewProps) {
        super(props);
        this.state = {
            blobString: '',
        };
    }

    public readonly componentDidMount = () => {
        const { value } = this.props;

        if (value.file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (typeof e.target?.result === 'string') {
                    this.setState({
                        blobString: e.target?.result ?? '',
                    });
                } else {
                    this.setState({
                        blobString: '',
                    });
                }
            };
            reader.readAsDataURL(value.file);
        }
    }

    public render = () => {
        const { value } = this.props;
        const { blobString } = this.state;
        return (
            <div className='fdc'>
                <embed height='100%' width='100%' type={value.file?.type} src={blobString} />
            </div>
        );
    }
}
