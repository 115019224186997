import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import lodash from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import { ACCESS_POINT_REASONS } from '../../../../appConstants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createSelector } from 'reselect';
import TextField from '@material-ui/core/TextField';

interface IAccessPointReasonDropdownProps {
    value : string | null;

    onChange : (value : string) => void;
}

interface IAccessPointReasonDropdownState {}

export default class AccessPointReasonDropdown extends React.Component<IAccessPointReasonDropdownProps, IAccessPointReasonDropdownState> {
    constructor(props : IAccessPointReasonDropdownProps) {
        super(props);
        this.state = {};
    }

    private onChange = (event : React.ChangeEvent<HTMLSelectElement>, value : {
        label : string,
        value : string,
    }) => {
        this.props.onChange(value?.value ?? '');
    }

    private getValue = (state : IAccessPointReasonDropdownState, props : IAccessPointReasonDropdownProps) => props.value;
    private getDropdown = createSelector([
    ], () => {
        return lodash.map(ACCESS_POINT_REASONS, x => ({ label: x, value: x }));
    });

    private getSelectedValue = createSelector([
        this.getValue,
    ], (value) => {
        if (value) {
            return {
                label: value,
                value,
            };
        }

        return null;
    });

    public render = () => {
        const { } = this.props;
        const divisions = this.getDropdown(this.state);
        const value = this.getSelectedValue(this.state, this.props);

        return (
            <FormControl fullWidth>
                <Autocomplete
                    id='accessPointReason'
                    options={divisions}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={false}
                    openOnFocus
                    renderInput={params => <TextField {...params} fullWidth label='Default Reason' InputLabelProps={{
                        className: 'fs16 lh21 cpla lsp024',
                    }} />}
                />
            </FormControl>
        );
    }
}
