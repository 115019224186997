import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import React from 'react';
import Fab from '@material-ui/core/Fab';
import { FormControl, TextField } from '@material-ui/core';
import OutsideClicker from '../custom/OutsideClicker';
import OutlinedToggleButton from '../custom/button/OutlinedToggleButton';
import RoleDropdown from '../custom/dropdowns/RoleDropdown';
import { IRole } from '../../../@types/model/right';

interface IRightsFilterPopperProps {
    id ?: string;
    open : boolean;
    anchorEl : HTMLButtonElement | null;
    name : string | null;
    employeeNr : string | null;
    isActive : boolean | null;
    roleId : number | null;
    onChange ?: (
        name : string | null,
        employeeNr : string | null,
        isActive : boolean | null, 
        roleId : number | null,
        roleName : string | null,
    ) => void;
    onCancelClick ?: () => void;
}

interface IRightsFilterPopperState {
    name : string;
    employeeNr : string;
    isActive : boolean;
    roleId : number | null;
    roleName : string;
}

export default class RightsFilterPopper extends React.PureComponent<IRightsFilterPopperProps, IRightsFilterPopperState> {
    constructor(props : IRightsFilterPopperProps) {
        super(props);
        this.state = {
            name : '',
            employeeNr : '',
            roleName : '',
            isActive : true,
            roleId : null,
        };
    }

    public componentDidUpdate = (prevProps : IRightsFilterPopperProps) => {
        if (!prevProps.open && this.props.open) {
            const {
                name,
                employeeNr,
                roleId,
                isActive,
            } = this.props;

            this.setState({
                name : name ?? '',
                employeeNr : employeeNr ?? '',
                isActive: isActive ?? true,
                roleId: roleId,
            });
        }
    }

    private onNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({ name : event.target.value });
    }

    private onEmployeeNrChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({ employeeNr : event.target.value });
    }

    private onRoleChange = (role : IRole) => {
        this.setState({
            roleId: role.id,
            roleName: role.name,
        });
    }

    private onApplyClick = (event ?: React.FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }

        if (!this.props.onChange) return;

        const {
            name,
            employeeNr,
            isActive,
            roleId,
            roleName,
        } = this.state;

        this.props.onChange(name, employeeNr, isActive, roleId, roleName);
    }

    private isActiveChange = (value ?: boolean) => {
        this.setState({
            isActive: !value,
        });
    }

    public render = () => {
        const {
            open,
            anchorEl,
            id,
            onCancelClick,
        } = this.props;

        const {
            name,
            employeeNr,
            isActive,
            roleId,
        } = this.state;

        return (
            <Popper
                id={`${id}_rights_filter_menu`}
                open={open}
                anchorEl={anchorEl}
                transition
                className={'filter-popper'}
                placement={'bottom'}
            >
                {
                    ({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <div className='filter-popper-offset'>
                                {
                                    open &&
                                    <OutsideClicker onClickedOutside={onCancelClick}>
                                        <form onSubmit={this.onApplyClick} className={'fdc'}>
                                            <div className={'fdr'}>
                                                <div className={'flx1 pr5'}>
                                                    <FormControl fullWidth> 
                                                        <TextField
                                                            autoComplete={'off'}
                                                            id={'Name'}
                                                            label={'Name'}
                                                            value={name}
                                                            onChange={this.onNameChange}
                                                            margin={'normal'}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div className={'flx1 pr5 pl5'}>
                                                    <FormControl fullWidth> 
                                                        <TextField 
                                                            autoComplete='off'
                                                            id={'employeeNr'}
                                                            label={'Employee Nr'}
                                                            value={employeeNr}
                                                            onChange={this.onEmployeeNrChange}
                                                            margin={'normal'}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div className={'fdr mt15'}>
                                                <div className={'flx1 pr5'}>
                                                    <RoleDropdown roleId={roleId} onSelected={this.onRoleChange} fullWidth required/>
                                                </div>
                                                <div className={'flx1 pr5 pl5 aic jcc'}>
                                                    <OutlinedToggleButton
                                                        labelText='Is Active'
                                                        value={isActive}
                                                        onClick={this.isActiveChange}
                                                        selected={isActive}
                                                    />
                                                </div>
                                            </div>
                                            <div className={'fdr aic mt15 pb15'}>
                                                <span className='flx1'/>
                                                <Fab variant={'extended'} color={'secondary'} className={'fab cancel'} size={'small'} onClick={onCancelClick} type={'button'}>
                                                    CANCEL
                                                </Fab> 
                                                <Fab variant={'extended'} color={'secondary'} className={'fab save'} size={'small'} type={'submit'}>
                                                    APPLY
                                                </Fab>
                                            </div>
                                        </form>
                                    </OutsideClicker>
                                }
                            </div>
                        </Fade>
                    )
                }
            </Popper>
        )
    }
}