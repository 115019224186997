import React from 'react';
import { IRootState, RootAction, DispatchCall } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import vehicleActions from '../../../../store/accessPointEvent/actions';
import Dialog from '@material-ui/core/Dialog';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import ReactToPrint from 'react-to-print';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { setPageTitle, resetPageTitle } from '../../../../service/helper/functionHelperService';
import generalFunctions from '../../../../store/general/functions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import AvatarIcon from '../../custom/AvatarIcon';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { EnumGender } from '../../../../@types/model/base';
import { IAccessPointEvent } from '../../../../@types/model/access';
import { AccessPointEventFunctions } from '../../../../store/accessPointEvent/functions';
import { createSelector } from 'reselect';

interface IAccessPointEventEntryInfoDialogProps {
    guid? : string;
    accessPointEventEntry? : IAccessPointEvent;
    isLoading : boolean;
    setEntry : DispatchCall<IAccessPointEvent | undefined>;
    setGuid : DispatchCall<string | undefined>;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
}

interface IAccessPointEventEntryInfoDialogState {}

class AccessPointEventEntryInfoDialog extends React.Component<IAccessPointEventEntryInfoDialogProps, IAccessPointEventEntryInfoDialogState> {
    private componentRef : any;
    constructor(props : IAccessPointEventEntryInfoDialogProps) {
        super(props);
        this.state = {};
    }

    public onClose = () => {
        this.props.setEntry(undefined);
        this.props.setGuid(undefined);
    }

    public componentDidUpdate = (prevProps : IAccessPointEventEntryInfoDialogProps) => {
        if (prevProps.guid !== this.props.guid) {
            this.loadEntry();
        }
    }

    private loadEntry = () => {
        if (!this.props.guid) {
            this.props.setEntry(undefined);
            return;
        }

        AccessPointEventFunctions.get(undefined, this.props.guid);
    }

    public getData = (state : IAccessPointEventEntryInfoDialogState, props : IAccessPointEventEntryInfoDialogProps) => props.accessPointEventEntry;

    public getPersonnelAccessPointEvent = createSelector([
        this.getData,
    ],
        (data) => {
            if (data?.personnelAccessPointEvents && data?.personnelAccessPointEvents.length) {
                return data.personnelAccessPointEvents[0];
            }

            return null;
        },
    );

    public getLastDriver = createSelector([
        this.getData,
    ],
        (data) => {
            if (data?.person && data?.person.driverLicences.length) {
                return data.person.driverLicences[data.person.driverLicences.length - 1];
            }

            if (data?.personnelAccessPointEvents && data?.personnelAccessPointEvents.length) {
                const personnelAccess = this.getPersonnelAccessPointEvent(this.state, this.props);

                if (personnelAccess?.personnel?.driverLicences.length) {
                    return personnelAccess.personnel.driverLicences[personnelAccess.personnel.driverLicences.length - 1];
                }
            }

            return null;
        },
    );

    public getPerson = createSelector([
        this.getData,
    ],
        (data) => {
            if (data?.person) {
                return data.person;
            }

            if (data?.personnelAccessPointEvents && data?.personnelAccessPointEvents.length) {
                const personnelAccess = this.getPersonnelAccessPointEvent(this.state, this.props);

                if (personnelAccess?.personnel) {
                    return personnelAccess.personnel;
                }
            }

            return null;
        },
    );

    public getPeople = createSelector([
        this.getData,
    ],
        (data) => {
            if (data?.person) {
                return [data.person];
            }

            if (data?.personnelAccessPointEvents && data?.personnelAccessPointEvents.length) {
                return data.personnelAccessPointEvents.map(x => x.personnel);
            }

            return null;
        },
    );

    public render = () => {
        const { } = this.state;
        const { isLoading, accessPointEventEntry, fullScreen, transition, guid } = this.props;

        const lastLicence = this.getLastDriver(this.state, this.props);
        const people = this.getPeople(this.state, this.props);
        const mainPerson = this.getPerson(this.state, this.props);
        return (
            <Dialog
                    open={!!guid}
                    onClose={this.onClose}
                    aria-labelledby='accessPointEventEntry-info-dialog-title'
                    aria-describedby='accessPointEventEntry-info-description'
                    fullWidth={this.props.fullWidth}
                    maxWidth={this.props.maxWidth}
                    fullScreen={fullScreen}
                    TransitionComponent={transition}
                    transitionDuration={500}
                >
                    <div
                    ref={el => (this.componentRef = el)}>
                        <AppBar className='posr'>
                            <Toolbar>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <CloseIcon />
                                </IconButton>
                                {
                                    accessPointEventEntry &&
                                    <Typography variant='h6' color='inherit' className='flx1'>
                                        {moment.utc(accessPointEventEntry.date).local().format('ddd YYYY-MM-DD HH:mm')} - {accessPointEventEntry.isInbound ? 'IN' : 'OUT'}
                                    </Typography>
                                }
                                {
                                    accessPointEventEntry &&
                                    <ReactToPrint
                                        trigger={() =>
                                            <div>
                                                <Tooltip title='Print'>
                                                    <IconButton color='inherit'>
                                                        <Icon>
                                                            print
                                                        </Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        }
                                        onPrintError={generalFunctions.showPrintError}
                                        content={() => this.componentRef}
                                        onBeforePrint={() => setPageTitle(`${moment.utc(accessPointEventEntry.date).local().format('YYYY/MM/DD HH:mm')} - ${accessPointEventEntry.isInbound ? 'IN' : 'OUT'}`)}
                                        onAfterPrint={resetPageTitle}
                                    />
                                }
                            </Toolbar>
                        </AppBar>
                        <DialogContent>
                            <div className='p15'>
                                {
                                    !isLoading && accessPointEventEntry &&
                                    <div className='fdr'>
                                        <div className='fdc flx1 pr15'>
                                            <div className='fdc bcpd cw'>
                                                <div className='fdc p5'>
                                                    <div className='fdr'>
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>Created By:</Typography>
                                                            <Typography variant='body1'>{!accessPointEventEntry.createdByName ? accessPointEventEntry.createdBy : accessPointEventEntry.createdByName.toTitleCase()}</Typography>
                                                        </div>
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>Updated By:</Typography>
                                                            <Typography variant='body1'>{!accessPointEventEntry.updatedByName ? accessPointEventEntry.updatedBy : accessPointEventEntry.updatedByName.toTitleCase()}</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='fdc p5'>
                                                    <Divider className='bcw mb5' />
                                                    <div className='fdr'>
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>Gate:</Typography>
                                                            <Typography variant='body1'>{accessPointEventEntry.accessPointName}</Typography>
                                                        </div>
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>Identifier:</Typography>
                                                            {
                                                                !!mainPerson &&
                                                                <Typography variant='body1'>{!mainPerson.employeeNumber ? mainPerson.name : mainPerson.employeeNumber}</Typography>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='fdr'>
                                                        {
                                                            !!accessPointEventEntry.vehicleAccess &&
                                                            <div className='flx1 p5'>
                                                                <Typography variant='body1' className='w150 fw500'>Reason:</Typography>
                                                                <Typography variant='body1'>{accessPointEventEntry.vehicleAccess.reason}</Typography>
                                                            </div>
                                                        }
                                                        {
                                                            !!accessPointEventEntry.personAccess &&
                                                            <div className='flx1 p5'>
                                                                <Typography variant='body1' className='w150 fw500'>Reason:</Typography>
                                                                <Typography variant='body1'>{accessPointEventEntry.personAccess.reason}</Typography>
                                                            </div>
                                                        }
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>Direction:</Typography>
                                                            <Tooltip title={accessPointEventEntry.isInbound ? `IN` : 'OUT'}>
                                                                <div>
                                                                    {accessPointEventEntry.isInbound ? (<span className={`icon-direction-in`} style={{ fontSize: 18 }} />) : (<span className={`icon-direction-out`} style={{ fontSize: 18 }} />)}
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    {
                                                        !!accessPointEventEntry.vehicleAccess &&
                                                        <div className='fdr'>
                                                            <div className='flx1 p5'>
                                                                <Typography variant='body1' className='w150 fw500'>Destination:</Typography>
                                                                <Typography variant='body1'>{!accessPointEventEntry.vehicleAccess.destination || accessPointEventEntry.vehicleAccess.destination === 'null' ? 'Unknown' : accessPointEventEntry.vehicleAccess.destination}</Typography>
                                                            </div>
                                                            <div className='flx1 p5'>
                                                                <Typography variant='body1' className='w150 fw500'>ODO:</Typography>
                                                                <Typography variant='body1'>{!accessPointEventEntry.vehicleAccess.odoReading ? 'Unknown' : accessPointEventEntry.vehicleAccess.odoReading.toFixed(0)}</Typography>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className='fdr'>
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>Device ID:</Typography>
                                                            <Typography variant='body1'>{accessPointEventEntry.deviceId ?? '-'}</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                !!accessPointEventEntry.vehicle &&
                                                <div className='fdc bcg1 cpd'>
                                                    <div className='fdr aic m5'>
                                                        <Typography variant='h5' className='fw500'>VEHICLE</Typography>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                !!accessPointEventEntry.vehicle &&
                                                <div className='fdc'>
                                                    <div className='fdr p5'>
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>Make:</Typography>
                                                            <Typography variant='body1'>{accessPointEventEntry.vehicle.make}</Typography>
                                                        </div>
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>Model:</Typography>
                                                            <Typography variant='body1'>{accessPointEventEntry.vehicle.model}</Typography>
                                                        </div>
                                                    </div>
                                                    <div className='fdr p5'>
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>Colour:</Typography>
                                                            <Typography variant='body1'>{accessPointEventEntry.vehicle.colour}</Typography>
                                                        </div>
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>Description:</Typography>
                                                            <Typography variant='body1'>{accessPointEventEntry.vehicle.description}</Typography>
                                                        </div>
                                                    </div>
                                                    <div className='fdr p5'>
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>Plate Number:</Typography>
                                                            <Typography variant='body1'>{accessPointEventEntry.vehicle.registrationNumber}</Typography>
                                                        </div>
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>VIN:</Typography>
                                                            <Typography variant='body1'>{accessPointEventEntry.vehicle.vin}</Typography>
                                                        </div>
                                                    </div>
                                                    <div className='fdr p5'>
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>Engine Number:</Typography>
                                                            <Typography variant='body1'>{accessPointEventEntry.vehicle.engineNumber}</Typography>
                                                        </div>
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>Number:</Typography>
                                                            <Typography variant='body1'>{accessPointEventEntry.vehicle.number}</Typography>
                                                        </div>
                                                    </div>
                                                    <div className='fdr p5'>
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>Licence Number:</Typography>
                                                            <Typography variant='body1'>{accessPointEventEntry.vehicle.licenceNumber}</Typography>
                                                        </div>
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>Expire Date:</Typography>
                                                            <Typography className={`${accessPointEventEntry.vehicle.licenceDoE && moment.utc(accessPointEventEntry.vehicle.licenceDoE).isBefore(moment.utc(accessPointEventEntry.date)) ? 'cpr' : ''}`} variant='body1'>{!accessPointEventEntry.vehicle.licenceDoE ? '' : moment.utc(accessPointEventEntry.vehicle.licenceDoE).local().format('YYYY-MM-DD')}</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className='fdc flx1'>
                                            {
                                                !!people?.length &&
                                                people.map((person, i) => (
                                                    <Accordion key={`${i}`} defaultExpanded className='m0 bn nbs incident-expansion'>
                                                        <AccordionSummary className={`aic pl5 pr5 bcg1 cpd m0 mh48`}
                                                            expandIcon={<ExpandMoreIcon className={`cpd`} />}>
                                                            <div className={`aic`}>
                                                                <AvatarIcon iconClass='icon-suspect' iconSize={42} iconClassName={`cpd`} className='mr10 bcg1'/>
                                                                <Typography variant='h5' className='fw500'>{accessPointEventEntry.vehicle ? 'DRIVER' : 'PERSON'}</Typography>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails className='flx1 p0'>
                                                            {
                                                                !!lastLicence &&
                                                                <div className='fdc flx1'>
                                                                    <div className='fdr p5'>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>Name:</Typography>
                                                                            <Typography variant='body1'>{lastLicence.init} {lastLicence.surname}</Typography>
                                                                        </div>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>Date of Birth:</Typography>
                                                                            <Typography variant='body1'>{!lastLicence.dateOfBirth ? '' :
                                                                                moment.utc(lastLicence.dateOfBirth).local().format('YYYY-MM-DD')}</Typography>
                                                                        </div>
                                                                    </div>
                                                                    <div className='fdr p5'>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>ID:</Typography>
                                                                            <Typography variant='body1'></Typography>
                                                                        </div>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>Gender:</Typography>
                                                                            <Typography variant='body1'>{lastLicence.gender === EnumGender.Male ? 'Male' : 'Female'}</Typography>
                                                                        </div>
                                                                    </div>
                                                                    <div className='fdr p5'>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>Country of Birth:</Typography>
                                                                            <Typography variant='body1'>{lastLicence.countryOfBirth}</Typography>
                                                                        </div>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>Country of Issue:</Typography>
                                                                            <Typography variant='body1'>{lastLicence.countryOfIssue}</Typography>
                                                                        </div>
                                                                    </div>
                                                                    <div className='fdr p5'>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>Codes:</Typography>
                                                                            <Typography variant='body1'>{lastLicence.codes.join(', ')}</Typography>
                                                                        </div>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>Type:</Typography>
                                                                            <Typography variant='body1'>{lastLicence.licenceType}</Typography>
                                                                        </div>
                                                                    </div>
                                                                    <div className='fdr p5'>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>Valid From:</Typography>
                                                                            <Typography variant='body1'>{!lastLicence.validFrom ? '' :
                                                                                moment.utc(lastLicence.validFrom).local().format('YYYY-MM-DD')}</Typography>
                                                                        </div>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>Expire Date:</Typography>
                                                                            <Typography className={`${lastLicence.expiryDate && moment.utc(lastLicence.expiryDate).isBefore(moment.utc(accessPointEventEntry.date)) ? 'cpr' : ''}`}
                                                                                variant='body1'>{!lastLicence.expiryDate ? '' :
                                                                                    moment.utc(lastLicence.expiryDate).local().format('YYYY-MM-DD')}</Typography>
                                                                        </div>
                                                                    </div>
                                                                    <div className='fdr p5'>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>Issue Number:</Typography>
                                                                            <Typography variant='body1'>{lastLicence.issueNumber}</Typography>
                                                                        </div>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>Licence Number:</Typography>
                                                                            <Typography variant='body1'>{lastLicence.licenceNumber}</Typography>
                                                                        </div>
                                                                    </div>
                                                                    <div className='fdr p5'>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>Vehicle Restrictions:</Typography>
                                                                            <Typography variant='body1'>{lastLicence.vehicleRestrictions}</Typography>
                                                                        </div>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>Issue Dates:</Typography>
                                                                            <Typography variant='body1'>
                                                                                {!lastLicence.issueDate ? '' :
                                                                                    moment.utc(lastLicence.issueDate).local().format('YYYY-MM-DD')}
                                                                                {
                                                                                    lastLicence.issueDate2 &&
                                                                                    <span>, {moment.utc(lastLicence.issueDate2!).local().format('YYYY-MM-DD')}</span>
                                                                                }
                                                                                {
                                                                                    lastLicence.issueDate3 &&
                                                                                    <span>, {moment.utc(lastLicence.issueDate3!).local().format('YYYY-MM-DD')}</span>
                                                                                }
                                                                                {
                                                                                    lastLicence.issueDate4 &&
                                                                                    <span>, {moment.utc(lastLicence.issueDate4!).local().format('YYYY-MM-DD')}</span>
                                                                                }
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                    <div className='fdr p5'>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>Driver Restrictions:</Typography>
                                                                            <Typography variant='body1'>{lastLicence.driverRestrictions}</Typography>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                !lastLicence && !!person &&
                                                                <div className='fdc flx1'>
                                                                    <div className='fdr p5'>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>Name:</Typography>
                                                                            <Typography variant='body1'>{person.name} {person.surname}</Typography>
                                                                        </div>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>ID:</Typography>
                                                                            <Typography variant='body1'></Typography>
                                                                        </div>
                                                                    </div>
                                                                    <div className='fdr p5'>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>Gender:</Typography>
                                                                            <Typography variant='body1'>{{ 1 : 'Male', 2 : 'Female', 3 : 'Unknown' }[person.gender]}</Typography>
                                                                        </div>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>Country of Birth:</Typography>
                                                                            <Typography variant='body1'>{person.countryOfBirth}</Typography>
                                                                        </div>
                                                                    </div>
                                                                    <div className='fdr p5'>
                                                                        <div className='flx1 p5'>
                                                                            <Typography variant='body1' className='w150 fw500'>Employee Nr:</Typography>
                                                                            <Typography variant='body1'>{person.employeeNumber}</Typography>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                !accessPointEventEntry.person &&
                                                                !accessPointEventEntry.personnelAccessPointEvents?.length &&
                                                                <div className='fdc flx1'>
                                                                    <span>None</span>
                                                                </div>
                                                            }
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    isLoading &&
                                    <div className='flx1 fdc aic'>
                                        <CircularProgress />
                                    </div>
                                }
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        accessPointEventEntry: state.accessPointEvent.selectedEntry,
        isLoading: state.accessPointEvent.isLoadingEntries || state.accessPointEvent.isLoadingEntry,
        guid: state.accessPointEvent.selectedGuid,
    };
};
const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => bindActionCreators({
    setEntry: vehicleActions.setEntry,
    setGuid: vehicleActions.setGuid,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccessPointEventEntryInfoDialog);
