import { Button, Divider, Icon, IconButton, InputAdornment, LinearProgress, List, ListItem, ListItemText, Paper, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IUserListView } from '../../../../@types/model/right';
import { IRootState } from '../../../../@types/redux';
import RightsFunctions from '../../../../store/right/functions';
import lodash from 'lodash';

interface IUserSelectorProps {
    users : Array<IUserListView>;
    isLoading : boolean;

    selectedUsers : Array<number>;

    onSelectionChange : (users : Array<number>) => void;

    disabled? : boolean;
}

interface IUserSelectorState {
    selectedFrom : Array<number>;
    selectedTo : Array<number>;

    fromSearchText : string;
    toSearchText : string;
}

class UserSelectorComponent extends React.PureComponent<IUserSelectorProps, IUserSelectorState> {
    constructor(props : IUserSelectorProps) {
        super(props);
        this.state = {
            selectedFrom: [],
            selectedTo: [],
            fromSearchText: '',
            toSearchText: '',
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        await RightsFunctions.loadUsers();
    }

    private getUsers = (props : IUserSelectorProps) => props.users;
    private getSelectedUsers = (props : IUserSelectorProps) => props.selectedUsers;
    private getFromSearchText = (props : IUserSelectorProps, state : IUserSelectorState) => state.fromSearchText;
    private getToSearchText = (props : IUserSelectorProps, state : IUserSelectorState) => state.toSearchText;

    private getActiveUser = createSelector(
        [this.getUsers],
        (users) => {
            return lodash.chain(users)
                .filter(x => !!x.isActive)
                .sortBy(x => x.employeeNr ?? '')
                .value();
        },
    );

    private getSelected = createSelector(
        [this.getActiveUser, this.getSelectedUsers, this.getToSearchText],
        (users, selectedUsers, toSearchText) => {
            return users
                .filter(x => selectedUsers.includes(x.id))
                .filter(x => !!x.employeeNr?.toLowerCase().includes(toSearchText.toLowerCase()) ||
                            x.name.toLowerCase().includes(toSearchText.toLowerCase()) ||
                            x.email.toLowerCase().includes(toSearchText.toLowerCase()));
        },
    );

    private getNotSelected = createSelector(
        [this.getActiveUser, this.getSelectedUsers, this.getFromSearchText],
        (users, selectedUsers, fromSearchText) => {
            return users
                .filter(x => !selectedUsers.includes(x.id))
                .filter(x => !!x.employeeNr?.toLowerCase().includes(fromSearchText.toLowerCase()) ||
                            x.name.toLowerCase().includes(fromSearchText.toLowerCase()) ||
                            x.email.toLowerCase().includes(fromSearchText.toLowerCase()));
        },
    );

    private readonly onFromClearClick = () => {
        this.setState({
            fromSearchText: '',
        });
    }

    private readonly onFromSearchChanged = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            fromSearchText: event.currentTarget.value,
        });
    }

    private readonly onToClearClick = () => {
        this.setState({
            toSearchText: '',
        });
    }

    private readonly onToSearchChanged = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            toSearchText: event.currentTarget.value,
        });
    }

    private readonly onToClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const selectedTo = this.state.selectedTo.slice();

        const index = selectedTo.indexOf(Number(event.currentTarget.id));
        if (index > -1) selectedTo.splice(index, 1);
        else selectedTo.push(Number(event.currentTarget.id));

        this.setState({
            selectedTo,
        });
    }

    private readonly onFromClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const selectedFrom = this.state.selectedFrom.slice();

        const index = selectedFrom.indexOf(Number(event.currentTarget.id));
        if (index > -1) selectedFrom.splice(index, 1);
        else selectedFrom.push(Number(event.currentTarget.id));

        this.setState({
            selectedFrom,
        });
    }

    private readonly onAddClick = () => {
        const selectedUsers = this.props.selectedUsers.slice();
        const selectedFrom = this.state.selectedFrom.slice();

        selectedFrom.forEach((n) => {
            const index = selectedUsers.indexOf(n);
            if (index === -1) selectedUsers.push(n);
        });

        this.props.onSelectionChange(selectedUsers);
        this.setState({
            selectedFrom: [],
        });
    }

    private readonly onRemoveClick = () => {
        const selectedUsers = this.props.selectedUsers.slice();
        const selectedTo = this.state.selectedTo.slice();

        selectedTo.forEach((n) => {
            const index = selectedUsers.indexOf(n);
            if (index > -1) selectedUsers.splice(index, 1);
        });

        this.props.onSelectionChange(selectedUsers);
        this.setState({
            selectedTo: [],
        });
    }

    public render = () => {
        const { toSearchText, selectedFrom, fromSearchText, selectedTo } = this.state;
        const { isLoading } = this.props;

        const selected = this.getSelected(this.props, this.state);
        const notSelected = this.getNotSelected(this.props, this.state);

        const disabled = this.props.disabled || isLoading;
        return (
            <div className='fdc hfill'>
                <div className='fdr hfill'>
                    <div className='fdc flx3'>
                        <div className='fdc flx1'>
                            <div className='fdc mt10 mb15'>
                                <Typography className='cpd fs20 fwb'>Not Allowed Access</Typography>
                            </div>
                            <Paper className='fdc flx1'>
                                <Toolbar className={'fdr aic'}>
                                    <TextField
                                        fullWidth
                                        value={fromSearchText}
                                        onChange={this.onFromSearchChanged}
                                        placeholder='Search'
                                        margin='dense'
                                        disabled={disabled}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <Icon>search</Icon>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <Tooltip title='Clear'>
                                                    <IconButton onClick={this.onFromClearClick}>
                                                        <Icon className='cr'>close</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            ),
                                        }}
                                    />
                                </Toolbar>
                                <Divider />
                                <List className={'fdc hfill oya'}>
                                    <div className='mnh4'>
                                        {
                                            isLoading &&
                                            <LinearProgress />
                                        }
                                    </div>
                                    {
                                        notSelected.map(n => (
                                            <ListItem
                                                button
                                                key={n.id}
                                                id={`${n.id}`}
                                                onClick={this.onFromClick}
                                                selected={selectedFrom.includes(n.id)}
                                                disabled={disabled}
                                            >
                                                <ListItemText
                                                    color='primary'
                                                    primary={n.employeeNr ?? ''}
                                                    secondary={`${n.name}`}
                                                />
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Paper>
                        </div>
                    </div>
                    <div className='fdc flx1 ais jcc ml15 mr15'>
                        <div className='fdr mb5'>
                            <Button
                                variant='contained'
                                color='primary'
                                className='flx1'
                                onClick={this.onAddClick}
                                disabled={disabled}
                            >
                                {
                                    '>>'
                                }
                            </Button>
                        </div>
                        <div className='fdr mt5'>
                            <Button
                                variant='contained'
                                color='primary'
                                className='flx1'
                                onClick={this.onRemoveClick}
                                disabled={disabled}
                            >
                                {
                                    '<<'
                                }
                            </Button>
                        </div>

                    </div>
                    <div className='fdc flx3'>
                        <div className='fdc flx1 '>
                            <div className='fdc mt10 mb15'>
                                <Typography className='cpd fs20 fwb'>Allowed Access</Typography>
                            </div>
                            <Paper className='fdc flx1'>
                                <Toolbar className={'fdr aic'}>
                                    <TextField
                                        fullWidth
                                        value={toSearchText}
                                        onChange={this.onToSearchChanged}
                                        disabled={disabled}
                                        placeholder='Search'
                                        margin='dense'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <Icon>search</Icon>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <Tooltip title='Clear'>
                                                    <IconButton onClick={this.onToClearClick}>
                                                        <Icon className='cr'>close</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            ),
                                        }}
                                    />
                                </Toolbar>
                                <Divider />
                                <List className={'fdc flx1 oya'}>
                                    <div className='mnh4'>
                                        {
                                            isLoading &&
                                            <LinearProgress />
                                        }
                                    </div>
                                    {
                                        selected.map(n => (
                                            <ListItem
                                                button
                                                key={n.id}
                                                id={`${n.id}`}
                                                onClick={this.onToClick}
                                                selected={selectedTo.includes(n.id)}
                                                disabled={disabled}
                                            >
                                                <ListItemText
                                                    color='primary'
                                                    primary={n.employeeNr ?? ''}
                                                    secondary={`${n.name}`}
                                                />
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        users: state.rights.users,
        isLoading: state.rights.isLoadingUsers,
    };
};

// tslint:disable-next-line: variable-name
const UserSelector = connect(
    mapStateToProps,
)(UserSelectorComponent);

export default UserSelector;
