import React from 'react';
import { IIncident } from '../../../../@types/model/incident';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IncidentEvidenceEditDialog from '../dialog/edit/EvidenceEditDialog';

interface IIncidentEvidenceViewProps {
    incident : IIncident;
    isEditAdmin : boolean;
    isSaving : boolean;
}

interface IIncidentEvidenceViewState {}

export default class IncidentEvidenceView extends React.PureComponent<IIncidentEvidenceViewProps, IIncidentEvidenceViewState> {
    constructor(props : IIncidentEvidenceViewProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const {} = this.state;
        const { isEditAdmin, incident, isSaving } = this.props;
        return (
            <div className='flx1 fdr aifs pt20 pb20 bcg1'>
                <span className='flx1' />
                <Paper className='fdc flx2' elevation={3}>
                    <AppBar position='static' className='pbr bcpl' elevation={0}>
                        <Toolbar>
                            <Typography className='fwm fs18'>
                                EVIDENCE
                            </Typography>
                            <span className='flx1' />
                            {
                                isEditAdmin &&
                                <div className='posa posr10'>
                                    <IncidentEvidenceEditDialog maxWidth='md' fullWidth incident={incident} isLoading={isSaving}/>
                                </div>
                            }
                        </Toolbar>
                    </AppBar>
                    {
                        !incident.evidence &&
                        <div className='fdc pt10 mb10 aic jcc'>
                            <Typography className={'flx1 fs16 lh37 fwm'} variant='subtitle1'>
                                None
                            </Typography>
                        </div>
                    }
                    {
                        incident.evidence &&
                        <div className='fdc'>
                            <div className={'fdr pl10 pr10 pt10 mb10'}>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Description/Type
                                </Typography>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    ZZ2 Exhibit Reg Nr
                                </Typography>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Result
                                </Typography>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Owner Received
                                </Typography>
                            </div>
                            <div className={'fdr mb10 pl10 pr10'}>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.evidence.description}
                                </Typography>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.evidence.exhibitRegisterNumber}
                                </Typography>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.evidence.result}
                                </Typography>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.evidence.ownerReceived ? 'Yes' : 'No'}
                                </Typography>
                            </div>
                        </div>

                    }
                </Paper>
                <span className='flx1' />
            </div>
        );
    }
}
