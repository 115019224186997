import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { ILatLng } from '../../../../@types/model/base';
import { IDivision } from '../../../../@types/model/division';
import { IObservation } from '../../../../@types/model/observation';
import { IRootState } from '../../../../@types/redux';
import { IFileUpload } from '../../../../@types/viewmodel/file';
import ObservationFunctions from '../../../../store/observation/functions';
import DivisionDropdown from '../../custom/dropdowns/DivisionDropdown';
import MultiFileDropZone from '../../custom/file/MultiFileDropZone';
import LocationInput from '../../custom/input/LocationInput';

interface IObservationEditDialogProps {
    observation : IObservation;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IObservationEditDialogState {
    open : boolean;

    date : moment.Moment;
    description : string;
    division? : IDivision;
    files : Array<IFileUpload>;
    geo : ILatLng;
}

class ObservationEditDialog extends React.Component<IObservationEditDialogProps, IObservationEditDialogState> {
    private readonly maxDate : moment.Moment = moment().utc().startOf('day');
    private readonly maxFiles = 12;
    constructor(props : IObservationEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            date: moment.utc().startOf('day'),
            description: '',
            files: [],
            geo: {
                latitude: 0,
                longitude: 0,
            },
        };
    }

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private onClick = () => {
        const { date, description, geo, divisionCode, divisionId, divisionName } = this.props.observation;
        this.setState({
            open: true,
            date: moment.utc(date).startOf('day'),
            description,
            division: !divisionId ? undefined : {
                code: divisionCode ?? '',
                description: divisionName ?? '',
                id: divisionId ?? 0,
            } as IDivision,
            files: [],
            geo: {
                latitude: geo.latitude,
                longitude: geo.longitude,
            },
        });
    }
    private readonly onDateChange = (momentDate : moment.Moment) => {
        if (momentDate && momentDate?.isAfter(this.maxDate)) return;
        const date = momentDate?.startOf('day');

        this.setState({
            date,
        });
    }

    private readonly onFileSelected = (file : IFileUpload) => {
        const files = this.state.files.slice();

        const index = files.findIndex(n => n.name === file.name);

        if (index > -1) {
            files.splice(index, 1, file);
        } else {
            files.push(file);
        }

        this.setState({
            files,
        });
    }

    private readonly onFileRemove = (file : IFileUpload) => {
        const files = this.state.files.slice();

        const index = files.findIndex(n => n.name === file.name);

        if (index > -1) {
            files.splice(index, 1);
        }

        this.setState({
            files,
        });
    }

    private readonly onDivisionChange = (division? : IDivision | undefined) => {
        this.setState({
            division,
        });
    }

    private readonly onDescriptionChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            description: event.currentTarget.value,
        });
    }

    private onLocationChange = (geo : ILatLng) => {
        this.setState({
            geo,
        });
    }

    private onLatitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.currentTarget.value && parseFloat(event.currentTarget.value) === null) return;
        this.setState({
            geo: { ...this.state.geo, latitude: parseFloat(!event.currentTarget.value ? '0' : event.currentTarget.value) },
        });
    }

    private onLongitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.currentTarget.value && parseFloat(event.currentTarget.value) === null) return;
        this.setState({
            geo: { ...this.state.geo, longitude: parseFloat(!event.currentTarget.value ? '0' : event.currentTarget.value) ?? null },
        });
    }

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private readonly save = async () => {
        const { date, files, description, division, geo } = this.state;

        if (!date || !description || !division) return;

        const result = await ObservationFunctions.update(this.props.observation.guid, date.valueOf(), division, description, geo, files);

        if (result != null) {
            this.onClose();
        }

    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, observation, isLoading } = this.props;
        const { open, date, files, description, division, geo } = this.state;
        return (<React.Fragment>
            <Tooltip title='Edit'>
                <div>
                    <IconButton color='inherit' size='small' onClick={this.onClick} aria-label='Edit' disabled={isLoading}>
                        <Icon>edit</Icon>
                    </IconButton>
                </div>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='edit-info-title'
                aria-describedby='edit-info-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            Edit Observation {observation.number}
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <form className='fdc flx1 aifs ais' onSubmit={this.onSubmit}>
                    <DialogContent className='fdc flx1 hfill'>
                        <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                            <FormControl fullWidth>
                                    <KeyboardDatePicker
                                        value={date}
                                        maxDate={this.maxDate}
                                        onChange={this.onDateChange}
                                        format={'YYYY-MM-DD'} label='Observation Date'
                                        id='ObservationDate'
                                        className={'TextField'}
                                        required
                                        margin={'normal'}
                                        error={!!!date}
                                        fullWidth
                                    />
                                    {
                                        !date &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                        </div>
                        <div className={'flx1 aic p5 mb10 pr20'}>
                            <DivisionDropdown fullWidth required onChange={this.onDivisionChange} value={division} />
                        </div>
                        <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                            <FormControl fullWidth>
                                <InputLabel required error={!description} htmlFor='description-input'>Description</InputLabel>
                                <Input
                                    id='description-input'
                                    value={description}
                                    onChange={this.onDescriptionChange}
                                    aria-describedby='description-input-helper-text'
                                    required
                                    multiline
                                    error={!description}
                                />
                                {
                                    !description &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <div className='fdr'>
                            <div className={'flx1 ais mb10 pr20'}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete='off'
                                        id='lat'
                                        label='Latitude'
                                        value={geo?.latitude ?? null}
                                        onChange={this.onLatitudeChange}
                                        margin='normal'
                                        className={'TextField'}
                                        required
                                        error={!geo?.latitude && !!geo?.longitude}
                                        type='number'
                                    />
                                    {
                                        !geo?.latitude && !!geo?.longitude &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                            <div className={'flx1 ais mb10'}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete='off'
                                        id='long'
                                        label='Longitude'
                                        value={geo?.longitude}
                                        onChange={this.onLongitudeChange}
                                        margin='normal'
                                        className={'TextField'}
                                        required
                                        error={!!geo?.latitude && !geo?.longitude}
                                        type='number'
                                    />
                                    {
                                        !!geo?.latitude && !geo?.longitude &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                        </div>
                        <div className='h250'>
                            <LocationInput geo={geo} onChange={this.onLocationChange} />
                        </div>
                        <div className='fdr aifs mb10'>
                            <div className={'flx1'}>
                                <MultiFileDropZone accept='audio/*,video/*,image/*,application/pdf' files={files} disabled={files.length >= this.maxFiles}
                                onFileSelected={this.onFileSelected} onFileRemoved={this.onFileRemove} />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>);
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.observation.isLoading,
    };
};

export default connect(
    mapStateToProps,
)(ObservationEditDialog);
