import Avatar from '@material-ui/core/Avatar';
import React from 'react';

interface IAvatarIconProps {
    iconClass : string;
    iconSize? : string | number;
    className? : string;
    iconClassName? : string;
    style? : React.CSSProperties;
}
class AvatarIcon extends React.Component<IAvatarIconProps> {
    public render = () => {
        return (
            <Avatar className={this.props.className} style={this.props.style}>
                <span className={`${this.props.iconClass} ${this.props.iconClassName}`} style={{
                    fontSize: this.props.iconSize,
                }} />
            </Avatar>
        );
    }
}

export default AvatarIcon;
