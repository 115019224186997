import React from 'react';
import { IRootState, RootAction } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MaterialTable from '../custom/materialTable/Table';
import DataFunctions from '../../../store/data/functions';
import EditIncidentGroup from './dialog/Edit';
import { Transitions } from '../custom/animations/Transitions';
import moment from 'moment';
import PublishIcon from '@material-ui/icons/Publish';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { IIncidentGroup } from '../../../@types/model/masterData/incidentGroup';

interface IIncidentGroupProps {
    isLoading : boolean;
    incidentGroups : Array<IIncidentGroup>;
}

interface IIncidentGroupState {
}

class IncidentGroupList extends React.Component<IIncidentGroupProps, IIncidentGroupState> {
    constructor(props : IIncidentGroupProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount = () => {
        DataFunctions.getAllIncidentGroups();
    }

    public onRefreshClick = () => {
        DataFunctions.getAllIncidentGroups(true);
    }

    public getGroups = (state : IIncidentGroupState, props : IIncidentGroupProps) => props.incidentGroups;

    public getInitialCode = createSelector([
        this.getGroups,
    ],
        (groups) => {
            const num = lodash
            .chain(groups)
            .map(group => group.code)
            .map(code => parseInt(code?.substring(2)) ?? 0)
            .max()
            .value() + 1;

            return `IG${(!num ? 1 : num).toString().padStart(4, '0')}`;
        },
    );

    public render = () => {
        const {  } = this.state;
        const { isLoading, incidentGroups } = this.props;

        const initialCode = this.getInitialCode(this.state, this.props);

        return (
            <div className={'flx1 fdc p5'}>
                <Card className={'flx1 fdc'}>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            Incident Group
                        </Typography>
                        <IconButton color='primary' onClick={this.onRefreshClick}>
                            <Tooltip title='Refresh'>
                                <Icon>
                                    refresh
                                </Icon>
                            </Tooltip>
                        </IconButton>
                        <span className={'flx1'} />
                        <EditIncidentGroup initialCode={initialCode} isNew transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading} />
                    </Toolbar>
                    <MaterialTable<IIncidentGroup>
                        id={'incidentGroupsTable'}
                        data={incidentGroups}
                        isLoading={isLoading}
                        rowsPerPage={50}
                        columns={[
                            {
                                header: '',
                                width: '60px',
                                renderCell: row => (
                                    <div className='aic'>
                                        <EditIncidentGroup incidentGroup={row} transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading} />
                                    </div>
                                ),
                            },
                            {
                                header: 'Code',
                                field: 'code',
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Name',
                                field: 'name',
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Active',
                                field: 'isActive',
                                enableSort: true,
                                renderCell: row => (<Icon className={`${row.isActive ? 'cp' : 'cpr'}`}>{row.isActive ? 'check' : 'close' }</Icon>),
                            },
                            {
                                header: 'Created By',
                                field: 'createdByName',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                    <Tooltip title={moment.utc(row.createdOn).local().format('YYYY-MM-DD HH:mm')}>
                                        <span>{row.createdByName.toTitleCase()}</span>
                                    </Tooltip>);
                                },
                            },
                            {
                                header: 'Updated By',
                                field: 'createdByName',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                    <Tooltip title={moment.utc(row.updatedOn).local().format('YYYY-MM-DD HH:mm')}>
                                        <span>{row.updatedByName.toTitleCase()}</span>
                                    </Tooltip>);
                                },
                            },
                        ]}
                    />
                </Card>
        </div>);
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        incidentGroups: state.data.incidentGroups,
        isLoading: state.data.isLoadingIncidentGroups,
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => {
    return bindActionCreators({}, dispatch);
};

// tslint:disable-next-line: variable-name
const IncidentGroupListComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(IncidentGroupList);

export default IncidentGroupListComponent;
