import { ILatLng } from './model/base';

export interface IWebNotification {
    key : number;
    from : number;
    notification : INotification;
    priority : 'very-low' | 'low' | 'normal' | 'high';
}

export interface INotification extends Notification {
    type : number | NotificationType;
    data : IMapNotification;
}

export interface IMapNotification {
    geo : ILatLng;
    type : number | NotificationType;
    reference : string;
    priority : number | NotificationPriority;
    iconURL : string;
}

export enum NotificationType {
    OBS = 1,
    INS = 2,
}

export enum NotificationPriority {
    Alpha = 0,
    Beta = 1,
    Gamma = 2,
    Omega = 3,
}
