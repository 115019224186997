import { LoadScript } from '@react-google-maps/api';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DispatchCall, IRootState, RootAction } from '../../@types/redux';
import GeneralActions from '../../store/general/actions';
import GeneralFunctions from '../../store/general/functions';

interface IScriptLoaderProps {
    setIsLoadingMap : DispatchCall<boolean>;
}

interface IScriptLoaderState { }

class ScriptLoader extends React.PureComponent<IScriptLoaderProps, IScriptLoaderState> {
    constructor(props : IScriptLoaderProps) {
        super(props);
        this.state = {};
    }

    private readonly onScriptLoaded = () => {
        this.props.setIsLoadingMap(false);
    }

    private readonly onUnmount = () => {
        this.props.setIsLoadingMap(true);
    }

    private readonly onError = (error : Error) => {
        console.info(error);
        GeneralFunctions.showErrorSnackbar('Error loading Google Scripts');
    }

    public render = () => {
        return (
            <LoadScript id='maps-script-loader'
                googleMapsApiKey={MAPS_KEY}
                onLoad={this.onScriptLoaded}
                onError={this.onError}
                loadingElement={<div />}
                onUnmount={this.onUnmount}>
            </LoadScript>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => bindActionCreators({
    setIsLoadingMap: GeneralActions.setIsLoadingMap,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ScriptLoader);
