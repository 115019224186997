import axios, { AxiosPromise, CancelTokenStatic, Canceler } from 'axios';
import { IAccessPointEvent, IAccessPointEventList, EnumEntryType } from '../../@types/model/access';

export class AccessPointEventHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;

    /**
     * Allows you to cancel current latest inbound request
     */
    public static cancelIn? : Canceler;

    /**
     * Allows you to cancel current latest outbound request
     */
    public static cancelOut? : Canceler;

    /**
     * Allows you to cancel current Person request
     */
    public static cancelPerson? : Canceler;

    public static getEntry(id? : number, guid? : string) : AxiosPromise<IAccessPointEvent> {
        return axios.get(`${API_URL}/api/AcessPointEvent/Get`, {
            params: {
                id,
                guid,
            },
        });
    }

    public static getAllVehicleEntries(startDateUnixMilli : number, endDateUnixMilli : number, isInbound? : boolean, limit? : number) : AxiosPromise<Array<IAccessPointEventList>> {
        return axios.get(`${API_URL}/api/AcessPointEvent/GetAll`, {
            params: {
                startDateUnixMilli,
                endDateUnixMilli,
                isInbound,
                limit,
            },
        });
    }

    public static getAllPeopleEntries(startDateUnixMilli : number, endDateUnixMilli : number, isInbound? : boolean, limit? : number) : AxiosPromise<Array<IAccessPointEventList>> {
        if (AccessPointEventHttpService.cancelPerson) {
            AccessPointEventHttpService.cancelPerson('Cancel');
        }
        return axios.get(`${API_URL}/api/AcessPointEvent/GetAll`, {
            cancelToken: new AccessPointEventHttpService.CancelToken((c) => {
                AccessPointEventHttpService.cancelPerson = c;
            }),
            params: {
                startDateUnixMilli,
                endDateUnixMilli,
                isInbound,
                limit,
                type: EnumEntryType.Person,
            },
        });
    }

    public static getVehicleLatest(isInbound : boolean, accessPointId : number, limit : number) : AxiosPromise<Array<IAccessPointEventList>> {
        if (isInbound) {
            if (AccessPointEventHttpService.cancelIn) {
                AccessPointEventHttpService.cancelIn('Cancel');
            }
        } else {
            if (AccessPointEventHttpService.cancelOut) {
                AccessPointEventHttpService.cancelOut('Cancel');
            }
        }

        return axios.get(`${API_URL}/api/AcessPointEvent/GetAllLatest`, {
            cancelToken: new AccessPointEventHttpService.CancelToken((c) => {
                if (isInbound) {
                    AccessPointEventHttpService.cancelIn = c;
                } else {
                    AccessPointEventHttpService.cancelOut = c;
                }
            }),
            params: {
                limit,
                accessPointId,
                isInbound,
            },
        });
    }
}
