import { IWebNotification } from '../../../../@types/notification';
import React from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import Link from '@material-ui/core/Link';

interface INotificationMarkerProps {
    webNotification : IWebNotification;
    onInfoClick? : (webNotification : IWebNotification) => void;
}

interface INotificationMarkerState {
    showInfo : boolean;
}

class NotificationMarker extends React.Component<INotificationMarkerProps, INotificationMarkerState> {
    constructor(props : INotificationMarkerProps) {
        super(props);

        this.state = {
            showInfo : false,
        };
    }

    public toggleInfo = () => {
        this.setState({
            showInfo: !this.state.showInfo,
        });
    }

    public onInfoClick = () => {
        if (this.props.onInfoClick) {
            this.props.onInfoClick(this.props.webNotification);
        }
    }

    public render = () => {
        const { webNotification } = this.props;
        const { showInfo } = this.state;

        return (
            <Marker
                title={webNotification.notification.title}
                clickable
                position={{
                    lat: webNotification.notification.data.geo.latitude,
                    lng: webNotification.notification.data.geo.longitude,
                }}
                icon={ webNotification.notification.data.iconURL ? {
                    url: webNotification.notification.data.iconURL,
                    scaledSize: new google.maps.Size(22, 22),
                    anchor: new google.maps.Point(11, 5),
                } : undefined}
                onClick={this.toggleInfo}>
                {
                    showInfo &&
                    <InfoWindow
                        position={{
                            lat: webNotification.notification.data.geo.latitude,
                            lng: webNotification.notification.data.geo.longitude,
                        }}
                        onCloseClick={this.toggleInfo}>
                        <div style={{
                            background: `white`,
                            padding: 15,
                        }}>
                            <Link component='button'
                                variant='h6'
                                color='inherit'
                                onClick={this.onInfoClick}>
                                {webNotification.notification.body}
                            </Link>
                        </div>
                    </InfoWindow>
                }
            </Marker>
        );
    }
}

export default NotificationMarker;
