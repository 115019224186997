import axios from 'axios';
import { IFileQueue } from '../../@types/viewmodel/file';

export default class FileHttpService {
    public static uploadFile(queueFile : IFileQueue, onUploadProgress : (progress : number) => void) {
        const data = new FormData();
        data.append('file', queueFile.file);

        return axios.post<void>(`${API_URL}/api/Files/Upload`, data, {
            params : {
                reference: queueFile.reference,
                referenceType: queueFile.referenceType,
                type: queueFile.type,
                category: queueFile.category,
                duration: queueFile.duration,
            },
            onUploadProgress: (progressEvent : any) => {
                onUploadProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100));
            },
        });
    }
}
