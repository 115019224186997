import axios, { AxiosPromise } from 'axios';
import { IIncident, IVirusIncident } from '../../../@types/model/incident';

export default class VirusIncidentHttpService {
    public static save(incident : IIncident) {
        return axios.patch<IIncident>(`${API_URL}/api/Incidents/Incident/Save`, incident, {
            params: {},
        });
    }
    public static getAll(startDateUnixMilli : number, endDateUnixMilli : number) : AxiosPromise<Array<IVirusIncident>> {
        return axios.get(`${API_URL}/api/Incidents/VirusIncident/GetAll`, {
            params: {
                startDateUnixMilli,
                endDateUnixMilli,
            },
        });
    }
}
