import { IAccessPoint } from '../../../@types/model/access';
import axios, { Canceler, CancelTokenStatic } from 'axios';
import { IFileUpload } from '../../../@types/model/base';

export default class AccessPointHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel current Person request
     */
    public static cancelGet? : Canceler;

    public static get(id? : number) {
        if (AccessPointHttpService.cancelGet) {
            AccessPointHttpService.cancelGet();
        }

        return axios.get<IAccessPoint>(`${API_URL}/api/MasterData/AccessPoint/Get`, {
            cancelToken: new AccessPointHttpService.CancelToken((c) => {
                AccessPointHttpService.cancelGet = c;
            }),
            params : {
                id,
            },
        });
    }
    public static getAll(isActive? : boolean) {
        return axios.get<Array<IAccessPoint>>(`${API_URL}/api/MasterData/AccessPoint/GetAll`, {
            params : {
                isActive,
            },
        });
    }
    public static save(accessPoint : IAccessPoint, fileUpload? : IFileUpload) {
        return axios.post<IAccessPoint>(`${API_URL}/api/MasterData/AccessPoint/Save`, {
            accessPoint,
            fileUpload,
        }, {
            params : {
            },
        });
    }
}
