import { AccessPointEventAction } from '../../@types/redux';
import actions from './actions';
import { getType } from 'typesafe-actions';
import { IAccessPointEventList, IAccessPointEvent } from '../../@types/model/access';

export interface IAccessPointEventState {
    vehicleEntries : Array<IAccessPointEventList>;
    selectedGuid? : string;
    selectedEntry? : IAccessPointEvent;
    isLoadingEntries : boolean;
    isLoadingEntry : boolean;
    personEntries : Array<IAccessPointEventList>;
    isLoadingPersonEntries : boolean;
}

const initialState = {
    vehicleEntries: [],
    isLoadingEntries: false,
    isLoadingEntry: false,
    personEntries: [],
    isLoadingPersonEntries: false,
};

export default function dataReducer(state : IAccessPointEventState = initialState, action : AccessPointEventAction) : IAccessPointEventState {
    switch (action.type) {
        case getType(actions.setVehicleEntries):
            return { ...state, vehicleEntries: action.payload };
        case getType(actions.setEntry):
            return { ...state, selectedEntry: action.payload };
        case getType(actions.setIsLoadingEntries):
            return { ...state, isLoadingEntries: action.payload };
        case getType(actions.setGuid):
            return { ...state, selectedGuid: action.payload };
        case getType(actions.setIsLoadingEntry):
            return { ...state, isLoadingEntry: action.payload };
        case getType(actions.setPersonEntries):
            return { ...state, personEntries: action.payload };
        case getType(actions.setIsLoadingPersonEntries):
            return { ...state, isLoadingPersonEntries: action.payload };
        default:
            return state;
    }
}
