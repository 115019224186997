import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IGraveSite } from '../../../@types/model/graveSite/graveSite';
import { IRootState } from '../../../@types/redux';
import Card from '@material-ui/core/Card';
import MaterialTable from '../custom/materialTable/Table';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { createSelector } from 'reselect';
import { Icon, IconButton } from '@material-ui/core';
import { Transitions } from '../custom/animations/Transitions';
import GraveSiteCreateDialog from './dialog/GraveSiteCreateDialog';
import GraveSiteFunctions from '../../../store/graveSites/functions';
import GraveSiteInfoDialog from './dialog/InfoDialog';
import LongDescriptionColumn from '../custom/materialTable/LongDescriptionColumn';
import { RIGHTS } from '../../../appConstants';

interface IGraveSiteListProps extends RouteComponentProps<{
    guid : string;
}, {}, {}> {
    graveSites : Array<IGraveSite>;
    isLoading : boolean;
    isGraveSiteAdmin : boolean;
}

interface IGraveSiteListState {

}

class GraveSiteList extends React.Component<IGraveSiteListProps, IGraveSiteListState> {
    constructor(props : IGraveSiteListProps) {
        super(props);

        this.state = {

        };
    }

    public readonly componentDidMount = () => {
        this.loadData();

    }

    private readonly loadData = async () => {
        await GraveSiteFunctions.getAll(true);
    }

    private readonly getData = (state : IGraveSiteListState, props : IGraveSiteListProps) => props.graveSites;
    private readonly getGraveSites = createSelector(
        [this.getData],
        (graveSite) => {
            return graveSite;
        },
    );

    public readonly render = () => {
        const graveSites = this.getGraveSites(this.state, this.props);
        const { isLoading, isGraveSiteAdmin } = this.props;

        return (
            <div className='flx1 fdc p15 bcg1'>
                <Card className='flx1 fdc mb15'>
                    <MaterialTable<IGraveSite>
                        id={'graveSiteTable'}
                        data={graveSites}
                        isLoading={isLoading}
                        rowsPerPage={20}
                        columns={[
                            {
                                header: '',
                                field: '',
                                width: 100,
                                renderCell: (row) => {
                                    return (<>
                                        <GraveSiteInfoDialog
                                            graveSite={row}
                                            key={row.id}
                                            isGraveSiteAdmin={isGraveSiteAdmin}
                                            isLoading={isLoading}
                                        />
                                        <IconButton>
                                            <Icon className={'cp'}>
                                                map
                                            </Icon>
                                        </IconButton>
                                    </>);
                                },
                            },
                            {
                                header: 'Site Nr',
                                field: 'number',
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Region',
                                field: 'division.regionName',
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Division',
                                field: 'division.description',
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Description',
                                field: 'description',
                                enableSort: true,
                                enableFilter: true,
                                width: 400,
                                renderCell: row => <LongDescriptionColumn text={row.description} />,
                            },
                            {
                                header: 'Number of Graves',
                                field: 'numberOfGraves',
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => row.graves.filter(x => x.isActive).length ?? 0,
                            },
                            {
                                header: 'Representative',
                                field: 'representative',
                                enableFilter: true,
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                        <Tooltip title={row.representative}>
                                            <span>{row.representativeName.toTitleCase()}</span>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                header: 'Identified Site',
                                field: 'identifiedSite',
                                enableFilter: true,
                                enableSort: true,
                                align: 'center',
                                renderCell: (row) => {
                                    return (
                                        <div className={'jcc aic pr40'}>
                                        {
                                            !!row.identified &&
                                            <Icon className={'cp'}>
                                                check_circle
                                            </Icon>
                                        }
                                        {
                                            !row.identified &&
                                            <Icon className={'cpr'}>
                                                cancel_outlined
                                            </Icon>
                                        }
                                        </div>);
                                },
                            },

                        ]}
                    />
                </Card>
                <div className='fdr jcfe'>
                    <GraveSiteCreateDialog disabled={isLoading} isLoading={isLoading} fullWidth maxWidth='md' transition={Transitions.Up} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        graveSites : state.graveSites.graveSites,
        isLoading: state.graveSites.isLoading,
        isGraveSiteAdmin: !!state.auth.session?.user.userRights.some(x => x.rightId === RIGHTS.GRAVESITE),
    };
};

export default connect(
    mapStateToProps,
)(withRouter(GraveSiteList));
