import React from 'react';
import { IMappedRight } from '../../../../../@types/model/right';

interface IRightsParentProps {
    selected : boolean;
    right : IMappedRight;
    onClicked : (right : IMappedRight, checked : boolean) => void;
}

class RightParent extends React.Component<IRightsParentProps> {
    public render() {
        const { right, selected, onClicked } = this.props;
        return (
            <div className={'flx1 aic jcfe right-parent'}>
                <div onClick={ev => onClicked(right, !!!selected)}>
                    <span className={`${!!!selected ? '' : 'selected'}`}>{right.name}</span>
                </div>
            </div>
        );
    }
}

export default RightParent;
