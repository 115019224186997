import React from 'react';

interface ITabViewsProps {
    index : number;
    children : Array<JSX.Element>;
}

interface ITabViewsState {}

export default class TabViews extends React.PureComponent<ITabViewsProps, ITabViewsState> {
    constructor(props : ITabViewsProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { index, children } = this.props;

        return children[index] ?? (<div></div>);
    }
}
