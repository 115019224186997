import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import { FormControl, TextField } from '@material-ui/core';

interface IIncidentDocketFileRenameDialogProps {
    open : boolean;
    value : string;
    onClose : (result ?: string) => void;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    isLoading ?: boolean;
}

interface IIncidentDocketFileRenameDialogState {
    value : string;
}

export default class IncidentDocketFileRenameDialog extends React.Component<IIncidentDocketFileRenameDialogProps, IIncidentDocketFileRenameDialogState> {
    constructor(props : IIncidentDocketFileRenameDialogProps) {
        super(props);
        this.state = {
            value: '',
        };
    }

    public componentDidUpdate(prevProps : Readonly<IIncidentDocketFileRenameDialogProps>): void {
        if (!prevProps.open && this.props.open) {
            this.setState({
                value: this.props.value.substring(0, this.props.value.lastIndexOf('.')),
            });
        }
    }

    private readonly onClose = () => {
        if (this.props.isLoading) return;
        this.props.onClose();
    };

    private readonly onSaveClick = () => {
        this.props.onClose(this.state.value + this.props.value.substring(this.props.value.lastIndexOf('.')));
    };

    private readonly onCloseClick = () => {
        this.props.onClose();
    };

    private readonly onChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            value: event.currentTarget.value,
        });
    };

    public readonly render = () => {
        const {
            open,
            transition,
            fullWidth,
            maxWidth,
            fullScreen,
            isLoading,
        } = this.props;
        const {
            value,
        } = this.state;

        return (
            <Dialog
                open={open}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                aria-labelledby='file rename-dialog-title'
                aria-describedby='file rename-dialog-description'
            >
                <AppBar className='posr p10' position='static'>
                    <Typography variant='h5' color='inherit' className='flx1'>
                        Rename {this.props.value.substring(0, this.props.value.lastIndexOf('.'))}
                    </Typography>
                </AppBar>
                <DialogContent>
                    <FormControl fullWidth>
                        <TextField
                            autoComplete='off'
                            label='File Name'
                            value={value}
                            onChange={this.onChange}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={this.onSaveClick} type='button' variant='contained' color='primary'>
                        SAVE
                    </Button>
                    <Button disabled={isLoading} variant='outlined' onClick={this.onCloseClick} color='primary' autoFocus>
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
}
