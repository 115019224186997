import { IAccessPoint } from '../../../@types/model/access';
import React from 'react';
import { IRootState, RootAction } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MaterialTable from '../custom/materialTable/Table';
import dataFunctions from '../../../store/data/functions';
import { ACCESS_POINT_TYPE_STRING } from '../../../appConstants';
import EditAccessPoint from './dialog/Edit';
import { Transitions } from '../custom/animations/Transitions';
import PrintAccessPointQR from './dialog/Print';
import Fab from '@material-ui/core/Fab';
import { createSelector } from 'reselect';
import DateHelperService from '../../../service/helper/dateHelperService';
import AccessPointFilterSelector from '../custom/selector/AccessPointFilter';

interface IAccessPointProps {
    isLoading : boolean;
    accessPoints : Array<IAccessPoint>;
}

interface IAccessPointState {
    selectedAccessPoints : Array<IAccessPoint>;
    showAll : boolean;

    code : string;
    name : string;
    division : string;
    category : Array<number>;
}

class AccessPointListComponent extends React.PureComponent<IAccessPointProps, IAccessPointState> {
    constructor(props : IAccessPointProps) {
        super(props);
        this.state = {
            selectedAccessPoints: [],
            showAll: false,
            code: '',
            name: '',
            division: '',
            category: [],
        };
    }

    public componentDidMount = () => {
        dataFunctions.getAllAccessPoints();
    }

    public onRefreshClick = () => {
        dataFunctions.getAllAccessPoints(true);
    }

    private onSelectionChanged = (selectedRows : Array<IAccessPoint>) => {
        this.setState({
            selectedAccessPoints: selectedRows,
        });
    }

    private getAccessPoints = (props : IAccessPointProps, state : IAccessPointState) => props.accessPoints;
    private getShowAll = (props : IAccessPointProps, state : IAccessPointState) => state.showAll;
    private getCode = (props : IAccessPointProps, state : IAccessPointState) => state.code;
    private getName = (props : IAccessPointProps, state : IAccessPointState) => state.name;
    private getDivision = (props : IAccessPointProps, state : IAccessPointState) => state.division;
    private getCategory = (props : IAccessPointProps, state : IAccessPointState) => state.category;

    public getFilteredData = createSelector(
        [
            this.getAccessPoints,
            this.getShowAll,
            this.getCode,
            this.getName,
            this.getDivision,
            this.getCategory,
        ],
        (
            accessPoints,
            showAll,
            code,
            name,
            division,
            category,
        ) => {
            return accessPoints
            .filter(n => !code || n.code.toLowerCase().includes(code.toLowerCase()))
            .filter(n => !name || n.name.toLowerCase().includes(name.toLowerCase()))
            .filter(n => !division || n.division?.code.toLowerCase().includes(division.toLowerCase()) || n.division?.description.toLowerCase().includes(division.toLowerCase()))
            .filter(n => !category.length || n.accessPointCategories?.some(x => category.includes(x.accessPointCategoryId)))
            .filter(n => showAll || n.isActive);
        },
    );

    private onShowOnlyActiveClick = () => {
        this.setState({
            showAll: !this.state.showAll,
        });
    }

    public onFilterChange = (code : string, name : string, division : string, category : Array<number>) => {
        this.setState({
            category,
            code,
            division,
            name,
        });
    }

    public render = () => {
        const {
            selectedAccessPoints,
            showAll,
            category,
            code,
            division,
            name,
        } = this.state;
        const { isLoading } = this.props;

        const accessPoints = this.getFilteredData(this.props, this.state);

        return (
            <div className={'flx1 fdc p5'}>
                <Card className={'flx1 fdc'}>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            Access Points
                        </Typography>
                        <IconButton color='primary' onClick={this.onRefreshClick}>
                            <Tooltip title='Refresh'>
                                <Icon>
                                    refresh
                                </Icon>
                            </Tooltip>
                        </IconButton>
                        <span className={'flx2'} />
                        <div className='flx1 aic p5 mb10'>
                           <AccessPointFilterSelector
                                code={code}
                                name={name}
                                division={division}
                                category={category}
                                fullWidth
                                onChange={this.onFilterChange}
                           />
                        </div>
                        <EditAccessPoint transition={Transitions.Up} maxWidth='md' fullWidth fullScreen isLoading={isLoading} />
                        {
                            !!selectedAccessPoints.length &&
                            <PrintAccessPointQR accessPoints={selectedAccessPoints} fullScreen transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading} />
                        }
                        <Tooltip title='Show only Active'>
                            <Fab color={!showAll ? 'primary' : 'default'} size='small' onClick={this.onShowOnlyActiveClick}>
                                <Icon>
                                    <img src={`${ASSET_BASE}/assets/images/icons/active.svg`} />
                                </Icon>
                            </Fab>
                        </Tooltip>
                    </Toolbar>
                    <MaterialTable<IAccessPoint>
                        id={'accessPointTable'}
                        data={accessPoints}
                        isLoading={isLoading}
                        rowsPerPage={100}
                        selectable
                        selectedRows={selectedAccessPoints}
                        onSelectionChanged={this.onSelectionChanged}
                        columns={[
                            {
                                header: '',
                                width: '60px',
                                renderCell: row => (
                                    <div className='aic'>
                                        <EditAccessPoint accessPoint={row} fullWidth transition={Transitions.Up} maxWidth='md' fullScreen isLoading={isLoading} />
                                    </div>
                                ),
                            },
                            {
                                header: 'Code',
                                field: 'code',
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Name',
                                field: 'name',
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Division',
                                field: 'division.description',
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (<span>{row.division?.description ?? ''}</span>),
                            },
                            {
                                header: 'Categories',
                                field: 'accessPointCategories',
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <span>
                                    {
                                        row.accessPointCategories?.filter(x => x.isActive)
                                            .map(x => x.accessPointCategory.description)
                                            .join(', ') ?? ''
                                    }
                                    </span>),
                            },
                            {
                                header: 'Power Sources',
                                field: 'accessPointPowerSources',
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <span>
                                    {
                                        row.accessPointPowerSources?.filter(x => x.isActive)
                                            .map(x => x.powerSource?.name)
                                            .join(', ') ?? ''
                                    }
                                    </span>),
                            },
                            {
                                header: 'Priority',
                                field: 'priorityDescription',
                                enableSort: true,
                            },
                            {
                                header: 'Type',
                                field: 'accessPointType',
                                enableSort: true,
                                renderCell: row => (<span>{ACCESS_POINT_TYPE_STRING[row.accessPointType]}</span>),
                            },
                            {
                                header: 'Guarded',
                                field: 'isControlled',
                                enableSort: true,
                                renderCell: row => (<Icon className={`${row.isControlled ? 'cp' : 'cpr'}`}>{row.isControlled ? 'check' : 'close' }</Icon>),
                            },
                            {
                                header: 'IoT',
                                field: 'controlIdentifier',
                                enableSort: true,
                                renderCell: row => (
                                    <Tooltip title={row.controlIdentifier ?? 'No Controller'}>
                                        <Icon className={`${row.controlIdentifier ? 'cp' : 'cpr'}`}>{row.controlIdentifier ? 'check' : 'close' }</Icon>
                                    </Tooltip>
                                ),
                            },
                            {
                                header: 'Active?',
                                field: 'isActive',
                                enableSort: true,
                                renderCell: row => (<Icon className={`${row.isActive ? 'cp' : 'cpr'}`}>{row.isActive ? 'check' : 'close' }</Icon>),
                            },
                            {
                                header: 'Created By',
                                field: 'createdByName',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                        <Tooltip title={DateHelperService.format(row.createdOn, 'YYYY-MM-DD HH:mm')}>
                                        <span>{row.createdByName.toTitleCase()}</span>
                                    </Tooltip>);
                                },
                            },
                            {
                                header: 'Updated By',
                                field: 'createdByName',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                    <Tooltip title={DateHelperService.format(row.updatedOn, 'YYYY-MM-DD HH:mm')}>
                                        <span>{row.updatedByName.toTitleCase()}</span>
                                    </Tooltip>);
                                },
                            },
                        ]}
                    />
                </Card>
        </div>);
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        accessPoints: state.data.accessPoints,
        isLoading: state.data.isLoading,
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => {
    return bindActionCreators({}, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccessPointListComponent);
