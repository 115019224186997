import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import lodash from 'lodash';
import { connect } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import { IRootState } from '../../../../../@types/redux';
import { IGraveVisitReason } from '../../../../../@types/model/masterData/graveVisitReason';
import MasterDataFunctions from '../../../../../store/masterData/functions';

interface IGraveVisitReasonAutocompleteProps {
    id? : string;
    value : number | null;
    label? : string;

    onChange? : (value? : IGraveVisitReason) => void;

    required? : boolean;
    clearable? : boolean;
    autoFocus? : boolean;

    fullWidth? : boolean;
    disabled? : boolean;

    graveVisitReasons : Array<IGraveVisitReason>;
    isLoading : boolean;
}

interface IGraveVisitReasonAutocompleteState {
}

class GraveVisitReasonAutocompleteComponent extends React.Component<IGraveVisitReasonAutocompleteProps, IGraveVisitReasonAutocompleteState> {
    constructor(props : IGraveVisitReasonAutocompleteProps) {
        super(props);

        this.state = {
            graves: [],
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        await MasterDataFunctions.getAllGraveVisitReasons();
    }

    private onChange = (event : React.ChangeEvent<HTMLSelectElement>, value : {
        label : string,
        value : number,
    }) => {
        if (!this.props.onChange) return;

        // We select the last one as it is the newest.
        this.props.onChange(this.props.graveVisitReasons.find(n => n.id === value.value));
    }

    private getData = (state : IGraveVisitReasonAutocompleteState, props : IGraveVisitReasonAutocompleteProps) => props.graveVisitReasons;
    private getValue = (state : IGraveVisitReasonAutocompleteState, props : IGraveVisitReasonAutocompleteProps) => props.value;
    private getRequired = (state : IGraveVisitReasonAutocompleteState, props : IGraveVisitReasonAutocompleteProps) => props.required;

    private getActiveGraveVisitReason = createSelector([
        this.getData,
    ], graveVisitReasons => graveVisitReasons.filter(x => x.isActive));

    private getDropdown = createSelector([
        this.getActiveGraveVisitReason,
        this.getRequired,
    ], (graveVisitReasons, required) => {
        const result = lodash.map(graveVisitReasons, n => ({
            label: `${n.description}`,
            value: n.id,
        }));

        if (!required) {
            result.unshift({
                label: 'ALL',
                value: 0,
            });
        }

        return result;
    });

    private getSelectedValue = createSelector([
        this.getValue,
        this.getDropdown,
        this.getRequired,
    ], (value, dropdown, required) => {
        if (typeof value === 'number') {
            return dropdown.find(x => x.value === value) ?? null;
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: 0,
            };
        }

        return null;
    });

    public render = () => {
        const { required,  fullWidth, disabled, id, clearable, label, isLoading } = this.props;

        const dropdown = this.getDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                <Autocomplete
                    openOnFocus
                    disabled={disabled || isLoading}
                    id={id}
                    options={dropdown}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required && !clearable}
                    renderInput={
                        params => <TextField error={required && !value} {...params}
                            required={required}
                            fullWidth={fullWidth}
                            label={label ?? 'Reason For Visit'}
                        />
                    }
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
                {
                    isLoading &&
                    <LinearProgress className='wfill posb0 posa' />
                }
            </FormControl>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        graveVisitReasons : state.masterData.graveVisitReasons,
        isLoading: state.masterData.isLoadingGraveVisitReason,
    };
};

// tslint:disable-next-line: variable-name
const GraveVisitReasonAutocomplete = connect(
    mapStateToProps,
)(GraveVisitReasonAutocompleteComponent);

export default GraveVisitReasonAutocomplete;
