import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { IGrave } from '../../../../@types/model/graveSite/grave';
import { IGraveSite, IGraveSiteUpsert } from '../../../../@types/model/graveSite/graveSite';
import GraveSiteFunctions from '../../../../store/graveSites/functions';

interface IGraveDeleteDialogProps {
    graveSite : IGraveSite;
    grave : IGrave | null;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    onClose ?: () => void;
}

interface IGraveDeleteDialogState {
    isLoading : boolean;
}

export default class GraveDeleteDialog extends React.Component<IGraveDeleteDialogProps, IGraveDeleteDialogState> {
    constructor(props : IGraveDeleteDialogProps) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    private delete = async () => {
        const { graveSite, grave } = this.props;

        if (!grave) return;

        this.setState({
            isLoading: true,
        });

        const upsert = {
            ...graveSite,
            graves: [
                ...graveSite.graves,
            ],
        } as IGraveSiteUpsert;

        const index = graveSite.graves.findIndex(x => x.id === grave.id);
        upsert.graves[index] = {
            ...grave,
            isActive: false,
        };

        const result = await GraveSiteFunctions.save(upsert, []);

        if (result != null && this.props.onClose) {
            this.props.onClose();
        }

        this.setState({
            isLoading: false,
        });
    }

    private onClose = () => {
        if (this.state.isLoading) return;
        
    }

    private onOkClick = () => {
        this.delete();
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, grave } = this.props;
        const { isLoading } = this.state;
        return (
            <Dialog
                open={!!grave}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='grave-delete-title'
                aria-describedby='grave-delete-title-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            Delete Grave?
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='fdc flx1 hfill'>
                    <div className='flx1 fdr aifs'>
                        <Typography>
                            Remove Grave {grave?.fileNumber}?
                        </Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={this.onOkClick} type='button' variant='contained' color='primary'>
                        OK
                    </Button>
                    <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
