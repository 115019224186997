import { createAction } from 'typesafe-actions';
import { IAccessPointEventList, IAccessPointEvent } from '../../@types/model/access';

const vehicleActions = {
    setVehicleEntries: createAction('ACCESS_POINT_EVENT_SET_VEHICLE_ENTRIES', resolve => (entries : Array<IAccessPointEventList>) => resolve(entries)),
    setEntry: createAction('ACCESS_POINT_EVENT_SET_ENTRY', resolve => (entry : IAccessPointEvent | undefined) => resolve(entry)),
    setGuid: createAction('ACCESS_POINT_EVENT_SET_SELECTED_GUID', resolve => (entry? : string) => resolve(entry)),
    setIsLoadingEntries: createAction('ACCESS_POINT_EVENT_SET_VEHICLE_ENTRIES_LOADING', resolve => (loading : boolean) => resolve(loading)),
    setIsLoadingEntry: createAction('ACCESS_POINT_EVENT_SET_VEHICLE_ENTRY_LOADING', resolve => (loading : boolean) => resolve(loading)),
    setPersonEntries: createAction('ACCESS_POINT_EVENT_SET_PERSON_ENTRIES', resolve => (entries : Array<IAccessPointEventList>) => resolve(entries)),
    setIsLoadingPersonEntries: createAction('ACCESS_POINT_EVENT_SET_PERSON_ENTRIES_LOADING', resolve => (loading : boolean) => resolve(loading)),
};

export default vehicleActions;
