import React from 'react';
import { IIncident } from '../../../../@types/model/incident';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IncidentInfoEditDialog from '../dialog/edit/IncidentInfoEditDialog';
import LocationInput from '../../custom/input/LocationInput';
import IncidentLocationEditDialog from '../dialog/edit/IncidentLocationEditDialog';

interface IIncidentIncidentViewProps {
    incident : IIncident;
    isEditAdmin : boolean;
    isSaving : boolean;
}

interface IIncidentIncidentViewState {}

export default class IncidentIncidentView extends React.PureComponent<IIncidentIncidentViewProps, IIncidentIncidentViewState> {
    constructor(props : IIncidentIncidentViewProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const {} = this.state;
        const { isEditAdmin, incident, isSaving } = this.props;
        return (
            <div className='flx1 fdr aifs pt20 pb20 bcg1'>
                <span className='flx1' />
                <Paper className='fdc flx2' elevation={3}>
                    <AppBar position='static' className='pbr bcpl' elevation={0}>
                        <Toolbar>
                            <Typography className='fwm fs18'>
                                INCIDENT
                            </Typography>
                            <span className='flx1' />
                            {
                                isEditAdmin &&
                                <div className='posa posr10'>
                                    <IncidentInfoEditDialog maxWidth='sm' fullWidth incident={incident} isLoading={isSaving} />
                                </div>
                            }
                        </Toolbar>
                    </AppBar>
                    <div className='fdc'>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Place
                            </Typography>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Category
                            </Typography>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Injury Type
                            </Typography>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Value
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                {incident.place}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                {incident.incidentCategories.map(n => n.categoryName).join(', ') ?? 'None'}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                {incident.injuryType}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                {incident.value?.toFixed(2) ?? '-'}
                            </Typography>
                        </div>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Short Description
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                <div className='fdc'>
                                    {
                                        /** This is ugly but needed for displaying new lines... */
                                        incident.type.split('\n').map((n, index) => (
                                            <Typography key={`${n}-${index}`} variant='body1' className='flx1' component='p'>
                                            {
                                                n
                                            }
                                            </Typography>
                                        ))
                                    }
                                </div>
                            </Typography>
                        </div>
                        {
                            !!incident.poachingMethods?.length &&
                            <>
                                <div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                        Poaching Methods
                                    </Typography>
                                </div>
                                <div className={'fdr mb10 pl10 pr10'}>
                                    <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {
                                        incident.poachingMethods?.join(', ')
                                    }
                                    </Typography>
                                </div>
                            </>
                        }
                        {
                            !!incident.houseBreakingEntryPoints?.length &&
                            <>
                                <div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                        Housebreaking Entry Points
                                    </Typography>
                                </div>
                                <div className={'fdr mb10 pl10 pr10'}>
                                    <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {
                                        incident.houseBreakingEntryPoints?.join(', ')
                                    }
                                    </Typography>
                                </div>
                            </>
                        }
                        {
                            !!incident.weatherConditions?.length &&
                            <>
                                <div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                        Weather Conditions
                                    </Typography>
                                </div>
                                <div className={'fdr mb10 pl10 pr10'}>
                                    <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {
                                        incident.weatherConditions?.join(', ')
                                    }
                                    </Typography>
                                    <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {
                                        incident.roadCondition
                                    }
                                    </Typography>
                                </div>
                            </>
                        }
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Detailed Description
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                <div className='fdc'>
                                    {
                                        /** This is ugly but needed for displaying new lines... */
                                        incident.comment.split('\n').map((n, index) => (
                                            <Typography key={`${n}-${index}`} variant='body1' className='flx1' component='p'>
                                            {
                                                n
                                            }
                                            </Typography>
                                        ))
                                    }
                                </div>
                            </Typography>
                        </div>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Action Taken
                            </Typography>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Suggestions
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                <div className='fdc'>
                                    {
                                        /** This is ugly but needed for displaying new lines... */
                                        incident.actionTaken?.split('\n').map((n, index) => (
                                            <Typography key={`${n}-${index}`} variant='body1' className='flx1' component='p'>
                                            {
                                                n
                                            }
                                            </Typography>
                                        )) ?? '-'
                                    }
                                </div>
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                <div className='fdc'>
                                    {
                                        /** This is ugly but needed for displaying new lines... */
                                        incident.suggestions?.split('\n').map((n, index) => (
                                            <Typography key={`${n}-${index}`} variant='body1' className='flx1' component='p'>
                                            {
                                                n
                                            }
                                            </Typography>
                                        )) ?? '-'
                                    }
                                </div>
                            </Typography>
                        </div>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Outcome
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                <div className='fdc'>
                                    {
                                        /** This is ugly but needed for displaying new lines... */
                                        incident.outcome?.split('\n').map((n, index) => (
                                            <Typography key={`${n}-${index}`} variant='body1' className='flx1' component='p'>
                                            {
                                                n
                                            }
                                            </Typography>
                                        )) ?? '-'
                                    }
                                </div>
                            </Typography>
                        </div>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                Location
                            </Typography>
                            <span className='flx1' />
                            {
                                isEditAdmin &&
                                <IncidentLocationEditDialog maxWidth='sm' fullWidth incident={incident} isLoading={isSaving} />
                            }
                        </div>
                        <div className={'fdr mb10 pl10 pr10 h300'}>
                            <LocationInput id='edit-dialog-map' geo={incident.geo} />
                        </div>
                    </div>
                </Paper>
                <span className='flx1' />
            </div>
        );
    }
}
