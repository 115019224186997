import { Tabs, withStyles } from '@material-ui/core';

// tslint:disable-next-line: variable-name
const PrimaryTabs = withStyles({
    root: {
    },
    indicator: {
        backgroundColor: '#FEDD31',
    },
})(Tabs);

export default PrimaryTabs;
