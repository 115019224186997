import React from 'react';
import { IIncidentDocketFile } from '../../../../@types/model/incident';
import { Card, CardActionArea, CardActions, CardMedia, IconButton, Menu, MenuItem, Paper, Typography } from '@material-ui/core';
import { Description, MoreVert, PlayArrow, Image, Help, OndemandVideo, TextFields, Delete, Visibility } from '@material-ui/icons';
import moment from 'moment';
import { EnumFileType } from '../../../../@types/model/base';
import FileCarouselDialog from '../../custom/dialog/FileCarouselDialog';
import ConfirmNoButtonDialog from '../../custom/dialog/ConfirmNoButtonDialog';
import { Transitions } from '../../custom/animations/Transitions';
import IncidentDocketFileRenameDialog from '../dialog/docket/FileRename';

interface IIncidentDocketFileViewProps {
    docketFiles : Array<IIncidentDocketFile>;
    disabled ?: boolean;

    onDelete ?: (docketFile : IIncidentDocketFile) => void;
    onRename ?: (docketFile : IIncidentDocketFile) => void;
}

interface IIncidentDocketFileViewState {
    selectedIndex : number;
    menuTarget : HTMLButtonElement | null;

    deleteSelected : IIncidentDocketFile | null;
    renameSelected : IIncidentDocketFile | null;
}

export default class IncidentDocketFileView extends React.PureComponent<IIncidentDocketFileViewProps, IIncidentDocketFileViewState> {
    constructor(props : IIncidentDocketFileViewProps) {
        super(props);
        this.state = {
            selectedIndex: -1,
            menuTarget: null,
            deleteSelected: null,
            renameSelected: null,
        };
    }

    private readonly onFileCarouselClose = () => {
        this.setState({
            selectedIndex: -1,
        });
    }

    private readonly onFileClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const selected = this.props.docketFiles.findIndex(n => n.id === Number(event.currentTarget.value));
        this.setState({
            selectedIndex: selected,
        });
    }

    private readonly onMoreClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            menuTarget: event.currentTarget,
        });
    }

    private readonly onMenuClose = () => {
        this.setState({
            menuTarget: null,
        });
    };

    private readonly onViewClick = () => {
        const selected = this.props.docketFiles.findIndex(x => x.id === Number(this.state.menuTarget?.value));
        this.setState({
            selectedIndex: selected,
            menuTarget: null,
        });
    }

    private readonly onDeleteClick = () => {
        this.setState({
            deleteSelected: this.props.docketFiles.find(x => x.id === Number(this.state.menuTarget?.value)) ?? null,
            menuTarget: null,
        });
    };

    private readonly onDelete = (result : boolean) => {
        if (result && this.state.deleteSelected && this.props.onDelete) {
            this.props.onDelete(this.state.deleteSelected);
        }

        this.setState({
            deleteSelected: null,
        });
    };

    private readonly onRenameClick = () => {
        this.setState({
            renameSelected: this.props.docketFiles.find(x => x.id === Number(this.state.menuTarget?.value)) ?? null,
            menuTarget: null,
        });
    };

    private readonly onRename = (fileName ?: string) => {
        if (fileName && this.state.renameSelected && this.props.onRename) {
            const docketFile : IIncidentDocketFile = {
                ...this.state.renameSelected,
            }

            if (docketFile.file) {
                docketFile.file = {
                    ...docketFile.file,
                    fileName: fileName,
                };
            }
            
            this.props.onRename(docketFile);
        }

        this.setState({
            renameSelected: null,
        });
    };

    public readonly render = () => {
        const {
            selectedIndex,
            menuTarget,
            deleteSelected,
            renameSelected,
        } = this.state;
        const {
            docketFiles,
            disabled,
        } = this.props;

        return (
            <>
                <div className='fdr flx1 fww'>
                    {
                        docketFiles.map((docketFile) => (
                            <Card key={docketFile.id} className='fdc h250 w200 mr15 oh' elevation={1}>
                                <CardActionArea value={docketFile.id} className='fdc flx1' onClick={this.onFileClick}>
                                    <CardMedia className='fdc flx1'>
                                        {
                                            {
                                                [EnumFileType.Document]: (<embed height='100%' width='100%' src={docketFile.file?.url} />),
                                                [EnumFileType.Audio]: (<PlayArrow />),
                                                [EnumFileType.Picture]: (<img height='100%' width='100%' src={docketFile.file?.thumbUrl ?? docketFile.file?.url} alt={docketFile.file?.fileName} />),
                                                [EnumFileType.Video]: (<img height='100%' width='100%' src={docketFile.file?.thumbUrl} alt={docketFile.file?.fileName} />),
                                                [EnumFileType.Unknown]: (<embed height='100%' width='100%' src={docketFile.file?.url} />),
                                            }[docketFile.file?.fileType ?? EnumFileType.Unknown]
                                        }
                                    </CardMedia>
                                </CardActionArea>
                                <CardActions className='fdr bcg4 h60 aic p0' disableSpacing>
                                    <div className='fdc flx1 p10 oxh'>
                                        <Typography className='cpd fs12 fwm tnw'>{docketFile.file?.fileName.substring(0, docketFile.file?.fileName.lastIndexOf('.'))}</Typography>
                                        <div className='fdr aic mt5'>
                                            <div className='mr10 cfpd'>
                                                {
                                                    {
                                                        [EnumFileType.Document]: (<Description className='cfpd' />),
                                                        [EnumFileType.Audio]: (<PlayArrow className='cfpd' />),
                                                        [EnumFileType.Picture]: (<Image className='cfpd' />),
                                                        [EnumFileType.Video]: (<OndemandVideo className='cfpd' />),
                                                        [EnumFileType.Unknown]: (<Help className='cfpd' />),
                                                    }[docketFile.file?.fileType ?? EnumFileType.Unknown]
                                                }
                                            </div>
                                            
                                            <Typography className='cpd fs10 fwr'>{moment.utc(docketFile.date).format('DD MMM YYYY')}</Typography>
                                        </div>
                                    </div>
                                    {
                                        !disabled &&
                                        <div className='fdc ml5'>
                                            <IconButton
                                                value={docketFile.id}
                                                onClick={this.onMoreClick}
                                            >
                                                <MoreVert />
                                            </IconButton>
                                        </div>
                                    }
                                </CardActions>
                            </Card>
                        ))
                    }
                </div>
                {
                    !disabled &&
                    <Menu
                        anchorEl={menuTarget}
                        open={!!menuTarget}
                        onClose={this.onMenuClose}
                        closeAfterTransition
                    >
                        
                        <MenuItem onClick={this.onViewClick}>
                            <div className='fdr aic'>
                                <Visibility />
                                <Typography>View</Typography>
                            </div>
                        </MenuItem>
                        <MenuItem onClick={this.onRenameClick}>
                            <div className='fdr aic'>
                                <TextFields />
                                <Typography>Rename</Typography>
                            </div>
                        </MenuItem>
                        <MenuItem onClick={this.onDeleteClick}>
                            <div className='fdr aic'>
                                <Delete />
                                <Typography>Remove</Typography>
                            </div>
                        </MenuItem>
                    </Menu>
                }
                <FileCarouselDialog
                    fullWidth
                    maxWidth='md'
                    selectedIndex={selectedIndex}
                    files={selectedIndex > -1 ? docketFiles : []}
                    open={selectedIndex > -1}
                    onClose={this.onFileCarouselClose}
                />
                <ConfirmNoButtonDialog
                    open={!!deleteSelected}
                    title={!deleteSelected ? '' : `Confirm Delete ${deleteSelected.file?.fileName}?`}
                    message={!deleteSelected ? '' : `Delete file ${deleteSelected.file?.fileName}, this cannot be undone?`}
                    onClose={this.onDelete}
                    transition={Transitions.Up}
                />
                <IncidentDocketFileRenameDialog
                    open={!!renameSelected}
                    value={renameSelected?.file?.fileName ?? ''}
                    onClose={this.onRename}
                    fullWidth
                    maxWidth='sm'
                    transition={Transitions.Up}
                />
            </>
        );
    };
}
