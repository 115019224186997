import { EnumFileCategory, EnumFileUploadType } from '../../@types/model/base';
import generalFunctions from '../general/functions';
import { getState, dispatch } from '..';
import fileActions from './actions';
import FileHttpService from '../../service/http/fileHttpService';
import { IFileUpload } from '../../@types/viewmodel/file';
import { v4 } from 'uuid';
import LocalStorageService from '../../service/localStorageService';

export default class FileFunctions {
    private static clearQueue = () => {
        const files = getState().file.files;
        if (!files.some(n => !n.completed)) {
            dispatch(fileActions.setFiles([]));
            dispatch(fileActions.setCurrent());
        }
    }

    public static queueIncidentFile = (file : IFileUpload, category : EnumFileCategory, guid : string) => {
        try {
            if (!file.file) return;

            const queueFile = {
                duration: file.duration,
                guid: v4(),
                category,
                completed: false,
                error: false,
                file: file.file,
                reference: guid,
                referenceType: EnumFileUploadType.Incident,
                type: file.type,
            };

            LocalStorageService.setQueueFile(queueFile);

            const files = getState().file.files.slice();
            files.push(queueFile);

            dispatch(fileActions.setFiles(files));

            if (files.length === 1) {
                FileFunctions.processQueue();
            }
        } catch (ex) {
            generalFunctions.showErrorSnackbar('An error occurred while uploading file.', ex);
        }
    }

    public static queueObservationFile = (file : IFileUpload, category : EnumFileCategory, guid : string) => {
        try {
            if (!file.file) return;

            const queueFile = {
                duration: file.duration,
                guid: v4(),
                category,
                completed: false,
                error: false,
                file: file.file,
                reference: guid,
                referenceType: EnumFileUploadType.Observation,
                type: file.type,
            };

            LocalStorageService.setQueueFile(queueFile);

            const files = getState().file.files.slice();
            files.push(queueFile);

            dispatch(fileActions.setFiles(files));

            if (files.length === 1) {
                FileFunctions.processQueue();
            }
        } catch (ex) {
            generalFunctions.showErrorSnackbar('An error occurred while uploading file.', ex);
        }
    }

    public static queueGraveSiteFile = (file : IFileUpload, category : EnumFileCategory, guid : string) => {
        try {
            if (!file.file) return;

            const queueFile = {
                duration: file.duration,
                guid: v4(),
                category,
                completed: false,
                error: false,
                file: file.file,
                reference: guid,
                referenceType: EnumFileUploadType.GraveSite,
                type: file.type,
            };

            LocalStorageService.setQueueFile(queueFile);

            const files = getState().file.files.slice();
            files.push(queueFile);

            dispatch(fileActions.setFiles(files));

            if (files.length === 1) {
                FileFunctions.processQueue();
            }
        } catch (ex) {
            generalFunctions.showErrorSnackbar('An error occurred while uploading file.', ex);
        }
    }

    public static queueGraveFile = (file : IFileUpload, category : EnumFileCategory, guid : string) => {
        try {
            if (!file.file) return;

            const queueFile = {
                duration: file.duration,
                guid: v4(),
                category,
                completed: false,
                error: false,
                file: file.file,
                reference: guid,
                referenceType: EnumFileUploadType.Grave,
                type: file.type,
            };

            LocalStorageService.setQueueFile(queueFile);

            const files = getState().file.files.slice();
            files.push(queueFile);

            dispatch(fileActions.setFiles(files));

            if (files.length === 1) {
                FileFunctions.processQueue();
            }
        } catch (ex) {
            generalFunctions.showErrorSnackbar('An error occurred while uploading file.', ex);
        }
    }

    public static processQueue = async () => {
        let files = getState().file.files.slice();

        if (files.length === 0) return;

        const file = files.find(n => !n.completed);

        if (file) {
            try {
                dispatch(fileActions.setCurrent(file.guid));
                await FileHttpService.uploadFile(file, (progress : number) => {
                    dispatch(fileActions.setProgress(progress));
                });

                LocalStorageService.deleteQueueFile(file.guid);
            } catch (ex) {
                generalFunctions.showErrorSnackbar('An error occurred while processing file queue.', ex);
                file.error = true;
            }
            file.completed = true;

            // Get current files
            files = getState().file.files.slice();
            const index = files.findIndex(n => n.guid === file.guid);
            files.splice(index, 1, file);
            dispatch(fileActions.setFiles(files));
            dispatch(fileActions.setProgress(0));

            FileFunctions.processQueue();
        } else {
            FileFunctions.clearQueue();
        }
    }
}
