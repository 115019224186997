import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { IAccessPoint } from '../../../../@types/model/access';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import { resetPageTitle, setPageTitle } from '../../../../service/helper/functionHelperService';

import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';
import generalFunctions from '../../../../store/general/functions';

interface IPrintAccessPointQRProps {
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    accessPoints : Array<IAccessPoint>;
}

interface IPrintAccessPointQRState {
    open : boolean;
}

export default class PrintAccessPointQR extends React.Component<IPrintAccessPointQRProps, IPrintAccessPointQRState> {
    private componentRef : any;
    constructor(props : IPrintAccessPointQRProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    private onPrintClick = () => {
        this.setState({
            open: true,
        });
    }

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, accessPoints } = this.props;
        const { open } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Print QR Code'>
                    <div>
                        <IconButton color='primary' onClick={this.onPrintClick} aria-label='Print' disabled={isLoading}>
                            <Icon>print</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='access-point-edit-dialog-title'
                    aria-describedby='access-point-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Print QR Codes
                            </Typography>
                            <ReactToPrint
                                trigger={() =>
                                    <div>
                                        <Tooltip title='Print'>
                                            <IconButton color='inherit'>
                                                <Icon>
                                                    print
                                                </Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                }
                                onPrintError={generalFunctions.showPrintError}
                                onBeforePrint={() => setPageTitle(`Gates`)}
                                onAfterPrint={resetPageTitle}
                                content={() => this.componentRef}
                                bodyClass={'printElement'}
                            />
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc flx1 hfill'>
                        <div ref={el => (this.componentRef = el)} className='grid25050 printElement'>
                        {
                            accessPoints.map(n => (
                                <div key={n.guid} className='fdc flx1 jcc aic h50vh'>
                                    <div className='fdc jcc aic p50 bw2 m20'>
                                        <QRCode
                                        value={n.code}
                                        size={256}
                                        renderAs='svg'
                                        level='H'
                                        imageSettings={{
                                            src: `${ASSET_BASE}/assets/images/zz2-logo.svg`,
                                            height: 18,
                                            width: 35,
                                            excavate: true,
                                        }}/>
                                        <Typography variant='h4' color='inherit' className='pt10'>
                                            {n.code}
                                        </Typography>
                                        <Typography variant='h4' color='inherit'>
                                            {n.name}
                                        </Typography>
                                    </div>
                                </div>
                            ))
                        }
                        </div>
                    </DialogContent>
                </Dialog>
            </React.Fragment>);
    }
}
