import { Accordion, AccordionDetails, AccordionSummary, Button, Icon, IconButton, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { IGraveSite } from '../../../../@types/model/graveSite/graveSite';
import { EnumGraveFileType, IGrave } from '../../../../@types/model/graveSite/grave';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import lodash from 'lodash';
import FileThumbnail from '../../custom/fileItem/FileThumbnail';
import FileCarouselDialog from '../../custom/dialog/FileCarouselDialog';
import StadiumButton from '../../custom/button/StadiumButton';
import ImageFileUpload from '../../custom/file/ImageFileUpload';
import GraveSiteFileUpload from '../dialog/GraveSiteUploadFiles';
import { Transitions } from '../../custom/animations/Transitions';
import { createSelector } from 'reselect';
import GraveInfoDocument from './GraveInfoDocument';

interface IGraveInfoExpansionTileProps {
    grave : IGrave;
    graveSite : IGraveSite;
    key : number;
    isGraveSiteAdmin : boolean;

    onEditClick? : (grave : IGrave) => void;
    onDeleteClick? : (grave : IGrave) => void;
}

interface IGraveInfoExpansionTileState {
    expanded : boolean;
    uploadOpen : boolean;
    imageIndex? : number;
}

export default class GraveInfoExpansionTile extends React.PureComponent<IGraveInfoExpansionTileProps, IGraveInfoExpansionTileState> {
    constructor(props : IGraveInfoExpansionTileProps) {
        super(props);
        this.state = {
            expanded: false,
            uploadOpen: false,
        };
    }

    private onExpandClick = () : void => {
        this.setState({
            expanded: !this.state.expanded,
        });
    }

    public onOpenImageFileCarousel = (index : number) => {
        if (this.props.graveSite) {
            this.setState({
                imageIndex: index,
            });
        }
    }

    public onImageFileCarouselClose = () => {
        this.setState({
            imageIndex: undefined,
        });
    }

    private readonly onDeleteClick = (e : React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (!this.props.onDeleteClick) return;
        this.props.onDeleteClick(this.props.grave);
    }

    private readonly onEditClick = (e : React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (!this.props.onEditClick) return;
        this.props.onEditClick(this.props.grave);
    }

    private readonly onUploadClick = () => {
        this.setState({
            uploadOpen: true,
        });
    }

    private readonly onClose = () => {
        this.setState({
            uploadOpen: false,
        });
    }

    public readonly getFiles = (props : IGraveInfoExpansionTileProps) => props.grave.files;
    public getImages = createSelector([
        this.getFiles,
    ],
        (files) => {
            return lodash
                .chain(files)
                .filter(n => n.type === EnumGraveFileType.Image)
                .sort((a, b) => moment.utc(b.createdOn).diff(moment.utc(a.createdOn)))
                .value();
        },
    );

    public getInitialApplications = createSelector([
        this.getFiles,
    ],
        (files) => {
            return lodash
                .chain(files)
                .filter(n => n.type === EnumGraveFileType.InitialApplication)
                .sort((a, b) => moment.utc(b.createdOn).diff(moment.utc(a.createdOn)))
                .value();
        },
    );

    public getGraveSiteAgreements = createSelector([
        this.getFiles,
    ],
        (files) => {
            return lodash
                .chain(files)
                .filter(n => n.type === EnumGraveFileType.GraveSiteAgreement)
                .sort((a, b) => moment.utc(b.createdOn).diff(moment.utc(a.createdOn)))
                .value();
        },
    );

    public getTombstoneErectionApplications = createSelector([
        this.getFiles,
    ],
        (files) => {
            return lodash
                .chain(files)
                .filter(n => n.type === EnumGraveFileType.TombstoneErectionApplication)
                .sort((a, b) => moment.utc(b.createdOn).diff(moment.utc(a.createdOn)))
                .value();
        },
    );

    public getBurialAgreements = createSelector([
        this.getFiles,
    ],
        (files) => {
            return lodash
                .chain(files)
                .filter(n => n.type === EnumGraveFileType.BurialAgreement)
                .sort((a, b) => moment.utc(b.createdOn).diff(moment.utc(a.createdOn)))
                .value();
        },
    );

    public render = () => {
        const { grave, graveSite, isGraveSiteAdmin } = this.props;
        const { expanded, imageIndex, uploadOpen } = this.state;

        const images = this.getImages(this.props);
        const initialApplications = this.getInitialApplications(this.props);
        const graveSiteAgreements = this.getGraveSiteAgreements(this.props);
        const tombstoneErectionApplications = this.getTombstoneErectionApplications(this.props);
        const burialAgreements = this.getBurialAgreements(this.props);
        return (
            <div className='mb15'>
                <Accordion expanded={expanded} className='m0 bn br4'>
                    <AccordionSummary
                        onClick={this.onExpandClick}
                        className={`aic pl5 ${expanded ? 'bcpl pbr' : 'bcw br4' } cpd m0 mh48`}
                        expandIcon={<ExpandMoreIcon className={`${expanded ? 'cw' : 'cg2'}`} />}
                    >
                        <div className={`wfill jcsb jcc`}>
                            <div className={'fdr pl15 jcc aic'}>
                                <Typography className={`${expanded ? 'cw' : 'cp' } fs20`}>
                                    { grave.nameOfDeceased ?? ' - ' } - { grave.fileNumber ?? ' - '}
                                </Typography>
                            </div>
                            <div>
                                {
                                    isGraveSiteAdmin &&
                                    <Tooltip title='Delete'>
                                        <IconButton onClick={this.onDeleteClick}>
                                            <DeleteIcon className={`${expanded ? 'cw' : 'cg2' }`} />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {
                                    isGraveSiteAdmin &&
                                    <Tooltip title='Edit'>
                                        <IconButton color='inherit' onClick={this.onEditClick}>
                                            <EditIcon className={`${expanded ? 'cw' : 'cg2' }`} />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className='flx1 p0'>
                        <div className={'flx1 fdr pt15 pl15 pr15 pb15'}>
                            <div className={'flx2 fdc'}>
                                <div className={'flx1 fdc pb15'}>
                                    <div className={'flx1 fdr'}>
                                        <div className={'flx1 fdc'}>
                                            <Typography className={'fs12 cg3'}>
                                                Status
                                            </Typography>
                                            <Typography className={`fwm fs16 ${grave.identified ? 'cp' : 'cpr'}`}>
                                                {grave.identified ? 'IDENTIFIED' : 'UNIDENTIFIED'}
                                            </Typography>
                                        </div>
                                        <div className={'flx1 fdc'}>
                                            <Typography className={'fs12 cg3'}>
                                                Registered Date
                                            </Typography>
                                            <Typography className={'fs16 fwm cg2'}>
                                                {grave.registerDate ? moment.utc(grave.registerDate).format('DD/MM/yyyy') : 'Unknown'}
                                            </Typography>
                                        </div>
                                        <div className={'flx1 fdc'}>
                                            <Typography className={'fs12 cg3'}>
                                                Division
                                            </Typography>
                                            <Typography className={'fs16 fwm cg2'}>
                                                {graveSite?.division?.description ?? '-'}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className={'flx1 fdr'}>
                                        <div className={'flx1 fdc'}>
                                            <Typography className={'fs12 cg3'}>
                                                File Number
                                            </Typography>
                                            <Typography className={'fs16 fwm cg2'}>
                                                {grave.fileNumber ?? '-'}
                                            </Typography>
                                        </div>
                                        <div className={'flx1 fdc'}>
                                            <Typography className={'fs12 cg3'}>
                                                ZZ2 Representative
                                            </Typography>
                                            <Typography className={'fs16 fwm cg2'}>
                                                {graveSite.representativeName}
                                            </Typography>
                                        </div>
                                        <div className={'flx1 fdc'}>
                                            <Typography className={'fs12 cg3'}>
                                                ZZ2 Representative No
                                            </Typography>
                                            <Typography className={'fs16 fwm cg2'}>
                                                {graveSite.representative}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className={'flx1 fdc pt15 pb30'}>
                                    <div className={'h50 bclg aic'}>
                                        <Typography className={'pl15 fwb fs20 cw'}>
                                            DOCUMENTS UPLOADED
                                        </Typography>
                                    </div>
                                    <div className={'fdr flx1 jsce aic'}>
                                        <div className={'fdc pt10 flx1 pl10'}>
                                            <div className='mb15'>
                                                <GraveInfoDocument title='1. Initial Application' files={initialApplications} />
                                            </div>
                                            <div className='mb15'>
                                                <GraveInfoDocument title='2. Grave Visit Agreement' files={graveSiteAgreements} />
                                            </div>
                                        </div>
                                        <div className={'fdc pt10 flx1'}>
                                            <div className='mb15'>
                                                <GraveInfoDocument title='3. Tombstone Erection Application' files={tombstoneErectionApplications} />
                                            </div>
                                            <div className='mb15'>
                                                <GraveInfoDocument title='4. Burial Agreement' files={burialAgreements} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'fdr flx1 mb15 mt5 h250 w350 ml15 mr15'}>
                                {
                                    !!images.length &&
                                    <FileThumbnail
                                        imageWidth={'h250 w350'}
                                        imageClassName={'h250 w350'}
                                        key={`file_${lodash.last(images)?.file?.fileName}`}
                                        index={images.length - 1}
                                        file={lodash.last(images)?.file!}
                                        onOpenFileCarousel={this.onOpenImageFileCarousel} />
                                }
                                {
                                    !images.length &&
                                    <ImageFileUpload single accept='image/*' value={[]}  />
                                }
                                {
                                    // Images carousel.
                                    <FileCarouselDialog
                                        fullWidth
                                        maxWidth='md'
                                        selectedIndex={imageIndex}
                                        files={images}
                                        open={typeof imageIndex !== 'undefined'}
                                        onClose={this.onImageFileCarouselClose} />
                                }
                            </div>
                            <div className={'flx2 fdc'}>
                            <div className={'flx1 fdc pb15'}>
                                    <div className={'flx1 fdr'}>
                                        <div className={'flx1 fdc'}>
                                            <Typography className={'fs12 cg3'}>
                                                Name of deceased
                                            </Typography>
                                            <Typography>
                                                {grave.nameOfDeceased}
                                            </Typography>
                                        </div>
                                        <div className={'flx1 fdc'}>
                                            <Typography className={'fs12 cg3'}>
                                                Applicant
                                            </Typography>
                                            <Typography className={'fs16 fwm cg2'}>
                                                {grave.applicantName}
                                            </Typography>
                                        </div>
                                        <div className={'flx1 fdc'}>
                                            <Typography className={'fs12 cg3'}>
                                                Applicant Number
                                            </Typography>
                                            <Typography className={'fs16 fwm cg2'}>
                                                {grave.applicantContact ?? '-'}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className={'flx1 fdr'}>
                                        <div className={'flx1 fdc'}>
                                            <Typography className={'fs12 cg3'}>
                                                Date of Burial
                                            </Typography>
                                            <Typography className={'fs16 fwm cg2'}>
                                                {
                                                    grave.dateOfDeath &&
                                                    moment.utc(grave.dateOfBurial).local().format('DD/MM/yyyy')
                                                }
                                                {
                                                    !grave.dateOfBurial &&
                                                    'UNKNOWN'
                                                }
                                            </Typography>
                                        </div>
                                        <div className={'flx1 fdc'}>
                                            <Typography className={'fs12 cg3'}>
                                                Date of Death
                                            </Typography>
                                            <Typography className={'fs16 fwm cg2'}>
                                                {
                                                    grave.dateOfDeath &&
                                                    moment.utc(grave.dateOfDeath).local().format('DD/MM/yyyy')
                                                }
                                                {
                                                    !grave.dateOfDeath &&
                                                    'UNKNOWN'
                                                }
                                            </Typography>
                                        </div>
                                        <span className={'flx1'}/>
                                    </div>
                                </div>
                                <div className={'fdr jcfe pr30 pb30'}>
                                    <StadiumButton
                                        icon={(
                                            <Icon className={'cp'}>
                                                upload
                                            </Icon>
                                        )}
                                        onClick={this.onUploadClick}
                                    >
                                        <Typography component='div' className={'cp fs16 fwb'}>
                                            UPLOAD DOCUMENTS
                                        </Typography>
                                    </StadiumButton>
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <GraveSiteFileUpload
                    graveSite={graveSite}
                    grave={grave}
                    fullWidth
                    maxWidth='lg'
                    transition={Transitions.Up}
                    onClose={this.onClose}
                    open={uploadOpen}
                />
            </div>
        );
    }
}
