import { IBaseImportModel, IFile, ILatLng } from '../base';
import { IDivision } from '../division';
import { IGrave, IGraveUpsert } from './grave';

export interface IGraveSite extends IBaseImportModel {
    guid : string;
    number : string;
    date : string;
    divisionId : number;
    division : IDivision | null;
    isPrivate : boolean;
    description : string;
    identified : boolean | null;
    representative : string;
    representativeName : string;
    representativeContact : string;
    geo : ILatLng;
    files : Array<IGraveSiteFile>;
    graves : Array<IGrave>;
}

export interface IGraveSiteFile extends IBaseImportModel {
    fileId : number;
    file : IFile | null;
    graveSiteId : number;
    type : EnumGraveSiteFileType;
    readonly typeDescription : string;
}

export interface IGraveSiteUpsert extends Omit<IGraveSite, 'graves'> {
    graves : Array<IGraveUpsert>;
}

export enum EnumGraveSiteFileType {
    Image = 0,
    RepresentativeImage = 1,
}
