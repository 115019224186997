import React from 'react';
import { GoogleMap as Map } from '@react-google-maps/api';

interface IGoogleMapProps extends React.ComponentPropsWithRef<any> {
    id? : string | undefined;
    center? : {
        lat : number,
        lng : number,
    };
    zoom? : number | undefined;
    onClick? : (e : google.maps.MouseEvent) => void;
    showMap : boolean;

    children? : React.ReactNode;
}

interface IGoogleMapState {
}

export default class GoogleMap extends React.PureComponent<IGoogleMapProps, IGoogleMapState> {
    private center? : google.maps.LatLng;
    private zoom? : number;
    private mapRef : google.maps.Map<Element> | null;
    constructor(props : IGoogleMapProps) {
        super(props);
        this.state = {
        };

        this.center = this.props.center ? new google.maps.LatLng(this.props.center.lat, this.props.center.lng) : undefined;
        this.zoom = this.props.zoom;
    }

    public readonly panTo = (geo : {
        lat : number,
        lng : number,
    }) => {
        this.mapRef?.panTo(geo);
    }

    public readonly onLoad = (map : google.maps.Map<Element>) => {
        this.mapRef = map;
    }

    public readonly onUnmount = (map : google.maps.Map<Element>) => {
        this.mapRef = null;
    }

    public render = () => {
        const { id, children, showMap } = this.props;
        const { } = this.state;

        if (!showMap) {
            return <div />;
        }

        return (
            <Map id={id}
                onLoad={this.onLoad}
                onUnmount={this.onUnmount}
                center={this.center}
                zoom={this.zoom}
                mapContainerStyle={{
                    height: '100%',
                    width: '100%',
                }}
                onClick={this.props.onClick}
            >
                {
                    children
                }
            </Map>
        );
    }
}
