import React from 'react';
import { IIncident } from '../../../../@types/model/incident';
import ReactPDF, { Page, Text, View, Image, Document, pdf } from '@react-pdf/renderer';
import style from '../../../../style/pdfStyleSheet';
import PDFIncidentHeader from '../../custom/pdf/IncidentHeader';
import PDFIncidentReportTitle from '../../custom/pdf/IncidentReportTitle';
import { EnumFileType } from '../../../../@types/model/base';
import { INCIDENT_TYPE_TO_TEXT } from '../../../../appConstants';
import moment from 'moment';
import FileSaver from 'file-saver';
import GeneralFunctions from '../../../../store/general/functions';

export const createAndDownloadPDF = async (incident : IIncident) : Promise<void> => {
    try {
        const generatedPdfReport = getPages(incident);
        const blob = await pdf(generatedPdfReport).toBlob();

        FileSaver.saveAs(blob, `${incident.number}.pdf`);
    } catch (ex) {
        console.error(ex);
        GeneralFunctions.showErrorSnackbar('Could not download file.');
    }
};

const getIncidentImages = (incident : IIncident) => {
    const result = incident.files.filter(x => x.file.fileType === EnumFileType.Picture).map(x => ({
        url: x.file.url,
        type: INCIDENT_TYPE_TO_TEXT[x.incidentFileType],
    }));

    incident.updates.forEach((update, index) => {
        update.files
            .filter(x => x.file.fileType === EnumFileType.Picture)
            .forEach((file) => {
                result.push({
                    url: file.file.url,
                    type: `Update ${index + 1}`,
                });
            });
    });

    return result
    .sort((a, b) => a.type.localeCompare(b.type));
}

const getPages = (incident : IIncident) => {
    const imageUrls = getIncidentImages(incident);

    return <Document>
        <Page size='A4' key='page1' style={[style.wfill, style.hfill, style.body, style.pb35]}>
            {/* HEADER DATA */}
            <PDFIncidentReportTitle title='INCIDENT REPORT' sub={incident.number} />
            <View style={[style.fdc, style.ais, style.flx1, style.page]}>
                {/* SUMMARY DATA */}
                <PDFIncidentHeader title='SUMMARY' />
                <View style={[style.fdr, style.mb10]}>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field, style.mr5]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w30]}>Group</Text>
                        </View>
                        <Text style={[]}>{incident.group ?? '-'}</Text>
                    </View>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w40]}>Division</Text>
                        </View>
                        <Text style={[]}>{incident.division}</Text>
                    </View>
                </View>
                <View style={[style.fdr, style.mb10]}>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field, style.mr5]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w40]}>Farm OB</Text>
                        </View>
                        <Text style={[]}>{incident.farmOBNumber ?? '-'}</Text>
                    </View>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5, style.mr5]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w50]}>Created By</Text>
                        </View>
                        <Text style={[]}>{incident.createdByName}</Text>
                    </View>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w60]}>Updated By</Text>
                        </View>
                        <Text style={[]}>{incident.updatedByName}</Text>
                    </View>
                </View>
                {/* SUMMARY DATA */}
                <PDFIncidentHeader title='COMPLAINANT' />
                <View style={[style.fdr, style.mb10]}>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field, style.mr5]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w35]}>Name</Text>
                        </View>
                        <Text style={[]}>{incident.complainant.name} {incident.complainant.surname}</Text>
                    </View>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w90]}>Employee Number</Text>
                        </View>
                        <Text style={[]}>{incident.complainant.employeeNr ? incident.complainant.employeeNr : 'None'}</Text>
                    </View>
                </View>
                <View style={[style.fdr, style.mb10]}>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field, style.mr5]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w35]}>Tel No</Text>
                        </View>
                        <Text style={[]}>{!incident.complainant.tel ? '-' : incident.complainant.tel}</Text>
                    </View>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5, style.mr5]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w35]}>Cel No</Text>
                        </View>
                        <Text style={[]}>{!incident.complainant.cel ? '-' : incident.complainant.cel}</Text>
                    </View>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w90]}>Spoken Language</Text>
                        </View>
                        <Text style={[]}>{incident.complainant.language ? incident.complainant.language : 'Unknown'}</Text>
                    </View>
                </View>
                <View style={[style.fdr, style.mb10]}>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w50]}>Address</Text>
                        </View>
                        <Text style={[]}>{!incident.complainant.address ? '-' : incident.complainant.address}</Text>
                    </View>
                </View>
                {/* INCIDENT DATA */}
                <PDFIncidentHeader title='INCIDENT' />
                <View style={[style.fdr, style.mb10]}>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w50]}>Category</Text>
                        </View>
                        <Text style={[]}>{incident.incidentCategories.map(x => x.categoryName).join(', ')}</Text>
                    </View>
                </View>
                <View style={[style.fdr, style.mb10]}>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field, style.mr5]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w35]}>Place</Text>
                        </View>
                        <Text style={[]}>{incident.place}</Text>
                    </View>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w60]}>Injury Type</Text>
                        </View>
                        <Text style={[]}>{incident.injuryType ? incident.injuryType : '-'}</Text>
                    </View>
                </View>
                <View style={[style.fdr, style.mb10]}>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w80]}>Short Description</Text>
                        </View>
                        <View style={[style.fdc]}>
                        {
                            !!incident.injuryType &&
                            incident.injuryType.split('\n').map((x, i) => (
                                <Text key={i} style={[]}>{x}</Text>
                            ))
                        }
                        {
                            !incident.injuryType &&
                            <Text style={[]}>-</Text>
                        }
                        </View>
                    </View>
                </View>
                <View style={[style.fdr, style.mb10]}>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w60]}>Action Taken</Text>
                        </View>
                        <View style={[style.fdc]}>
                        {
                            !!incident.actionTaken &&
                            incident.actionTaken.split('\n').map((x, i) => (
                                <Text key={i} style={[style.mb5]}>{x}</Text>
                            ))
                        }
                        {
                            !incident.actionTaken &&
                            <Text style={[]}>-</Text>
                        }
                        </View>
                    </View>
                </View>
                <View style={[style.fdr, style.mb10]}>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w60]}>Suggestions</Text>
                        </View>
                        <View style={[style.fdc]}>
                        {
                            !!incident.suggestions &&
                            incident.suggestions.split('\n').map((x, i) => (
                                <Text key={i} style={[style.mb5]}>{x}</Text>
                            ))
                        }
                        {
                            !incident.suggestions &&
                            <Text style={[]}>-</Text>
                        }
                        </View>
                    </View>
                </View>
                <View style={[style.fdr, style.mb10]} wrap={false}>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w100]}>Detailed Description</Text>
                        </View>
                        <View style={[style.fdc]}>
                        {
                            !!incident.comment &&
                            incident.comment.split('\n').map((x, i) => (
                                <Text key={i} style={[style.mb5]}>{x}</Text>
                            ))
                        }
                        {
                            !incident.comment &&
                            <Text style={[]}>-</Text>
                        }
                        </View>
                    </View>
                </View>
                <View style={[style.fdr, style.mb10]} wrap={false}>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field, style.mr5]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w40]}>Value</Text>
                        </View>
                        <Text style={[]}>{incident.value ? incident.value.toFixed(2) : '-'}</Text>
                    </View>
                    <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5]}>
                        <View style={[style.fdr, style.fieldTitle]}>
                            <Text style={[style.w50]}>Outcome</Text>
                        </View>
                        <Text style={[]}>{incident.outcome ? incident.outcome : '-'}</Text>
                    </View>
                </View>
            </View>
            <View style={[style.fdc, style.ais, style.flx1, style.page]}>
                {/* SAPS INFO DATA */}
                <PDFIncidentHeader title='SAPS INFO' />
                {
                    !incident.saps &&
                    <View style={[style.fdc, style.aic, style.jcc, style.mb20, style.h30]}>
                        <Text style={[style.fs12, style.fwm, style.w100]}>Not Involved</Text>
                    </View>
                }
                {
                    incident.saps &&
                    <View style={[style.fdc]}>
                        <View style={[style.fdr, style.mb10]} wrap={false}>
                            <View style={[style.fdr, style.aic, style.jcfs, style.field, style.mr5]}>
                                <View style={[style.fdr, style.fieldTitle]}>
                                    <Text style={[style.w40]}>Station</Text>
                                </View>
                                <Text style={[]}>{incident.saps.station ? incident.saps.station : '-'}</Text>
                            </View>
                            <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5]}>
                                <View style={[style.fdr, style.fieldTitle]}>
                                    <Text style={[style.w60]}>Info Book Nr</Text>
                                </View>
                                <Text style={[]}>{incident.saps.infBookNr ? incident.saps.infBookNr : '-'}</Text>
                            </View>
                        </View>
                        <View style={[style.fdr, style.mb10]} wrap={false}>
                            <View style={[style.fdr, style.aic, style.jcfs, style.field, style.mr5]}>
                                <View style={[style.fdr, style.fieldTitle]}>
                                    <Text style={[style.w60]}>Occ Reg Nr</Text>
                                </View>
                                <Text style={[]}>{!incident.saps.occRegNr ? '-' : incident.saps.occRegNr}</Text>
                            </View>
                            <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5, style.mr5]}>
                                <View style={[style.fdr, style.fieldTitle]}>
                                    <Text style={[style.w90]}>Accident Report Nr</Text>
                                </View>
                                <Text style={[]}>{!incident.saps.accidentReportNr ? '-' : incident.saps.accidentReportNr}</Text>
                            </View>
                            <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5]}>
                                <View style={[style.fdr, style.fieldTitle]}>
                                    <Text style={[style.w70]}>Exhibit Reg Nr</Text>
                                </View>
                                <Text style={[]}>{incident.saps.exhibitRegNr ? incident.saps.exhibitRegNr : 'Unknown'}</Text>
                            </View>
                        </View>
                        <View style={[style.fdr, style.mb10]} wrap={false}>
                            <View style={[style.fdr, style.aic, style.jcfs, style.field, style.mr5]}>
                                <View style={[style.fdr, style.fieldTitle]}>
                                    <Text style={[style.w60]}>Case Reg Nr</Text>
                                </View>
                                <Text style={[]}>{!incident.saps.caseRegNr ? '-' : incident.saps.caseRegNr}</Text>
                            </View>
                            <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5, style.mr5]}>
                                <View style={[style.fdr, style.fieldTitle]}>
                                    <Text style={[style.w90]}>Officer On Scene</Text>
                                </View>
                                <Text style={[]}>{!incident.saps.officerName ? '-' : incident.saps.officerName}</Text>
                            </View>
                            <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5]}>
                                <View style={[style.fdr, style.fieldTitle]}>
                                    <Text style={[style.w90]}>Investigating Officer</Text>
                                </View>
                                <Text style={[]}>{incident.saps.investigatingOfficerName ? incident.saps.investigatingOfficerName : 'Unknown'}</Text>
                            </View>
                        </View>
                        <View style={[style.fdr, style.mb10]} wrap={false}>
                            <View style={[style.fdr, style.aic, style.jcfs, style.field, style.mr5]}>
                                <View style={[style.fdr, style.fieldTitle]}>
                                    <Text style={[style.w110]}>Officer On Scene Rank</Text>
                                </View>
                                <Text style={[]}>{!incident.saps.officerRank ? '-' : incident.saps.officerRank}</Text>
                            </View>
                            <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5, style.mr5]}>
                                <View style={[style.fdr, style.fieldTitle]}>
                                    <Text style={[style.w120]}>Investigating Officer Rank</Text>
                                </View>
                                <Text style={[]}>{!incident.saps.investigatingOfficerRank ? '-' : incident.saps.investigatingOfficerRank}</Text>
                            </View>
                            <View style={[style.fdr, style.aic, style.jcfs, style.wfill, style.ml5]}>
                            </View>
                        </View>
                    </View>
                }
                {/* EVIDENCE */}
                <PDFIncidentHeader title='EVIDENCE' />
                {
                    !incident.evidence &&
                    <View style={[style.fdc, style.aic, style.jcc, style.mb10, style.h30]}>
                        <Text style={[style.fs12, style.fwm, style.w100]}>No Evidence</Text>
                    </View>
                }
                {
                    incident.evidence &&
                    <View style={[style.fdc]}>
                        <View style={[style.fdr, style.mb10]} wrap={false}>
                            <View style={[style.fdr, style.aic, style.jcfs, style.field, style.mr5]}>
                                <View style={[style.fdr, style.fieldTitle]}>
                                    <Text style={[style.w30]}>Type</Text>
                                </View>
                                <Text style={[]}>{!incident.evidence.description ? '-' : incident.evidence.description}</Text>
                            </View>
                            <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5, style.mr5]}>
                                <View style={[style.fdr, style.fieldTitle]}>
                                    <Text style={[style.w90]}>ZZ2 Exhibit Reg Nr</Text>
                                </View>
                                <Text style={[]}>{!incident.evidence.exhibitRegisterNumber ? '-' : incident.evidence.exhibitRegisterNumber}</Text>
                            </View>
                            <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5]}>
                                <View style={[style.fdr, style.fieldTitle]}>
                                    <Text style={[style.w40]}>Result</Text>
                                </View>
                                <Text style={[]}>{incident.evidence.result ? incident.evidence.result : 'Unknown'}</Text>
                            </View>
                        </View>
                        <View style={[style.fdr, style.mb10]} wrap={false}>
                            <View style={[style.fdr, style.aic, style.jcfs, style.field, style.mr5]}>
                                <View style={[style.fdr, style.fieldTitle]}>
                                    <Text style={[style.w80]}>Owner Received</Text>
                                </View>
                                <Text style={[]}>{!incident.evidence.ownerReceived ? '-' : incident.evidence.ownerReceived}</Text>
                            </View>
                            <View style={[style.fdr, style.aic, style.jcfs, style.wfill, style.ml5, style.mr5]}>
                            </View>
                            <View style={[style.fdr, style.aic, style.jcfs, style.wfill, style.ml5]}>
                            </View>
                        </View>
                    </View>
                }
                {/* SUSPECTS */}
                <PDFIncidentHeader title='SUSPECTS' />
                {
                    !incident.suspects.length &&
                    <View style={[style.fdc, style.aic, style.jcc, style.mb10, style.h30]}>
                        <Text style={[style.fs12, style.fwm, style.w100]}>No Suspects</Text>
                    </View>
                }
                {
                    !!incident.suspects.length &&
                    incident.suspects.map((suspect, i) => (
                        <View key={i} style={[style.fdc]}>
                            <View style={[style.fdr, style.wfill, style.bcg1, style.mb10]} wrap={false}>
                                <Text style={[style.fs12, style.fwb, style.cpm]}>Suspect {i + 1}</Text>
                            </View>
                            <View style={[style.fdr, style.mb10]} wrap={false}>
                                <View style={[style.fdr, style.aic, style.jcfs, style.field, style.mr5]}>
                                    <View style={[style.fdr, style.fieldTitle]}>
                                        <Text style={[style.w80]}>Name & Surname</Text>
                                    </View>
                                    <Text style={[]}>{suspect.name} {suspect.surname}</Text>
                                </View>
                                <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5]}>
                                    <View style={[style.fdr, style.fieldTitle]}>
                                        <Text style={[style.w40]}>Adress</Text>
                                    </View>
                                    <Text style={[]}>{!suspect.address ? '-' : suspect.address}</Text>
                                </View>
                            </View>
                            <View style={[style.fdr, style.mb10]} wrap={false}>
                                <View style={[style.fdr, style.aic, style.jcfs, style.field, style.mr5]}>
                                    <View style={[style.fdr, style.fieldTitle]}>
                                        <Text style={[style.w30]}>Tel No</Text>
                                    </View>
                                    <Text style={[]}>{!suspect.tel ? '-' : suspect.tel}</Text>
                                </View>
                                <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5, style.mr5]}>
                                    <View style={[style.fdr, style.fieldTitle]}>
                                        <Text style={[style.w70]}>Employee Nr</Text>
                                    </View>
                                    <Text style={[]}>{!suspect.employeeNr ? '-' : suspect.employeeNr}</Text>
                                </View>
                                <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5]}>
                                    <View style={[style.fdr, style.fieldTitle]}>
                                        <Text style={[style.w40]}>Cel No</Text>
                                    </View>
                                    <Text style={[]}>{suspect.cel ? suspect.cel : 'Unknown'}</Text>
                                </View>
                            </View>
                            <View style={[style.fdr, style.mb10]} wrap={false}>
                                <View style={[style.fdr, style.aic, style.jcfs, style.field, style.mr5]}>
                                    <View style={[style.fdr, style.fieldTitle]}>
                                        <Text style={[style.w90]}>Spoken Language</Text>
                                    </View>
                                    <Text style={[]}>{!suspect.language ? '-' : suspect.language}</Text>
                                </View>
                                <View style={[style.fdr, style.aic, style.jcfs, style.wfill, style.ml5, style.mr5]}>
                                </View>
                                <View style={[style.fdr, style.aic, style.jcfs, style.wfill, style.ml5]}>
                                </View>
                            </View>
                        </View>
                    ))
                }
                {
                    !!incident.updates && incident.updates.length > 0 &&
                    incident.updates.map((update, i) => (
                        <View key={i} style={[style.fdc]} wrap={false}>
                            <View style={[style.fdc]}>
                                <View style={[style.fdr, style.bcp, style.cw, style.h35, style.aic, style.pl12, style.mb10]}>
                                    <Text style={[style.fs16, style.fwm]}>UPDATES</Text>
                                </View>
                            </View>
                            <View style={[style.fdr, style.mb10]}>
                                <View style={[style.fdr, style.aic, style.jcfs, style.field, style.mr5]}>
                                    <View style={[style.fdr, style.fieldTitle]}>
                                        <Text style={[style.w25]}>Date</Text>
                                    </View>
                                    <Text style={[]}>{moment.utc(update.date).local().format('DD/MM/YYYY')}</Text>
                                </View>
                                <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5, style.mr5]}>
                                    <View style={[style.fdr, style.fieldTitle]}>
                                        <Text style={[style.w30]}>Files</Text>
                                    </View>
                                    <Text style={[]}>{update.files.length}</Text>
                                </View>
                                <View style={[style.fdr, style.aic, style.jcfs, style.field, style.ml5]}>
                                    <View style={[style.fdr, style.fieldTitle]}>
                                        <Text style={[style.w60]}>Updated By</Text>
                                    </View>
                                    <Text style={[]}>{update.updatedByName}</Text>
                                </View>
                            </View>
                            <View style={[style.fdr, style.mb10]}>
                                <View style={[style.fdr, style.aic, style.jcfs, style.field]}>
                                    <View style={[style.fdr, style.fieldTitle]}>
                                        <Text style={[style.w60]}>Description</Text>
                                    </View>
                                    <View style={[style.fdc]}>
                                    {
                                        !!update.comment &&
                                        update.comment.split('\n').map((x, index) => (
                                            <Text key={index} style={[style.mb5]}>{x}</Text>
                                        ))
                                    }
                                    {
                                        !update.comment &&
                                        <Text style={[]}>-</Text>
                                    }
                                    </View>
                                </View>
                            </View>
                        </View>
                    ))
                }
                <View style={[style.fdc]} break>
                    <View style={[style.fdr, style.bcp, style.cw, style.h35, style.aic, style.pl12, style.mb10]}>
                        <Text style={[style.fs16, style.fwm]}>PHOTOS</Text>
                    </View>
                </View>
                {imageUrls.length > 0 &&
                    <View style={[style.fdr, style.fww, style.mt10]}>
                        {
                            imageUrls.map((image, index) => (
                                <View key={index} style={[style.fdc, style.aic, style.jcc, style.ml12, style.mb10]} wrap={false}>
                                    <Image style={[
                                        style.h100,
                                        style.bcg1,
                                    ]}
                                        src={image.url}
                                        // cache
                                    />
                                    <Text style={[style.fs12, style.fwm, style.mt10]}>{image.type}</Text>
                                </View>
                            ))
                        }
                    </View>
                }
            </View>
            <Text style={[style.pageNumber, style.cpl]} render={({ pageNumber }) => (
                `Page ${pageNumber ?? ''}`
            )} fixed />
        </Page>
    </Document>
}