import React from 'react';
import Dropzone from 'react-dropzone';
import Typography from '@material-ui/core/Typography';
import { fileTypeToEnumFileType } from '../../../../service/helper/functionHelperService';
import DeleteIcon from '@material-ui/icons/Delete';
import { EnumFileType } from '../../../../@types/model/base';
import { IFileUpload } from '../../../../@types/viewmodel/file';
import IconButton from '@material-ui/core/IconButton';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

interface IMultiFileDropZoneProps {
    files : Array<IFileUpload>;

    onFileSelected? : (file : IFileUpload) => void;
    onFileRemoved? : (file : IFileUpload) => void;

    disabled? : boolean;

    accept : string;
}

interface IMultiFileDropZoneState {}

export default class MultiFileDropZone extends React.Component<IMultiFileDropZoneProps, IMultiFileDropZoneState> {
    constructor(props : IMultiFileDropZoneProps) {
        super(props);
        this.state = {

        };
    }

    private onFileDrop = (acceptedFiles : Array<File>) => {
        const file = acceptedFiles[0];

        if (!!this.props.onFileSelected) {
            const type = fileTypeToEnumFileType(file.type);
            switch (type) {
                case EnumFileType.Audio:
                    this.onAudioFileSelect(file);
                    break;
                case EnumFileType.Video:
                    this.onVideoFileSelect(file);
                    break;
                default:
                    this.props.onFileSelected({
                        name: file.name,
                        file,
                        type,
                        duration: null,
                    });
            }
        }
    }

    private onAudioFileSelect = (file : File) => {
        const audio = new Audio(URL.createObjectURL(file));
        audio.preload = 'metadata';
        audio.onloadedmetadata = () => {
            if (!!this.props.onFileSelected) {
                this.props.onFileSelected({
                    name: file.name,
                    file,
                    type: fileTypeToEnumFileType(file.type),
                    duration: Number(audio.duration),
                });
            }
        };
    }

    private onVideoFileSelect = (file : File) => {
        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = () => {
            if (!!this.props.onFileSelected) {
                this.props.onFileSelected({
                    name: file.name,
                    file,
                    type: fileTypeToEnumFileType(file.type),
                    duration: Number(video.duration),
                });
            }
        };
        video.src = URL.createObjectURL(file);
    }

    private onRemoveFile = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const file = this.props.files.slice().find(x => x.name === event.currentTarget.value);

        if (!!file && !!this.props.onFileRemoved) {
            this.props.onFileRemoved(file);
        }
    }

    public render = () => {
        const { disabled, accept, files } = this.props;
        const { } = this.state;
        return (
            <div className='fdr flx1 cursorPointer'>
                <Dropzone onDrop={this.onFileDrop} accept={accept}>
                    {({ getRootProps, getInputProps }) => (
                        <section className='fdc flx1'>
                            <div className='fdr flx1 pb15'>
                                {
                                    files.map(n => (
                                        <div key={n.name} className='fdr aic fww pr15'>
                                            <InsertDriveFileIcon className='cp pr5' />
                                            <Typography className='w250' variant='body2'>
                                                {n.file?.name}
                                            </Typography>
                                            <IconButton size='small' disabled={disabled} value={n.name} onClick={this.onRemoveFile}>
                                                <DeleteIcon className='cpr' />
                                            </IconButton>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                !disabled &&
                                <div className='fdr flx1 p20 bw1d' {...getRootProps()}>
                                    <input disabled={disabled} multiple={false} {...getInputProps()} />
                                    <div className='fdr flx1 jcc'>
                                        <Typography className='cg0' variant='body2'>
                                            Click to Upload Files or Drag &amp; Drop Files Here
                                        </Typography>
                                    </div>
                                </div>
                            }
                        </section>
                    )}
                </Dropzone>
            </div>
        );
    }
}
