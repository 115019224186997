import { Icon, IconButton, Menu, MenuItem } from '@material-ui/core';
import React from 'react';

interface ISortButtonProps {
    alphabetical ?: boolean;
    date ?: boolean;

    onChange ?: (
        alphabetical : boolean,
        date : boolean,
    ) => void;
}

interface ISortButtonState {
    open : boolean;
}

export default class SortButton extends React.PureComponent<ISortButtonProps, ISortButtonState> {
    private readonly alphabeticalValue = 'alphabetical';
    private readonly dateValue = 'date';
    private readonly ref : React.RefObject<HTMLButtonElement>;
    constructor(props : ISortButtonProps) {
        super(props);
        this.state = {
            open: false,
        };
        this.ref = React.createRef();
    }

    private readonly onSortClick = () => {
        this.setState({
            open: !this.state.open,
        });
    }

    private readonly onMenuItemClick = (event : React.MouseEvent<HTMLLIElement>) => {
        const {
            alphabetical,
            date,
            onChange,
        } = this.props;

        if (!onChange) return;

        this.onMenuClose();

        let alphabeticalChange = !!alphabetical;
        let dateChange = !!date;

        switch (`${event.currentTarget.id}`) {
            case this.alphabeticalValue:
                alphabeticalChange = !alphabeticalChange;
                dateChange = false;
                break;
            case this.dateValue:
                dateChange = !dateChange;
                alphabeticalChange = false;
                break;
        }

        onChange(alphabeticalChange, dateChange);
    }

    private readonly onMenuClose = () => {
        this.setState({
            open: false,
        });
    };

    public readonly render = () => {
        const {
            alphabetical,
            date,
        } = this.props;
        const {
            open,
        } = this.state;

        return (
            <div>
                <IconButton
                    ref={this.ref}
                    onClick={this.onSortClick}
                >
                    <Icon className={alphabetical || date ? 'cfpd' : 'cfg2'}>
                        <svg xmlns='http://www.w3.org/2000/svg' height={23} viewBox='0 0 19 33.462'>
                            <g transform='translate(-1076 -126.539)'>
                                <text transform='translate(1076 148)' fontSize='14' fontFamily='Roboto-Bold, Roboto' fontWeight='700' letterSpacing='0.015em'><tspan x='0' y='0'>AZ</tspan></text>
                                <path d='M9,19.962,15.462,13.5l6.462,6.462Z' transform='translate(1069.539 113.039)' />
                                <path d='M9,13.5l6.462,6.462L21.923,13.5Z' transform='translate(1069.539 140.039)' />
                            </g>
                        </svg>
                    </Icon>
                </IconButton>
                <Menu
                    anchorEl={this.ref.current}
                    open={open}
                    onClose={this.onMenuClose}
                >
                    <MenuItem
                        id={this.alphabeticalValue}
                        selected={alphabetical}
                        onClick={this.onMenuItemClick}
                    >
                        Sort by A-Z
                    </MenuItem>
                    <MenuItem
                        id={this.dateValue}
                        selected={date}
                        onClick={this.onMenuItemClick}
                    >
                        Sort by date
                    </MenuItem>
                </Menu>
            </div>
        );
    };
}
