import React, { SyntheticEvent, ChangeEvent } from 'react';
import { IRole } from '../../../../@types/model/right';
import { addArrayElement, removeArrayElement } from '../../../../service/helper/functionHelperService';
import Dialog from '@material-ui/core/Dialog';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import RightsFunctions from '../../../../store/right/functions';
import RightsSelector from '../../custom/selector/rights/RightsSelector';

interface IRoleEditDialogProps {
    onClose : (event : SyntheticEvent<{}, Event>) => void;

    open : boolean;

    role? : IRole;

    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
}

interface IRoleEditDialogState {
    isLoading : boolean;
    selectedRightIds : Array<number>;

    code : string;
    name : string;
}

class RoleEditDialog extends React.Component<IRoleEditDialogProps, IRoleEditDialogState> {

    constructor(props : IRoleEditDialogProps) {
        super(props);

        this.state = {
            isLoading: false,
            code: !!!props.role ? '' : props.role.code,
            name: !!!props.role ? '' : props.role.name,
            selectedRightIds: [],
        };
    }

    public componentDidUpdate = (prevProps : IRoleEditDialogProps, prevState : IRoleEditDialogState) => {
        if (prevProps.role !== this.props.role) {
            this.setState({
                selectedRightIds: !!!this.props.role ? [] : this.props.role.roleRights.map(x => x.rightId),
                code: !!!this.props.role ? '' : this.props.role.code,
                name: !!!this.props.role ? '' : this.props.role.name,
            });
        }

    }

    public onSaveClick = (event : SyntheticEvent<{}, Event>) => {
        this.setState({
            isLoading: true,
        });

        this.save().then(() => {
            this.setState({
                isLoading: false,
            });

            this.props.onClose(event);
        }).catch(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    public onCloseClick = (event : SyntheticEvent<{}, Event>) => {
        this.props.onClose(event);
    }

    public save = () => {
        if (!!!this.state.code) return Promise.resolve();
        if (!!!this.state.name) return Promise.resolve();

        const role = Object.assign({}, this.props.role);

        role.code = this.state.code;
        role.name = this.state.name;

        role.roleRights = this.state.selectedRightIds.map(n => ({
            rightId: n,
            roleId: role.id,
        }));

        return RightsFunctions.saveRole(role);
    }

    private codeChanged = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            code: event.target.value,
        });
    }

    private nameChanged = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            name: event.target.value,
        });
    }

    private submitForm = async (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.onCloseClick(event);
    }

    private onRightSelected = (id : number, checked : boolean, children : Array<number>) => {
        let selectedRightIds = this.state.selectedRightIds.slice();

        if (checked && selectedRightIds.indexOf(id) === -1) {
            selectedRightIds = addArrayElement(selectedRightIds, id);
        } else if (!!!checked) {
            selectedRightIds = removeArrayElement(selectedRightIds, selectedRightIds.indexOf(id));

            children.forEach((x) => {
                selectedRightIds = removeArrayElement(selectedRightIds, selectedRightIds.indexOf(x));
            });
        }

        this.setState({
            selectedRightIds,
        });
    }

    public render() {
        const { isLoading, code, name, selectedRightIds } = this.state;
        const { open, role, onClose, transition } = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    aria-labelledby='bakkie-edit-dialog-title'
                    aria-describedby='bakkie-edit-dialog-description'
                    TransitionComponent={transition}
                    transitionDuration={500}
                    fullScreen
                    onClose={onClose}
                >
                    <AppBar className='posr'>
                        <Toolbar>
                            <IconButton color='inherit' onClick={this.onCloseClick} aria-label='Close'>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant='h6' color='inherit' className='flx1'>
                                {!!!role ? 'CREATE ROLE' : 'EDIT ROLE'}
                            </Typography>
                            <Button color='inherit' disabled={isLoading} onClick={this.onSaveClick}>
                                {!!!isLoading && <SaveIcon style={{ marginRight: '10px' }}></SaveIcon>}
                                {!!isLoading && <CircularProgress color='secondary' size={24} style={{ marginRight: '10px' }}></CircularProgress>}
                                Save
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <div className={'fdc ais'}>
                            <form autoComplete='off' onSubmit={this.submitForm} style={{ paddingLeft: '25px' }}>
                                <div style={{ paddingTop: '15px' }}>
                                    <div className={'fdr aifs'}>
                                        <div className={'aic p5 mb10 pr20'}>
                                            <FormControl>
                                                <TextField
                                                    autoComplete='off'
                                                    id='code'
                                                    label='Code'
                                                    value={code}
                                                    onChange={this.codeChanged}
                                                    margin='normal'
                                                />
                                            </FormControl>
                                        </div>
                                        <div className={'aic p5 mb10 pr20'}>
                                            <FormControl>
                                                <TextField
                                                    autoComplete='off'
                                                    id='name'
                                                    label='Name'
                                                    value={name}
                                                    onChange={this.nameChanged}
                                                    margin='normal'
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <RightsSelector selectedIds={selectedRightIds} onSelected={this.onRightSelected} />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default RoleEditDialog;
