import React from 'react';
import { createSelector } from 'reselect';
import { IAuthState } from '../../../store/auth/reducer';
import { IUserRight } from '../../../@types/model/right';
import { RIGHTS } from '../../../appConstants';
import NavMenuItem from '../../component/custom/listItem/NavMenuItem';

interface IObservationNavMenuProps {
    auth : IAuthState;
    onClick : (url : string) => void;
}

interface IObservationNavMenuState {}

export default class ObservationNavMenu extends React.PureComponent<IObservationNavMenuProps, IObservationNavMenuState> {
    constructor(props : IObservationNavMenuProps) {
        super(props);
        this.state = {};
    }
    public readonly getUserRights = (props : IObservationNavMenuProps) => props.auth.session?.user.userRights ?? [];
    public readonly getRights = createSelector(
        [this.getUserRights],
        (userRights : Array<IUserRight>) => {
            return userRights.find(n => n.rightId === RIGHTS.OBS && n.isActive);
        },
    );

    public readonly render = () => {
        const {
            onClick,
        } = this.props;

        const rights = this.getRights(this.props);

        return (
            !!rights &&
            <NavMenuItem userRight={rights}
                key={`${rights.right?.name}_Nav`}
                onClick={onClick}
                icon={`${ASSET_BASE}/assets/images/observation.png`}
                imgProps={{
                    className: 'h30',
                }}
            />
        );
    }
}
