import React from 'react';
import { EnumIncidentCourtCaseAccusedBailApplication, IIncident } from '../../../../@types/model/incident';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import IncidentCourtCaseDialog from '../dialog/edit/IncidentCourtCaseDialog';
import IncidentCourtCaseAccusedDeleteButton from '../../custom/button/IncidentCourtCaseAccusedDeleteButton';
import IncidentCourtCaseAccusedEditDialog from '../dialog/edit/IncidentCourtCaseAccusedEditDialog';

interface IIncidentCourtInfoViewProps {
    incident : IIncident;
    isEditAdmin : boolean;
    isSaving : boolean;
}

interface IIncidentCourtInfoViewState {}

export default class IncidentCourtInfoView extends React.PureComponent<IIncidentCourtInfoViewProps, IIncidentCourtInfoViewState> {
    constructor(props : IIncidentCourtInfoViewProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const {} = this.state;
        const { isEditAdmin, incident, isSaving } = this.props;
        return (
            <div className='flx1 fdr aifs pt20 pb20 bcg1'>
                <span className='flx1' />
                <Paper className='fdc flx4' elevation={3}>
                    <AppBar position='static' className='pbr bcpl' elevation={0}>
                        <Toolbar>
                            <Typography className='fwm fs18'>
                                Court Info
                            </Typography>
                            <span className='flx1' />
                            {
                                isEditAdmin &&
                                <div className='posa posr10'>
                                    <IncidentCourtCaseDialog maxWidth='md' fullWidth incident={incident} isLoading={isSaving}/>
                                </div>
                            }
                        </Toolbar>
                    </AppBar>
                    {
                        !incident.courtCase?.isActive &&
                        <div className='fdc pt10 mb10 aic jcc'>
                            <Typography className={'flx1 fs16 lh37 fwm'} variant='subtitle1'>
                                None
                            </Typography>
                        </div>
                    }
                    {
                        incident.courtCase?.isActive &&
                        <div className='fdc'>
                            <div className={'fdr pl10 pr10 pt10 mb10'}>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    CAS Number
                                </Typography>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Court Number
                                </Typography>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Court
                                </Typography>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Original Court Date
                                </Typography>
                            </div>
                            <div className={'fdr mb10 pl10 pr10'}>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.courtCase.caseNumber ?? 'None'}
                                </Typography>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.courtCase.courtNumber ?? 'None'}
                                </Typography>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.courtCase.court ?? 'None'}
                                </Typography>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {!incident.courtCase.originalCourtDate ? 'None' : moment.utc(incident.courtCase.originalCourtDate).local().format('YYYY-MM-DD')}
                                </Typography>
                            </div>
                            <div className={'fdr pl10 pr10 pt10 mb10'}>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Magistrate
                                </Typography>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Prosecutor
                                </Typography>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Case Status
                                </Typography>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Next Court Date
                                </Typography>
                            </div>
                            <div className={'fdr mb10 pl10 pr10'}>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.courtCase.magistrate ?? 'None'}
                                </Typography>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.courtCase.prosecutor ?? 'None'}
                                </Typography>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {incident.courtCase.statusDescription ?? 'None'}
                                </Typography>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {!incident.courtCase.nextCourtDate ? 'None' : moment.utc(incident.courtCase.nextCourtDate).local().format('YYYY-MM-DD')}
                                </Typography>
                            </div>
                            <div className={'fdr pl10 pr10 pt10 mb10'}>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Date of Verdict
                                </Typography>
                                <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                    Date of Sentence
                                </Typography>
                            </div>
                            <div className={'fdr mb10 pl10 pr10'}>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {!incident.courtCase.dateOfVerdict ? 'None' : moment.utc(incident.courtCase.dateOfVerdict).local().format('YYYY-MM-DD')}
                                </Typography>
                                <Typography className={'flx1 fs16 lh37 fwm bbw1 bocg1'} variant='subtitle1'>
                                    {!incident.courtCase.dateOfSentence ? 'None' : moment.utc(incident.courtCase.dateOfSentence).local().format('YYYY-MM-DD')}
                                </Typography>
                            </div>
                        </div>
                    }
                    {

                        incident.courtCase?.isActive &&
                        <AppBar position='static' className='bcpl' elevation={0}>
                            <Toolbar>
                                <Typography className='fwm fs18'>
                                    Accused
                                </Typography>
                                <span className='flx1' />
                                {
                                    isEditAdmin &&
                                    <div className='posa posr10'>
                                        <IncidentCourtCaseAccusedEditDialog index={-1} maxWidth='md' fullWidth incident={incident} isLoading={isSaving}/>
                                    </div>
                                }
                            </Toolbar>
                        </AppBar>
                    }
                    {
                        incident.courtCase?.isActive &&
                        incident.courtCase.incidentCourtCaseAccuseds?.map((n, i) => (
                            <div key={`suspect_${n.id}`} className='fdr bbw1 bocg1'>
                                <div className='fdc aic jcc ml15 mr15'>
                                    <Typography className='h42 w42 aic jcc bcp standard-box fs23 fwm cw brh' variant='subtitle1'>
                                        {i + 1}
                                    </Typography>
                                </div>
                                <div className='fdc flx1'>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                            Name
                                        </Typography>
                                        <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                            Legal Representative
                                        </Typography>
                                        <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                            Verdict
                                        </Typography>
                                        <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                            Bail
                                        </Typography>
                                        <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                            Charges
                                        </Typography>
                                    </div>
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                            {n.name} {n.surname}
                                        </Typography>
                                        <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                            {n.representative}
                                        </Typography>
                                        <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                            {n.verdictDescription}
                                        </Typography>
                                        <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                            {n.bailApplicationDescription}
                                        </Typography>
                                        <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                            {n.charges ?? '-'}
                                        </Typography>
                                    </div>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                            Sentence
                                        </Typography>
                                    </div>
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                            {n.sentence ?? '-'}
                                        </Typography>
                                    </div>
                                    {
                                        n.bailApplication === EnumIncidentCourtCaseAccusedBailApplication.Granted &&
                                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                                Amount
                                            </Typography>
                                            <Typography className={'flx3 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                                Conditions
                                            </Typography>
                                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                            </Typography>
                                        </div>
                                    }
                                    {
                                        n.bailApplication === EnumIncidentCourtCaseAccusedBailApplication.Granted &&
                                        <div className={'fdr mb10 pl10 pr10'}>
                                            <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                                R {n.bailAmount}
                                            </Typography>
                                            <Typography className={'flx3 aic fs16 lh37 fwm'} variant='subtitle1'>
                                                {n.bailConditions}
                                            </Typography>
                                            <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                            </Typography>
                                        </div>
                                    }
                                    {
                                        n.bailApplication === EnumIncidentCourtCaseAccusedBailApplication.NotGranted &&
                                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                                            <Typography className={'flx3 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                                Reason
                                            </Typography>
                                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                            </Typography>
                                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                            </Typography>
                                        </div>
                                    }
                                    {
                                        n.bailApplication === EnumIncidentCourtCaseAccusedBailApplication.NotGranted &&
                                        <div className={'fdr mb10 pl10 pr10'}>
                                            <Typography className={'flx3 aic fs16 lh37 fwm'} variant='subtitle1'>
                                                {n.bailRejectReason}
                                            </Typography>
                                            <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                            </Typography>
                                            <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                            </Typography>
                                        </div>
                                    }
                                </div>
                                <Typography className={'fdr w100 aic jcc fs16 lh37 fwm'} variant='subtitle1'>
                                    <IncidentCourtCaseAccusedEditDialog index={i} maxWidth='md' incident={incident} isLoading={isSaving} />
                                    <IncidentCourtCaseAccusedDeleteButton index={i} maxWidth='sm' incident={incident} isLoading={isSaving} />
                                </Typography>
                            </div>
                        ))
                    }
                </Paper>
                <span className='flx1' />
            </div>
        );
    }
}
