import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Checkbox, Divider, FormControlLabel, Icon, IconButton, InputAdornment, LinearProgress, List, ListItem, ListItemText, Paper, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IAccessPoint } from '../../../../@types/model/access';
import { IAccessPointCategory } from '../../../../@types/model/masterData/accessPointCategory';
import { IRootState } from '../../../../@types/redux';
import DataFunctions from '../../../../store/data/functions';
import AccessPointCategoryDropdown from '../autocomplete/AccessPointCategoryMultiDropdown';

interface IAccessPointSelectorProps {
    accessPoints : Array<IAccessPoint>;

    selectedAccessPoints : Array<number>;

    onSelectionChange : (accessPoints : Array<number>) => void;

    disabled? : boolean;
}

interface IAccessPointSelectorState {
    selectedFrom : Array<number>;
    selectedTo : Array<number>;

    fromSearchText : string;
    toSearchText : string;
    isLoading : boolean;

    fromIsControlled : boolean | null;
    fromIsIoT : boolean | null;
    fromCategories : Array<number>;

    toIsControlled : boolean | null;
    toIsIoT : boolean | null;
    toCategories : Array<number>;
}

class AccessPointSelectorComponent extends React.PureComponent<IAccessPointSelectorProps, IAccessPointSelectorState> {
    constructor(props : IAccessPointSelectorProps) {
        super(props);
        this.state = {
            selectedFrom: [],
            selectedTo: [],
            fromSearchText: '',
            toSearchText: '',
            isLoading: false,
            fromIsControlled: null,
            fromIsIoT: null,
            fromCategories: [],
            toIsControlled: null,
            toIsIoT: null,
            toCategories: [],
        };
    }

    public componentDidMount = () => {
        this.setState({
            isLoading: true,
        });
        this.loadData();
    }

    public loadData = async () => {
        await DataFunctions.getAllAccessPoints(true);

        this.setState({
            isLoading: false,
        });
    }

    private getAccessPoints = (props : IAccessPointSelectorProps) => props.accessPoints;
    private getSelectedAccessPoints = (props : IAccessPointSelectorProps) => props.selectedAccessPoints;
    private getFromSearchText = (props : IAccessPointSelectorProps, state : IAccessPointSelectorState) => state.fromSearchText;
    private getToSearchText = (props : IAccessPointSelectorProps, state : IAccessPointSelectorState) => state.toSearchText;
    private getFromIsControlled = (props : IAccessPointSelectorProps, state : IAccessPointSelectorState) => state.fromIsControlled;
    private getFromIsIoT = (props : IAccessPointSelectorProps, state : IAccessPointSelectorState) => state.fromIsIoT;
    private getFromCategories = (props : IAccessPointSelectorProps, state : IAccessPointSelectorState) => state.fromCategories;
    private getToIsControlled = (props : IAccessPointSelectorProps, state : IAccessPointSelectorState) => state.toIsControlled;
    private getToIsIoT = (props : IAccessPointSelectorProps, state : IAccessPointSelectorState) => state.toIsIoT;
    private getToCategories = (props : IAccessPointSelectorProps, state : IAccessPointSelectorState) => state.toCategories;

    private getActiveAccessPoint = createSelector(
        [this.getAccessPoints],
        (accessPoints) => {
            return accessPoints
                .filter(x => !!x.isActive);
        },
    );

    private getSelected = createSelector(
        [
            this.getActiveAccessPoint,
            this.getSelectedAccessPoints,
            this.getToSearchText,
            this.getToIsControlled,
            this.getToIsIoT,
            this.getToCategories,
        ],
        (
            accessPoints,
            selectedAccessPoints,
            toSearchText,
            toIsControlled,
            toIsIoT,
            toCategories,
        ) => {
            return accessPoints
                .filter(x => selectedAccessPoints.includes(x.id))
                .filter(x => x.code.toLowerCase().includes(toSearchText.toLowerCase()) ||
                            x.name.toLowerCase().includes(toSearchText.toLowerCase()) ||
                            x.division?.description.toLowerCase().includes(toSearchText.toLowerCase()))
                .filter(x => toIsControlled === null || x.isControlled === toIsControlled)
                .filter(x => toIsIoT === null || (!!x.controlIdentifier) === toIsIoT)
                .filter(x => !toCategories.length || x.accessPointCategories?.some(a => a.isActive &&  toCategories.includes(a.accessPointCategoryId)));
        },
    );

    private getNotSelected = createSelector(
        [
            this.getActiveAccessPoint,
            this.getSelectedAccessPoints,
            this.getFromSearchText,
            this.getFromIsControlled,
            this.getFromIsIoT,
            this.getFromCategories,
        ],
        (
            accessPoints,
            selectedAccessPoints,
            fromSearchText,
            fromIsControlled,
            fromIsIoT,
            fromCategories,
        ) => {
            return accessPoints
                .filter(x => !selectedAccessPoints.includes(x.id))
                .filter(x => x.code.toLowerCase().includes(fromSearchText.toLowerCase()) ||
                            x.name.toLowerCase().includes(fromSearchText.toLowerCase()) ||
                            x.division?.description.toLowerCase().includes(fromSearchText.toLowerCase()))
                .filter(x => fromIsControlled === null || x.isControlled === fromIsControlled)
                .filter(x => fromIsIoT === null || (!!x.controlIdentifier) === fromIsIoT)
                .filter(x => !fromCategories.length || x.accessPointCategories?.some(a => a.isActive && fromCategories.includes(a.accessPointCategoryId)));
        },
    );

    private readonly onFromClearClick = () => {
        this.setState({
            fromSearchText: '',
        });
    }

    private readonly onFromSearchChanged = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            fromSearchText: event.currentTarget.value,
        });
    }

    private readonly onToClearClick = () => {
        this.setState({
            toSearchText: '',
        });
    }

    private readonly onToSearchChanged = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            toSearchText: event.currentTarget.value,
        });
    }

    private readonly onToClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const selectedTo = this.state.selectedTo.slice();

        const index = selectedTo.indexOf(Number(event.currentTarget.id));
        if (index > -1) selectedTo.splice(index, 1);
        else selectedTo.push(Number(event.currentTarget.id));

        this.setState({
            selectedTo,
        });
    }

    private readonly onFromClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const selectedFrom = this.state.selectedFrom.slice();

        const index = selectedFrom.indexOf(Number(event.currentTarget.id));
        if (index > -1) selectedFrom.splice(index, 1);
        else selectedFrom.push(Number(event.currentTarget.id));

        this.setState({
            selectedFrom,
        });
    }

    private readonly onAddClick = () => {
        const selectedAccessPoints = this.props.selectedAccessPoints.slice();
        const selectedFrom = this.state.selectedFrom.slice();

        selectedFrom.forEach((n) => {
            const index = selectedAccessPoints.indexOf(n);
            if (index === -1) selectedAccessPoints.push(n);
        });

        this.props.onSelectionChange(selectedAccessPoints);
        this.setState({
            selectedFrom: [],
        });
    }

    private readonly onRemoveClick = () => {
        const selectedAccessPoints = this.props.selectedAccessPoints.slice();
        const selectedTo = this.state.selectedTo.slice();

        selectedTo.forEach((n) => {
            const index = selectedAccessPoints.indexOf(n);
            if (index > -1) selectedAccessPoints.splice(index, 1);
        });

        this.props.onSelectionChange(selectedAccessPoints);
        this.setState({
            selectedTo: [],
        });
    }

    private onFromIsControlledChanged = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            fromIsControlled: checked,
            selectedFrom: [],
        });
    }

    private onFromIsIoTChanged = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            fromIsIoT: checked,
            selectedFrom: [],
        });
    }

    private onFromAccessPointCategoriesChange = (value? : Array<IAccessPointCategory>) => {
        this.setState({
            fromCategories: value?.map(x => x.id) ?? [],
            selectedFrom: [],
        });
    }

    private onFromClearSearch = () => {
        this.setState({
            fromIsControlled: null,
            fromIsIoT: null,
            fromCategories: [],
            selectedFrom: [],
        });
    }

    private onFromSelectAll = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        if (checked) {
            const notSelected = this.getNotSelected(this.props, this.state);
            this.setState({
                selectedFrom: notSelected.map(x => x.id),
            });
        } else {
            this.setState({
                selectedFrom: [],
            });
        }
    }

    private onToIsControlledChanged = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            toIsControlled: checked,
            selectedTo: [],
        });
    }

    private onToIsIoTChanged = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            toIsIoT: checked,
            selectedTo: [],
        });
    }

    private onToAccessPointCategoriesChange = (value? : Array<IAccessPointCategory>) => {
        this.setState({
            toCategories: value?.map(x => x.id) ?? [],
            selectedTo: [],
        });
    }

    private onToClearSearch = () => {
        this.setState({
            toIsControlled: null,
            toIsIoT: null,
            toCategories: [],
            selectedTo: [],
        });
    }

    private onToSelectAll = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        if (checked) {
            const selected = this.getSelected(this.props, this.state);
            this.setState({
                selectedTo: selected.map(x => x.id),
            });
        } else {
            this.setState({
                selectedTo: [],
            });
        }
    }

    public render = () => {
        const { toSearchText, selectedFrom, fromSearchText, selectedTo, isLoading,
            fromIsControlled, fromIsIoT, fromCategories,
            toIsControlled, toIsIoT, toCategories } = this.state;

        const selected = this.getSelected(this.props, this.state);
        const notSelected = this.getNotSelected(this.props, this.state);

        const disabled = this.props.disabled || isLoading;
        return (
            <div className='fdc hfill'>
                <div className='fdr hfill'>
                    <div className='fdc flx3'>
                        <div className='fdc flx1'>
                            <div className='fdc mt10 mb15'>
                                <Typography className='cpd fs20 fwb'>Not Allowed Access</Typography>
                            </div>
                            <div className='fdc mt10 mb15'>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        ADVANCED FILTER
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='fdc flx1'>
                                            <div className={'fdr aifs'}>
                                                <div className={'flx1 ais p5 mb10 pr20'}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color='primary'
                                                                indeterminate={fromIsControlled == null}
                                                                checked={!!fromIsControlled}
                                                                onChange={this.onFromIsControlledChanged}
                                                            />
                                                        }
                                                        label='Guarded?'
                                                    />
                                                </div>
                                                <div className={'flx1 ais p5 mb10 pr20'}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color='primary'
                                                                indeterminate={fromIsIoT == null}
                                                                checked={!!fromIsIoT}
                                                                onChange={this.onFromIsIoTChanged}
                                                            />
                                                        }
                                                        label='IoT?'
                                                    />
                                                </div>
                                            </div>
                                            <div className={'fdr aifs'}>
                                                <AccessPointCategoryDropdown
                                                    fullWidth
                                                    value={fromCategories}
                                                    onChange={this.onFromAccessPointCategoriesChange} label='Categories'
                                                />
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <Button style={{ backgroundColor: orange[600] }} color='primary' variant='contained' onClick={this.onFromClearSearch}>
                                            <Icon style={{ marginRight: 10 }}>highlight_off</Icon>
                                            Clear
                                        </Button>
                                    </AccordionActions>
                                </Accordion>
                            </div>
                            <Paper className='fdc flx1'>
                                <Toolbar className={'fdr aic'}>
                                    <div className={'flx1 aic jcc'}>
                                        <Tooltip title='Select All'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color='primary'
                                                        indeterminate={!!selectedFrom.length && selectedFrom.length !== notSelected.length}
                                                        checked={!!selectedFrom.length && selectedFrom.length === notSelected.length}
                                                        onChange={this.onFromSelectAll}
                                                    />
                                                }
                                                label=''
                                            />
                                        </Tooltip>
                                    </div>
                                    <TextField
                                        fullWidth
                                        value={fromSearchText}
                                        onChange={this.onFromSearchChanged}
                                        placeholder='Search'
                                        margin='dense'
                                        disabled={disabled}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <Icon>search</Icon>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <Tooltip title='Clear'>
                                                    <IconButton onClick={this.onFromClearClick}>
                                                        <Icon className='cr'>close</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            ),
                                        }}
                                    />
                                </Toolbar>
                                <Divider />
                                <List className={'fdc hfill oya'}>
                                    <div className='mnh4'>
                                        {
                                            isLoading &&
                                            <LinearProgress />
                                        }
                                    </div>
                                    {
                                        notSelected.map(n => (
                                            <ListItem
                                                button
                                                key={n.id}
                                                id={`${n.id}`}
                                                onClick={this.onFromClick}
                                                selected={selectedFrom.includes(n.id)}
                                                disabled={disabled}
                                            >
                                                <ListItemText
                                                    color='primary'
                                                    primary={n.code}
                                                    secondary={`${n.name} - ${n.division?.description.toTitleCase() ?? ''}`}
                                                />
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Paper>
                        </div>
                    </div>
                    <div className='fdc flx1 ais jcc ml15 mr15'>
                        <div className='fdr mb5'>
                            <Button
                                variant='contained'
                                color='primary'
                                className='flx1'
                                onClick={this.onAddClick}
                                disabled={disabled}
                            >
                                {
                                    '>>'
                                }
                            </Button>
                        </div>
                        <div className='fdr mt5'>
                            <Button
                                variant='contained'
                                color='primary'
                                className='flx1'
                                onClick={this.onRemoveClick}
                                disabled={disabled}
                            >
                                {
                                    '<<'
                                }
                            </Button>
                        </div>

                    </div>
                    <div className='fdc flx3'>
                        <div className='fdc flx1 '>
                            <div className='fdc mt10 mb15'>
                                <Typography className='cpd fs20 fwb'>Allowed Access</Typography>
                            </div>
                            <div className='fdc mt10 mb15'>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        ADVANCED FILTER
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='fdc flx1'>
                                            <div className={'fdr aifs'}>
                                                <div className={'flx1 ais p5 mb10 pr20'}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color='primary'
                                                                indeterminate={toIsControlled == null}
                                                                checked={!!toIsControlled}
                                                                onChange={this.onToIsControlledChanged}
                                                            />
                                                        }
                                                        label='Guarded?'
                                                    />
                                                </div>
                                                <div className={'flx1 ais p5 mb10 pr20'}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color='primary'
                                                                indeterminate={toIsIoT == null}
                                                                checked={!!toIsIoT}
                                                                onChange={this.onToIsIoTChanged}
                                                            />
                                                        }
                                                        label='IoT?'
                                                    />
                                                </div>
                                            </div>
                                            <div className={'fdr aifs'}>
                                                <AccessPointCategoryDropdown
                                                    fullWidth
                                                    value={toCategories}
                                                    onChange={this.onToAccessPointCategoriesChange} label='Categories'
                                                />
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <Button
                                            style={{
                                                backgroundColor: orange[600],
                                            }}
                                            color='primary'
                                            variant='contained'
                                            onClick={this.onToClearSearch}
                                        >
                                            <Icon style={{ marginRight: 10 }}>highlight_off</Icon>
                                            Clear
                                        </Button>
                                    </AccordionActions>
                                </Accordion>
                            </div>
                            <Paper className='fdc flx1'>
                                <Toolbar className={'fdr aic'}>
                                    <div className={'flx1 aic jcc'}>
                                        <Tooltip title='Select All'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color='primary'
                                                        indeterminate={!!selectedTo.length && selectedTo.length !== selected.length}
                                                        checked={!!selectedTo.length && selectedTo.length === selected.length}
                                                        onChange={this.onToSelectAll}
                                                    />
                                                }
                                                label=''
                                            />
                                        </Tooltip>
                                    </div>
                                    <TextField
                                        fullWidth
                                        value={toSearchText}
                                        onChange={this.onToSearchChanged}
                                        disabled={disabled}
                                        placeholder='Search'
                                        margin='dense'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <Icon>search</Icon>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <Tooltip title='Clear'>
                                                    <IconButton onClick={this.onToClearClick}>
                                                        <Icon className='cr'>close</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            ),
                                        }}
                                    />
                                </Toolbar>
                                <Divider />
                                <List className={'fdc flx1 oya'}>
                                    <div className='mnh4'>
                                        {
                                            isLoading &&
                                            <LinearProgress />
                                        }
                                    </div>
                                    {
                                        selected.map(n => (
                                            <ListItem
                                                button
                                                key={n.id}
                                                id={`${n.id}`}
                                                onClick={this.onToClick}
                                                selected={selectedTo.includes(n.id)}
                                                disabled={disabled}
                                            >
                                                <ListItemText
                                                    color='primary'
                                                    primary={n.code}
                                                    secondary={`${n.name} - ${n.division?.description.toTitleCase() ?? ''}`}
                                                />
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        accessPoints: state.data.accessPoints,
    };
};

// tslint:disable-next-line: variable-name
const AccessPointSelector = connect(
    mapStateToProps,
)(AccessPointSelectorComponent);

export default AccessPointSelector;
