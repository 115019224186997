import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Checkbox, Divider, FormControlLabel, Icon, IconButton, InputAdornment, LinearProgress, List, ListItem, ListItemText, Paper, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IDivision } from '../../../../@types/model/division';
import { IDivisionType } from '../../../../@types/model/masterData/divisionType';
import { IRegion } from '../../../../@types/model/region';
import { IRootState } from '../../../../@types/redux';
import MasterDataFunctions from '../../../../store/masterData/functions';
import DivisionTypeDropdown from '../autocomplete/DivisionTypeMultiDropdown';
import RegionMultiDropdown from '../autocomplete/RegionMultiDropdown';
import RegionDropdown from '../dropdowns/RegionDropdown';

interface IDivisionSelectorProps {
    divisions : Array<IDivision>;

    selectedDivisions : Array<string>;
    isLoading : boolean;

    onSelectionChange : (divisions : Array<string>) => void;

    disabled? : boolean;
}

interface IDivisionSelectorState {
    selectedFrom : Array<string>;
    selectedTo : Array<string>;

    fromSearchText : string;
    fromTypes : Array<number>;
    fromRegions : Array<number>;

    toSearchText : string;
    toTypes : Array<number>;
    toRegions : Array<number>;
}

class DivisionSelectorComponent extends React.PureComponent<IDivisionSelectorProps, IDivisionSelectorState> {
    constructor(props : IDivisionSelectorProps) {
        super(props);
        this.state = {
            selectedFrom: [],
            selectedTo: [],
            fromSearchText: '',
            fromTypes: [],
            fromRegions: [],
            toSearchText: '',
            toTypes: [],
            toRegions: [],
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        await MasterDataFunctions.getAllDivisions();
    }

    private getDivisions = (props : IDivisionSelectorProps) => props.divisions;
    private getSelectedDivisions = (props : IDivisionSelectorProps) => props.selectedDivisions;
    private getFromSearchText = (props : IDivisionSelectorProps, state : IDivisionSelectorState) => state.fromSearchText;
    private getFromTypes = (props : IDivisionSelectorProps, state : IDivisionSelectorState) => state.fromTypes;
    private getFromRegions = (props : IDivisionSelectorProps, state : IDivisionSelectorState) => state.fromRegions;
    private getToSearchText = (props : IDivisionSelectorProps, state : IDivisionSelectorState) => state.toSearchText;
    private getToTypes = (props : IDivisionSelectorProps, state : IDivisionSelectorState) => state.toTypes;
    private getToRegions = (props : IDivisionSelectorProps, state : IDivisionSelectorState) => state.toRegions;

    private getActiveDivision = createSelector(
        [this.getDivisions],
        (divisions) => {
            return divisions
                .filter(x => !!x.isActive);
        },
    );

    private getSelected = createSelector(
        [
            this.getActiveDivision,
            this.getSelectedDivisions,
            this.getToSearchText,
            this.getToTypes,
            this.getToRegions,
        ],
        (
            divisions,
            selectedDivisions,
            toSearchText,
            toTypes,
            toRegions,
        ) => {
            return divisions
                .filter(x => selectedDivisions.includes(x.gl))
                .filter(x => x.code.toLowerCase().includes(toSearchText.toLowerCase()) ||
                            x.description.toLowerCase().includes(toSearchText.toLowerCase()) ||
                            x.gl.toLowerCase().includes(toSearchText.toLowerCase()))
                .filter(x => !toTypes.length || x.divisionTypes?.some(a => a.isActive &&  toTypes.includes(a.divisionTypeId)))
                .filter(x => !toRegions.length || (x.regionId && toRegions.includes(x.regionId)));
        },
    );

    private getNotSelected = createSelector(
        [
            this.getActiveDivision,
            this.getSelectedDivisions,
            this.getFromSearchText,
            this.getFromTypes,
            this.getFromRegions,
        ],
        (
            divisions,
            selectedDivisions,
            fromSearchText,
            fromTypes,
            fromRegions,
        ) => {
            return divisions
                .filter(x => !selectedDivisions.includes(x.gl))
                .filter(x => x.code.toLowerCase().includes(fromSearchText.toLowerCase()) ||
                            x.description.toLowerCase().includes(fromSearchText.toLowerCase()) ||
                            x.gl.toLowerCase().includes(fromSearchText.toLowerCase()))
                .filter(x => !fromTypes.length || x.divisionTypes?.some(a => a.isActive &&  fromTypes.includes(a.divisionTypeId)))
                .filter(x => !fromRegions.length || (x.regionId && fromRegions.includes(x.regionId)));
        },
    );

    private readonly onFromClearClick = () => {
        this.setState({
            fromSearchText: '',
        });
    }

    private readonly onFromSearchChanged = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            fromSearchText: event.currentTarget.value,
        });
    }

    private readonly onToClearClick = () => {
        this.setState({
            toSearchText: '',
        });
    }

    private readonly onToSearchChanged = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            toSearchText: event.currentTarget.value,
        });
    }

    private readonly onToClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const selectedTo = this.state.selectedTo.slice();

        const index = selectedTo.indexOf(event.currentTarget.id);
        if (index > -1) selectedTo.splice(index, 1);
        else selectedTo.push(event.currentTarget.id);

        this.setState({
            selectedTo,
        });
    }

    private readonly onFromClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const selectedFrom = this.state.selectedFrom.slice();

        const index = selectedFrom.indexOf(event.currentTarget.id);
        if (index > -1) selectedFrom.splice(index, 1);
        else selectedFrom.push(event.currentTarget.id);

        this.setState({
            selectedFrom,
        });
    }

    private readonly onAddClick = () => {
        const selectedDivisions = this.props.selectedDivisions.slice();
        const selectedFrom = this.state.selectedFrom.slice();

        selectedFrom.forEach((n) => {
            const index = selectedDivisions.indexOf(n);
            if (index === -1) selectedDivisions.push(n);
        });

        this.props.onSelectionChange(selectedDivisions);
        this.setState({
            selectedFrom: [],
        });
    }

    private readonly onRemoveClick = () => {
        const selectedDivisions = this.props.selectedDivisions.slice();
        const selectedTo = this.state.selectedTo.slice();

        selectedTo.forEach((n) => {
            const index = selectedDivisions.indexOf(n);
            if (index > -1) selectedDivisions.splice(index, 1);
        });

        this.props.onSelectionChange(selectedDivisions);
        this.setState({
            selectedTo: [],
        });
    }

    private onFromTypesChange = (value? : Array<IDivisionType>) => {
        this.setState({
            fromTypes: value?.map(x => x.id) ?? [],
            selectedFrom: [],
        });
    }

    private onFromRegionsChange = (value? : Array<IRegion>) => {
        this.setState({
            fromRegions: value?.map(x => x.id) ?? [],
            selectedFrom: [],
        });
    }

    private onFromClearSearch = () => {
        this.setState({
            fromTypes: [],
            fromRegions: [],
            selectedFrom: [],
        });
    }

    private onFromSelectAll = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        if (checked) {
            const notSelected = this.getNotSelected(this.props, this.state);
            this.setState({
                selectedFrom: notSelected.map(x => x.gl),
            });
        } else {
            this.setState({
                selectedFrom: [],
            });
        }
    }

    private onToTypesChange = (value? : Array<IDivisionType>) => {
        this.setState({
            toTypes: value?.map(x => x.id) ?? [],
            selectedTo: [],
        });
    }

    private onToRegionsChange = (value? : Array<IRegion>) => {
        this.setState({
            toRegions: value?.map(x => x.id) ?? [],
            selectedTo: [],
        });
    }

    private onToClearSearch = () => {
        this.setState({
            toTypes: [],
            toRegions: [],
            selectedTo: [],
        });
    }

    private onToSelectAll = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        if (checked) {
            const selected = this.getSelected(this.props, this.state);
            this.setState({
                selectedTo: selected.map(x => x.gl),
            });
        } else {
            this.setState({
                selectedTo: [],
            });
        }
    }

    public render = () => {
        const { toSearchText, selectedFrom, fromSearchText, selectedTo,
            fromTypes, toTypes, fromRegions, toRegions } = this.state;
        const { isLoading } = this.props;

        const selected = this.getSelected(this.props, this.state);
        const notSelected = this.getNotSelected(this.props, this.state);

        const disabled = this.props.disabled || isLoading;
        return (
            <div className='fdc hfill'>
                <div className='fdr hfill'>
                    <div className='fdc flx3'>
                        <div className='fdc flx1 '>
                            <div className='fdc mt10 mb15'>
                                <Typography className='cpd fs20 fwb'>Unassigned Divisions</Typography>
                            </div>
                            <div className='fdc mt10 mb15'>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        ADVANCED FILTER
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='fdc flx1'>
                                            <div className={'fdr aifs'}>
                                                <DivisionTypeDropdown
                                                    fullWidth
                                                    value={fromTypes}
                                                    onChange={this.onFromTypesChange} label='Types'
                                                />
                                            </div>
                                            <div className={'fdr aifs mt10'}>
                                                <RegionMultiDropdown
                                                    fullWidth
                                                    value={fromRegions}
                                                    onChange={this.onFromRegionsChange} label='Regions'
                                                />
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <Button style={{ backgroundColor: orange[600] }} color='primary' variant='contained' onClick={this.onFromClearSearch}>
                                            <Icon style={{ marginRight: 10 }}>highlight_off</Icon>
                                            Clear
                                        </Button>
                                    </AccordionActions>
                                </Accordion>
                            </div>
                            <Paper className='fdc flx1'>
                                <Toolbar className={'fdr aic'}>
                                    <div className={'flx1 aic jcc'}>
                                        <Tooltip title='Select All'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color='primary'
                                                        indeterminate={!!selectedFrom.length && selectedFrom.length !== notSelected.length}
                                                        checked={!!selectedFrom.length && selectedFrom.length === notSelected.length}
                                                        onChange={this.onFromSelectAll}
                                                    />
                                                }
                                                label=''
                                            />
                                        </Tooltip>
                                    </div>
                                    <TextField
                                        fullWidth
                                        value={fromSearchText}
                                        onChange={this.onFromSearchChanged}
                                        placeholder='Search'
                                        margin='dense'
                                        disabled={disabled}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <Icon>search</Icon>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <Tooltip title='Clear'>
                                                    <IconButton onClick={this.onFromClearClick}>
                                                        <Icon className='cr'>close</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            ),
                                        }}
                                    />
                                </Toolbar>
                                <Divider />
                                <List className={'fdc flx1 oya'}>
                                    <div className='mnh4'>
                                        {
                                            isLoading &&
                                            <LinearProgress />
                                        }
                                    </div>
                                    {
                                        notSelected.map(n => (
                                            <ListItem
                                                button
                                                key={n.id}
                                                id={n.gl}
                                                onClick={this.onFromClick}
                                                selected={selectedFrom.includes(n.gl)}
                                                disabled={disabled}
                                            >
                                                <ListItemText
                                                    color='primary'
                                                    primary={n.code}
                                                    secondary={`${n.gl} - ${n.description}`}
                                                />
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Paper>
                        </div>
                    </div>
                    <div className='fdc flx1 ais jcc ml15 mr15'>
                        <div className='fdr mb5'>
                            <Button
                                variant='contained'
                                color='primary'
                                className='flx1'
                                onClick={this.onAddClick}
                                disabled={disabled}
                            >
                                {
                                    '>>'
                                }
                            </Button>
                        </div>
                        <div className='fdr mt5'>
                            <Button
                                variant='contained'
                                color='primary'
                                className='flx1'
                                onClick={this.onRemoveClick}
                                disabled={disabled}
                            >
                                {
                                    '<<'
                                }
                            </Button>
                        </div>

                    </div>
                    <div className='fdc flx3 hfill'>
                        <div className='fdc flx1 '>
                            <div className='fdc mt10 mb15'>
                                <Typography className='cpd fs20 fwb'>Assigned Divisions</Typography>
                            </div>
                            <div className='fdc mt10 mb15'>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        ADVANCED FILTER
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='fdc flx1'>
                                            <div className={'fdr aifs'}>
                                                <DivisionTypeDropdown
                                                    fullWidth
                                                    value={toTypes}
                                                    onChange={this.onToTypesChange} label='Types'
                                                />
                                            </div>
                                            <div className={'fdr aifs mt10'}>
                                                <RegionMultiDropdown
                                                    fullWidth
                                                    value={toRegions}
                                                    onChange={this.onToRegionsChange} label='Regions'
                                                />
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <Button style={{ backgroundColor: orange[600] }} color='primary' variant='contained' onClick={this.onToClearSearch}>
                                            <Icon style={{ marginRight: 10 }}>highlight_off</Icon>
                                            Clear
                                        </Button>
                                    </AccordionActions>
                                </Accordion>
                            </div>
                            <Paper className='fdc flx1'>
                                <Toolbar className={'fdr aic'}>
                                    <div className={'flx1 aic jcc'}>
                                        <Tooltip title='Select All'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color='primary'
                                                        indeterminate={!!selectedTo.length && selectedTo.length !== selected.length}
                                                        checked={!!selectedTo.length && selectedTo.length === selected.length}
                                                        onChange={this.onToSelectAll}
                                                    />
                                                }
                                                label=''
                                            />
                                        </Tooltip>
                                    </div>
                                    <TextField
                                        fullWidth
                                        value={toSearchText}
                                        onChange={this.onToSearchChanged}
                                        disabled={disabled}
                                        placeholder='Search'
                                        margin='dense'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <Icon>search</Icon>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <Tooltip title='Clear'>
                                                    <IconButton onClick={this.onToClearClick}>
                                                        <Icon className='cr'>close</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            ),
                                        }}
                                    />
                                </Toolbar>
                                <Divider />
                                <List className={'fdc flx1 oya'}>
                                    <div className='mnh4'>
                                        {
                                            isLoading &&
                                            <LinearProgress />
                                        }
                                    </div>
                                    {
                                        selected.map(n => (
                                            <ListItem
                                                button
                                                key={n.id}
                                                id={n.gl}
                                                onClick={this.onToClick}
                                                selected={selectedTo.includes(n.gl)}
                                                disabled={disabled}
                                            >
                                                <ListItemText
                                                    color='primary'
                                                    primary={n.code}
                                                    secondary={`${n.gl} - ${n.description}`}
                                                />
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        divisions: state.masterData.divisions,
        isLoading: state.masterData.isLoadingDivisions,
    };
};

// tslint:disable-next-line: variable-name
const DivisionSelector = connect(
    mapStateToProps,
)(DivisionSelectorComponent);

export default DivisionSelector;
