import { IFileQueue } from '../../@types/viewmodel/file';
import { createAction } from 'typesafe-actions';

const fileActions = {
    setFiles: createAction('FILE_SET_FILES', resolve => (files : Array<IFileQueue>) => resolve(files)),
    setProgress: createAction('FILE_SET_PROGRESS', resolve => (progress : number) => resolve(progress)),
    setCurrent: createAction('FILE_SET_CURRENT', resolve => (current? : string) => resolve(current)),
};

export default fileActions;
