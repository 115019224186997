import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { IGraveSite } from '../../../../@types/model/graveSite/graveSite';
import { Transitions } from '../../custom/animations/Transitions';
import GraveSiteSummaryView from '../views/GraveSiteSummaryView';
import GraveSiteGraveView from '../views/GraveSiteGraveView';
import GraveSiteImagesView from '../views/GraveSiteImagesView';
import GraveSiteVisitsView from '../views/GraveSiteVisitsView';
import TabViews from '../../custom/tab/TabViews';
import PrimaryTabs from '../../custom/tab/PrimaryTabs';
import PrimaryTab from '../../custom/tab/PrimaryTab';
import { CircularProgress, Typography } from '@material-ui/core';

interface IGraveSiteInfoDialogProps {
    graveSite : IGraveSite;

    isGraveSiteAdmin : boolean;

    isLoading ?: boolean;
}

interface IGraveSiteInfoDialogState {
    open : boolean;
    tabIndex : number;
}

export default class GraveSiteInfoDialog extends React.PureComponent<IGraveSiteInfoDialogProps, IGraveSiteInfoDialogState> {
    constructor(props : IGraveSiteInfoDialogProps) {
        super(props);
        this.state = {
            open: false,
            tabIndex: 0,
        };
    }

    public componentDidUpdate = (prevProps : IGraveSiteInfoDialogProps) => {
        if (prevProps.graveSite?.guid !== this.props.graveSite?.guid) {
            this.setState({
                tabIndex: 0,
            });
        }
    }

    public onClick = () => {
        this.setState({
            open: true,
        });
    }

    public onClose = () => {
        this.setState({
            open: false,
        });
    }

    private onTabChange = (event : React.ChangeEvent<{}>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    }

    public render = () => {
        const { graveSite, isGraveSiteAdmin, isLoading } = this.props;
        const { open, tabIndex } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Info'>
                    <div>
                        <IconButton color='primary' onClick={this.onClick} aria-label='Info'>
                            <Icon>info_outlined</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={Transitions.Up}
                    transitionDuration={500}
                    onClose={this.onClose}
                    fullScreen
                    >
                    <AppBar className='fdc posr ' position='static'>
                        <Toolbar className={'fdr flx1 jcc'}>
                            {
                                graveSite &&
                                <Typography variant='h6' color='inherit' className='flx1 fwb fs22'>
                                    GRAVESITE - {graveSite.number}
                                </Typography>
                            }
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                        <PrimaryTabs value={tabIndex} onChange={this.onTabChange} aria-label='Info Tabs'>
                            <PrimaryTab label='SUMMARY' value={0} />
                            <PrimaryTab label='GRAVES' value={1} />
                            <PrimaryTab label='IMAGES OF GRAVES' value={2} />
                            <PrimaryTab label='VISITS' value={3} />
                        </PrimaryTabs>
                    </AppBar>
                    <DialogContent className='fdc flx1 hfill p0'>
                            {
                                !isLoading &&
                                <div className='fdc flx1'>
                                    {
                                        graveSite &&
                                        <TabViews index={tabIndex}>
                                            <GraveSiteSummaryView graveSite={graveSite} isGraveSiteAdmin={isGraveSiteAdmin} />
                                            <GraveSiteGraveView isGraveSiteAdmin={isGraveSiteAdmin} graveSite={graveSite} />
                                            <GraveSiteImagesView graves={graveSite.graves}/>
                                            <GraveSiteVisitsView graveSite={graveSite} />
                                        </TabViews>
                                    }
                                </div>
                            }
                            {
                                isLoading &&
                                <div className='fdc aic jcc flx1'>
                                    <CircularProgress />
                                </div>
                            }
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}
