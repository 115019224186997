import React from 'react';
import { createSelector } from 'reselect';
import { IAuthState } from '../../../store/auth/reducer';
import { EnumRightType, IUserRight } from '../../../@types/model/right';
import NavMenuItem from '../../component/custom/listItem/NavMenuItem';

interface IGraveSiteNavMenuProps {
    auth : IAuthState;
    onClick : (url : string) => void;
}

interface IGraveSiteNavMenuState {}

export default class GraveSiteNavMenu extends React.PureComponent<IGraveSiteNavMenuProps, IGraveSiteNavMenuState> {
    constructor(props : IGraveSiteNavMenuProps) {
        super(props);
        this.state = {};
    }
    public readonly getUserRights = (props : IGraveSiteNavMenuProps) => props.auth.session?.user.userRights ?? [];
    public readonly getRights = createSelector(
        [this.getUserRights],
        (userRights : Array<IUserRight>) => {
            return userRights.find(n => (
                n.right?.type === EnumRightType.GraveSite
                && !n.right?.parentId
                && n.isActive
                ));
        },
    );

    public readonly render = () => {
        const {
            onClick,
        } = this.props;

        const rights = this.getRights(this.props);

        return (
            !!rights &&
            <NavMenuItem userRight={rights}
                key={`${rights.right?.name}_Nav`}
                onClick={onClick}
                icon={`${ASSET_BASE}/assets/images/icons/largeGrave.svg`}
                imgProps={{
                    className: 'h30',
                }}
            />
        );
    }
}
