import { CircularProgress, Paper, Zoom } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IMappedRight, IRight } from '../../../../../@types/model/right';
import { IRootState } from '../../../../../@types/redux';
import RightsFunctions from '../../../../../store/right/functions';
import RightChild from './RightChild';
import RightParent from './RightParent';

interface IRightsSelectorProps {
    rights : Array<IRight>;
    isLoading : boolean;
    selectedIds : Array<number>;

    onSelected : (id : number, checked : boolean, childrenIds? : Array<number>) => void;
}

interface IRightsSelectorState {
}

class RightsSelectorComponent extends React.Component<IRightsSelectorProps, IRightsSelectorState> {
    constructor(props : IRightsSelectorProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadRights();
    }

    public loadRights = () => {
        RightsFunctions.getAllRights(undefined, undefined, true);
    }

    private getChildrenIds = (right : IMappedRight) => {
        let ids : Array<number> = [];

        right.children.forEach((n) => {
            ids = ids.concat(this.getChildrenIds(n));
        });

        ids.push(right.id);

        return ids;
    }

    public onSelectionChanged = (right : IMappedRight, checked : boolean) => {
        const childrenIds : Array<number> = this.getChildrenIds(right);

        this.props.onSelected(right.id, checked, childrenIds);
    }

    private getRights = (props : IRightsSelectorProps) => props.rights;
    private getMappedRights = createSelector(
        [this.getRights],
        (rights) => {
            return RightsFunctions.mapRightsToMappedRights(rights);
        },
    );

    public render() {
        const { selectedIds, isLoading } = this.props;

        const mappedRights = this.getMappedRights(this.props);
        return (
            <Paper className={'fdc oya'}>
                {
                    !!isLoading &&
                    <div className='fdc aic jcc mnh450'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    mappedRights.map((n =>
                        <div key={`${n.code}_parent_div`} className={'fdr ais'} style={{ borderBottom: '1px solid lightgrey' }}>
                            <RightParent key={`${n.code}_parent_select`} right={n} selected={selectedIds.indexOf(n.id) > -1} onClicked={this.onSelectionChanged} />
                            <Zoom in={selectedIds.indexOf(n.id) > -1}>
                                <div className={'flx6 aic jcfs fdr fww'}>
                                    {
                                        n.children.map(o =>
                                            <RightChild key={`${o.code}_child_select`} right={o} selected={selectedIds.indexOf(o.id) > -1} onClicked={this.onSelectionChanged} />)
                                    }
                                </div>
                            </Zoom>
                        </div>
                    ))
                }
            </Paper>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        rights: state.rights.rights,
        isLoading: state.rights.isLoading,
    };
};

// tslint:disable-next-line: variable-name
const RightsSelector = connect(
    mapStateToProps,
)(RightsSelectorComponent);

export default RightsSelector;
