import { Tab, withStyles } from '@material-ui/core';

// tslint:disable-next-line: variable-name
const PrimaryTab = withStyles({
    selected: {
        color: '#FEDD31',
    },
})(Tab);

export default PrimaryTab;
