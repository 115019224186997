import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import lodash from 'lodash';
import { IIncidentGroup } from '../../../../@types/model/masterData/incidentGroup';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import DataFunctions from '../../../../store/data/functions';
import { LinearProgress } from '@material-ui/core';

interface IIncidentGroupDropdownProps {
    id? : string;
    value? : Array<string>;
    label? : string;

    onChange? : (value? : Array<IIncidentGroup>) => void;

    required? : boolean;
    clearable? : boolean;
    autoFocus? : boolean;
    fullWidth? : boolean;
    disabled? : boolean;

    incidentGroups : Array<IIncidentGroup>;
    isLoading : boolean;
}

interface IIncidentGroupDropdownState {
}

class IncidentCategoryGroupMultiDropdownComponent extends React.Component<IIncidentGroupDropdownProps, IIncidentGroupDropdownState> {
    constructor(props : IIncidentGroupDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = () => {
        DataFunctions.getAllIncidentGroups();
    }

    private onChange = (event : React.ChangeEvent<HTMLSelectElement>, value : Array<{
        label : string,
        value : string,
    }>) => {
        if (!this.props.onChange) return;

        const names = value.map(x => x.value);
        const result = this.props.incidentGroups
            .filter(group => names.includes(group.name));

        this.props.onChange(result);
    }

    private getIncidentGroups = (props : IIncidentGroupDropdownProps) => props.incidentGroups;
    private getValue = (props : IIncidentGroupDropdownProps) => props.value;

    private getActiveIncidentGroups = createSelector([
        this.getIncidentGroups,
    ], incidentGroups => incidentGroups.filter(x => x.isActive));

    private getDropdown = createSelector([
        this.getActiveIncidentGroups,
        this.getValue,
    ], (incidentGroups, value) => {
        const result = lodash.map(incidentGroups, (n, k) => ({ label: `${n.name}`, value: n.name }));

        if (!value) return result;

        value.forEach((n) => {
            if (!result.some(x => x.value === n)) {
                result.push({
                    label: n,
                    value: n,
                });
            }
        });

        return result;
    });

    private getSelectedValue = createSelector([
        this.getValue,
        this.getDropdown,
    ], (value, dropdown) => {
        if (!dropdown.length) return [];
        if (!value) return [];

        return value.map(n => ({
            label: n,
            value: n,
        }));
    });

    public render = () => {
        const { required,  fullWidth, disabled, id, clearable, label, isLoading } = this.props;

        const dropdown = this.getDropdown(this.props);

        const value = this.getSelectedValue(this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value.length} required={required}>
                <Autocomplete
                    multiple
                    openOnFocus
                    disableCloseOnSelect
                    disabled={disabled || isLoading}
                    id={id}
                    options={dropdown}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required && !clearable}
                    renderTags={s => (<span className='fs16 lh21 cpla lsp024'>{s.map(n => n.label).join(', ')}</span>)}
                    renderInput={params => <TextField maxRows={1} error={required && !value.length} {...params}
                        fullWidth={fullWidth} value={value.map(n => n.label).join(', ')} label={label ?? 'Incident  Group'} InputLabelProps={{
                            className: 'fs16 lh21 cpla lsp024',
                        }} />}
                />
                {
                    required && !value.length &&
                    <FormHelperText error>Required</FormHelperText>
                }
                {
                    isLoading &&
                    <LinearProgress className='wfill posb0 posa' />
                }
            </FormControl>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        incidentGroups: state.data.incidentGroups,
        isLoading: state.data.isLoadingIncidentGroups,
    };
};

// tslint:disable-next-line: variable-name
const IncidentCategoryGroupMultiDropdown = connect(
    mapStateToProps,
)(IncidentCategoryGroupMultiDropdownComponent);

export default IncidentCategoryGroupMultiDropdown;
