import axios from 'axios';
import { IIncidentGroup } from '../../../@types/model/masterData/incidentGroup';

export default class IncidentGroupHttpService {
    public static getAll(isActive? : boolean) {
        return axios.get<Array<IIncidentGroup>>(`${API_URL}/api/MasterData/IncidentGroup/GetAll`, {
            params : {
                isActive,
            },
        });
    }
    public static save(incidentCategory : IIncidentGroup) {
        return axios.post<IIncidentGroup>(`${API_URL}/api/MasterData/IncidentGroup/Save`, incidentCategory, {
            params : {
            },
        });
    }
}
