import React, { Fragment } from 'react';
import TableCell, { TableCellBaseProps } from '@material-ui/core/TableCell';
import './materialTable.css';
import MaterialTableHeader from './components/Header';
import lodash from 'lodash';
import { createSelector } from 'reselect';
import moment from 'moment';
import TableFunctions from './functions';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import TablePagination from '@material-ui/core/TablePagination';

export class MaterialTableColumn<T> {
    /**
     * Default: th.
     */
    public component? : React.ElementType<TableCellBaseProps> = 'th';
    public colSpan? : number;
    public align? : 'inherit' | 'left' | 'center' | 'right' | 'justify';
    public renderCell? : ((row : T) => React.ReactNode);
    public header? : string | JSX.Element;
    public width? : string | number | undefined;
    public paddingRight? : string | number | undefined;
    public enableSort? : boolean = false;
    public enableFilter? : boolean = false;
    public fieldType? : 'undefined' | 'object' | 'number' | 'bigint' | 'boolean' | 'string' | 'symbol';
    public field? : string;
    public fixed? : boolean = false;
    public filtered? : boolean = false;
    public filterRender? : () => JSX.Element;
}

interface IMaterialTableProps<T> {
    id : string;
    data : Array<T & { id ?: number }>;
    columns : Array<MaterialTableColumn<T>>;
    isLoading? : boolean;
    rowsPerPage? : number;
    rowsPerPageOptions? : Array<number>;

    selectable? : boolean;
    selectedRows? : Array<T>;
    onSelectionChanged? : (selectedRows : Array<T>) => void;

    onExportExcelClick? : () => void;
    onExportCsvClick? : () => void;

    childComponent? : ((row : T) => React.ReactNode);

    disablePagination? : boolean;

    externalSort? : boolean;
    onSortChange? : (sortList : Array<{ field : string; direction : 'asc' | 'desc' }>) => void;
}

interface IMaterialTableState {
    currentPage : number;
    rowsPerPage : number;
    rowsPerPageOptions : Array<number>;

    sortList : Array<{ field : string; direction : 'asc' | 'desc' }>;
    filterList : Array<{ field : string; value? : string }>;

    rowExpanded : Array<boolean>;
}

export default class MaterialTable<T> extends React.Component<IMaterialTableProps<T>, IMaterialTableState> {
    constructor(props : IMaterialTableProps<T>) {
        super(props);

        this.state = {
            currentPage: 0,
            rowsPerPage: !!!props.rowsPerPage ? 10 : props.rowsPerPage,
            sortList: [],
            filterList: [],
            rowsPerPageOptions: !!!props.rowsPerPageOptions || props.rowsPerPageOptions.length === 0 ? [10, 20, 30, 50, 100] : props.rowsPerPageOptions,
            rowExpanded: [],
        };
    }

    public componentDidUpdate = (prevProps : IMaterialTableProps<T>) => {
        if (prevProps.isLoading !== this.props.isLoading && this.props.onSelectionChanged) {
            this.props.onSelectionChanged([]);
        }
    }

    private handleChangePage = (event : any, page : number) => {
        this.setState({ currentPage: page });
    }

    private handleChangeRowsPerPage = (event : any) => {
        this.setState({ rowsPerPage: event.target.value });
    }

    private onDataSortChange = (field? : string, sort? : { field : string; direction : 'asc' | 'desc' }) => {
        if (!!!field) return;
        const sorts = this.state.sortList.slice();

        let sortList = [];
        if (!!!sort) {
            sortList = lodash.filter(sorts, n => n.field !== field);
        } else {
            const index = TableFunctions.getIndexOfArrayElement(sorts, sort, 'field');

            if (index > -1) {
                sortList = TableFunctions.setArrayElement(sorts, index, sort);
            } else {
                sortList = TableFunctions.addArrayElement(sorts, sort);
            }
        }

        this.setState({
            sortList,
        });

        if (this.props.externalSort && this.props.onSortChange) this.props.onSortChange(sortList);
    }

    private onFilterChange = (filterValue : { field : string; value? : string }) => {
        const filters = this.state.filterList.slice();

        if (!filterValue.value) {
            this.setState({
                filterList: lodash.filter(filters, n => n.field !== filterValue.field),
            });
        } else {
            const index = TableFunctions.getIndexOfArrayElement(filters, filterValue, 'field');

            if (index > -1) {
                this.setState({
                    filterList: TableFunctions.setArrayElement(filters, index, filterValue),
                });
            } else {
                this.setState({
                    filterList: TableFunctions.addArrayElement(filters, filterValue),
                });
            }
        }
    }

    private onAllSelected = () => {
        if (!!this.props.selectedRows && this.props.selectedRows.length !== this.props.data.length && !!this.props.onSelectionChanged) {
            this.props.onSelectionChanged(this.props.data);
        } else if (!!this.props.onSelectionChanged) {
            this.props.onSelectionChanged([]);
        }
    }

    private onRowSelected = (row : T) => {
        if (!!!this.props.onSelectionChanged) return;
        if (!!!this.props.selectedRows) return;

        const index = this.props.selectedRows.indexOf(row);

        if (index > -1) {
            this.props.onSelectionChanged(TableFunctions.removeArrayElement(this.props.selectedRows, index));
        } else {
            this.props.onSelectionChanged(TableFunctions.addArrayElement(this.props.selectedRows, row));
        }
    }

    public getData = (props : IMaterialTableProps<T>) => props.data;
    public getSortList = (props : IMaterialTableProps<T>, state : IMaterialTableState) => state.sortList;
    public getFilterList = (props : IMaterialTableProps<T>, state : IMaterialTableState) => state.filterList;
    public getRow = (props : IMaterialTableProps<T>, selected : T) => selected;
    public getSelectedList = (props : IMaterialTableProps<T>) => props.selectedRows;
    public getDisablePagination = (props : IMaterialTableProps<T>) => props.disablePagination;
    public getCurrentPage = (props : IMaterialTableProps<T>, state : IMaterialTableState) => state.currentPage;
    public getRowsPerPage = (props : IMaterialTableProps<T>, state : IMaterialTableState) => state.rowsPerPage;

    public getFilteredOrderedData = createSelector(
        [this.getData, this.getSortList, this.getFilterList],
        (data, sortList, filterList) => {
            const list = lodash.orderBy(data, sortList.map(n => n.field), sortList.map(n => n.direction)).filter((x) => {
                return filterList.every((y) => {
                    const value = TableFunctions.getField(x, y.field);

                    // TODO: Add type filtering.
                    if (!value || !y.value) return false;

                    if (typeof value === 'boolean') {
                        return (value ? 'YES' : 'NO').toLowerCase().includes(y.value.toLowerCase());
                    } else if (value instanceof Date || ((new Date(value as any)).toString() !== 'Invalid Date' && moment.utc(value).isValid)) {
                        return TableFunctions.formatDateTime(value.toString()).includes(y.value);
                    } else {
                        return value.toString().toLowerCase().includes(y.value.toLowerCase());
                    }
                });
            });

            return list;
        },
    );

    public getIsAllSelected = createSelector(
        [this.getData, this.getSelectedList],
        (data, selectedList) => {
            return !!selectedList && selectedList.length > 0 && selectedList.length === data.length;
        },
    );

    public getIsSomeSelected = createSelector(
        [this.getData, this.getSelectedList],
        (data, selectedList) => {
            return !!selectedList && selectedList.length > 0 && selectedList.length !== data.length;
        },
    );

    public getIsSelectedData = createSelector(
        [this.getSelectedList, this.getRow],
        (selectedList, row) => {
            return selectedList && lodash.findIndex(selectedList, row) > -1;
        },
    );

    public onExpandClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const index = Number(event.currentTarget.value);
        const rowExpanded = this.state.rowExpanded.slice();
        rowExpanded[index] = !rowExpanded[index];
        this.setState({
            rowExpanded,
        });
    }

    public getPaginatedList = createSelector(
        [this.getFilteredOrderedData, this.getDisablePagination, this.getCurrentPage, this.getRowsPerPage],
        (sortedList, disablePagination, currentPage, rowsPerPage) => {
            return sortedList.slice(disablePagination ? 0 : (currentPage * rowsPerPage), disablePagination ? sortedList.length : (currentPage * rowsPerPage + rowsPerPage));
        },
    );

    public render = () => {
        const { currentPage, rowsPerPage, sortList, rowsPerPageOptions, rowExpanded } = this.state;
        const { id, columns, isLoading, selectable, onExportExcelClick, onExportCsvClick, childComponent, disablePagination } = this.props;

        const sortedList = this.getFilteredOrderedData(this.props, this.state);
        const paginatedList = this.getPaginatedList(this.props, this.state);
        return (
            <div className={'flx1 fdc mh0 mw0 material-table'}>
                <div style={{ position: 'relative' }}>
                    {isLoading && <LinearProgress className='wfill load-bar' />}
                </div>
                <div className={'oxo oyo wfill hfill table-scroll'}>
                    <Table id={id}>
                        <TableHead>
                            <TableRow className={'table-header table-padding'}>
                                {
                                    childComponent &&
                                    <TableCell component='th' scope='row' align='center'
                                        style={{
                                            width: 36,
                                            minWidth: 36,
                                            maxWidth: 36,
                                        }}>
                                    </TableCell>
                                }
                                {
                                    selectable &&
                                    <TableCell component='th' scope='row' align='center'
                                        style={{
                                            width: 36,
                                            minWidth: 36,
                                            maxWidth: 36,
                                        }}>
                                        <Checkbox onClick={this.onAllSelected}
                                            className='cw'
                                            checked={this.getIsAllSelected(this.props)}
                                            indeterminate={this.getIsSomeSelected(this.props)} />
                                    </TableCell>
                                }
                                {
                                    columns.map((cell, i) => (
                                        <TableCell key={`${id}_header_${i}`}
                                            component={cell.component}
                                            scope='row'
                                            colSpan={cell.colSpan}
                                            align={cell.align}
                                            style={{
                                                paddingRight: cell.paddingRight,
                                                marginLeft: !cell.fixed ? undefined : 10,
                                                width: cell.width,
                                                minWidth: cell.width,
                                                maxWidth: cell.width,
                                                position: !cell.fixed ? undefined : 'sticky',
                                                left: !cell.fixed ? undefined : 0,
                                                zIndex: !cell.fixed ? undefined : 2,
                                                textAlign: !cell.fixed ? undefined : 'right',
                                            }}>
                                            <MaterialTableHeader
                                                filtered={cell.filtered}
                                                filterRender={cell.filterRender}
                                                align={!cell.fixed ? undefined : 'right'}
                                                onDateSortChange={sort => this.onDataSortChange(cell.field, sort)}
                                                onFilterChange={this.onFilterChange}
                                                field={cell.field}
                                                sorted={sortList.some(n => n.field === cell.field)}
                                                enableSorting={cell.enableSort}
                                                enableFiltering={!!cell.field && cell.enableFilter}>
                                                {cell.header}
                                            </MaterialTableHeader>
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody className='table-body'>
                            {
                                paginatedList.map((row, i) => (
                                    <Fragment key={`${id}_data_${i}_${row?.id}`}>
                                        <TableRow className='table-padding table-row'>
                                            {
                                                childComponent &&
                                                <TableCell component='td' scope='row' align='center'
                                                    style={{
                                                        width: 36,
                                                        minWidth: 36,
                                                        maxWidth: 36,
                                                    }}>
                                                    <IconButton onClick={this.onExpandClick} value={i}>
                                                        <Icon>
                                                            {rowExpanded[i] ? 'expand_less' : 'expand_more'}
                                                        </Icon>
                                                    </IconButton>
                                                </TableCell>
                                            }
                                            {
                                                selectable &&
                                                <TableCell component='td' scope='row' align='center'
                                                    style={{
                                                        width: 36,
                                                        minWidth: 36,
                                                        maxWidth: 36,
                                                    }}>
                                                    <Checkbox onClick={() => this.onRowSelected(row)}
                                                        checked={this.getIsSelectedData(this.props, row)}
                                                        color='primary' />
                                                </TableCell>
                                            }
                                            {
                                                columns.map((cell, ii) => (
                                                    <TableCell key={`${id}_data_${i}_${ii}`}
                                                        component='td'
                                                        scope='row'
                                                        colSpan={cell.colSpan}
                                                        align={cell.align}
                                                        style={{
                                                            width: cell.width,
                                                            paddingRight: cell.paddingRight,
                                                            minWidth: cell.width,
                                                            maxWidth: cell.width,
                                                            position: !cell.fixed ? undefined : 'sticky',
                                                            left: !cell.fixed ? undefined : 0,
                                                            zIndex: !cell.fixed ? undefined : 1,
                                                        }}>
                                                            <span className={`cell-value aic ${cell.align === 'center' ? 'jcc' : ''}`}>
                                                            {!!!cell.renderCell && !!cell.field && TableFunctions.getField(row, cell.field)}
                                                            {!!cell.renderCell && cell.renderCell(row)}
                                                            </span>
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                        {
                                            rowExpanded[i] &&
                                            <TableRow>
                                                <TableCell colSpan={columns.length + 2}>
                                                    {childComponent && childComponent(row)}
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </Fragment>
                                ))
                            }
                        </TableBody>
                    </Table>
                </div>
                <div className={'flx1 fdr aic'}>
                    <span className='flx1'></span>
                    {
                        onExportExcelClick &&
                        <Tooltip title='Export Excel'>
                            <div>
                                <Fab
                                    disabled={isLoading}
                                    style={{ marginRight: '12px' }}
                                    size='medium'
                                    color='default'
                                    onClick={onExportExcelClick}>
                                    <Icon style={{ height: '1.25em', width: '1.25em' }}>
                                        <img style={{ height: '1.25em', width: '1.4em' }} src={`${ASSET_BASE}/assets/images/icons/excel.svg`} />
                                    </Icon>
                                </Fab>
                            </div>
                        </Tooltip>
                    }
                    {
                        onExportCsvClick &&
                        <Tooltip title='Export CSV'>
                            <div>
                                <Fab
                                    disabled={isLoading}
                                    size='medium'
                                    color='default'
                                    onClick={onExportCsvClick}>
                                    <Icon style={{ height: '1.25em', width: '1.25em' }}>
                                        <img style={{ height: '1.25em', width: '1.4em' }} src={`${ASSET_BASE}/assets/images/icons/csv.svg`} />
                                    </Icon>
                                </Fab>
                            </div>
                        </Tooltip>
                    }
                    {
                        !disablePagination &&
                        <TablePagination
                            component='div'
                            rowsPerPageOptions={rowsPerPageOptions}
                            count={sortedList.length}
                            rowsPerPage={rowsPerPage}
                            page={currentPage}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    }
                </div>
            </div>);
    }
}
