import React from 'react';
import { IIncidentSuspect } from '../../../../@types/viewmodel/incident';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import SpokenLanguageDropdown from '../../custom/dropdowns/SpokenLanguageDropdown';
import { vehicleOwnershipType } from '../../../../@types/model/incident';
import VehicleOwnershipDropdown from '../../custom/dropdowns/VehicleOwnershipDropdown';

interface IIncidentSuspectCaptureProps {
    index : number;
    value : IIncidentSuspect;
    isMotorVehicleAccident : boolean;

    onChange? : (value : IIncidentSuspect, index : number) => void;
    onDelete? : (index : number) => void;
}

interface IIncidentSuspectCaptureState {
}

export default class IncidentSuspectCapture extends React.Component<IIncidentSuspectCaptureProps, IIncidentSuspectCaptureState> {
    constructor(props : IIncidentSuspectCaptureProps) {
        super(props);
        this.state = {
        };
    }

    private onDeleteClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (this.props.onDelete) {
            this.props.onDelete(this.props.index);
        }
    }

    private onNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const value = {
            ...this.props.value,
        };

        if (this.props.onChange) {
            value.name = event.currentTarget.value;
            this.props.onChange(value, this.props.index);
        }
    }

    private onSurnameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const value = {
            ...this.props.value,
        };

        if (this.props.onChange) {
            value.surname = event.currentTarget.value;
            this.props.onChange(value, this.props.index);
        }
    }

    private onEmployeeNrChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.value.length > 10) return;
        const value = {
            ...this.props.value,
        };

        if (this.props.onChange) {
            value.employeeNr = event.currentTarget.value;
            this.props.onChange(value, this.props.index);
        }
    }

    private onAddressChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const value = {
            ...this.props.value,
        };

        if (this.props.onChange) {
            value.address = event.currentTarget.value;
            this.props.onChange(value, this.props.index);
        }
    }

    private onTelChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const value = {
            ...this.props.value,
        };

        if (this.props.onChange) {
            value.tel = event.currentTarget.value;
            this.props.onChange(value, this.props.index);
        }
    }

    private onCelChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const value = {
            ...this.props.value,
        };

        if (this.props.onChange) {
            value.cel = event.currentTarget.value;
            this.props.onChange(value, this.props.index);
        }
    }

    private onWorkplaceChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const value = {
            ...this.props.value,
        };

        if (this.props.onChange) {
            value.workplace = event.currentTarget.value;
            this.props.onChange(value, this.props.index);
        }
    }

    private onLanguageChange = (language : string) => {
        const value = {
            ...this.props.value,
        };

        if (this.props.onChange) {
            value.language = language;
            this.props.onChange(value, this.props.index);
        }
    }

    private onVehicleOwnershipChange = (vehicleOwnership : vehicleOwnershipType) => {
        const value = {
            ...this.props.value,
        };

        if (this.props.onChange) {
            value.vehicleOwnership = vehicleOwnership;
            this.props.onChange(value, this.props.index);
        }
    }

    private onVehicleRegistrationNumberChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const value = {
            ...this.props.value,
        };

        if (this.props.onChange) {
            value.vehicleRegistrationNumber = event.currentTarget.value;
            this.props.onChange(value, this.props.index);
        }
    }

    private onVehicleMakeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const value = {
            ...this.props.value,
        };

        if (this.props.onChange) {
            value.vehicleMake = event.currentTarget.value;
            this.props.onChange(value, this.props.index);
        }
    }

    private onVehicleColourChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const value = {
            ...this.props.value,
        };

        if (this.props.onChange) {
            value.vehicleColour = event.currentTarget.value;
            this.props.onChange(value, this.props.index);
        }
    }

    private onInsuranceCompanyChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const value = {
            ...this.props.value,
        };

        if (this.props.onChange) {
            value.insuranceCompany = event.currentTarget.value;
            this.props.onChange(value, this.props.index);
        }
    }

    public render = () => {
        const { name, surname, workplace, employeeNr, address, tel, cel, language,
        vehicleColour, vehicleMake, vehicleOwnership, vehicleRegistrationNumber, insuranceCompany } = this.props.value;
        return (
            <div className='fdc'>
                <div className='fdr aifs flx1'>
                    <div className={'aic p5 mb10 pr20 flx1'} style={{ marginTop: '16px' }}>
                        <FormControl fullWidth>
                            <InputLabel required error={!name} htmlFor='description-input'>Name</InputLabel>
                            <Input
                                autoFocus
                                className=''
                                id='name-input'
                                value={name}
                                onChange={this.onNameChange}
                                aria-describedby='name-input-helper-text'
                                required
                                error={!name}
                                fullWidth
                            />
                            {
                                !name &&
                                <FormHelperText error>Required</FormHelperText>
                            }
                        </FormControl>
                    </div>
                    <div className={'aic p5 mb10 pr20 flx1'} style={{ marginTop: '16px' }}>
                        <FormControl fullWidth>
                            <InputLabel required error={!surname} htmlFor='description-input'>Surname</InputLabel>
                            <Input
                                className=''
                                id='surname-input'
                                value={surname}
                                onChange={this.onSurnameChange}
                                aria-describedby='surname-input-helper-text'
                                required
                                error={!surname}
                                fullWidth
                            />
                            {
                                !surname &&
                                <FormHelperText error>Required</FormHelperText>
                            }
                        </FormControl>
                    </div>
                    <div className={'aic p5 mb10 pr20 flx1'} style={{ marginTop: '16px' }}>
                        <FormControl>
                            <InputLabel htmlFor='employeeNr-input'>Employee Nr / ID Nr</InputLabel>
                            <Input
                                className='w200'
                                id='employeeNr-input'
                                value={employeeNr}
                                onChange={this.onEmployeeNrChange}
                                aria-describedby='employeeNr-input-helper-text'
                                inputProps={{
                                    maxLength: '10',
                                }}
                                fullWidth
                            />
                        </FormControl>
                    </div>
                </div>
                <div className='fdr aifs flx1'>
                    <div className={'aic p5 mb10 pr20 flx1'} style={{ marginTop: '16px' }}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor='workplace-input'>Workplace</InputLabel>
                            <Input
                                className=''
                                id='workplace-input'
                                value={workplace}
                                onChange={this.onWorkplaceChange}
                                aria-describedby='workplace-input-helper-text'
                            />
                        </FormControl>
                    </div>
                    <div className={'aic p5 mb10 pr20 flx1'} style={{ marginTop: '16px' }}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor='tel-input'>Tel</InputLabel>
                            <Input
                                inputProps={{
                                    pattern: '[0-9]{10}',
                                }}
                                placeholder='0121231234'
                                className=''
                                id='tel-input'
                                value={tel}
                                onChange={this.onTelChange}
                                aria-describedby='tel-input-helper-text'
                            />
                        </FormControl>
                    </div>
                    <div className={'aic p5 mb10 pr20 flx1'} style={{ marginTop: '16px' }}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor='cel-input'>Cel</InputLabel>
                            <Input
                                inputProps={{
                                    pattern: '[0-9]{10}',
                                }}
                                placeholder='0121231234'
                                className=''
                                id='cel-input'
                                value={cel}
                                onChange={this.onCelChange}
                                aria-describedby='cel-input-helper-text'
                            />
                        </FormControl>
                    </div>
                </div>
                <div className='fdr aifs flx1'>
                    <div className={'aic p5 mb10 pr20 flx1'} style={{ marginTop: '16px' }}>
                        <FormControl fullWidth>
                            <InputLabel required error={!address} htmlFor='address-input'>Address</InputLabel>
                            <Input
                                id='address-input'
                                value={address}
                                onChange={this.onAddressChange}
                                aria-describedby='address-input-helper-text'
                                required
                                error={!address}
                                multiline
                                maxRows='5'
                                fullWidth
                            />
                            {
                                !address &&
                                <FormHelperText error>Required</FormHelperText>
                            }
                        </FormControl>
                    </div>
                    <div className={'aic p5 mb10 pr20 flx1'} style={{ marginTop: '16px' }}>
                        <SpokenLanguageDropdown fullWidth value={language} onChange={this.onLanguageChange} required />
                    </div>
                    <div className={'aic p5 mb10 pr20 flx1'} style={{ marginTop: '16px' }}>
                        {
                            this.props.isMotorVehicleAccident &&
                            <VehicleOwnershipDropdown fullWidth value={vehicleOwnership} onChange={this.onVehicleOwnershipChange} required />
                        }
                    </div>
                </div>
                {
                    this.props.isMotorVehicleAccident &&
                    <div className='fdr aifs flx1'>
                        <div className={'aic p5 mb10 pr20 flx1'} style={{ marginTop: '16px' }}>
                            {
                                vehicleOwnership &&
                                <FormControl fullWidth>
                                    <InputLabel required error={!vehicleRegistrationNumber} htmlFor='vehicleRegistrationNumber-input'>
                                        {
                                            vehicleOwnership === 'Company' &&
                                            'Fleet Nr'
                                        }
                                        {
                                            vehicleOwnership === 'Private' &&
                                            'Vehicle Registration Nr'
                                        }
                                    </InputLabel>
                                    <Input
                                        fullWidth
                                        className=''
                                        id='vehicleRegistrationNumber-input'
                                        value={vehicleRegistrationNumber}
                                        onChange={this.onVehicleRegistrationNumberChange}
                                        aria-describedby='vehicleRegistrationNumber-input-helper-text'
                                        required
                                        error={!vehicleRegistrationNumber}
                                    />
                                    {
                                        !vehicleRegistrationNumber &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                            }
                        </div>
                        <div className={'aic p5 mb10 pr20 flx1'} style={{ marginTop: '16px' }}>
                            {
                                vehicleOwnership === 'Private' &&
                                <FormControl fullWidth>
                                    <InputLabel required error={!vehicleMake} htmlFor='vehicleMake-input'>
                                        Vehicle Make
                                    </InputLabel>
                                    <Input
                                        fullWidth
                                        className=''
                                        id='vehicleMake-input'
                                        value={vehicleMake}
                                        onChange={this.onVehicleMakeChange}
                                        aria-describedby='vehicleMake-input-helper-text'
                                        required
                                        error={!vehicleMake}
                                    />
                                    {
                                        !vehicleMake &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                            }
                        </div>
                        <div className={'aic p5 mb10 pr20 flx1'} style={{ marginTop: '16px' }}>
                            {
                                vehicleOwnership === 'Private' &&
                                <FormControl fullWidth>
                                    <InputLabel required error={!vehicleColour} htmlFor='vehicleColour-input'>
                                        Vehicle Colour
                                    </InputLabel>
                                    <Input
                                        className=''
                                        id='vehicleColour-input'
                                        value={vehicleColour}
                                        onChange={this.onVehicleColourChange}
                                        aria-describedby='vehicleColour-input-helper-text'
                                        required
                                        error={!vehicleColour}
                                        fullWidth
                                    />
                                    {
                                        !vehicleColour &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                            }
                        </div>
                    </div>
                }
                <div className='fdr aifs flx1'>
                    <div className={'aic p5 mb10 pr20 flx1'} style={{ marginTop: '16px' }}>
                        {
                            vehicleOwnership === 'Private' &&
                            <FormControl fullWidth>
                                <InputLabel htmlFor='insuranceCompany-input'>
                                    Insurance Company
                                </InputLabel>
                                <Input
                                    className=''
                                    id='insuranceCompany-input'
                                    value={insuranceCompany}
                                    onChange={this.onInsuranceCompanyChange}
                                    aria-describedby='insuranceCompany-input-helper-text'
                                    fullWidth
                                />
                            </FormControl>
                        }
                    </div>
                    <div className={'aic p5 mb10 pr20 flx1'} style={{ marginTop: '16px' }}>
                    </div>
                    <div className={'aic p5 mb10 pr20 flx1'} style={{ marginTop: '16px' }}>
                    {
                        !!this.props.onDelete &&
                        <div className='fdr aifs ais flx1'>
                            <div>
                                <Button
                                    type='button'
                                    variant='contained'
                                    className='bcpr'
                                    color='primary'
                                    onClick={this.onDeleteClick}
                                >
                                    Delete
                                </Button>
                            </div>
                        </div>
                    }
                    </div>
                </div>
            </div>
        );
    }
}
