import { IFileQueue } from '../../@types/viewmodel/file';
import { FileAction } from '../../@types/redux';
import fileActions from './actions';
import { getType } from 'typesafe-actions';

export interface IFileState {
    files : Array<IFileQueue>;
    uploadProgress : number;
    current? : string;
}

const initialState = {
    files: [],
    uploadProgress: 0,
};

export default function dataReducer(state : IFileState = initialState, action : FileAction) : IFileState {
    switch (action.type) {
        case getType(fileActions.setFiles):
            return { ...state, files: action.payload };
        case getType(fileActions.setProgress):
            return { ...state, uploadProgress: action.payload };
        case getType(fileActions.setCurrent):
            return { ...state, current: action.payload };
        default:
            return state;
    }
}
