import { INotificationType } from '../../../@types/model/right';
import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../@types/redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RightsFunctions from '../../../store/right/functions';

interface IUserNotificationSubscriptionProps {
    selectedIds : Array<number>;
    notificationTypes : Array<INotificationType>;

    onCheckChanged : (id : number) => void;
}

interface IUserNotificationSubscriptionState {
    isLoading : boolean;
}

class UserNotificationSubscription extends React.Component<IUserNotificationSubscriptionProps, IUserNotificationSubscriptionState> {
    constructor(props : IUserNotificationSubscriptionProps) {
        super(props);

        this.state = {
            isLoading: true,
        };
    }

    public componentDidMount = () => {
        this.loadTypes();
    }

    public loadTypes = async () => {
        await RightsFunctions.getAllNotificationTypes();
        this.setState({
            isLoading: false,
        });
    }

    public onCheckBoxClicked = (event : any) => {
        this.props.onCheckChanged(Number(event.target.value));
    }

    public render = () =>
        (
            <div className='fdc'>
                <Typography variant='h6' component='u'>
                    Notification Subscription
                </Typography>
                {
                    this.state.isLoading &&
                    <LinearProgress />
                }
                {
                    this.props.notificationTypes.map(n => (
                        <div key={`note_type_${n.id}`}>
                            <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.selectedIds.includes(n.id)}
                                    color='primary'
                                    onClick={this.onCheckBoxClicked} />
                            }
                            label={`${n.code} - ${n.description}`}
                            value={`${n.id}`} />
                        </div>
                    ))
                }
            </div>
        )
}

const mapStateToProps = (state : IRootState) => {
    return {
        notificationTypes: state.rights.notificationTypes,
    };
};

export default connect(mapStateToProps)(UserNotificationSubscription);
