import { withStyles, Fab, FabProps } from '@material-ui/core';

interface IProps {
    position ?: 'absolute' | 'fixed' | 'relative' | 'static' | 'sticky';
}
const StandardFab = withStyles<'root', {}, FabProps & IProps>(style => ({
    root: {
        position: props => props.position ?? 'absolute',
        bottom: style.spacing(2),
        right: style.spacing(2),
        color: props => props.color === 'primary' ? 'white': style.palette.primary.dark,
        backgroundColor: props => props.color === 'primary' ? style.palette.primary.main : '#fd0',
        '&:hover': {
            backgroundColor: props => props.color === 'primary' ? style.palette.primary.dark : '#fd0',
        },
    },
}))(Fab);

export default StandardFab;
