import React from 'react';
import { IRootState, RootAction } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MaterialTable from '../custom/materialTable/Table';
import EditRegionDialog from './dialog/Edit';
import { Transitions } from '../custom/animations/Transitions';
import { IRegion } from '../../../@types/model/region';
import moment from 'moment';
import MasterDataFunctions from '../../../store/masterData/functions';
import { createSelector } from 'reselect';
import Fab from '@material-ui/core/Fab/Fab';

interface IRegionProps {
    isLoading : boolean;
    regions : Array<IRegion>;
}

interface IRegionState {
    showAll : boolean;
}

class RegionListComponent extends React.Component<IRegionProps, IRegionState> {
    constructor(props : IRegionProps) {
        super(props);
        this.state = {
            showAll: false,
        };
    }

    public componentDidMount = () => {
        MasterDataFunctions.getAllRegions();
    }

    public onRefreshClick = () => {
        MasterDataFunctions.getAllRegions(true);
    }

    private getRegions = (props : IRegionProps, state : IRegionState) => props.regions;
    private getShowAll = (props : IRegionProps, state : IRegionState) => state.showAll;

    public getFilteredData = createSelector(
        [this.getRegions, this.getShowAll],
        (regions, showAll) => {
            return regions
            .filter(n => showAll || n.isActive);
        },
    );

    private onShowOnlyActiveClick = () => {
        this.setState({
            showAll: !this.state.showAll,
        });
    }

    public render = () => {
        const { showAll } = this.state;
        const { isLoading } = this.props;

        const regions = this.getFilteredData(this.props, this.state);

        return (
            <div className={'flx1 fdc p5'}>
                <Card className={'flx1 fdc'}>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            Regions
                        </Typography>
                        <IconButton color='primary' onClick={this.onRefreshClick}>
                            <Tooltip title='Refresh'>
                                <Icon>
                                    refresh
                                </Icon>
                            </Tooltip>
                        </IconButton>
                        <span className={'flx1'} />
                        <EditRegionDialog transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading} />
                        <Tooltip title='Show only Active'>
                            <Fab color={!showAll ? 'primary' : 'default'} size='small' onClick={this.onShowOnlyActiveClick}>
                                <Icon>
                                    <img src={`${ASSET_BASE}/assets/images/icons/active.svg`} />
                                </Icon>
                            </Fab>
                        </Tooltip>
                    </Toolbar>
                    <MaterialTable<IRegion>
                        id={'regionsTable'}
                        data={regions}
                        isLoading={isLoading}
                        rowsPerPage={100}
                        columns={[
                            {
                                header: '',
                                width: '60px',
                                renderCell: row => (
                                    <div className='aic'>
                                        <EditRegionDialog region={row} transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading} />
                                    </div>
                                ),
                            },
                            {
                                header: 'Code',
                                field: 'code',
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Name',
                                field: 'name',
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Active',
                                field: 'isActive',
                                enableSort: true,
                                renderCell: row => (<Icon className={`${row.isActive ? 'cp' : 'cpr'}`}>{row.isActive ? 'check' : 'close' }</Icon>),
                            },
                            {
                                header: 'Created By',
                                field: 'createdByName',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                    <Tooltip title={moment.utc(row.createdOn).local().format('YYYY-MM-DD HH:mm')}>
                                        <span>{row.createdByName.toTitleCase()}</span>
                                    </Tooltip>);
                                },
                            },
                            {
                                header: 'Updated By',
                                field: 'createdByName',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                    <Tooltip title={moment.utc(row.updatedOn).local().format('YYYY-MM-DD HH:mm')}>
                                        <span>{row.updatedByName.toTitleCase()}</span>
                                    </Tooltip>);
                                },
                            },
                        ]}
                    />
                </Card>
        </div>);
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        regions: state.masterData.regions,
        isLoading: state.masterData.isLoadingRegions,
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => {
    return bindActionCreators({}, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RegionListComponent);
