import React from 'react';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import { createSelector } from 'reselect';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { IRegion } from '../../../../@types/model/region';
import MasterDataFunctions from '../../../../store/masterData/functions';
import EditRegionDialog from '../../region/dialog/Edit';
import { Transitions } from '../animations/Transitions';

interface IRegionDropdownProps {
    value? : number | null;

    onChange : (value? : IRegion) => void;

    regions : Array<IRegion>;

    required? : boolean;
    autoFocus? : boolean;

    width? : string | number;
    fullWidth? : boolean;
    isLoading : boolean;

    hasRegionRight : boolean;
}

interface IRegionDropdownState {
}

class RegionDropdown extends React.Component<IRegionDropdownProps, IRegionDropdownState> {
    constructor(props : IRegionDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        await MasterDataFunctions.getAllRegions();
    }

    private onChanged = (event : React.ChangeEvent<HTMLSelectElement>, value : {
        label : string,
        value : string,
    }) => {
        const result = this.props.regions.find(n => n.id === Number(value?.value));

        this.props.onChange(result);
    }

    private getData = (state : IRegionDropdownState, props : IRegionDropdownProps) => props.regions;
    private getValue = (state : IRegionDropdownState, props : IRegionDropdownProps) => props.value;
    private getRegionsDropdown = createSelector([
        this.getData,
    ], (regions) => {
        const regionDrop = regions.filter(x => x.isActive).map(x => ({ label: x.name.toTitleCase(), value: x.id.toString() }));

        return regionDrop;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getData,
    ], (value, data) => {
        if (value && data.length) {
            const region = data.find(x => x.id === Number(value));

            if (region) {
                return {
                    label: region.name.toTitleCase(),
                    value: region.id.toString(),
                };
            }
        }

        return null;
    });

    public render = () => {
        const {  } = this.state;
        const { required, isLoading, autoFocus, width, fullWidth, hasRegionRight } = this.props;
        const regions = this.getRegionsDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required} >
                <div className='flx1 fdr ais'>
                    <Autocomplete
                        className='flx1'
                        id='region_select'
                        options={regions}
                        value={value}
                        getOptionSelected={(option, val) => option.value === val.value}
                        getOptionLabel={option => option.label}
                        onChange={this.onChanged}
                        disableClearable={required}
                        openOnFocus
                        style={{ width: !!width ? width : '' }}
                        renderInput={params => <TextField autoFocus={autoFocus} fullWidth={fullWidth} error={required && !value} {...params} label='Regions' />}
                    />
                    {
                        hasRegionRight &&
                        <EditRegionDialog transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading} />
                    }
                </div>
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                {
                    isLoading &&
                    <LinearProgress />
                }
                </div>
            </FormControl>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        regions: state.masterData.regions,
        isLoading: state.masterData.isLoadingRegions,
        hasRegionRight: !!state.auth.session?.user?.userRights?.some(n => n.rightId === 13),
    };
};

export default connect(
    mapStateToProps,
)(RegionDropdown);
