import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, DialogActions, Typography } from '@material-ui/core';
import { IIncident, IIncidentDocket } from '../../../../../@types/model/incident';
import moment from 'moment';
import IncidentDocketHttpService from '../../../../../service/http/incident/incidentDocketHttpService';
import GeneralFunctions from '../../../../../store/general/functions';

interface IIncidentDocketFileDialogProps {
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    files : Array<File>;
    onClose ?: (docket ?: IIncidentDocket) => void;

    incident : IIncident;
    docket : IIncidentDocket | null;
}

interface IIncidentDocketFileDialogState {
    open : boolean;

    isLoading : boolean;
}

export default class IncidentDocketFileDialog extends React.PureComponent<IIncidentDocketFileDialogProps, IIncidentDocketFileDialogState> {
    constructor(props : IIncidentDocketFileDialogProps) {
        super(props);
        this.state = {
            open: false,
            isLoading: false,
        };
    }
    
    public componentDidUpdate(prevProps : Readonly<IIncidentDocketFileDialogProps>): void {
        if (!prevProps.files.length && this.props.files.length) {
            this.setState({
                open: true,
            });
        }

        if (prevProps.files.length && !this.props.files.length) {
            this.setState({
                open: false,
            });
        }
    }
    
    public readonly onClose = () => {
        if (this.state.isLoading) return;
        if (!this.props.onClose) return;

        this.props.onClose();
    };
    
    public readonly onOkClick = () => {
        this.save();
    };
    
    public readonly save = async () => {
        this.setState({
            isLoading: true,
        });

        const docket = {
            ...(this.props.docket ?? {
                isActive: true,
                incidentId: this.props.incident.id,
                date: moment.utc().toISOString(),
                users: [],
                files: []
            }),
        } as IIncidentDocket;

        try {
            const result = await IncidentDocketHttpService.save(docket, this.props.files);
            if (!this.props.onClose) return;
    
            this.props.onClose(result.data);
        } catch (ex) {
            GeneralFunctions.showErrorSnackbar('Error saving docket.', ex);
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, files } = this.props;
        const { open, isLoading } = this.state;
        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='tooltip-dialog-title'
                aria-describedby='tooltip-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h6' color='inherit' className='flx1'>
                            Upload Selected Files?
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='fdc flx1 hfill'>
                    <div className='fdc flx1'>
                        {
                            files.map(n => (
                                <div key={`${n.name}_file`} className='fdr flx1 h50 aic'>
                                    <span className='w400'>{n.name}</span>
                                </div>
                            ))
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={this.onOkClick} type='button' variant='contained' color='primary'>
                        UPLOAD
                    </Button>
                    <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
