import { IOccurrence } from '../../@types/model/occurrence';
import { OccurrenceAction } from '../../@types/redux';
import occurrenceActions from './actions';
import { getType } from 'typesafe-actions';

export interface IOccurrenceState {
    isLoading : boolean;
    occurrences : Array<IOccurrence>;
    selectedOccurrence? : IOccurrence;
}

const initialState = {
    isLoading: false,
    occurrences: [],
};

export default function dataReducer(state : IOccurrenceState = initialState, action : OccurrenceAction) : IOccurrenceState {
    switch (action.type) {
        case getType(occurrenceActions.setOccurrences):
            return { ...state, occurrences: action.payload };
        case getType(occurrenceActions.setOccurrence):
            return { ...state, selectedOccurrence: action.payload };
        case getType(occurrenceActions.setLoadingOccurrences):
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}
