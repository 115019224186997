import React from 'react';
import { IIncidentCourtCaseAccusedCreate, IIncidentCourtCaseCreate, IIncidentSuspects } from '../../../../@types/viewmodel/incident';
import { Card, CardContent, Fab, FormControl, FormControlLabel, Input, InputLabel, Switch, Typography } from '@material-ui/core';
import { EnumIncidentCourtCaseStatus } from '../../../../@types/model/incident';
import moment, { Moment } from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import IncidentCourtCaseAccusedCapture from './IncidentCourtCaseAccusedCapture';
import IncidentCourtCaseStatusAutocomplete from '../../custom/autocomplete/incident/IncidentCourtCaseStatusDropdown';

interface IIncidentCourtCaseCaptureProps {
    value ?: IIncidentCourtCaseCreate | null;
    suspects : IIncidentSuspects;

    onSubmit? : (value : IIncidentCourtCaseCreate, back? : boolean) => void;
}

interface IIncidentCourtCaseCaptureState {
    hasCourtCase : boolean;

    caseNumber : string | null;
    courtNumber : string | null;
    court : string | null;
    originalCourtDate : Moment | null;
    magistrate : string | null;
    prosecutor : string | null;
    status : EnumIncidentCourtCaseStatus | null;
    dateOfVerdict : Moment | null;
    dateOfSentence : Moment | null;
    incidentCourtCaseAccuseds : Array<IIncidentCourtCaseAccusedCreate>;
}

export default class IncidentCourtCaseCapture extends React.PureComponent<IIncidentCourtCaseCaptureProps, IIncidentCourtCaseCaptureState> {
    constructor(props : IIncidentCourtCaseCaptureProps) {
        super(props);
        this.state = {
            hasCourtCase: !!props.value,
            caseNumber: props.value?.caseNumber ?? null,
            courtNumber: props.value?.courtNumber ?? null,
            court: props.value?.court ?? null,
            originalCourtDate: !props.value?.originalCourtDate ? null : moment.utc(props.value.originalCourtDate),
            magistrate: props.value?.magistrate ?? null,
            prosecutor: props.value?.prosecutor ?? null,
            status: props.value?.status ?? null,
            dateOfVerdict: !props.value?.dateOfVerdict ? null : moment.utc(props.value.dateOfVerdict),
            dateOfSentence: !props.value?.dateOfSentence ? null : moment.utc(props.value.dateOfSentence),
            incidentCourtCaseAccuseds: props.value?.incidentCourtCaseAccuseds ?? [],
        };
    }

    private readonly submit = (back? : boolean) => {
        if (!!this.props.onSubmit) {
            const { 
                caseNumber,
                courtNumber,
                court,
                originalCourtDate,
                magistrate,
                prosecutor,
                status,
                dateOfVerdict,
                dateOfSentence,
                incidentCourtCaseAccuseds,
             } = this.state;

            this.props.onSubmit({
                caseNumber,
                courtNumber,
                court,
                originalCourtDate: originalCourtDate?.valueOf() ?? null,
                magistrate,
                prosecutor,
                status,
                dateOfVerdict: dateOfVerdict?.valueOf() ?? null,
                dateOfSentence: dateOfSentence?.valueOf() ?? null,
                incidentCourtCaseAccuseds,
            }, back);
        }
    }

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.submit();
    }

    private readonly onBack = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.submit(true);
    }

    private readonly onHasCourtCaseChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            hasCourtCase: checked,
        });
        
        this.setState({
            caseNumber: null,
            courtNumber: null,
            court: null,
            originalCourtDate: null,
            magistrate: null,
            prosecutor: null,
            status: null,
            dateOfVerdict: null,
            dateOfSentence: null,
            incidentCourtCaseAccuseds: this.props.suspects.suspects.map((suspect) => ({
                name: suspect.name,
                representative: null,
                sentence: null,
                surname: suspect.surname,
                verdict: null,
                charges: null,
            })),
        });
    }

    private readonly onAddAccusedClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const incidentCourtCaseAccuseds = this.state.incidentCourtCaseAccuseds.slice();
        incidentCourtCaseAccuseds.push({
            name: null,
            representative: null,
            sentence: null,
            surname: null,
            verdict: null,
            charges: null,
        });

        this.setState({
            incidentCourtCaseAccuseds,
        });
    }

    private readonly onCaseNumberChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            caseNumber: event.currentTarget.value,
        });
    }

    private readonly onCourtNumberChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            courtNumber: event.currentTarget.value,
        });
    }

    private readonly onCourtChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            court: event.currentTarget.value,
        });
    }

    private onOriginalCourtDateChange = (momentDate : Moment | null) => {
        const date = momentDate?.startOf('day');

        this.setState({
            originalCourtDate: date?.asUTC() ?? null,
        });
    }

    private readonly onMagistrateChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            magistrate: event.currentTarget.value,
        });
    }

    private readonly onProsecutorChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            prosecutor: event.currentTarget.value,
        });
    }

    private readonly onStatusChange = (value : EnumIncidentCourtCaseStatus | null) => {
        this.setState({
            status: value,
        });
    }

    private readonly onDateOfVerdictChange = (momentDate : Moment | null) => {
        const date = momentDate?.startOf('day');

        this.setState({
            dateOfVerdict: date?.asUTC() ?? null,
        });
    }

    private readonly onDateOfSentenceChange = (momentDate : Moment | null) => {
        const date = momentDate?.startOf('day');

        this.setState({
            dateOfSentence: date?.asUTC() ?? null,
        });
    }

    private readonly onAccusedChange = (accused : IIncidentCourtCaseAccusedCreate, index : number) => {
        const incidentCourtCaseAccuseds = this.state.incidentCourtCaseAccuseds.slice();
        incidentCourtCaseAccuseds.splice(index, 1, accused);

        this.setState({
            incidentCourtCaseAccuseds,
        });
    }

    private readonly onAccusedDelete = (index : number) => {
        const incidentCourtCaseAccuseds = this.state.incidentCourtCaseAccuseds.slice();
        incidentCourtCaseAccuseds.splice(index, 1);

        this.setState({
            incidentCourtCaseAccuseds,
        });
    }

    public render = () => {
        const { 
            hasCourtCase,
            caseNumber,
            courtNumber,
            court,
            originalCourtDate,
            magistrate,
            prosecutor,
            status,
            dateOfVerdict,
            dateOfSentence,
            incidentCourtCaseAccuseds,
         } = this.state;

        return (
            <form className='fdc flx1 aifs ais' onSubmit={this.onSubmit}>
                <Card className='fdr aifs mb10 sbs mnh450 oys'>
                    <CardContent className='flx1 fdr aifs'>
                        <div className='flx1 fdr aifs'>
                            <div className='fdc flx1'>
                                <div className='fdr aic m5'>
                                    <FormControlLabel
                                        labelPlacement='start'
                                        className='cp'
                                        control={
                                            <Switch
                                                checked={hasCourtCase}
                                                color='primary'
                                                onChange={this.onHasCourtCaseChange}/>
                                        }
                                        label={`Court Case?`}/>
                                </div>
                                {
                                    hasCourtCase &&
                                    <div className='fdc flx1'>
                                        <div className='fdr aifs'>
                                            <div className={'fdc flx1 aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='caseNumber-input'>CAS Number</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='caseNumber-input'
                                                        value={caseNumber ?? ''}
                                                        onChange={this.onCaseNumberChange}
                                                        aria-describedby='caseNumber-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='courtNumber-input'>Court Number</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='courtNumber-input'
                                                        value={courtNumber ?? ''}
                                                        onChange={this.onCourtNumberChange}
                                                        aria-describedby='courtNumber-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='court-input'>Court</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='court-input'
                                                        value={court ?? ''}
                                                        onChange={this.onCourtChange}
                                                        aria-describedby='court-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='fdr aifs'>
                                            <div className={'flx1 aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <KeyboardDatePicker
                                                        value={originalCourtDate}
                                                        onChange={this.onOriginalCourtDateChange}
                                                        format={'YYYY-MM-DD'}
                                                        label='Original Court Date'
                                                        id='originalCourtDate'
                                                        fullWidth
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='magistrate-input'>Magistrate</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='magistrate-input'
                                                        value={magistrate ?? ''}
                                                        onChange={this.onMagistrateChange}
                                                        aria-describedby='magistrate-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='prosecutor-input'>Prosecutor</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='prosecutor-input'
                                                        value={prosecutor ?? ''}
                                                        onChange={this.onProsecutorChange}
                                                        aria-describedby='prosecutor-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='fdr aifs'>
                                            <div className={'flx1 aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <IncidentCourtCaseStatusAutocomplete
                                                        value={status}
                                                        onChange={this.onStatusChange}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <KeyboardDatePicker
                                                        value={dateOfVerdict}
                                                        onChange={this.onDateOfVerdictChange}
                                                        format={'YYYY-MM-DD'}
                                                        label='Date Of Verdict'
                                                        id='dateOfVerdict'
                                                        fullWidth
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <KeyboardDatePicker
                                                        value={dateOfSentence}
                                                        onChange={this.onDateOfSentenceChange}
                                                        format={'YYYY-MM-DD'}
                                                        label='Date Of Sentence'
                                                        id='dateOfSentence'
                                                        fullWidth
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='fdc ais'>
                                            <Typography className='fs24 cpd fwm'>
                                                Accused
                                            </Typography>
                                        </div>
                                        <div className='fdc ais'>
                                            {
                                                incidentCourtCaseAccuseds.map((n, i) => (
                                                    <IncidentCourtCaseAccusedCapture
                                                        key={`${i}_accused`}
                                                        value={n}
                                                        index={i}
                                                        onChange={this.onAccusedChange}
                                                        onDelete={this.onAccusedDelete}
                                                    />
                                                ))
                                            }
                                        </div>
                                        <div className='fdr aife'>
                                            <div>
                                                <Fab className={`pl40 pr40 mr15 mt15 mb15`}
                                                    variant='extended'
                                                    color='primary'
                                                    onClick={this.onAddAccusedClick}
                                                    size='medium'
                                                    type='button'>
                                                    ADD ACCUSED
                                                </Fab>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <div className='fdr mr15 ais'>
                    <span className='flx1' />
                    <div>
                        <Fab
                            className={`nbs bct cp pl40 pr40 mr15`}
                            variant='extended'
                            onClick={this.onBack}
                            size='medium'
                            type='button'
                        >
                            Back
                        </Fab>
                    </div>
                    <div>
                        <Fab
                            className={`cp bcy pl40 pr40`}
                            variant='extended'
                            size='medium'
                            type='submit'
                        >
                            Submit
                        </Fab>
                    </div>
                </div>
            </form>
        );
    }
}
