import generalFunctions from '../general/functions';
import { dispatch, getState } from '..';
import { IFileUpload } from '../../@types/viewmodel/file';
import FileFunctions from '../file/functions';
import { EnumFileCategory } from '../../@types/model/base';
import graveSiteActions from './actions';
import GraveSiteHttpService from '../../service/http/graveSite/graveSiteHttpService';
import { IGraveSite, IGraveSiteFile, IGraveSiteUpsert } from '../../@types/model/graveSite/graveSite';
import { IGrave } from '../../@types/model/graveSite/grave';
import { IGraveVisit } from '../../@types/model/graveSite/graveVisit';
import GraveVisitHttpService from '../../service/http/graveSite/graveVisitHttpService';

export default class GraveSiteFunctions {
    public static async getAll(refresh? : boolean) {
        try {
            if (!refresh && getState().graveSites.graveSites.length) return;

            dispatch(graveSiteActions.setLoadingGraveSites(true));
            const result = await GraveSiteHttpService.getAll();

            dispatch(graveSiteActions.setGraveSites(result.data));
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading grave sites', e);
        } finally {
            dispatch(graveSiteActions.setLoadingGraveSites(false));
        }
    }

    public static async create(
        graveSite : IGraveSiteUpsert,
        files : Array<IFileUpload>) {
        try {
            dispatch(graveSiteActions.setLoadingGraveSites(true));
            const result = await GraveSiteHttpService.save(graveSite);

            if (result.data) {
                const graveSites = getState().graveSites.graveSites.slice();
                graveSites.push(result.data);
                dispatch(graveSiteActions.setGraveSites(graveSites));
            }

            files.forEach(n => FileFunctions.queueGraveSiteFile({ ...n }, EnumFileCategory.GraveSiteImage, graveSite.guid));
            graveSite.graves.filter(x => x.file).forEach(n =>
                FileFunctions.queueGraveFile(
                    { ...n.file! },
                    EnumFileCategory.GraveImage,
                    `${graveSite.guid},${n.guid}`,
                ),
            );

            return result.data;
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while creating grave site.', e);
        } finally {
            dispatch(graveSiteActions.setLoadingGraveSites(false));
        }

        return null;
    }

    public static async save(
        graveSite : IGraveSiteUpsert,
        files : Array<IFileUpload>) {
        try {
            const result = await GraveSiteHttpService.save(graveSite);

            if (result.data) {
                const state = getState();

                const graveSites = state.graveSites.graveSites.slice();
                const index = graveSites.findIndex(n => n.guid === result.data.guid);
                if (index > -1) {
                    graveSites.splice(index, 1, result.data);
                } else {
                    graveSites.push(result.data);
                }
                dispatch(graveSiteActions.setGraveSites(graveSites));
            }

            files.forEach(n => FileFunctions.queueGraveSiteFile({ ...n }, EnumFileCategory.GraveSiteImage, graveSite.guid));
            graveSite.graves.filter(x => x.file).forEach(n =>
                FileFunctions.queueGraveFile(
                    { ...n.file! },
                    EnumFileCategory.GraveImage,
                    `${graveSite.guid},${n.guid}`,
                ),
            );

            return result.data;
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while creating grave site.', e);
        } finally {
            dispatch(graveSiteActions.setLoadingGraveSites(false));
        }

        return null;
    }

    public static async generalImageSave(
        guid : string,
        files : Array<IFileUpload>) {
        try {
            dispatch(graveSiteActions.setLoadingGraveSites(true));

            files.forEach(n => FileFunctions.queueGraveSiteFile({ ...n }, EnumFileCategory.GraveSiteImage, guid));

        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while queuing files.', e);
        } finally {
            dispatch(graveSiteActions.setLoadingGraveSites(false));
        }

        return null;
    }

    public static async deleteImageSave(
        graveSiteId : number,
        file : IGraveSiteFile,
    ) {
        try {
            dispatch(graveSiteActions.setLoadingGraveSites(true));
            
            const result = await GraveSiteHttpService.deleteFile(graveSiteId, file.fileId);

            if (!result.data) return;
            
            const graveSites = getState().graveSites.graveSites.slice();
            const graveSiteIndex = graveSites.findIndex(x => x.id === graveSiteId);

            if (graveSiteIndex > -1) {
                graveSites[graveSiteIndex] = result.data;
                dispatch(graveSiteActions.setGraveSites(graveSites));
            }

        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while queuing files.', e);
        } finally {
            dispatch(graveSiteActions.setLoadingGraveSites(false));
        }

        return null;
    }

    public static async representativeImageSave(
        guid : string,
        files : Array<IFileUpload>) {
        try {
            dispatch(graveSiteActions.setLoadingGraveSites(true));

            files.forEach(n => FileFunctions.queueGraveSiteFile({ ...n }, EnumFileCategory.GraveSiteRepresentativeImage, guid));

        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while queuing files.', e);
        } finally {
            dispatch(graveSiteActions.setLoadingGraveSites(false));
        }

        return null;
    }

    public static async uploadInitialGraveDocuments(
        graveSite : IGraveSite,
        grave : IGrave,
        initialApplications : Array<IFileUpload>,
        ) {
        try {
            initialApplications.filter(x => x.file).forEach(n =>
                FileFunctions.queueGraveFile(
                    { ...n! },
                    EnumFileCategory.GraveInitialApplicationFiles,
                    `${graveSite.guid},${grave.guid}`,
                ),
            );
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while uploading files.', e);
            throw e;
        }
    }

    public static async uploadVisitGraveDocuments(
        graveSite : IGraveSite,
        grave : IGrave,
        visitAgreements : Array<IFileUpload>,
        ) {
        try {
            visitAgreements.filter(x => x.file).forEach(n =>
                FileFunctions.queueGraveFile(
                    { ...n! },
                    EnumFileCategory.GraveVisitAgreementFiles,
                    `${graveSite.guid},${grave.guid}`,
                ),
            );
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while uploading files.', e);
            throw e;
        }
    }

    public static async uploadTombstoneGraveDocuments(
        graveSite : IGraveSite,
        grave : IGrave,
        tombstoneAgreement : Array<IFileUpload>,
        ) {
        try {
            tombstoneAgreement.filter(x => x.file).forEach(n =>
                FileFunctions.queueGraveFile(
                    { ...n! },
                    EnumFileCategory.GraveTombstoneErectionApplicationFiles,
                    `${graveSite.guid},${grave.guid}`,
                ),
            );
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while uploading files.', e);
            throw e;
        }
    }

    public static async uploadBurialGraveDocuments(
        graveSite : IGraveSite,
        grave : IGrave,
        burialAgreement : Array<IFileUpload>,
        ) {
        try {
            burialAgreement.filter(x => x.file).forEach(n =>
                FileFunctions.queueGraveFile(
                    { ...n! },
                    EnumFileCategory.GraveBurialAgreementFiles,
                    `${graveSite.guid},${grave.guid}`,
                ),
            );
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while uploading files.', e);
            throw e;
        }
    }

    public static async saveVisit(graveVisit : IGraveVisit) {
        try {
            const result = await GraveVisitHttpService.save(graveVisit);

            const graveSites = getState().graveSites.graveSites.slice();
            const graveSiteIndex = graveSites.findIndex(x => x.graves.some(n => n.id === result.data.graveId));
            const graveSite = {
                ...graveSites[graveSiteIndex],
                graves: graveSites[graveSiteIndex].graves.slice(),
            };

            const graves = graveSite.graves.slice();
            const graveIndex = graves.findIndex(x => x.id === result.data.graveId);
            const grave = {
                ...graves[graveIndex],
                graveVisits: graves[graveIndex].graveVisits.slice(),
            };

            const visitIndex = grave.graveVisits.findIndex(x => x.id === result.data.id);

            if (visitIndex === -1) {
                grave.graveVisits.unshift(result.data);
            } else {
                grave.graveVisits.splice(visitIndex, 1, result.data);
            }

            graveSite.graves.splice(graveIndex, 1, grave);

            graveSites.splice(graveSiteIndex, 1, graveSite);

            const old = getState().graveSites.graveSites.slice();
            dispatch(graveSiteActions.setGraveSites(graveSites));
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while saving visit.', e);
            throw e;
        }
    }
}
