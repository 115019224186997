import { IObservation } from '../../@types/model/observation';
import { ObservationAction } from '../../@types/redux';
import observationActions from './actions';
import { getType } from 'typesafe-actions';

export interface IObservationState {
    isLoading : boolean;
    observations : Array<IObservation>;
    selectedObservation? : IObservation;
}

const initialState = {
    isLoading: false,
    observations: [],
};

export default function dataReducer(state : IObservationState = initialState, action : ObservationAction) : IObservationState {
    switch (action.type) {
        case getType(observationActions.setObservations):
            return { ...state, observations: action.payload };
        case getType(observationActions.setObservation):
            return { ...state, selectedObservation: action.payload };
        case getType(observationActions.setLoadingObservations):
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}
