import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import { v4 } from 'uuid';
import { Button, CircularProgress, DialogActions, DialogContent, Icon } from '@material-ui/core';
import moment from 'moment';
import { IFileUpload } from '../../../../@types/viewmodel/file';
import { IGraveSite, IGraveSiteUpsert } from '../../../../@types/model/graveSite/graveSite';
import { IGraveUpsert } from '../../../../@types/model/graveSite/grave';
import GraveSiteFunctions from '../../../../store/graveSites/functions';
import GraveForm from '../form/GraveForm';
import { createSelector } from 'reselect';
import numWords from 'num-words';
import StandardFab from '../../custom/button/StandardFab';
import NumberWordDiv from '../../custom/div/NumberWordDiv';

interface IGraveAddDialogProps {
    graveSite : IGraveSite;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
}

interface IGraveAddDialogState {
    open : boolean;
    files : Array<IFileUpload>;
    graves : Array<IGraveUpsert>;
    isLoading : boolean;
}

export default class GraveAddDialog extends React.Component<IGraveAddDialogProps, IGraveAddDialogState> {
    constructor(props : IGraveAddDialogProps) {
        super(props);
        this.state = {
            open: false,
            files: [],
            graves: [],
            isLoading: false,
        };
    }

    public componentDidUpdate = (prevProps : IGraveAddDialogProps, prevState : IGraveAddDialogState) => {
        const { graveSite } = this.props;
        if (!prevState.open && this.state.open) {
            this.setState({
                files: [],
                graves: [],
            });
        }
    }

    public onCreateClick = () => {
        this.setState({
            open: true,
        });
    }

    public readonly onClose = () => {
        this.setState({
            open: false,
            graves: [],
        });
    }

    public readonly addGrave = () => {
        this.setState({
            graves: [
                ...this.state.graves, {
                    id: 0,
                    applicantName: '',
                    applicantContact: '',
                    dateOfBurial: moment.utc().toISOString(),
                    dateOfDeath: moment.utc().toISOString(),
                    registerDate: moment.utc().toISOString(),
                    nameOfDeceased: '',
                    number: this.state.graves.length + this.props.graveSite.graves.length + 1,
                    graveSiteId: 0,
                    fileNumber: '',
                    identified: true,
                    guid: v4(),
                } as IGraveUpsert,
            ],
        });
    }

    private readonly save = async () => {
        const { graveSite } = this.props;
        const { graves, files } = this.state;
        this.setState({
            isLoading: true,
        });

        const result = await GraveSiteFunctions.save({
            id: graveSite.id,
            guid: graveSite.guid,
            date: graveSite.date,
            description: graveSite.description,
            divisionId: graveSite.division?.id,
            representative: graveSite.representative,
            representativeName: graveSite.representativeName,
            representativeContact: graveSite.representativeContact,
            number: graveSite.number,
            geo: graveSite.geo,
            isPrivate: graveSite.isPrivate,
            graves,
        } as IGraveSiteUpsert, files);

        if (result != null) {
            this.onClose();
        }

        this.setState({
            isLoading: false,
        });

    }
    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private onChange = (grave : IGraveUpsert, index : number) => {
        const newGraves = [...this.state.graves];
        newGraves.splice(index, 1, grave);

        this.setState({
            graves: newGraves,
        });
    }

    public readonly getGravesCount = (state : IGraveAddDialogState, props : IGraveAddDialogProps) => state.graves.length + props.graveSite.graves.length;
    private readonly getNextNumberWord = createSelector(
        [this.getGravesCount],
        (number) => {
            return numWords(number + 1).toLocaleUpperCase();
        },
    );

    public render = () => {
        const { transition, maxWidth, fullScreen, fullWidth, graveSite } = this.props;
        const { open, graves, isLoading } = this.state;

        const gravesCount = this.getNextNumberWord(this.state, this.props);
        return (
            <React.Fragment>
                <Tooltip title={'Add'}>
                    <div>
                        {
                            <StandardFab onClick={this.onCreateClick} aria-label='Add'>
                                <AddIcon />
                            </StandardFab>
                        }
                    </div>
                </Tooltip>
                    <Dialog
                        open={open}
                        TransitionComponent={transition}
                        transitionDuration={500}
                        onClose={this.onClose}
                        maxWidth={maxWidth}
                        fullScreen={fullScreen}
                        fullWidth={fullWidth}
                        aria-labelledby='Create-dialog-title'
                        aria-describedby='Create-description'>
                        <AppBar position='static'>
                            <Toolbar>
                                <Typography variant='h6' color='inherit' className='flx1'>
                                    ADD GRAVE
                                </Typography>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close' className='aife'>
                                    <CloseIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        {
                            !isLoading &&
                            <form className='fdc flx1 aifs ais' onSubmit={this.onSubmit}>
                                <DialogContent className='fdc flx1 oxh'>
                                    <AppBar position='static'>
                                        <Toolbar>
                                            <Typography variant='h6' color='inherit' className='flx1'>
                                                SITE INFO
                                            </Typography>
                                        </Toolbar>
                                    </AppBar>
                                    <div className={'fdr flx1 bocg3 p20'}>
                                        <div className={'fdc flx1'}>
                                            <Typography variant='caption' color='textPrimary' className='flx1'>
                                                Division No
                                            </Typography>
                                            <Typography variant='subtitle1' color='textPrimary' className='flx1 pb20'>
                                                {graveSite.division?.code}
                                            </Typography>
                                            <Typography variant='caption' color='textPrimary' className='flx1'>
                                                Site Description
                                            </Typography>
                                            <Typography variant='subtitle1' color='textPrimary' className='flx1 pb20'>
                                                {graveSite.description}
                                            </Typography>
                                            <Typography variant='caption' color='textPrimary' className='flx1'>
                                                Region
                                            </Typography>
                                            <Typography variant='subtitle1' color='textPrimary' className='flx1 pb20'>
                                                {graveSite.number}
                                            </Typography>
                                        </div>
                                        <div className={'fdc flx1'}>
                                        <Typography variant='caption' color='textPrimary' className='flx1'>
                                                Registration Date
                                            </Typography>
                                            <Typography variant='subtitle1' color='textPrimary' className='flx1 pb20'>
                                                {graveSite.createdOn}
                                            </Typography>
                                            <Typography variant='caption' color='textPrimary' className='flx1'>
                                                ZZ2 Representative
                                            </Typography>
                                            <Typography variant='subtitle1' color='textPrimary' className='flx1 pb20'>
                                                {graveSite.representativeName}
                                            </Typography>
                                            <Typography variant='caption' color='textPrimary' className='flx1'>
                                                ZZ2 Representative Contact
                                            </Typography>
                                            <Typography variant='subtitle1' color='textPrimary' className='flx1 pb20'>
                                                {graveSite.representativeContact}
                                            </Typography>
                                        </div>
                                    </div>
                                    <AppBar position='static'>
                                        <Toolbar>
                                            <Typography variant='h6' color='inherit' className='flx1'>
                                                ADD GRAVES
                                            </Typography>
                                        </Toolbar>
                                    </AppBar>
                                    <div className='p10 hfill wfill'>
                                        {
                                            graves.map((grave, index) => {
                                                return (
                                                    <GraveForm
                                                        grave={grave}
                                                        index={index}
                                                        key={index}
                                                        onChange={this.onChange}
                                                    />);
                                            })
                                        }
                                        <div className='fdr flx1 pl10'>
                                            <NumberWordDiv
                                                number={graveSite.graves.length + graves.length + 1}
                                            >
                                                GRAVE
                                            </NumberWordDiv>
                                            <IconButton color='inherit' onClick={this.addGrave} className='aife'>
                                                <Icon className='bcp brh p10'>
                                                    <AddIcon className='cw'/>
                                                </Icon>
                                            </IconButton>
                                        </div>
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button variant='text' onClick={this.onClose} color='primary' autoFocus>
                                        CANCEL
                                    </Button>
                                    <Button className='br25 bcy cp' type='submit' variant='contained'>
                                        SAVE
                                    </Button>
                                </DialogActions>
                            </form>
                        }
                        {
                            isLoading &&
                            <div className={'fdc hfill aic jcc'}>
                                <div className={'aic jcc'}>
                                    <CircularProgress color={'primary'} className={''} />
                                </div>
                            </div>
                        }
                    </Dialog>
            </React.Fragment>
        );
    }
}
