import * as React from 'react';
import Icon from '@material-ui/core/Icon/Icon';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Collapse from '@material-ui/core/Collapse/Collapse';
import Divider from '@material-ui/core/Divider/Divider';
import Avatar from '@material-ui/core/Avatar';

interface INavMenuListItemProps {
    icon : string;
    title : string;
    imgProps? : React.ImgHTMLAttributes<HTMLImageElement>;
}

interface INavMenuListItemState {
    open : boolean;
}

export default class NavMenuListItem extends React.Component<INavMenuListItemProps, INavMenuListItemState> {
    constructor(props : INavMenuListItemProps) {
        super(props);

        this.state = {
            open: false,
        };

    }

    public onClick = () => {
        this.setState({
            open: !this.state.open,
        });
    }

    public render() {
        return (
            <List className='pt0'>
                <ListItem button onClick={this.onClick} key={this.props.title}>
                    <ListItemIcon>
                        <Avatar src={this.props.icon} imgProps={this.props.imgProps}>
                        </Avatar>
                    </ListItemIcon>
                    <ListItemText primary={this.props.title} />
                    {this.state.open ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
                </ListItem>
                {
                    this.state.open && <Divider />
                }
                <Collapse in={this.state.open} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                        {this.props.children}
                    </List>
                </Collapse>
            </List>
        );
    }
}
