import * as React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { connect } from 'react-redux';
import { IAuthState } from '../../store/auth/reducer';
import { IRootState } from '../../@types/redux';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Divider from '@material-ui/core/Divider/Divider';
import Avatar from '@material-ui/core/Avatar';
import authFunctions from '../../store/auth/functions';
import navActions from '../../store/nav/actions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import RightsNavMenu from './navMenu/Rights';
import MasterDataNavMenu from './navMenu/MasterData';
import AccessEventNavMenu from './navMenu/AccessEvent';
import IncidentNavMenu from './navMenu/Incident';
import ObservationNavMenu from './navMenu/Observation';
import VirusNavMenu from './navMenu/Virus';
import GraveSiteNavMenu from './navMenu/GraveSite';
import OccurrenceNavMenu from './navMenu/Occurrence';
import EnvHelper from '../../service/helper/envHelper';

interface INavDrawerProps {
    isOpen : boolean;
    toggleDrawer : () => void;
    auth : IAuthState;
}

class NavDrawer extends React.Component<INavDrawerProps> {
    constructor(props : INavDrawerProps) {
        super(props);
    }

    public logout = () => {
        authFunctions.authLogOut().then(() => {
            this.props.toggleDrawer();
        });
    }

    public navUrl = (url : null | string) => {
        if (!url) return;
        navActions.navUrl(url.replace('*', ''));
        this.props.toggleDrawer();
    }

    private navMap = () => {
        this.navUrl('/map');
    }

    private onLogoutClick = () => {
        this.props.toggleDrawer();
        authFunctions.authLogOut();
    }

    public render() {
        const {
            auth,
        } = this.props;
        const env = EnvHelper.getEnvName();

        return (
            <div>
                <Drawer variant='temporary' anchor='left' open={this.props.isOpen} onClose={this.props.toggleDrawer}>
                    <AppBar position='static' elevation={0}>
                        <Toolbar variant='dense'>
                            <IconButton color={'inherit'} onClick={this.props.toggleDrawer}>
                                <MenuIcon />
                            </IconButton>
                            <img height={40} src={`${ASSET_BASE}/assets/images/logo.png`} className={'mr10 mb5 mt5'} />
                            <Typography color='inherit' variant='h6'>
                                {env}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className=' fdc flx1 bcg1 oa drawer cpd fs16'>
                        <ListItem button onClick={this.navMap} key='Notification_Map'>
                            <ListItemIcon>
                                <Avatar src={`${ASSET_BASE}/assets/images/icons/notificationMap.svg`} />
                            </ListItemIcon>
                            <ListItemText className='cpd fs16' primary='Notification Map' />
                        </ListItem>
                        <IncidentNavMenu
                            auth={auth}
                            onClick={this.navUrl}
                        />
                        <ObservationNavMenu
                            auth={auth}
                            onClick={this.navUrl}
                        />
                        <OccurrenceNavMenu
                            auth={auth}
                            onClick={this.navUrl}
                        />
                        <GraveSiteNavMenu
                            auth={auth}
                            onClick={this.navUrl}
                        />
                        <VirusNavMenu
                            auth={auth}
                            onClick={this.navUrl}
                        />
                        <AccessEventNavMenu
                            auth={auth}
                            onClick={this.navUrl}
                        />
                        <MasterDataNavMenu
                            auth={auth}
                            onClick={this.navUrl}
                        />
                        <RightsNavMenu
                            auth={auth}
                            onClick={this.navUrl}
                        />
                    </div>
                    <Divider className='bcg0' />
                    <div className='bcg1'>
                        <MenuItem onClick={this.onLogoutClick} className='cpd fs16'>
                            <img className={'h20'} src={`${ASSET_BASE}/assets/images/icons/logout.svg`} />
                            <span className={'flx1'} />
                            Logout
                            <span className={'flx2'} />
                        </MenuItem>
                    </div>
                </Drawer>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};
export default connect(
    mapStateToProps,
)(NavDrawer);
