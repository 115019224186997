import generalFunctions from '../general/functions';
import { dispatch, getState } from '..';
import roleHttpService from '../../service/http/roleHttpService';
import { IMappedRight, IRight, IRole } from '../../@types/model/right';
import rightHttpService from '../../service/http/rightHttpService';
import lodash from 'lodash';
import { setArrayElement, addArrayElement, getIndexOfArrayElement } from '../../service/helper/functionHelperService';
import * as notificationTypeHttpService from '../../service/http/notificationTypeHttpService';
import rightsActions from './actions';
import UserHttpService from '../../service/http/userHttpService';

export default class RightsFunctions {
    public static async getAllNotificationTypes() {
        try {
            const state = getState();

            if (state.rights.notificationTypes.length === 0) {
                const result = await notificationTypeHttpService.getAll();

                dispatch(rightsActions.setNotificationTypes(result.data));
            }
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading Notification Types.', e);
        }
    }

    public static async getAllRoles(name? : string, code? : string, isActive? : boolean) {
        try {
            const state = getState();

            if (state.rights.roles.length === 0) {
                dispatch(rightsActions.setRolesLoading(true));
                const result = await roleHttpService.getList(name, code, isActive);

                dispatch(rightsActions.setRoles(result.data));
            }
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading Roles.', e);
        } finally {
            dispatch(rightsActions.setRolesLoading(false));
        }
    }

    public static async saveRole(role : IRole) {
        try {
            const result = await roleHttpService.save(role);
            const state = getState();

            const index = getIndexOfArrayElement(state.rights.roles, result.data, 'id');

            let items = [];
            if (index > -1) {
                items = setArrayElement<IRole>(state.rights.roles, index, result.data);
            } else {
                items = addArrayElement<IRole>(state.rights.roles, result.data, 'end');
            }

            dispatch(rightsActions.setRoles(items));
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred saving Role.', e);
            await Promise.reject();
        }
    }

    public static getUserRole() : number | null {
        const state = getState();

        if (!!!state.auth.session) return null;
        if (!!!state.auth.session.user.roleId) return null;

        return state.auth.session.user.roleId;
    }

    public static async getAllRights(name? : string, code? : string, isActive? : boolean) {
        try {
            const state = getState();

            if (state.rights.rights.length === 0) {
                dispatch(rightsActions.setIsLoading(true));
                const result = await rightHttpService.getList(name, code, isActive);

                dispatch(rightsActions.setRights(result.data));
            }
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading Rights.', e);
        } finally {
            dispatch(rightsActions.setIsLoading(false));
        }
    }

    public static mapRightsToMappedRights(rights : Array<IRight>) : Array<IMappedRight> {
        const result : Array<IMappedRight> = [];

        rights.forEach((n) => {
            if (!n.parentId) {
                result.push({
                    id: n.id,
                    code: n.code,
                    name: n.name,
                    url: n.url,
                    isMenu: n.isActive,
                    children: this.mapRightChildrenToMappedRights(rights, n.id),
                });
            }
        });

        return result;
    }

    public static mapRightChildrenToMappedRights(rights : Array<IRight>, parentId? : number) : Array<IMappedRight> {
        const result : Array<IMappedRight> = [];

        const children = lodash.filter(rights, n => n.parentId === parentId);

        children.forEach((n) => {
            result.push({
                id: n.id,
                code: n.code,
                name: n.name,
                url: n.url,
                isMenu: n.isActive,
                children: this.mapRightChildrenToMappedRights(rights, n.id),
            });
        });

        return result;
    }

    public static async loadUsers(refresh? : boolean) {
        try {
            const state = getState();

            if (refresh || state.rights.rights.length === 0) {
                dispatch(rightsActions.setUserLoading(true));
                const result = await UserHttpService.getUserList();

                dispatch(rightsActions.setUsers(result.data));
            }
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading Users.', e);
        } finally {
            dispatch(rightsActions.setUserLoading(false));
        }
    }

    public static async getUser(id : number) {
        try {
            const result = await UserHttpService.get(id);

            return result.data;
        } catch (ex) {
            generalFunctions.showErrorSnackbar('An error occurred while getting user', ex);
            return null;
        }
    }

    public static async saveUser(
        userId : number,
        roleId : number,
        employeeNr : string,
        rightIds : Array<number>,
        divisions : Array<string>,
        accessPoints : Array<number>,
        isActive : boolean,
    ) {
        try {
            dispatch(rightsActions.setUserLoading(true));
            const result = await UserHttpService.save(userId, roleId, employeeNr,
                rightIds, divisions, accessPoints, isActive);

            const users = getState().rights.users.slice();

            const index = users.findIndex(x => x.id === result.data.id);

            if (index > -1) {
                const current = {
                    ...users[index],
                };
                users.splice(index, 1, {
                    ...current,
                    email: !result.data.email ? current.email : result.data.email,
                    isActive: !result.data.isActive ? current.isActive : result.data.isActive,
                    name: !result.data.name ? current.name : result.data.name,
                    roleId: !result.data.roleId ? current.roleId : result.data.roleId,
                    role: !result.data.roleName ? current.role : result.data.roleName,
                    updatedOn: !result.data.updatedOn ? current.updatedOn : result.data.updatedOn,
                    employeeNr: !result.data.employeeNr ? current.employeeNr : result.data.employeeNr,
                });

                dispatch(rightsActions.setUsers(users));
            }
        } catch (ex) {
            generalFunctions.showErrorSnackbar('An error occurred while saving user', ex);
            throw ex;
        } finally {
            dispatch(rightsActions.setUserLoading(false));
        }
    }
}
