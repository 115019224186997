import React from 'react';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import { createSelector } from 'reselect';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { IDivision } from '../../../../@types/model/division';
import MasterDataFunctions from '../../../../store/masterData/functions';

interface IDepartmentDropdownProps {
    value ?: number | null;

    onChange : (value? : IDivision) => void;

    divisions : Array<IDivision>;

    required? : boolean;
    autoFocus? : boolean;

    width? : string | number;
    fullWidth? : boolean;
    isLoading : boolean;
    label? : string;
}

interface IDepartmentDropdownState {
}

class DepartmentDropdown extends React.Component<IDepartmentDropdownProps, IDepartmentDropdownState> {
    constructor(props : IDepartmentDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        await MasterDataFunctions.getAllDivisions();
    }

    private onChanged = (event : React.ChangeEvent<{}>, value : {
        label : string,
        value : number | null,
    } | null) => {
        const result = this.props.divisions.find(n => n.id === value?.value);

        this.props.onChange(result);
    }

    private getData = (state : IDepartmentDropdownState, props : IDepartmentDropdownProps) => props.divisions;
    private getValue = (state : IDepartmentDropdownState, props : IDepartmentDropdownProps) => props.value;
    private getRequired = (state : IDepartmentDropdownState, props : IDepartmentDropdownProps) => props.required;
    private getDivisionsDropdown = createSelector([
        this.getData, this.getRequired,
    ], (divisions, required) => {
        const divisionDrop = divisions.filter(x => x.isActive).map(x => ({ label: x.description.toTitleCase(), value: x.id }));

        if (!required) {
            divisionDrop.unshift({
                label: 'ALL',
                value: 0,
            });
        }

        return divisionDrop;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getDivisionsDropdown, this.getRequired,
    ], (value, values, required) => {
        if (value) {
            const dropdown = values.find(x => x.value === value);

            if (dropdown) {
                return dropdown;
            }
        }

        if (!required) {
            return {
                label: 'ALL',
                value: null as number | null,
            };
        }

        return null;
    });

    public render = () => {
        const {  } = this.state;
        const { required, isLoading, autoFocus, width, fullWidth, label } = this.props;
        const divisions = this.getDivisionsDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required} >
                <Autocomplete
                    id='division_select'
                    options={divisions}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChanged}
                    disableClearable={required}
                    openOnFocus
                    style={{ width: width ? width : undefined }}
                    renderInput={params => <TextField autoFocus={autoFocus} {...params}
                        fullWidth={fullWidth} label={label ?? 'Department'}
                        error={required && !value}
                        required={required}
                        InputLabelProps={{
                            className: 'fs16 lh21 cpla lsp024',
                        }}
                    />}
                />
                    {
                        required && !value &&
                        <FormHelperText error>Required</FormHelperText>
                    }
                    {
                        isLoading &&
                        <LinearProgress className='wfill posb0 posa' />
                    }
            </FormControl>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        divisions: state.masterData.divisions,
        isLoading: state.masterData.isLoadingDivisions,
    };
};

export default connect(
    mapStateToProps,
)(DepartmentDropdown);
