import React from 'react';
import { TableSortLabel } from '@material-ui/core';
import MaterialTableFilter from './FilterMenuComponent';

interface IMaterialHeaderProps {
    onDateSortChange : (sort? : { field : string; direction : 'asc' | 'desc' }) => void;
    onFilterChange : (filterValue : { field : string; value? : string }) => void;
    field? : string;
    align? : 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;

    sorted? : boolean;

    enableSorting? : boolean;
    enableFiltering? : boolean;

    filtered? : boolean;
    filterRender? : () => JSX.Element;
}

interface IMaterialHeaderState {
    sort? : { field : string; direction : 'asc' | 'desc' };

}

export default class MaterialTableHeader extends React.Component<IMaterialHeaderProps, IMaterialHeaderState> {
    constructor(props : IMaterialHeaderProps) {
        super(props);

        this.state = {
        };
    }

    private onDataSortChange = () => {
        const direction = !!!this.state.sort || !!!this.state.sort.direction ? 'desc' : this.state.sort.direction === 'desc' ? 'asc' : undefined;
        const field = this.props.field ? this.props.field : '';

        this.setState({
            sort: !!!direction ? undefined : { field, direction },
        }, () => {
            this.props.onDateSortChange(this.state.sort);
        });

    }

    private onFilterChanged = (filterValue? : string) => {
        this.props.onFilterChange({
            field: this.props.field ? this.props.field : '',
            value: filterValue,
        });

    }

    public render() {
        const { sorted, children, field, enableSorting, enableFiltering, filtered, filterRender } = this.props;
        const { sort } = this.state;
        return (
            <React.Fragment>
                {
                    (!!!field || !!!enableSorting) &&
                    children
                }
                {
                    !!field && !!enableSorting &&
                    <TableSortLabel
                    style={{
                        textAlign: this.props.align,
                        height: 'inherit',
                        color: !filtered ? '' : '#FEE733',
                    }}
                    className='sort-header'
                    active={!!sorted && !!sort && sort.field === field}
                    direction={!!!sorted || !!!sort || sort.field !== field ? undefined : sort.direction}
                    onClick={!!!field ? undefined : this.onDataSortChange}>
                        {children}
                    </TableSortLabel>
                }
                {
                    !!field && enableFiltering && !filterRender &&
                    <MaterialTableFilter field={field} onFilterChanged={this.onFilterChanged} />
                }
                {
                    !!field && enableFiltering && filterRender &&
                    filterRender()
                }
            </React.Fragment>
        );
    }
}
