import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch, withRouter } from 'react-router';
import { IRootState } from '../../@types/redux';
import { IAuthState } from '../../store/auth/reducer';
import NotificationMap from '../component/map/NotificationMap';
import ObservationList from '../component/observations/ObservationList';
import IncidentList from '../component/incidents/IncidentList';
import GraveSiteList from '../component/graveSite/GraveSiteList';
import Covid19IncidentList from '../component/incidents/Covid19IncidentList';
import UserListComponent from '../component/right/user/List';
import authFunctions from '../../store/auth/functions';
import RoleListComponent from '../component/right/role/RoleListComponent';
import VehicleEntryComponent from '../component/accessPointEvent/VehicleEntryList';
import PersonEntryComponent from '../component/accessPointEvent/PersonEntryList';
import AccessPointListComponent from '../component/accessPoint/List';
import IncidentCategoryListComponent from '../component/incidentCategory/List';
import DivisionListComponent from '../component/division/List';
import RegionListComponent from '../component/region/List';
import IncidentGroupListComponent from '../component/incidentGroup/List';
import GraveVisitReasonList from '../component/masterData/GraveVisitReason/List';
import AccessPointCategoryList from '../component/masterData/AccessPointCategory/List';
import DivisionTypeList from '../component/masterData/divisionType/List';
import PowerSourceList from '../component/masterData/powerSource/List';
import OccurrenceList from '../component/occurrence/OccurrenceList';

interface IPrivateRouteProps extends RouteProps {
    auth : IAuthState;
}

class PrivateRoute extends React.Component<IPrivateRouteProps> {

    /**
     * Check if current user is associated with a right that corresponds to the destination url.
     * @param url
     */
    public hasMenuRight = (url : string) : boolean => {
        // TODO: Add user rights.
        return !!this.props.auth;
    }

    public render () {
        const { component, auth, ...rest } = this.props;
        if (!component) {
            return;
        }

        /* tslint:disable-next-line:variable-name */
        const Component = component;
        const render = (props : RouteComponentProps<any>) : React.ReactNode => {
            if (this.props.auth.session) {
                if (!this.hasMenuRight(props.match.path)) {
                    return <div> You don't have access to this page. </div>;
                }

                return <Component {...props} />;
            }
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        };

        return (<Route {...rest} render={render} />);
    }
}

class PrivateRightRoute extends React.Component<IPrivateRouteProps> {
    public render () {
        const { component, auth, ...rest } = this.props;
        if (!component) {
            return;
        }

        /* tslint:disable-next-line:variable-name */
        const Component = component;
        const render = (props : RouteComponentProps<any>) : React.ReactNode => {
            if (this.props.auth.session) {
                if (!authFunctions.hasMenuRight(props.match.path)) {
                    return <div> You don't have access to this page. </div>;
                }

                return <Component {...props} />;
            }
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        };

        return (<Route {...rest} render={render} />);
    }
}

interface IRoutesProps extends RouteComponentProps {
    auth : IAuthState;
}

class Routes extends React.Component<IRoutesProps> {
    constructor(props : IRoutesProps) {
        super(props);
    }

    public render() {
        const { auth } = this.props;

        return (
            <Switch>
                <Route
                    path={'/'} exact
                    render={() => <Redirect from={'/'} to={{ pathname: '/map' }} />}
                />
                <PrivateRoute exact auth={auth} path='/map' component={NotificationMap} />
                <PrivateRightRoute exact auth={auth} path='/observation/list' component={ObservationList} />
                <PrivateRightRoute exact auth={auth} path='/occurrence/list' component={OccurrenceList} />
                <PrivateRightRoute exact auth={auth} path='/incident/list' component={IncidentList} />
                <PrivateRightRoute exact auth={auth} path='/gravesites/list' component={GraveSiteList} />
                <PrivateRightRoute exact auth={auth} path='/incident/covid19/list' component={Covid19IncidentList} />
                <PrivateRightRoute auth={auth} path='/right/user' component={UserListComponent} />
                <PrivateRightRoute auth={auth} path='/right/role' component={RoleListComponent} />
                <PrivateRightRoute exact auth={auth} path='/vehicle/list' component={VehicleEntryComponent} />
                <PrivateRightRoute exact auth={auth} path='/access/event/person' component={PersonEntryComponent} />
                <PrivateRightRoute auth={auth} path='/masterData/accessPoint' component={AccessPointListComponent} />
                <PrivateRightRoute auth={auth} path='/masterData/incidentCategory' component={IncidentCategoryListComponent} />
                <PrivateRightRoute auth={auth} path='/masterData/division' component={DivisionListComponent} />
                <PrivateRightRoute auth={auth} path='/masterData/regions' component={RegionListComponent} />
                <PrivateRightRoute auth={auth} path='/masterData/incidentGroups' component={IncidentGroupListComponent} />
                <PrivateRightRoute auth={auth} path='/masterData/graveVisitReason' component={GraveVisitReasonList} />
                <PrivateRightRoute auth={auth} path='/masterData/accessPointCategory' component={AccessPointCategoryList} />
                <PrivateRightRoute auth={auth} path='/masterData/divisionType' component={DivisionTypeList} />
                <PrivateRightRoute auth={auth} path='/masterData/powerSource' component={PowerSourceList} />
            </Switch>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

/* With router is important because it ensures the component updates with location changes, therefore allowing redirects */
export default connect(
    mapStateToProps,
)(withRouter(Routes));
