import React from 'react';

interface IAwayClickerProps {
    onClickAway : () => void;
}

export default class AwayClicker extends React.Component<IAwayClickerProps> {
    private wrapperRef : any;
    constructor(props : IAwayClickerProps) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
    }

    public onClick = (e : MouseEvent) => {
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
            this.props.onClickAway();
        }
    }

    public componentDidMount() {
        document.addEventListener('mousedown', e => this.onClick(e));
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', e => this.onClick(e));
    }

    /**
     * Set the wrapper ref
     */
    public setWrapperRef(node : any) {
        this.wrapperRef = node;
    }

    public render() {
        return <div ref={this.setWrapperRef}>{this.props.children}</div>;
    }
}
