import { dispatch } from '..';
import vehicleActions from './actions';
import generalFunctions from '../general/functions';
import { AccessPointEventHttpService } from '../../service/http/accessPointEventHttpService';

export class AccessPointEventFunctions {
    public static async getAll(startDateUnixMilli : number, endDateUnixMilli : number) {
        try {
            dispatch(vehicleActions.setIsLoadingEntries(true));
            const result = await AccessPointEventHttpService.getAllVehicleEntries(startDateUnixMilli, endDateUnixMilli);

            dispatch(vehicleActions.setVehicleEntries(result.data));
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading vehicles', e);
        }
        dispatch(vehicleActions.setIsLoadingEntries(false));
    }

    /**
     * Get all people access entries.
     * Has canceler
     * @param startDateUnixMilli
     * @param endDateUnixMilli
     */
    public static async getAllPeople(startDateUnixMilli : number, endDateUnixMilli : number) {
        try {
            dispatch(vehicleActions.setIsLoadingPersonEntries(true));
            const result = await AccessPointEventHttpService.getAllPeopleEntries(startDateUnixMilli, endDateUnixMilli);

            dispatch(vehicleActions.setPersonEntries(result.data));
        } catch (e) {
            if (e) {
                generalFunctions.showErrorSnackbar('An error occurred while loading people entries', e);
            }
        } finally {
            dispatch(vehicleActions.setIsLoadingPersonEntries(false));
        }
    }

    public static async get(id? : number, guid? : string) {
        try {
            dispatch(vehicleActions.setIsLoadingEntry(true));

            const result = await AccessPointEventHttpService.getEntry(id, guid);

            dispatch(vehicleActions.setEntry(result.data));
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading entry', e);
        }
        dispatch(vehicleActions.setIsLoadingEntry(false));
    }

    /**
     * Has canceler and returns null if canceled.
     * @param accessPointId
     * @param limit
     */
    public static async getLatest(isInbound : boolean, accessPointId : number, limit : number) {
        try {
            const result = await AccessPointEventHttpService.getVehicleLatest(isInbound, accessPointId, limit);

            return result.data;
        } catch (e) {
            if (e) {
                generalFunctions.showErrorSnackbar('An error occurred while loading entries', e);
            } else {
                return null;
            }
        }

        return [];
    }
}
