import React from 'react';
import { createSelector } from 'reselect';
import { IAuthState } from '../../../store/auth/reducer';
import { IUserRight } from '../../../@types/model/right';
import { RIGHTS } from '../../../appConstants';
import NavMenuItem from '../../component/custom/listItem/NavMenuItem';

interface IOccurrenceNavMenuProps {
    auth : IAuthState;
    onClick : (url : string) => void;
}

interface IOccurrenceNavMenuState {}

export default class OccurrenceNavMenu extends React.PureComponent<IOccurrenceNavMenuProps, IOccurrenceNavMenuState> {
    constructor(props : IOccurrenceNavMenuProps) {
        super(props);
        this.state = {};
    }
    public readonly getUserRights = (props : IOccurrenceNavMenuProps) => props.auth.session?.user.userRights ?? [];
    public readonly getRights = createSelector(
        [this.getUserRights],
        (userRights : Array<IUserRight>) => {
            return userRights.find(n => n.rightId === RIGHTS.OCC && n.isActive);
        },
    );

    public readonly render = () => {
        const {
            onClick,
        } = this.props;

        const rights = this.getRights(this.props);

        return (
            !!rights &&
            <NavMenuItem userRight={rights}
                key={`${rights.right?.name}_Nav`}
                onClick={onClick}
                icon={`${ASSET_BASE}/assets/images/Occurrence.png`}
                imgProps={{
                    className: 'h30',
                }}
            />
        );
    }
}
