import moment, { Moment } from 'moment';

export type FormatType = typeof DateHelperService.FORMATS[number];

export default class DateHelperService {
    public static readonly FORMATS : ['YYYY-MM-DD HH:mm'] = ['YYYY-MM-DD HH:mm'];
    public static format(value : null | number | Moment | string, format : FormatType) : string {
        return moment.utc(value).local().format(format);
    }
}
