import { getType } from 'typesafe-actions';
import { IGraveSite } from '../../@types/model/graveSite/graveSite';
import { GraveSiteAction } from '../../@types/redux';
import graveSiteActions from './actions';

export interface IGraveSiteState {
    isLoading : boolean;
    graveSites : Array<IGraveSite>;
    selectedGraveSite? : IGraveSite;
}

const initialState = {
    isLoading: false,
    graveSites: [],
};

export default function dataReducer(state : IGraveSiteState = initialState, action : GraveSiteAction) : IGraveSiteState {
    switch (action.type) {
        case getType(graveSiteActions.setGraveSites):
            return { ...state, graveSites: action.payload };
        case getType(graveSiteActions.setGraveSite):
            return { ...state, selectedGraveSite: action.payload };
        case getType(graveSiteActions.setLoadingGraveSites):
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}
