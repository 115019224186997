import { IRole } from '../../../../@types/model/right';
import React from 'react';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { Transitions } from '../../custom/animations/Transitions';
import RoleEditDialog from './RoleEditDialogComponent';
import RightsFunctions from '../../../../store/right/functions';
import MaterialTable from '../../custom/materialTable/Table';
import TableFunctions from '../../custom/materialTable/functions';

interface IRoleListProps {
    roles : Array<IRole>;
}

interface IRoleListState {
    isLoading : boolean;
    editItem? : IRole;
    deleteItem? : IRole;

    isEditing : boolean;
}

class RoleList extends React.Component<IRoleListProps, IRoleListState> {
    constructor(props : IRoleListProps) {
        super(props);

        this.state = {
            isLoading: true,
            editItem: undefined,
            deleteItem: undefined,
            isEditing: false,
        };
    }

    public componentDidMount() {
        this.loadData();
    }

    private loadData() {
        RightsFunctions.getAllRoles(
        ).then((result) => {
            this.setState({
                isLoading: false,
            });
        });
    }

    private setEditItem = (item? : IRole) => {
        this.setState({
            editItem: item,
            isEditing: true,
        });
    }

    private onEditItemClose = () => {
        this.setState({
            editItem: undefined,
            isEditing: false,
        });
    }

    private setDeleteItem = (item? : IRole) => {
        this.setState({
            deleteItem: item,
        });
    }

    private refresh = () => {
        this.setState({
            isLoading: true,
        });

        this.loadData();
    }

    public render() {
        const { isLoading, editItem, isEditing } = this.state;
        const { roles } = this.props;

        return (
            <div className={'fdc flx1 p10 mh0'}>
                <div className={'flx1 fdc mh0'}>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            Roles
                        </Typography>
                        <IconButton color='primary' onClick={() => this.refresh()}>
                            <Tooltip title='Refresh'>
                                <Icon>
                                    refresh
                                </Icon>
                            </Tooltip>
                        </IconButton>
                        <span className={'flx1'} />
                        <IconButton onClick={() => this.setEditItem()}>
                            <Tooltip title='Create'>
                                <Icon>
                                    add
                                </Icon>
                            </Tooltip>
                        </IconButton>
                    </Toolbar>
                    <MaterialTable<IRole>
                        id='roleListTable'
                        data={roles}
                        isLoading={isLoading}
                        rowsPerPage={50}
                        selectable={false}
                        columns={[{
                            header: '',
                            width: 100,
                            paddingRight: '0',
                            renderCell: row => (
                                <div>
                                    <Tooltip title='Edit'>
                                        <IconButton onClick={() => this.setEditItem(row)}>
                                            <Icon>
                                                edit
                                            </Icon>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            ),
                        },        {
                            header: 'CODE',
                            field: 'code',
                            width: 150,
                            enableSort: true,
                        },        {
                            header: 'NAME',
                            field: 'name',
                            width: 150,
                            enableSort: true,
                        },        {
                            header: 'ACTIVE',
                            field: 'isActive',
                            width: 50,
                            enableSort: true,
                            renderCell: row => !!!row.isActive ? 'NO' : 'YES',
                        },        {
                            header: 'CREATED BY',
                            field: 'createdByName',
                            enableSort: true,
                            width: 150,
                        },        {
                            header: 'CREATED ON',
                            field: 'createdOn',
                            enableSort: true,
                            width: 150,
                            renderCell: row => TableFunctions.formatDateTime(row.createdOn),
                        },        {
                            header: 'UPDATED BY',
                            field: 'updatedByName',
                            enableSort: true,
                            width: 150,
                        },        {
                            header: 'UPDATED ON',
                            field: 'updatedOn',
                            enableSort: true,
                            renderCell: row => TableFunctions.formatDateTime(row.updatedOn),
                        }]}
                    />
                </div>
                <RoleEditDialog transition={Transitions.Up} open={isEditing || !!editItem} role={editItem} onClose={this.onEditItemClose} />
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        roles: state.rights.roles,
    };
};

export default connect(
    mapStateToProps,
)(RoleList);
