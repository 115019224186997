import axios, { AxiosPromise } from 'axios';
import { IOccurrence } from '../../@types/model/occurrence';

export default class OccurrenceHttpService {

    public static get(id? : number, guid? : string) : AxiosPromise<IOccurrence> {
        return axios.get(`${API_URL}/api/Occurrences/Occurrence/Get`, {
            params: {
                id,
                guid,
            },
        });
    }

    public static getAll(startDateUnixMilli : number, endDateUnixMilli : number) : AxiosPromise<Array<IOccurrence>> {
        return axios.get(`${API_URL}/api/Occurrences/Occurrence/GetAll`, {
            params: {
                startDateUnixMilli,
                endDateUnixMilli,
            },
        });
    }

    public static create(guid : string, date : number, divisionId : number, description : string, incident: boolean, isActive: boolean,) {
        return axios.post<IOccurrence>(`${API_URL}/api/Occurrences/Occurrence/Create`, {
            guid,
            divisionId,
            description,
            date,
            incident,
            isActive,
        }, {
            params: {
            },
        });
    }

    public static update(guid : string, date : number, divisionId : number, description : string, incident: boolean, isActive: boolean,) {
        return axios.post<IOccurrence>(`${API_URL}/api/Occurrences/Occurrence/Update`, {
            guid,
            divisionId,
            description,
            date,
            incident,
            isActive,
        }, {
            params: {
            },
        });
    }

    public static remove(occurrence : IOccurrence) {
        return axios.delete(`${API_URL}/api/Occurrences/Occurrence/Delete`, {
            params: {
                guid: occurrence.guid,
            },
        });
    }
}
