const theme = {
    colors: {
        white: '#FFFFFF',
        grey0: '#B4B4B4',
        grey1: '#eaeaea',
        grey2: '#434343',
        grey3: '#9E9E9E',
        grey4: '#EDEDED',
        orange: '#FF7F00',
        red: '#e81d2c',
        yellow: '#fddb00',
        label: '#424242',
        lightGreen: '#89C35A',
        material: {
            primary: {
                dark: '#0F4323',
                main: '#0d703f',
                light: '#098442',
                contrastText: '#FFFFFF',
            },
            secondary: {
                main: '#FFFFFF',
            },
        }
    },
};

export default theme;
