
export default class EnvHelper {
    public static isDevelopmentQa = () : boolean => {
        return ENV_NAME === 'qa';
    }

    public static getEnvName = () : string => {
        if (ENV_NAME === 'production') {
            return 'SECURITY';
        } else if (ENV_NAME === 'qa') {
            return 'SECURITY QA';
        } else if (ENV_NAME === 'dev') {
            return 'SECURITY DEV';
        } else {
            return 'SECURITY ENV NOT FOUND';
        }
    };
}