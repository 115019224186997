import {  Text, View } from '@react-pdf/renderer';
import style from '../../../../style/pdfStyleSheet';
import React from 'react';

interface IPDFIncidentHeaderProps {
    title : string;
}

interface IPDFIncidentHeaderState {}

export default class PDFIncidentHeader extends React.PureComponent<IPDFIncidentHeaderProps, IPDFIncidentHeaderState> {
    constructor(props : IPDFIncidentHeaderProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        return (
            <View style={[style.fdc, style.bbw1, style.bocpm, style.mb10]}>
                <View style={[style.fdr, style.cpm, style.h35, style.aic, style.pl12]}>
                    <Text style={[style.fs16, style.fwb]}>{this.props.title}</Text>
                </View>
            </View>
        );
    }
}
