import { dispatch } from '../index';
import generalActions from './actions';
import { IWebNotification } from '../../@types/notification';

export default class GeneralFunctions {
    /**
     * Adds an error snackbar to the queue. Attempts to retrieve the error message from the API, falling back to the default
     * message if the API returns no such thing.
     * @param defaultMessage
     * @param e
     */
    public static showErrorSnackbar(defaultMessage : string, ex? : any) {
        let errorMessage = defaultMessage;

        if (typeof(ex) === 'object') {
            if (ex && ex.data && typeof(ex.data) === 'string') {
                errorMessage = ex.data;
            } else if (ex && ex.data && typeof(ex.data) === 'object' && ex.data.title) {
                errorMessage = ex.data.title;
            }
        } else if (typeof(ex) === 'string') {
            errorMessage += ` ${ex}`;
        }

        dispatch(generalActions.enqueueSnackbar({
            message: errorMessage,
            options: {
                variant: 'error',
            },
        }));
    }

    /**
     * Adds a success snackbar to the queue.
     * @param message
     */
    public static showSuccessSnackbar(message : string) {
        dispatch(generalActions.enqueueSnackbar({
            message,
            options: {
                variant: 'success',
            },
        }));
    }

    /**
     * Adds a warning snackbar to the queue.
     * @param message
     */
    public static showWarningSnackbar(message : string) {
        dispatch(generalActions.enqueueSnackbar({
            message,
            options: {
                variant: 'warning',
            },
        }));
    }

    /**
     * Adds an info snackbar to the queue.
     * @param message
     */
    public static showInfoSnackbar(message : string) {
        dispatch(generalActions.enqueueSnackbar({
            message,
            options: {
                variant: 'info',
            },
        }));
    }

    /**
     * Adds a web notification to queue
     * @param webNotification
     */
    public static addWebNotification(webNotification : IWebNotification) {
        dispatch(generalActions.enqueueWebNotification(
            webNotification,
        ));
    }

    /**
     * Removes a web notification from queue
     * @param key
     */
    public static removeWebNotification(key : number) {
        dispatch(generalActions.removeWebNotification(
            key,
        ));
    }

    public static showPrintError(errorLocation : string, error : Error) {
        this.showErrorSnackbar(error.message);
    }
}
