import { FormControl, TextField, FormHelperText, FormControlLabel, Switch } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import moment from 'moment';
import React from 'react';
import { createSelector } from 'reselect';
import { IGraveUpsert } from '../../../../@types/model/graveSite/grave';
import { IFileUpload } from '../../../../@types/viewmodel/file';
import ImageFileUpload from '../../custom/file/ImageFileUpload';
import NumberWordDiv from '../../custom/div/NumberWordDiv';

interface IGraveFormProps {
    grave : IGraveUpsert;
    index : number;
    onChange : (grave : IGraveUpsert, index : number) => void;
}

interface IGraveFormState {
}

export default class GraveForm extends React.PureComponent<IGraveFormProps, IGraveFormState> {
    private readonly maxDate : moment.Moment = moment().utc().startOf('day');
    constructor(props : IGraveFormProps) {
        super(props);
        this.state = {
        };
    }

    private onApplicantNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!this.props.onChange) return;

        this.props.onChange({
            ...this.props.grave,
            applicantName: event.target.value,
        }, this.props.index);
    }

    private onApplicantContactChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!this.props.onChange) return;

        this.props.onChange({
            ...this.props.grave,
            applicantContact: event.target.value,
        }, this.props.index);
    }

    private onNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!this.props.onChange) return;

        this.props.onChange({
            ...this.props.grave,
            nameOfDeceased: event.target.value,
        }, this.props.index);
    }

    private onApplicationDateChange = (date : moment.Moment) => {
        if (!this.props.onChange) return;

        this.props.onChange({
            ...this.props.grave,
            registerDate: date.asUTC().toISOString(),
        }, this.props.index);
    }

    private onDeathDateChange = (date : moment.Moment) => {
        if (!this.props.onChange) return;

        this.props.onChange({
            ...this.props.grave,
            dateOfDeath: date.asUTC().toISOString(),
        }, this.props.index);
    }

    private onBurialDateChange = (date : moment.Moment) => {
        if (!this.props.onChange) return;

        this.props.onChange({
            ...this.props.grave,
            dateOfBurial: date.asUTC().toISOString(),
        }, this.props.index);
    }

    private onIdentifiedChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        if (!this.props.onChange) return;

        this.props.onChange({
            ...this.props.grave,
            identified: checked,
        }, this.props.index);
    }

    private readonly onFileSelected = (files : Array<IFileUpload>) => {
        if (!this.props.onChange) return;

        this.props.onChange({
            ...this.props.grave,
            file: !files.length ? undefined : files[0],
        }, this.props.index);
    }

    public readonly getFile = (props : IGraveFormProps) => props.grave.file;
    public readonly getNumber = (props : IGraveFormProps) => props.grave.number;
    private readonly getFiles = createSelector(
        [this.getFile],
        (file) => {
            if (file) return [file];
            return [];
        },
    );

    public render = () => {
        const { grave } = this.props;
        const files = this.getFiles(this.props);

        return (
        <div className='fdc flx1 p10 pb30'>
            <NumberWordDiv
                number={grave.number}
            >
                GRAVE
            </NumberWordDiv>
            <div className='fdr flx1'>
                <div className='fdc asc'>
                    <div className='h256 w256 m10'>
                        <ImageFileUpload
                            accept='image/*'
                            value={files}
                            onFileChange={this.onFileSelected}
                            single
                        />

                    </div>
                </div>
                <div className='fdc flx1'>
                    <div className='fdr'>
                        <div className='fdc flx1 mr5'>
                            <FormControl fullWidth>
                                <KeyboardDatePicker
                                    value={moment.utc(grave.registerDate).local()}
                                    onChange={this.onApplicationDateChange}
                                    format={'YYYY-MM-DD'}
                                    label='Date Of Application'
                                    id='registerDate'
                                    className={'TextField'}
                                    required
                                    margin={'normal'}
                                    error={!grave.registerDate}
                                    fullWidth
                                    maxDate={this.maxDate}
                                />
                                {
                                    !grave.dateOfDeath &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <div className='fdc flx1 ml5'>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete='off'
                                    id='applicantName'
                                    label='Applicant Name and Surname'
                                    value={grave.applicantName ?? null}
                                    onChange={this.onApplicantNameChange}
                                    margin='normal'
                                    className={'TextField'}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className='fdr'>
                        <div className='fdc flx1 mr5'>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete='off'
                                    id='applicantContact'
                                    label='Applicant Number'
                                    value={grave.applicantContact ?? null}
                                    onChange={this.onApplicantContactChange}
                                    margin='normal'
                                    className={'TextField'}
                                />
                            </FormControl>
                        </div>
                        <div className='fdc flx1 ml5'>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete='off'
                                    id='name'
                                    label='Name of deceased'
                                    value={grave.nameOfDeceased ?? null}
                                    onChange={this.onNameChange}
                                    margin='normal'
                                    className={'TextField'}
                                    required
                                    error={!grave.nameOfDeceased}
                                />
                                {
                                    !grave.nameOfDeceased &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                    </div>
                    <div className='fdr'>
                        <div className='fdc flx1 mr5'>
                            <FormControl fullWidth>
                                <KeyboardDatePicker
                                    value={moment.utc(grave.dateOfDeath).local()}
                                    onChange={this.onDeathDateChange}
                                    format={'YYYY-MM-DD'}
                                    label='Date Of Death'
                                    id='dateOfDeath'
                                    className={'TextField'}
                                    required
                                    margin={'normal'}
                                    error={!grave.dateOfDeath}
                                    fullWidth
                                    maxDate={this.maxDate}
                                />
                                {
                                    !grave.dateOfDeath &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <div className='fdc flx1 ml5'>
                            <FormControl fullWidth>
                                <KeyboardDatePicker
                                    value={moment.utc(grave.dateOfBurial).local()}
                                    onChange={this.onBurialDateChange}
                                    format={'YYYY-MM-DD'}
                                    label='Date Of Burial'
                                    id='dateOfBurial'
                                    className={'TextField'}
                                    required
                                    margin={'normal'}
                                    error={!grave.dateOfBurial}
                                    fullWidth
                                    maxDate={this.maxDate}
                                />
                                {
                                    !grave.dateOfBurial &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                    </div>
                    <div className='fdr'>
                        <div className='fdc flx1 mr5'>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={grave.identified ?? false}
                                        color='primary'
                                        onChange={this.onIdentifiedChange}/>
                                }
                                label={`Identified?`}/>
                        </div>
                        <div className='fdc flx1 ml5'>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}
