import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import lodash from 'lodash';
import { PROVINCES } from '../../../../appConstants';

interface IProvinceDropdownProps {
    id? : string;
    value? : string;

    onChange? : (value? : string) => void;

    required? : boolean;
    clearable? : boolean;
    autoFocus? : boolean;

    fullWidth? : boolean;
    disabled? : boolean;
}

interface IProvinceDropdownState {
}

export default class ProvinceDropdown extends React.Component<IProvinceDropdownProps, IProvinceDropdownState> {
    constructor(props : IProvinceDropdownProps) {
        super(props);

        this.state = {
        };
    }

    private onChange = (event : React.ChangeEvent<HTMLSelectElement>, value : {
        label : string,
        value : string,
    }) => {
        if (this.props.onChange) this.props.onChange(value.value);
    }

    private getValue = (props : IProvinceDropdownProps, state : IProvinceDropdownState) => props.value;
    private getRequired = (props : IProvinceDropdownProps, state : IProvinceDropdownState) => props.required;

    private getDropdown = createSelector([
        this.getRequired,
        this.getValue,
    ], (required, value) => {
        const result = lodash.map(PROVINCES, (n, k) => ({ label: `${n}`, value: n }));

        if (value && !result.some(x => x.value === value)) {
            result.push({
                label: value,
                value,
            });
        }

        return result;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getDropdown,
    ], (value, required, dropdown) => {
        if (value) {
            return {
                label: value,
                value,
            };
        }

        return null;
    });

    public render = () => {
        const { required,  fullWidth, disabled, id, clearable } = this.props;

        const dropdown = this.getDropdown(this.props, this.state);

        const value = this.getSelectedValue(this.props, this.state);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                <Autocomplete
                    disabled={disabled}
                    id={id}
                    options={dropdown}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required && !clearable}
                    renderInput={params => <TextField maxRows={1} error={required && !value} {...params} fullWidth={fullWidth}  label='Province' />}
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    }
}
