import React from 'react';
import { IRootState, RootAction } from '../../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MaterialTable from '../../custom/materialTable/Table';
import EditPowerSource from './dialog/Edit';
import { Transitions } from '../../custom/animations/Transitions';
import moment from 'moment';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { IPowerSource } from '../../../../@types/model/masterData/powerSource';
import MasterDataFunctions from '../../../../store/masterData/functions';

interface IPowerSourceProps {
    isLoading : boolean;
    powerSources : Array<IPowerSource>;
}

interface IPowerSourceState {
}

class PowerSourceListComponent extends React.Component<IPowerSourceProps, IPowerSourceState> {
    constructor(props : IPowerSourceProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount = () => {
        MasterDataFunctions.getPowerSources();
    }

    public onRefreshClick = () => {
        MasterDataFunctions.getPowerSources(true);
    }

    public getPowerSource = (state : IPowerSourceState, props : IPowerSourceProps) => props.powerSources;

    public getInitialCode = createSelector([
        this.getPowerSource,
    ],
        (powerSources) => {
            const num = lodash
            .chain(powerSources)
            .map(powerSource => powerSource.code)
            .map(code => parseInt(code?.substring(2)) ?? 0)
            .max()
            .value() + 1;

            return `PW${(!num ? 1 : num).toString().padStart(4, '0')}`;
        },
    );

    public render = () => {
        const {  } = this.state;
        const { isLoading, powerSources } = this.props;

        const initialCode = this.getInitialCode(this.state, this.props);

        return (
            <div className={'flx1 fdc p5'}>
                <Card className={'flx1 fdc'}>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            Power Sources
                        </Typography>
                        <IconButton color='primary' onClick={this.onRefreshClick}>
                            <Tooltip title='Refresh'>
                                <Icon>
                                    refresh
                                </Icon>
                            </Tooltip>
                        </IconButton>
                        <span className={'flx1'} />
                        <EditPowerSource initialCode={initialCode} transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading} />
                    </Toolbar>
                    <MaterialTable<IPowerSource>
                        id={'powerSourcesTable'}
                        data={powerSources}
                        isLoading={isLoading}
                        rowsPerPage={50}
                        columns={[
                            {
                                header: '',
                                width: '60px',
                                renderCell: row => (
                                    <div className='aic'>
                                        <EditPowerSource powerSource={row} transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading} />
                                    </div>
                                ),
                            },
                            {
                                header: 'Code',
                                field: 'code',
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Name',
                                field: 'name',
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Active',
                                field: 'isActive',
                                enableSort: true,
                                renderCell: row => (<Icon className={`${row.isActive ? 'cp' : 'cpr'}`}>{row.isActive ? 'check' : 'close' }</Icon>),
                            },
                            {
                                header: 'Created By',
                                field: 'createdByName',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                    <Tooltip title={moment.utc(row.createdOn).local().format('YYYY-MM-DD HH:mm')}>
                                        <span>{row.createdByName.toTitleCase()}</span>
                                    </Tooltip>);
                                },
                            },
                            {
                                header: 'Updated By',
                                field: 'createdByName',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                    <Tooltip title={moment.utc(row.updatedOn).local().format('YYYY-MM-DD HH:mm')}>
                                        <span>{row.updatedByName.toTitleCase()}</span>
                                    </Tooltip>);
                                },
                            },
                        ]}
                    />
                </Card>
        </div>);
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        powerSources: state.masterData.powerSources,
        isLoading: state.masterData.isLoadingPowerSource,
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => {
    return bindActionCreators({}, dispatch);
};

// tslint:disable-next-line: variable-name
const PowerSourceList = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PowerSourceListComponent);

export default PowerSourceList;
