import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, MobileStepper } from '@material-ui/core';
import { IFile } from '../../../../@types/model/base';

interface IFileViewDialogProps {
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    files : Array<IFile>;
}

interface IFileViewDialogState {
    open : boolean;
    currentIndex : number;

}

export default class FileViewDialog extends React.PureComponent<IFileViewDialogProps, IFileViewDialogState> {
    constructor(props : IFileViewDialogProps) {
        super(props);
        this.state = {
            open: false,
            currentIndex: 0,
        };
    }

    public onClick = () => {
        this.setState({
            open: true,
        });
    }

    public onClose = () => {
        this.setState({
            open: false,
        });
    }

    public onNextClick = () => {
        if (this.state.currentIndex !== this.props.files.length - 1) {
            const newIndex = this.state.currentIndex + 1;
            this.setState({
                currentIndex: -1,
            }, () => {
                this.setState({
                    currentIndex: newIndex,
                });
            });
        }
    }

    public onBackClick = () => {
        if (this.state.currentIndex !== 0) {
            const newIndex = this.state.currentIndex - 1;
            this.setState({
                currentIndex: -1,
            }, () => {
                this.setState({
                    currentIndex: newIndex,
                });
            });
        }
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, files } = this.props;
        const { open, currentIndex } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='View'>
                    <div>
                        <Button variant='text' color='primary' onClick={this.onClick} aria-label='tooltip'>
                            {
                                files.length
                            }
                        </Button>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='tooltip-dialog-title'
                    aria-describedby='tooltip-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc h100vh'>
                        <div className='fdc flx1'>
                            {
                                files.length && currentIndex < files.length && currentIndex > -1 && {
                                    0 : (<img title={files[currentIndex].fileName} alt={files[currentIndex].fileName} className='wfill hfill ofc' src={files[currentIndex].url} />),
                                    1 : (<video className='wfill hfill ' title={files[currentIndex].fileName} controls>
                                            <source src={files[currentIndex].url} />
                                        </video>),
                                    2 : (<audio className='p15' title={files[currentIndex].fileName} controls> <source src={files[currentIndex].url} /> </audio>),
                                    3 : (<div>Unknown</div>),
                                    4 : (
                                        <embed height='100%' width='100%' type='application/pdf' src={files[currentIndex].url} />
                                    ),
                                }[files[currentIndex].fileType]
                            }
                        </div>
                        <div>
                            <MobileStepper
                                steps={files.length}
                                position='static'
                                variant='text'
                                activeStep={currentIndex}
                                nextButton={
                                    <Button size='small' onClick={this.onNextClick} disabled={currentIndex === files.length - 1}>
                                        Next
                                    </Button>
                                }
                                backButton={
                                    <Button size='small' onClick={this.onBackClick} disabled={currentIndex === 0}>
                                        Back
                                    </Button>
                                }
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}
