import { Card, CardActionArea, CardContent, CardMedia, Icon, Typography } from '@material-ui/core';
import lodash from 'lodash';
import moment from 'moment';
import React from 'react';
import { createSelector } from 'reselect';
import { EnumGraveFileType, IGrave } from '../../../../@types/model/graveSite/grave';
import { IGraveFileList } from '../../../../@types/viewmodel/graveFileList';
import FileCarouselDialog from '../../custom/dialog/FileCarouselDialog';
import CrossSvg from '../../custom/svg/CrossSvg';

interface IGraveSiteImagesViewProps {
    graves : Array<IGrave>;
}

interface IGraveSiteImagesViewState {
    fileIndex? : number;
}

export default class GraveSiteImagesView extends React.PureComponent<IGraveSiteImagesViewProps, IGraveSiteImagesViewState> {
    constructor(props : IGraveSiteImagesViewProps) {
        super(props);
        this.state = {};
    }

    public readonly getGraves = (props : IGraveSiteImagesViewProps) => props.graves;

    public getFiles = createSelector([
        this.getGraves,
    ],
        (graves) => {
            return lodash
            .chain(graves)
            .flatMap(g => g.files.map<IGraveFileList>(file => ({
                file: file.file!,
                dateOfBurial: g.dateOfBurial,
                dateOfDeath: g.dateOfDeath,
                nameOfDeceased: g.nameOfDeceased,
                type: file.type,
            })))
            .filter(g => g.type === EnumGraveFileType.Image)
            .value();
        },
    );

    public onOpenFileCarousel = (index : number) => {
        if (this.props.graves) {
            this.setState({
                fileIndex: index,
            });
        }
    }

    public onFileCarouselClose = () => {
        this.setState({
            fileIndex: undefined,
        });
    }

    public render = () => {
        const files = this.getFiles(this.props);
        const { fileIndex } = this.state;
        return (
            <div className={'flx1 fdc bcg1 p30'}>
                <Typography className={'cp fs20 fwm'}>
                    Images of Graves:
                </Typography>
                <div className={'flx1 fww p15 fdr'}>
                    {
                        files.map((grave : IGraveFileList, index : number) => {
                            return (
                                    <Card key={`${index}_${grave.file.fileName}`} className={`fdc jcc aic m10 h250 w250`}>
                                        <CardActionArea onClick={() => this.onOpenFileCarousel(index)} className='container'>
                                            <CardMedia component='img' className={`h250 w250`} image={grave.file.thumbUrl} />
                                            <div className={'overlay posa bcg2 fdc aic jcc mxh250'}>
                                                <Typography className={'fwm fs22 cw pb10'}>
                                                    {grave.nameOfDeceased}
                                                </Typography>
                                                <div className='fdr'>
                                                    <Icon className={'aife jcc pr5 cw'}>
                                                        <CrossSvg className={'cfw'} height={15} />
                                                    </Icon>
                                                    <Typography className={'fwm fs22 cw'}>
                                                        Date of death
                                                    </Typography>
                                                </div>
                                                <Typography className={'fwm fs22 cw pb10'}>
                                                    {grave.dateOfDeath ? moment.utc(grave.dateOfDeath).format('DD/MM/yyyy') : 'Unknown'}
                                                </Typography>
                                                <div className='fdr'>
                                                    <Icon className={'aife jcc pr5 cw'}>
                                                        <img height={15} src={`${ASSET_BASE}/assets/images/icons/smallGrave.svg`}/>
                                                    </Icon>
                                                    <Typography className={'fwm fs22 cw'}>
                                                        Date of burial
                                                    </Typography>
                                                </div>
                                                <Typography className={'fwm fs22 cw pb10'}>
                                                    {grave.dateOfBurial ? moment.utc(grave.dateOfBurial).format('DD/MM/yyyy') : 'Unknown'}
                                                </Typography>
                                            </div>
                                        </CardActionArea>
                                    </Card>
                            );
                        })
                    }
                    <FileCarouselDialog fullWidth maxWidth='md' selectedIndex={fileIndex} files={files} open={fileIndex !== undefined} onClose={this.onFileCarouselClose} />
                </div>
            </div>
        );
    }
}
