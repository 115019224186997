import { IAccessPoint } from '../../../../@types/model/access';
import { Marker, InfoWindow } from '@react-google-maps/api';
import React from 'react';
import { ACCESS_POINT_TYPE_TO_ICON } from '../../../../appConstants';
import FileThumbnail from '../fileItem/FileThumbnail';
import FileCarouselDialog from '../dialog/FileCarouselDialog';
import Typography from '@material-ui/core/Typography';
import { createSelector } from 'reselect';

interface IAccessPointMarkerProps {
    accessPoint : IAccessPoint;
    onInfoClick? : (accessPoint? : IAccessPoint) => void;
    selected? : boolean;
}

interface IAccessPointMarkerState {
    showInfo : boolean;

    fileIndex? : number;
}

export default class AccessPointMarker extends React.Component<IAccessPointMarkerProps, IAccessPointMarkerState> {
    constructor(props : IAccessPointMarkerProps) {
        super(props);

        this.state = {
            showInfo : false,
        };
    }

    public componentDidUpdate = (prevProps : IAccessPointMarkerProps) => {
        if (prevProps.selected !== this.props.selected) {
            this.setState({
                showInfo: !!this.props.selected,
            });
        }
    }

    public onMarkerClick = (e : google.maps.MouseEvent) => {
        if (this.props.onInfoClick) {
            if (!this.state.showInfo) {
                this.props.onInfoClick(this.props.accessPoint);
            } else {
                this.props.onInfoClick();
            }
        }

        if (this.props.selected === undefined) {
            this.setState({
                showInfo: !this.state.showInfo,
            });
        }
    }

    public onCloseClick = () => {
        if (this.props.onInfoClick) {
            this.props.onInfoClick();
        }

        if (this.props.selected === undefined) {
            this.setState({
                showInfo: false,
            });
        }

    }

    public onOpenFileCarousel = (index : number) => {
        this.setState({
            fileIndex: index,
        });
    }

    public onFileCarouselClose = () => {
        this.setState({
            fileIndex: undefined,
        });
    }
    private getAccessPoint = (state : IAccessPointMarkerState, props : IAccessPointMarkerProps) => props.accessPoint;
    private getMarkerUrl = createSelector([
        this.getAccessPoint,
    ], (accessPoint) => {
        if (accessPoint.accessPointType === 1) {
            if (accessPoint.controlIdentifier) {
                return `${ASSET_BASE}/assets/images/icons/gate_remote_controlled.svg`;
            }

            if (accessPoint.isControlled) {
                return `${ASSET_BASE}/assets/images/icons/gate_controlled.svg`;
            }
        }

        return ACCESS_POINT_TYPE_TO_ICON[accessPoint.accessPointType];
    },
    );

    public render = () => {
        const { accessPoint } = this.props;
        const { showInfo, fileIndex } = this.state;

        const url = this.getMarkerUrl(this.state, this.props);

        return (
            <Marker
                title={accessPoint.name + ' - ' + accessPoint.code}
                clickable
                position={{
                    lat: accessPoint.geo.latitude,
                    lng: accessPoint.geo.longitude,
                }}
                icon={{
                    url,
                    scaledSize: new google.maps.Size(22, 22),
                    anchor: new google.maps.Point(11, 5),
                }}
                onClick={this.onMarkerClick}>
                {
                    showInfo &&
                    <InfoWindow
                        position={{
                            lat: accessPoint.geo.latitude,
                            lng: accessPoint.geo.longitude,
                        }}
                        onCloseClick={this.onCloseClick}>
                        <div style={{
                            background: `white`,
                            padding: 15,
                        }}>
                            <div className='fdc jcc aic'>
                                <Typography variant='h5' color='inherit'>
                                    {accessPoint.code} - {accessPoint.name}
                                </Typography>
                                {
                                    accessPoint.controlIdentifier &&
                                    <Typography variant='h5' color='inherit' className='mt5'>
                                        Remote Controlled
                                    </Typography>
                                }
                                {
                                    accessPoint.isControlled &&
                                    !accessPoint.controlIdentifier &&
                                    <Typography variant='h5' color='inherit' className='mt5'>
                                        Controlled
                                    </Typography>
                                }
                                {
                                    !accessPoint.isControlled &&
                                    !accessPoint.controlIdentifier &&
                                    <Typography variant='h5' color='inherit' className='mt5'>
                                        Not Controlled
                                    </Typography>
                                }
                                {
                                    !!accessPoint.files.length &&
                                    <div className='fdr flx1 jcc'>
                                        <FileThumbnail index={accessPoint.files.length - 1} file={accessPoint.files[accessPoint.files.length - 1].file} onOpenFileCarousel={this.onOpenFileCarousel} />
                                        <FileCarouselDialog fullWidth maxWidth='md' selectedIndex={fileIndex} files={accessPoint.files} open={fileIndex !== undefined} onClose={this.onFileCarouselClose} />
                                    </div>
                                }
                            </div>
                        </div>
                    </InfoWindow>
                }
            </Marker>
        );
    }
}
