import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { IIncidentDocketFile, IIncidentFile } from '../../../../@types/model/incident';
import { IObservationFile } from '../../../../@types/model/observation';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';
import { IAccessPointFile } from '../../../../@types/model/access';
import { IGraveSiteFile } from '../../../../@types/model/graveSite/graveSite';
import { IGraveFile } from '../../../../@types/model/graveSite/grave';
import { IGraveFileList } from '../../../../@types/viewmodel/graveFileList';

interface IFileCarouselDialogProps {
    open : boolean;
    files : Array<IIncidentFile | IObservationFile | IAccessPointFile | IGraveSiteFile | IGraveFile | IGraveFileList | IIncidentDocketFile>;
    selectedIndex? : number;
    onClose : () => void;
    onDelete ?: (index : number) => void;

    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;

    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
}

interface IFileCarouselDialogState {
    currentIndex : number;
}

export default class FileCarouselDialog extends React.Component<IFileCarouselDialogProps, IFileCarouselDialogState> {
    constructor(props : IFileCarouselDialogProps) {
        super(props);

        this.state = {
            currentIndex: 0,
        };
    }

    public componentDidUpdate = (prevProps : IFileCarouselDialogProps) => {
        if (prevProps.selectedIndex !== this.props.selectedIndex && this.props.selectedIndex !== undefined && this.props.open) {
            this.setState({
                currentIndex: this.props.selectedIndex,
            });
        } else if (!prevProps.open && this.props.open) {
            this.setState({
                currentIndex: 0,
            });
        }

        if (prevProps.open && !this.props.open) {
            this.setState({
                currentIndex: -1,
            });
        }
    }

    public onNextClick = () => {
        if (this.state.currentIndex !== this.props.files.length - 1) {
            const newIndex = this.state.currentIndex + 1;
            this.setState({
                currentIndex: -1,
            }, () => {
                this.setState({
                    currentIndex: newIndex,
                });
            });
        }
    }

    public onBackClick = () => {
        if (this.state.currentIndex !== 0) {
            const newIndex = this.state.currentIndex - 1;
            this.setState({
                currentIndex: -1,
            }, () => {
                this.setState({
                    currentIndex: newIndex,
                });
            });
        }
    }

    public onDeleteClick = () => {
        if (!this.props.onDelete) return;
        this.props.onDelete(this.state.currentIndex);
    }

    public render = () => {
        const { files, onClose, fullWidth, maxWidth, fullScreen, transition, open, onDelete } = this.props;
        const { currentIndex } = this.state;
        return (
            <Dialog
                className='fdc flx1'
                open={!!open}
                onClose={onClose}
                aria-labelledby='file-carousel-dialog-title'
                aria-describedby='file-carousel-description'
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                TransitionComponent={transition}
                transitionDuration={500}
                fullScreen={fullScreen}
                >
                    <div className='fdc flx1'>
                        <AppBar className='posr'>
                            <Toolbar>
                                {
                                    !!files.length && currentIndex < files.length && currentIndex > -1 &&
                                    <Typography variant='h6' color='inherit' className='flx1'>
                                        {files[currentIndex]?.file?.fileName}
                                    </Typography>
                                }
                                {
                                    onDelete
                                    && !!files.length
                                    && currentIndex < files.length
                                    && currentIndex > -1 &&
                                    <Tooltip title='Delete'>
                                        <IconButton color='inherit' aria-label='Delete' onClick={this.onDeleteClick}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {
                                    !!files.length
                                    && currentIndex < files.length
                                    && currentIndex > -1 &&
                                    <Tooltip title='Download'>
                                        <IconButton component='a' target='_blank' color='inherit'  href={files[currentIndex]?.file?.url} aria-label='Close'>
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                <Tooltip title='Close'>
                                    <IconButton color='inherit' onClick={onClose} aria-label='Close'>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </Toolbar>
                        </AppBar>
                        <div className='fdc aic oxh flx1'>
                            {
                                files.length && currentIndex < files.length && currentIndex > -1 && {
                                    0 : (
                                        <img
                                            title={files[currentIndex]?.file?.fileName}
                                            alt={files[currentIndex]?.file?.fileName}
                                            className='wfill'
                                            src={files[currentIndex]?.file?.url}
                                        />),
                                    1 : (
                                        <video
                                            className='wfill'
                                            title={files[currentIndex]?.file?.fileName}
                                            controls>
                                            <source src={files[currentIndex]?.file?.url}
                                        />
                                        </video>),
                                    2 : (
                                        <audio
                                            className='p15'
                                            title={files[currentIndex]?.file?.fileName}
                                            controls>
                                                <source src={files[currentIndex]?.file?.url} />
                                            </audio>),
                                    3 : (<div>Unknown</div>),
                                    4 : (
                                        <div className='h100vh wfill'>
                                            <embed
                                                height='99%'
                                                width='100%'
                                                type='application/pdf'
                                                src={files[currentIndex]?.file?.url} />
                                        </div>
                                    ),
                                }[files[currentIndex].file?.fileType ?? 0]
                            }
                        </div>
                        <div>
                            <MobileStepper
                                steps={files.length}
                                position='static'
                                variant='text'
                                activeStep={currentIndex}
                                nextButton={
                                    <Button size='small' onClick={this.onNextClick} disabled={currentIndex === files.length - 1}>
                                        Next
                                    </Button>
                                }
                                backButton={
                                    <Button size='small' onClick={this.onBackClick} disabled={currentIndex === 0}>
                                        Back
                                    </Button>
                                }
                            />
                        </div>
                    </div>
            </Dialog>
        );
    }
}
