import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { v4 } from 'uuid';
import DataFunctions from '../../../../store/data/functions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { IRegion } from '../../../../@types/model/region';
import MasterDataFunctions from '../../../../store/masterData/functions';

interface IEditRegionDialogProps {
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    region? : IRegion;
}

interface IEditRegionDialogState {
    open : boolean;
    code : string;
    name : string;
    guid : string;
    isActive : boolean;
}

export default class EditRegionDialog extends React.Component<IEditRegionDialogProps, IEditRegionDialogState> {
    constructor(props : IEditRegionDialogProps) {
        super(props);
        this.state = {
            open: false,
            code: '',
            name: '',
            guid: v4(),
            isActive: true,
        };
    }

    public componentDidUpdate = (prevProps : IEditRegionDialogProps, prevState : IEditRegionDialogState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                code: '',
                name: '',
                guid: v4(),
                isActive: true,
            });
        }

        if (!prevState.open && this.state.open && this.props.region) {
            this.setState({
                code: this.props.region.code,
                name: this.props.region.name,
                guid: !this.props.region.guid ? v4() : this.props.region.guid,
                isActive: this.props.region.isActive,
            });
        }
    }

    private save = async () => {
        const { code, name, isActive } = this.state;

        if (!code) return;
        if (!name) return;

        const region = Object.assign({}, this.props.region);
        if (region) {
            region.code = code;
            region.name = name;
            region.isActive = isActive;

            if (!region.guid) region.guid = this.state.guid;

            const result = await MasterDataFunctions.saveRegion(region);

            if (result) {
                this.onClose();
            }
        }
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        this.save();
    }

    private onEditClick = () => {
        this.setState({
            open: true,
        });
    }

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private onCodeChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            code: event.currentTarget.value,
        });
    }

    private onNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: event.currentTarget.value,
        });
    }

    private onIsActiveChanged = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            isActive: checked,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, region } = this.props;
        const { open, code, name, isActive } = this.state;

        return (
            <React.Fragment>
                <Tooltip title={!region ? 'Add' : 'Edit'}>
                    <div>
                        <IconButton color='primary' onClick={this.onEditClick} aria-label={!region ? 'Add' : 'Edit'} disabled={isLoading}>
                            <Icon>{!region ? 'add' : 'edit'}</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='region-category-edit-dialog-title'
                    aria-describedby='region-category-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                {!region ? 'New' : 'Edit'} Region - {!this.props.region ? code : this.props.region.code}
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='code'
                                            label='Code'
                                            value={code}
                                            onChange={this.onCodeChange}
                                            margin='normal'
                                            className={'TextField'}
                                            required
                                            error={!!!code}
                                        />
                                        {
                                            !!!code &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='name'
                                            label='Name'
                                            value={name}
                                            onChange={this.onNameChange}
                                            margin='normal'
                                            className={'TextField'}
                                            required
                                            error={!!!name}
                                        />
                                        {
                                            !!!name &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl margin='normal' style={{ marginTop: '16px' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox color='primary' checked={isActive} onChange={this.onIsActiveChanged}  />
                                            }
                                            label='IsActive?'
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>);
    }
}
