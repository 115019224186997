import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { createSelector } from 'reselect';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { vehicleOwnershipType } from '../../../../@types/model/incident';

interface IVehicleOwnershipDropdownProps {
    id? : string;
    value : vehicleOwnershipType | null;

    onChange : (value? : vehicleOwnershipType) => void;

    required? : boolean;
    clearable? : boolean;
    autoFocus? : boolean;

    fullWidth? : boolean;
    disabled? : boolean;
}

interface IVehicleOwnershipDropdownState {
}

export default class VehicleOwnershipDropdown extends React.Component<IVehicleOwnershipDropdownProps, IVehicleOwnershipDropdownState> {
    constructor(props : IVehicleOwnershipDropdownProps) {
        super(props);

        this.state = {
        };
    }

    private onChanged = (event : React.ChangeEvent<HTMLSelectElement>, value : {
        label : string,
        value : vehicleOwnershipType,
    }) => {
        this.props.onChange(value?.value);
    }

    private getValue = (state : IVehicleOwnershipDropdownState, props : IVehicleOwnershipDropdownProps) => props.value;
    private getRequired = (state : IVehicleOwnershipDropdownState, props : IVehicleOwnershipDropdownProps) => props.required;

    private getDropdown = createSelector([
        this.getRequired,
    ], (required) => {
        const drop = [{
            label: 'Private',
            value: 'Private',
        },            {
            label: 'Company',
            value: 'Company',
        }];

        if (!required) {
            drop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return drop;
    });

    private getSelectedValue = createSelector([
        this.getValue,
        this.getRequired,
    ], (value, required) => {
        if (value) {
            return {
                label: value,
                value,
            };
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public render = () => {
        const {  } = this.state;
        const { required, autoFocus, clearable, fullWidth, id, disabled } = this.props;
        const regions = this.getDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                <Autocomplete
                    id={id}
                    disabled={disabled}
                    options={regions}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChanged}
                    disableClearable={required && !clearable}
                    openOnFocus
                    renderInput={params => <TextField autoFocus={autoFocus}  error={required && !value} {...params} fullWidth={fullWidth} label='Vehicle Ownership' />}
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    }
}
