import { createAction } from 'typesafe-actions';
import { IOccurrence } from '../../@types/model/occurrence';

const occurrenceActions = {
    setOccurrences: createAction('DATA_SET_OCCURENCES', resolve => (occurrences : Array<IOccurrence>) => resolve(occurrences)),
    setOccurrence: createAction('DATA_SET_OCCURENCE', resolve => (occurrence? : IOccurrence) => resolve(occurrence)),
    setLoadingOccurrences: createAction('DATA_SET_OCCURENCES_LOADING', resolve => (isLoading : boolean) => resolve(isLoading)),
};

export default occurrenceActions;
