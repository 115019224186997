import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import { Transitions } from '../animations/Transitions';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IFileQueue } from '../../../../@types/viewmodel/file';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

interface IFileUploadProgressIndicatorProps {
    progress : number;
    files : Array<IFileQueue>;
    current? : string;
}

interface IFileUploadProgressIndicatorState {
    open : boolean;
}

export default class FileUploadProgressIndicator extends React.Component<IFileUploadProgressIndicatorProps, IFileUploadProgressIndicatorState> {
    constructor(props : IFileUploadProgressIndicatorProps) {
        super(props);
        this.state = {
            open : false,
        };
    }

    private onFabClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.setState({
            open: !this.state.open,
        });
    }

    private onClose = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.setState({
            open: false,
        });
    }

    public render = () => {
        const { progress, files, current } = this.props;
        const { open } = this.state;
        return (
            <React.Fragment>
                {
                    files.some(n => !n.completed) &&
                    <div className={'posa post80 posr80 aic jcc'}>
                        <div className={'posa aic jcc zi2500'}>
                            <Fab
                                onClick={this.onFabClick}
                                aria-label='file-upload'
                                size='medium'
                                className={files.some(n => n.error) ? 'bcpr' : 'bcy'}
                                color='primary'>
                                <CloudUploadIcon className='cp'  />
                            </Fab>
                        </div>
                        <CircularProgress variant='indeterminate' value={progress} color={'primary'} className={'posa zi2000'} size={60} />
                    </div>
                }
                <Dialog
                    className='fdc flx1'
                    open={open}
                    aria-labelledby='file-upload-dialog-title'
                    aria-describedby='file-upload-description'
                    maxWidth='md'
                    fullWidth
                    TransitionComponent={Transitions.Up}
                    transitionDuration={500}
                    fullScreen={false}
                    >
                    <AppBar position='static'>
                        <Toolbar>
                            <Typography variant='h6' color='inherit' className='flx1'>
                                Uploading Files
                            </Typography>
                            <Tooltip title='Close'>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdr flx1'>
                        <div className='fdc flx1'>
                            {
                                files.map(n => (
                                    <div key={`${n.guid}_file`} className='fdr flx1 h50 aic'>
                                        <span className='w400'>{n.file.name}{!n.duration ? '' : ` - ${n.duration} sec`}</span>
                                        {
                                            n.guid === current &&
                                            <LinearProgress className='flx1 mr5' variant='buffer' value={progress} valueBuffer={100} />
                                        }
                                        {
                                            n.guid === current && progress === 100 &&
                                            <CircularProgress />
                                        }
                                        {
                                            n.completed && !n.error &&
                                            <CheckIcon color='primary' />
                                        }
                                        {
                                            !n.completed && n.error &&
                                            <CloseIcon color='error' />
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}
