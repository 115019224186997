import React from 'react';
import { createSelector } from 'reselect';
import { IAuthState } from '../../../store/auth/reducer';
import { EnumRightType, IUserRight } from '../../../@types/model/right';
import NavMenuListItem from '../../component/custom/listItem/NavMenuListItem';
import { RIGHTS, RIGHT_TO_ICON } from '../../../appConstants';
import NavMenuItem from '../../component/custom/listItem/NavMenuItem';

interface IRightsNavMenuProps {
    auth : IAuthState;
    onClick : (url : string) => void;
}

interface IRightsNavMenuState {}

export default class RightsNavMenu extends React.PureComponent<IRightsNavMenuProps, IRightsNavMenuState> {
    constructor(props : IRightsNavMenuProps) {
        super(props);
        this.state = {};
    }
    public getUserRights = (props : IRightsNavMenuProps) => props.auth.session?.user.userRights ?? [];
    public readonly getRights = createSelector(
        [this.getUserRights],
        (userRights : Array<IUserRight>) => {
            return userRights.find(n => n.rightId === RIGHTS.RIGHTS && n.isActive);
        },
    );
    public getRightsRights = createSelector(
        [this.getUserRights],
        (userRights : Array<IUserRight>) => {
            return userRights
                .filter(x => x.right?.type === EnumRightType.Right
                    && x.right.parentId
                    && x.right.isMenu
                    && x.right.parentId
                    && x.isActive)
                .sort((a, b) => a.right?.name.localeCompare(b.right?.name ?? '') ?? 0);
        },
    );
    public readonly getUserRight = createSelector(
        [this.getUserRights],
        (userRights : Array<IUserRight>) => {
            return userRights.find(n => n.rightId === RIGHTS.USER && n.isActive);
        },
    );

    public render = () => {
        const {
            onClick,
        } = this.props;

        const rights = this.getRights(this.props);
        const rightRights = this.getRightsRights(this.props);
        const userRight = this.getUserRight(this.props);

        return (
            !!rights &&
            <NavMenuListItem key={`rights_nav_header`} icon={`${ASSET_BASE}/assets/images/icons/rights.svg`}
                imgProps={{
                    className: 'h30 w30',
                }}
                title={'Rights'}>
                    {
                        userRight &&
                        <NavMenuItem userRight={userRight} key={`${userRight.right?.name}_Nav`} imgProps={{
                            className: 'h30 w30',
                        }} onClick={onClick} icon={RIGHT_TO_ICON[userRight.right?.code ?? '']} />
                    }
                    {
                        rightRights.map(o => (
                            <NavMenuItem userRight={o} key={`${o.right?.name}_Nav`} imgProps={{
                                className: 'h30 w30',
                            }} onClick={onClick} icon={RIGHT_TO_ICON[o.right?.code ?? '']} />
                        ))
                    }
            </NavMenuListItem>
        );
    }
}
