import { createAction } from 'typesafe-actions';
import { IObservation } from '../../@types/model/observation';

const observationActions = {
    setObservations: createAction('DATA_SET_OBSERVATIONS', resolve => (observations : Array<IObservation>) => resolve(observations)),
    setObservation: createAction('DATA_SET_OBSERVATION', resolve => (observation? : IObservation) => resolve(observation)),
    setLoadingObservations: createAction('DATA_SET_OBSERVATIONS_LOADING', resolve => (isLoading : boolean) => resolve(isLoading)),
};

export default observationActions;
