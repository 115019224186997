import React from 'react';
import Dropzone from 'react-dropzone';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { IFile } from '../../../@types/model/base';
import FileThumbnail from './fileItem/FileThumbnail';

interface IFileDropZoneProps {
    file? : IFile;

    onFileSelected : (file : File) => void;
}

interface IFileDropZoneState {
    selectedFile? : string;
}

export default class FileDropZone extends React.Component<IFileDropZoneProps, IFileDropZoneState> {
    constructor(props : IFileDropZoneProps) {
        super(props);
        this.state = {
        };
    }

    private onFileDrop = (acceptedFiles : Array<File>) => {
        this.setState({
            selectedFile: URL.createObjectURL(acceptedFiles[0]),
        });

        this.props.onFileSelected(acceptedFiles[0]);
    }

    public render = () => {
        const { file } = this.props;
        const { selectedFile } = this.state;
        return (
            <React.Fragment>
                <Dropzone onDrop={this.onFileDrop} accept='image/*'>
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Card className={`fdc flx1 h220`}>
                                    {
                                        selectedFile &&
                                        <CardMedia className='flx1' image={selectedFile}>
                                        </CardMedia>
                                    }
                                    {
                                        !selectedFile && file && file.thumbUrl &&
                                        <div className={'fdc jcc asc flx1'}>
                                            <FileThumbnail file={file} />
                                        </div>
                                    }
                                </Card>
                                <div>
                                    <Typography variant='body2'>
                                        Drag 'n' drop some files here, or click to select files
                                    </Typography>
                                </div>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </React.Fragment>
        );
    }
}
